import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import * as parse from 'date-fns/parse';
import * as format from 'date-fns/format';
import * as differenceInDays from 'date-fns/differenceInDays';
import * as startOfMonth from 'date-fns/startOfMonth';
import * as endOfMonth from 'date-fns/endOfMonth';
import * as startOfYear from 'date-fns/startOfYear';
import * as endOfYear from 'date-fns/endOfYear';
import * as toDate from 'date-fns/toDate';
import * as min from 'date-fns/min';
import { DATE_FORMAT_BACKEND } from '../constants';

export const DATE_FORMAT_DISPLAY = 'DD.MM.YYYY';
export const DATETIME_FORMAT_DISPLAY = 'DD.MM.YYYY HH:mm:ss';

@Injectable()
export class DateService {
    backendDateFormat: string = DATE_FORMAT_BACKEND;
    displayDateFormat: string = DATE_FORMAT_DISPLAY;
    displayDateTimeFormat: string = DATETIME_FORMAT_DISPLAY;

    fromDisplayToBackendFormat(date: string | Date): string {
        return format(
            date instanceof Date ? date : this.fromDisplayFormatToDate(date),
            this.backendDateFormat
        );
    }

    fromBackendToDisplayFormat(date: string | Date): string {
        return format(
            date instanceof Date ? date : this.fromBackendFormatToDate(date),
            this.displayDateFormat
        );
    }

    fromDisplayFormatToDate(date: string): Date {
        return parse(date, this.displayDateFormat, new Date());
    }

    fromBackendFormatToDate(date: string): Date {
        return parse(date, this.backendDateFormat, new Date());
    }

    formatDateForBackend(date: any) {
        return !isEmpty(date) ? format(toDate(date), this.backendDateFormat) : null;
    }

    getCurrentMonth() {
        return ('0' + (new Date().getMonth() + 1)).slice(-2);
    }

    getCurrentDateForDisplay() {
        return format(new Date(), this.displayDateFormat);
    }

    getCurrentDateForBackend() {
        return format(new Date(), this.backendDateFormat);
    }

    getLowerDate(dates: string[]) {
        const nonEmptyDates: any[] = dates.filter(d => !isEmpty(d));

        if (nonEmptyDates.length > 0) {
            return format(min(nonEmptyDates), this.displayDateFormat);
        } else {
            return null;
        }
    }

    countDaysBetween(date1: Date, date2: Date) {
        return differenceInDays(date2, date1);
    }

    getFirstDayOfCurrentMonth() {
        return format(startOfMonth(new Date()), this.backendDateFormat);
    }

    isFullMonth(startDate: string | 'YYYY-MM-DD', endDate: string | 'YYYY-MM-DD') {
        if (!startDate || !endDate) return false;

        const monthStart = format(
            startOfMonth(this.fromBackendFormatToDate(startDate)),
            this.backendDateFormat
        );
        const monthEnd = format(
            endOfMonth(this.fromBackendFormatToDate(endDate)),
            this.backendDateFormat
        );

        return startDate === monthStart && endDate === monthEnd;
    }

    isFullYear(startDate: string | 'YYYY-MM-DD', endDate: string | 'YYYY-MM-DD') {
        if (!startDate || !endDate) return false;

        const yearStart = format(
            startOfYear(this.fromBackendFormatToDate(startDate)),
            this.backendDateFormat
        );
        const yearEnd = format(
            endOfYear(this.fromBackendFormatToDate(endDate)),
            this.backendDateFormat
        );

        return startDate === yearStart && endDate === yearEnd;
    }
}
