import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { EventRouter, RoutingEvent } from '@zenhomes/frontend-routing';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';
import { environment } from 'environments/environment';
import { ProductSlug } from '../../types';

@Component({
    selector: 'zhm-product-teaser-small',
    templateUrl: './product-teaser-small.component.html',
    styleUrls: ['./product-teaser-small.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTeaserSmallComponent implements OnInit {
    @Input() teaserTitle: string;
    @Input() subtitle: string;
    @Input() description: string;
    @Input() buttonLabel: string;
    @Input() productSlug: ProductSlug;
    @Input() teaserName: string;
    @Input() imageUrl: string;
    @Input() target: string = '_self';
    @Input() redirectToShop: boolean = true;

    @Output() onTeaserClick = new EventEmitter<Event>();

    ngOnInit() {}

    openNewTabToScoutEnergyCertificate() {
        const url = `${environment.APP.IS24_HOST}/energieausweis.html#start`;

        IFrameHustla.sendMessage({
            type: 'CHILD_PARENT_REDIRECTION_REQUESTED',
            target: '__blank',
            url
        });
    }

    onClick(event: Event) {
        this.onTeaserClick.emit();
        event.stopPropagation();

        if (this.productSlug === ProductSlug.EnergyCertificate) {
            this.openNewTabToScoutEnergyCertificate();
        } else {
            EventRouter.dispatch(RoutingEvent.ShopProductOpened, {
                slug: this.productSlug,
                teaser: this.teaserName
            });
        }
    }
}
