import { PaymentCoreState } from '@payment-core/interfaces';
import { FILTER_AMOUNT_MAX, FILTER_AMOUNT_MIN } from '@zenhomes/filter-core';

export const initialPaymentCoreStoreState: PaymentCoreState = {
    payments: [],
    pagination: null,
    paymentsBeingLoaded: false,
    payment: null,
    paymentsSelected: [],
    paymentWaitingForBackend: false,
    transferCounterparties: [],
    transferCounterpartiesBeingLoaded: false,
    transferCounterpartiesAreLoaded: false,
    shouldLoadMissingPayments: false,
    filterOptions: {
        bankAccountId: null,
        bankAccountIbanHash: null,
        bankAccountNumberHash: null,
        query: null,
        startDate: null,
        endDate: null,
        matchingStatus: [],
        transactionType: [],
        counterpartySelection: [],
        amountFrom: FILTER_AMOUNT_MIN,
        amountTo: FILTER_AMOUNT_MAX,
        listView: false
    },
    uncategorizedPaymentStatsIsLoaded: false,
    uncategorizedPaymentStats: {
        totalUncategorizedPayments: 0,
        totalCategorizedPayments: 0,
        totalSnoozedPayments: 0,
        totalIgnoredPayments: 0,
        totalUncategorizedSinglePayments: 0,
        totalUncategorizedClusteredPayments: 0,
        bankAccountPaymentsStatistics: []
    }
};
