import { Action } from '@ngrx/store';
import { IPropertiesEndpointResponse } from '../../interfaces';

export const propertiesActionTypes = {
    LOAD_PROPERTIES: '[Properties] Load properties',
    LOAD_PROPERTIES_SUCCESS: '[Properties] Load properties success',
    LOAD_PROPERTIES_FAIL: '[Properties] Load properties fail',
    RELOAD_PROPERTIES: '[Properties] Reload properties',
    RESET_PROPERTIES: '[Properties] Reset'
};

export class LoadProperties implements Action {
    readonly type = propertiesActionTypes.LOAD_PROPERTIES;
}

export class LoadPropertiesSuccess implements Action {
    readonly type = propertiesActionTypes.LOAD_PROPERTIES_SUCCESS;

    constructor(public payload: IPropertiesEndpointResponse) {}
}

export class LoadPropertiesFail implements Action {
    readonly type = propertiesActionTypes.LOAD_PROPERTIES_FAIL;
}

export class ReloadProperties implements Action {
    readonly type = propertiesActionTypes.RELOAD_PROPERTIES;
}

export class Reset implements Action {
    readonly type = propertiesActionTypes.RESET_PROPERTIES;
}
