export const TEMPLATE_OWNR = 'SYSTEM';
export const UPLOADING_FILES_NUMBER = 5;

export const DOCUMENT_CATEGORY_ID_RENTAL_DOCS = '100';
export const DOCUMENT_CATEGORY_ID_ACCOUNTING = '300';
export const DOCUMENT_CATEGORY_ID_EXPOSE = '500';
export const DOCUMENT_CATEGORY_ID_INVESTMENT = '600';
export const DOCUMENT_CATEGORY_ID_OWN_TEMPLATES = '050';

export const DOCUMENT_TYPE_ID_EXPOSE_FLOORPLAN = '1018';
export const DOCUMENT_TYPE_ID_EXPOSE_PHOTO = '1020';
export const DOCUMENT_TYPE_ID_EXPOSE_CERTIFICATE = '1028';
export const DOCUMENT_TYPE_ID_ACCOUNTING_CONTRACT = '1014';
export const DOCUMENT_TYPE_ID_ACCOUNTING_RECEIPT = '1023';
export const DOCUMENT_TYPE_ID_OWN_TEMPLATES_OTHER = '1026';
export const DOCUMENT_TYPE_ID_OWN_TEMPLATES_LOGO = '1032';
