import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesService } from './services/messages.service';
import { MessagesComponent } from './components/messages/messages.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [MessagesComponent],
    imports: [CommonModule, TranslateModule],
    exports: [MessagesComponent],
    providers: []
})
export class ToastMessagesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ToastMessagesModule,
            providers: [MessagesService]
        };
    }
}
