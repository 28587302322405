import { IAccountingCategory, IAccountingMainCategory } from './accounting-categories.interface';

export interface ICategorizationUserTopic {
    id: string;
    title: string;
    relevance: number;
    userCategories: ICategorizationUserCategory[];
}

export interface ICategorizationUserCategory {
    id: string;
    title: string;
    type: ICategorizationType;
    relevance: number;
    subjects: ICategorizationSubject[];
}

export interface ICategorizationSubject {
    id: string;
    title: string;
    synonym?: string;
    type: ICategorizationType;
    relevance: number;
    allocatable?: boolean;
    accountingMainCategory: IAccountingMainCategory;
    accountingCategory: IAccountingCategory;
}

export enum ICategorizationType {
    Income = 'EXPENSE',
    Expense = 'INCOME'
}
