import { Action } from '@ngrx/store';

import { type } from '@util/type-cache.util';
import {
    IContentNavigationSelection,
    INavigationParams,
    IContentNavigationParams,
    IPropertyNavigationSelection
} from '../../interfaces';
import {
    IUnitModel,
    IBuildingModel,
    IPropertyNavigationParams,
    IProperty
} from '@zenhomes/domain/property';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */
export const propertyNavigationActionTypes = {
    PROPERTY_NAVIGATION_SELECT_CONTENT: type('[Property Navigation] Select content'),
    PROPERTY_NAVIGATION_SELECT_PROPERTY: type('[Property Navigation] Select property'),
    PROPERTY_NAVIGATION_SELECT_BOTH: type('[Property Navigation] Select both'),
    PROPERTY_NAVIGATION_NAVIGATE_TO_PROPERTY: type('[Property Navigation] Navigate to property'),
    PROPERTY_NAVIGATION_SELECT_CONTENT_SELECTION_ITEM: type(
        '[Property Navigation] Select content selection item'
    ),
    PROPERTY_NAVIGATION_SELECT_PROPERTY_NAVIGATION_ITEM: type(
        '[Property Navigation] Select property navigation item'
    ),
    PROPERTY_NAVIGATION_SELECT_BOTH_SELECTION_ITEMS: type(
        '[Property Navigation] Select both selection item'
    ),
    PROPERTY_NAVIGATION_DETERMINE_NAVIGATION_ITEMS: type(
        '[Property Navigation] Determine navigation items'
    ),
    PROPERTY_NAVIGATION_UPDATE_NAVIGATION_ITEMS: type(
        '[Property Navigation] Select navigation items'
    ),
    PROPERTY_NAVIGATION_SELECT_PREVIOUS_PROPERTY_NAVIGATION_ITEM: type(
        '[Property Navigation] Select previous property navigation item'
    ),
    PROPERTY_NAVIGATION_SELECT_NEXT_PROPERTY_NAVIGATION_ITEM: type(
        '[Property Navigation] Select next property navigation items'
    ),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING: type(
        '[Property Navigation] Reload selected building'
    ),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING_SUCCESS: type(
        '[Property Navigation] Reload selected building success'
    ),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING_FAIL: type(
        '[Property Navigation] Reload selected building fail'
    ),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT: type('[Property Navigation] Reload selected unit'),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT_SUCCESS: type(
        '[Property Navigation] Reload selected unit success'
    ),
    PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT_FAIL: type(
        '[Property Navigation] Reload selected unit fail'
    ),
    PROPERTY_NAVIGATION_RESET: type('[Property Navigation] Reset')
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class PropertyNavigationSelectContent implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_CONTENT;

    constructor(public payload: IContentNavigationParams) {}
}

export class PropertyNavigationSelectProperty implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PROPERTY;

    constructor(public payload: IPropertyNavigationParams) {}
}

export class PropertyNavigationSelectBoth implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_BOTH;

    constructor(public payload: INavigationParams) {}
}

export class PropertyNavigationNavigateToProperty implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_NAVIGATE_TO_PROPERTY;

    constructor(public property: IProperty) {}
}

export class PropertyNavigationSelectContentNavigationItem implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_CONTENT_SELECTION_ITEM;

    constructor(public payload: IContentNavigationSelection) {}
}

export class PropertyNavigationSelectPropertyNavigationItem implements Action {
    readonly type =
        propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PROPERTY_NAVIGATION_ITEM;

    constructor(public payload: IPropertyNavigationSelection) {}
}

export class PropertyNavigationSelectBothNavigationItems implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_BOTH_SELECTION_ITEMS;

    constructor(public payload: IContentNavigationSelection & IPropertyNavigationSelection) {}
}

export class PropertyDetermineNavigationItems implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_DETERMINE_NAVIGATION_ITEMS;

    constructor(public payload: INavigationParams) {}
}

export class PropertySelectNavigationItems implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_UPDATE_NAVIGATION_ITEMS;

    constructor(public payload: IContentNavigationSelection & IPropertyNavigationSelection) {}
}

export class PropertyNavigationSelectPreviousPropertyNavigationItem implements Action {
    readonly type =
        propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PREVIOUS_PROPERTY_NAVIGATION_ITEM;
}

export class PropertyNavigationSelectNextPropertyNavigationItem implements Action {
    readonly type =
        propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_NEXT_PROPERTY_NAVIGATION_ITEM;
}

export class PropertyNavigationReloadSelectedBuildingAction implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING;
}

export class PropertyNavigationReloadSelectedBuildingSuccessAction implements Action {
    readonly type =
        propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING_SUCCESS;

    constructor(public payload: IBuildingModel) {}
}

export class PropertyNavigationReloadSelectedBuildingFailAction implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING_FAIL;
}

export class PropertyNavigationReloadSelectedUnitAction implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT;
}

export class PropertyNavigationReloadSelectedUnitSuccessAction implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT_SUCCESS;

    constructor(public payload: IUnitModel) {}
}

export class PropertyNavigationReloadSelectedUnitFailAction implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT_FAIL;
}

export class PropertyNavigationReset implements Action {
    readonly type = propertyNavigationActionTypes.PROPERTY_NAVIGATION_RESET;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type PropertyNavigationActions = [
    PropertyNavigationSelectContentNavigationItem,
    PropertyNavigationSelectPropertyNavigationItem,
    PropertyDetermineNavigationItems,
    PropertySelectNavigationItems,
    PropertyNavigationSelectPreviousPropertyNavigationItem,
    PropertyNavigationSelectNextPropertyNavigationItem,
    PropertyNavigationReloadSelectedBuildingAction,
    PropertyNavigationReloadSelectedBuildingSuccessAction,
    PropertyNavigationReloadSelectedBuildingFailAction,
    PropertyNavigationReloadSelectedUnitAction,
    PropertyNavigationReloadSelectedUnitSuccessAction,
    PropertyNavigationReloadSelectedUnitFailAction,
    PropertyNavigationReset
];
