import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { catchError, mapTo, mergeMap, switchMap, tap, debounceTime } from 'rxjs/operators';
import { IPropertiesEndpointResponse } from '../../interfaces';
import { PropertiesApiService } from '../../services/properties.api.service';
import * as actions from '../actions/properties.actions';
import { of } from 'rxjs';

const RELOAD_PROPERTIES_DEBOUNCE = 100;

@Injectable()
export class PropertiesEffects {
    constructor(private actions$: Actions, private propertiesApiService: PropertiesApiService) {}

    @Effect()
    loadProperties$: Observable<Action> = this.actions$.pipe(
        ofType(actions.propertiesActionTypes.LOAD_PROPERTIES),
        switchMap(() => {
            return this.propertiesApiService.loadProperties().pipe(
                mergeMap((response: IPropertiesEndpointResponse) => {
                    return [new actions.LoadPropertiesSuccess(response)];
                }),
                catchError(() => of(new actions.LoadPropertiesFail()))
            );
        })
    );

    @Effect()
    reloadProperties$: Observable<Action> = this.actions$.pipe(
        ofType(actions.propertiesActionTypes.RELOAD_PROPERTIES),
        debounceTime(RELOAD_PROPERTIES_DEBOUNCE),
        mapTo(new actions.LoadProperties())
    );
}
