import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDocument } from '@zenhomes/domain/document';
import { JournalViewState } from '../state/constants/journal-view.constants';
import * as fromState from '../state';
import * as actions from '../state/actions/journal-view-documents.actions';
import { Observable } from 'rxjs/Observable';

@Injectable({
    providedIn: 'root'
})
export class JournalViewDocumentsService {
    expandedInvoiceDocuments$: Observable<IDocument[]> = this.store.select(
        fromState.getDocumentsForExpandedInvoice
    );

    constructor(private store: Store<JournalViewState>) {}

    deleteDocument(document: IDocument) {
        this.store.dispatch(new actions.DeleteDocument(document));
    }
}
