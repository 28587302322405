import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-explore-card',
    templateUrl: './explore-card.component.html',
    styleUrls: ['./explore-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreCardComponent {
    @Input() subtitle: string;
    @Input() bodyText: string;
    @Input() image: string;
    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    handleClick() {
        this.onClick.emit();
    }
}
