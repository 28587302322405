import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'zhm-progress-bar-small',
    templateUrl: './progress-bar-small.component.html',
    styleUrls: ['./progress-bar-small.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarSmallComponent implements OnInit {
    @Input() currentValue: number;
    @Input() maxValue: number;
    @Input() minValue: number;

    constructor() {}

    ngOnInit() {}
}
