import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'zhm-documents-drop-section-placeholder',
    templateUrl: './documents-drop-section-placeholder.component.html',
    styleUrls: ['./documents-drop-section-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsDropSectionPlaceholderComponent implements OnInit {
    @Input() isFileOver: boolean;
    @Input() labelDesktop: string;
    @Input() labelMobile: string;
    @Input() classNames?: string;

    constructor() {}

    ngOnInit() {}

    get classes() {
        return `drop-section flex flex-column justify-content-around align-items-center p-2 pointer ${
            this.classNames ? `${this.classNames}` : ``
        } ${this.isFileOver ? `file-over` : ``}`;
    }
}
