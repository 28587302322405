import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { IDocument } from '@zenhomes/domain/document';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

@Component({
    selector: 'zhm-floor-plans-attachments',
    templateUrl: './floor-plans-attachments.component.html',
    styleUrls: ['./floor-plans-attachments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorPlansAttachmentsComponent implements OnInit {
    @Input() floorPlans: IDocument[];

    ngOnInit() {}

    trackById(index: number, item: any) {
        return item.id;
    }

    thumbnailClicked(url: string) {
        IFrameHustla.sendMessage({
            type: 'CHILD_PARENT_REDIRECTION_REQUESTED',
            url,
            target: '_blank'
        });
    }
}
