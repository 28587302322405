import { all, compose, propEq, allPass, prop, isEmpty, complement } from 'ramda';
import { EXPENSE_SUB_ID_LOAN_INSTALLMENT } from '@zenhomes/domain/category';
import { IExpenseContract } from '@zenhomes/domain/contract';
import { ExpenseContractType } from '../../../modules/interfaces';

function isExpenseOrLoanContractType(contract: any): contract is IExpenseContract {
    return (
        !!contract &&
        (contract.type === ExpenseContractType.ExpenseContract ||
            contract.type === ExpenseContractType.LoanContract)
    );
}

const allPeriodsHaveOnlyLoanInstallments: (contract: IExpenseContract) => boolean = compose(
    all(
        compose(
            all(propEq('subjectId', EXPENSE_SUB_ID_LOAN_INSTALLMENT)),
            prop('items')
        )
    ),
    prop('periods')
);

const hasPeriods: (contract: IExpenseContract) => boolean = compose(
    complement(isEmpty),
    prop('periods')
);

export const isLoanContract: (contract: IExpenseContract) => boolean = allPass([
    isExpenseOrLoanContractType,
    hasPeriods,
    allPeriodsHaveOnlyLoanInstallments
]);
