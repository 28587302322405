import { FILTER_AMOUNT_MAX } from '../constants/filter.constants';
import { PaymentStatusFilter } from '../interfaces';
import { BookmarkableFilterOptions } from '../interfaces';
import { isArrayOfStrings } from '@zenhomes/sauron';
import {
    PaymentStatusType,
    PAYMENT_STATUS_NOTPAID,
    PAYMENT_STATUS_NOTPAID_OVERDUE,
    PAYMENT_STATUS_OVERPAID,
    PAYMENT_STATUS_PAID,
    PAYMENT_STATUS_PARTIALPAID,
    PAYMENT_STATUS_PARTIALPAID_OVERDUE
} from '@zenhomes/domain/invoice';
import { chain, clone, isArray, isEmpty } from 'lodash';

export function formPropertySelectionParams(filterOptions: BookmarkableFilterOptions) {
    const { propertyBuildingId = '', propertyUnitId = '', propertySelection } = filterOptions;

    let buildingId: string[] = null;
    let unitId: string[] = null;

    switch (true) {
        case !isEmpty(propertySelection):
            buildingId = propertySelection.filter(p => !p.unit).map(p => p.building.id);
            unitId = propertySelection.filter(p => !!p.unit).map(p => p.unit.id);
            break;
        case isArray(propertyBuildingId) || isArray(propertyUnitId):
            buildingId = (<any>clone(propertyBuildingId)) as string[];
            unitId = (<any>clone(propertyUnitId)) as string[];
            break;
        case !!propertyUnitId || !!propertyBuildingId:
            buildingId = propertyBuildingId && !propertyUnitId ? [propertyBuildingId] : null;
            unitId = propertyUnitId ? [propertyUnitId] : null;
            break;
    }

    return { buildingId, unitId };
}

export function formAmountParams(filterOptions: BookmarkableFilterOptions) {
    const amountFrom = filterOptions.amountFrom > 0 ? filterOptions.amountFrom : null;
    const amountTo = filterOptions.amountTo !== FILTER_AMOUNT_MAX ? filterOptions.amountTo : null;

    return { amountFrom, amountTo };
}

export function formCounterpartyIdsParams(filterOptions: BookmarkableFilterOptions) {
    if (isArrayOfStrings(filterOptions.counterpartySelection)) {
        return { counterpartyId: filterOptions.counterpartySelection };
    } else {
        const counterpartyId =
            filterOptions.counterpartySelection &&
            filterOptions.counterpartySelection.map(c => c.id);
        return { counterpartyId };
    }
}

export function formTransactionType(
    filterOptions: BookmarkableFilterOptions,
    inValue: string,
    outValue: string
) {
    const { transactionType } = filterOptions;

    if (!transactionType || transactionType.length !== 1) return null;

    switch (transactionType[0]) {
        case 'IN':
            return inValue;
        case 'OUT':
            return outValue;
        default:
            return null;
    }
}

export function formInvoicesStatusFilter(filterOptions: BookmarkableFilterOptions): string[] {
    return chain(filterOptions.status)
        .flatMap(formPaymentStatusFilterParams)
        .uniq()
        .value();
}

export function formPaymentStatusFilterParams(
    selectedStatus: PaymentStatusFilter
): PaymentStatusType[] {
    switch (selectedStatus) {
        case PaymentStatusFilter.Paid: {
            return [PAYMENT_STATUS_PAID, PAYMENT_STATUS_OVERPAID];
        }
        case PaymentStatusFilter.NotPaid: {
            return [PAYMENT_STATUS_NOTPAID, PAYMENT_STATUS_PARTIALPAID];
        }
        case PaymentStatusFilter.Overdue: {
            return [PAYMENT_STATUS_NOTPAID_OVERDUE, PAYMENT_STATUS_PARTIALPAID_OVERDUE];
        }
        case PaymentStatusFilter.PaidFull: {
            return [PAYMENT_STATUS_PAID, PAYMENT_STATUS_OVERPAID];
        }
        case PaymentStatusFilter.Unpaid: {
            return [
                PAYMENT_STATUS_NOTPAID,
                PAYMENT_STATUS_PARTIALPAID,
                PAYMENT_STATUS_NOTPAID_OVERDUE,
                PAYMENT_STATUS_PARTIALPAID_OVERDUE
            ];
        }
        case PaymentStatusFilter.Unbalanced: {
            return [
                PAYMENT_STATUS_OVERPAID,
                PAYMENT_STATUS_NOTPAID,
                PAYMENT_STATUS_PARTIALPAID,
                PAYMENT_STATUS_NOTPAID_OVERDUE,
                PAYMENT_STATUS_PARTIALPAID_OVERDUE
            ];
        }
        default:
            return [];
    }
}
