import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import * as fromUsagesState from '../state/usages-state';
import * as usagesActions from '../state/actions/usages.actions';
import { Tenancy, UsageList } from '../interfaces';
import { UsagesModuleState } from '../state/usages-state';

@Injectable({
    providedIn: 'root'
})
export class UsagesService {
    usages$: Observable<UsageList[]> = this.store.select(fromUsagesState.getUsages);
    tenancies$: Observable<Tenancy[]> = this.store.select(fromUsagesState.getTenancies);

    constructor(private store: Store<UsagesModuleState>) {}

    loadUsages(buildingId: string, unitId: string) {
        this.store.dispatch(new usagesActions.LoadUsages({ buildingId, unitId }));
    }

    loadTenancies(buildingId: string, unitId: string) {
        this.store.dispatch(new usagesActions.LoadTenancies({ buildingId, unitId }));
    }
}
