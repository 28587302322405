import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
    @Input() value: number = 0;
    @Input() max: number = 100;
    @Input() colored: boolean = false;
    @Input() label: string = '';

    get percentage() {
        return Math.round((this.value / this.max) * 100);
    }

    get color() {
        switch (true) {
            case this.percentage > 80:
                return 'green';
            case this.percentage > 60:
                return 'light-green';
            case this.percentage > 40:
                return 'yellow';
            case this.percentage > 20:
                return 'orange';
            default:
                return 'red';
        }
    }

    get width() {
        const { label, percentage } = this;
        switch (true) {
            case (percentage < 20 && !!label) || percentage < 10:
                return 'fit-content';
            default:
                return `${percentage}%`;
        }
    }
}
