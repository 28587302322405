import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { getPropertyIcon } from '../../utils/icons.util';
import {
    IBuildingModel,
    IBuildingType,
    isSingleBuildingUtil,
    isSingleCondoBuildingAsNotPM,
    IUnitModel
} from '@zenhomes/domain/property';
import { TranslateService } from '@ngx-translate/core';
import { ContactTitlePipe } from '@zenhomes/elements';

@Component({
    selector: 'zhm-property-list-item',
    templateUrl: './property-list-item.component.html',
    styleUrls: ['./property-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyListItemComponent implements OnInit {
    @Input() building: IBuildingModel;
    @Input() unit: IUnitModel;
    @Input() showUsages: boolean = true;
    @Input() usages: any[];
    @Input() showOnlyFirstTenants: boolean = false;
    @Input() showAddressOnSingleCondo: boolean = true;

    constructor(private contactTitlePipe: ContactTitlePipe, private translate: TranslateService) {}

    ngOnInit() {}

    get showBuildingAddress() {
        switch (true) {
            case !this.unit:
                return true;
            case isSingleBuildingUtil(this.building):
                return true;
            case this.showAddressOnSingleCondo && isSingleCondoBuildingAsNotPM(this.building):
                return true;
            default:
                return false;
        }
    }

    getPropertyIconClass() {
        return getPropertyIcon(
            this.building,
            this.building.buildingType === IBuildingType.Multi ? this.unit : null
        );
    }

    filterActiveOldUsages(usages: any) {
        return usages.filter((usage: any) => usage.isActive);
    }

    filterActiveNewUsages(usages: any[]) {
        const activeStatus = ['ACTIVE', 'ENDING_SOON'];
        return (
            usages &&
            usages.filter((usage: any) =>
                activeStatus.some(status => status === usage.activeStatus)
            )
        );
    }

    get hasUsages() {
        const oldUsages = this.unit.usages || [];
        const newUsages = this.usages || [];
        return oldUsages.length > 0 || newUsages.length > 0;
    }

    get activeOldUsages() {
        let leases = [];
        if (this.building && this.unit) {
            leases = this.unit.usages;
        } else {
            leases = this.building.units[0].usages;
        }

        return leases ? this.filterActiveOldUsages(leases).reverse() : [];
    }

    get getActiveNewUsages() {
        const unitId = this.unit && this.unit.id;
        const usages =
            this.usages &&
            this.usages.filter((usage: any) => usage.properties[0].unitId === unitId);

        return usages ? this.filterActiveNewUsages(usages) : [];
    }

    getTenantsLabel = (activeUsages: any) => {
        const tenantNames = activeUsages.map((usage: any) =>
            this.contactTitlePipe.transform(usage.tenants)
        );
        if (!tenantNames || tenantNames.length === 0) {
            return this.translate.instant('property.noTenants');
        } else if (!this.showOnlyFirstTenants) {
            return tenantNames.join(', ');
        } else {
            return tenantNames[0];
        }
    };

    getOldUsageLabel() {
        const activeUsages = this.activeOldUsages;

        const isVacancyOnly =
            activeUsages.length > 0 && activeUsages.every((usage: any) => usage.type === 'VACANCY');
        if (isVacancyOnly) {
            return this.translate.instant('lease.vacancy');
        }
        return this.getTenantsLabel(activeUsages);
    }

    getNewUsagesLabel() {
        const activeUsages = this.getActiveNewUsages;

        const isVacancyOnly =
            activeUsages.length > 0 &&
            activeUsages.every((usage: any) => usage.usageType === 'VACANCY');
        if (isVacancyOnly) {
            return this.translate.instant('lease.vacancy');
        }

        return this.getTenantsLabel(activeUsages);
    }

    get usagesLabel() {
        return this.usages ? this.getNewUsagesLabel() : this.getOldUsageLabel();
    }
}
