import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ElementsModule } from '@zenhomes/elements';
import { TranslateModule } from '@ngx-translate/core';
import { BankAccountsResolver } from './resolvers/bank-accounts.resolver';
// services
import { BankAccountsApiService } from './services/bank-accounts.api.service';
import { BankAccountsService } from './services/bank-accounts.service';
import { bankAccountsReducer } from './state';
// ngrx related things
import { BankAccountsEffects } from './state/effects/bank-accounts.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule,
        ElementsModule,
        TranslateModule,
        StoreModule.forFeature('BankAccountModule', bankAccountsReducer),
        EffectsModule.forFeature([BankAccountsEffects])
    ]
})
export class BankAccountCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: BankAccountCoreModule,
            providers: [BankAccountsApiService, BankAccountsService, BankAccountsResolver]
        };
    }
}
