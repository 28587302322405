import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IBuildingModel } from '@zenhomes/domain/property';

@Component({
    selector: 'buildings-dropdown',
    templateUrl: './buildings-dropdown.component.html',
    styleUrls: ['./buildings-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingsDropdownComponent {
    @Input() buildings: IBuildingModel[];
    @Input() model: IBuildingModel;
    @Input() emptyPlaceholder: string = 'placeholder.noSelection';
    @Input() disabled: boolean = false;

    @Output() onSelect: EventEmitter<IBuildingModel> = new EventEmitter();

    select(building: IBuildingModel) {
        this.onSelect.emit(building);

        this.model = building;
    }
}
