import { ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as fromDocumentCategoriesReducer from './reducers/document-categories.reducer';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface DocumentsModuleState {
    documentCategories: fromDocumentCategoriesReducer.IDocumentCategoriesState;
}

export const DOCUMENTS_MODULE_INIT_STATE: DocumentsModuleState = {
    documentCategories: fromDocumentCategoriesReducer.initialDocumentCategoriesStoreState
};

export const reducers: ActionReducerMap<DocumentsModuleState> = {
    documentCategories: fromDocumentCategoriesReducer.documentCategoriesReducer
};

// main selectors
export const getDocumentCategoriesState = (state: any) => state.DocumentsModule.documentCategories;

// selectors
export const getDocumentCategoriesList = createSelector(
    getDocumentCategoriesState,
    (state: fromDocumentCategoriesReducer.IDocumentCategoriesState) => state.documentCategoriesList
);
export const getDocumentCategoriesAreLoaded = createSelector(
    getDocumentCategoriesState,
    (state: fromDocumentCategoriesReducer.IDocumentCategoriesState) =>
        state.documentCategoriesAreLoaded
);
export const getDocumentCategoriesAreLoading = createSelector(
    getDocumentCategoriesState,
    (state: fromDocumentCategoriesReducer.IDocumentCategoriesState) =>
        state.documentCategoriesAreLoading
);
