import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ElementsModule } from '@zenhomes/elements';
import { FormsModule } from '@angular/forms';

// State Management
import { CategoryEffects } from './state/effects/category.effects';
import { categoryCoreReducer } from './state/index';

// Providers
import { UserTopicsService } from './services/user-topics.service';
import { UserTopicsApiService } from './services/user-topics-api.service';

// Components
import { CategorySubjectSelectorComponent } from './components/category-subject-selector/category-subject-selector.component';
import { SubjectItemComponent } from './components/subject-item/subject-item.component';
import { SubjectSearchComponent } from './components/subject-search/subject-search.component';
import { AutocompleteSuggestionItemComponent } from './components/autocomplete-suggestion-item/autocomplete-suggestion-item.component';

// Pipes
import { UserTopicIconPipe } from './pipes/user-topic-icon.pipe';
import { UserCategoryIconPipe } from './pipes/user-category-icon.pipe';
import { MostRelevantUserCategoryPipe } from './pipes/most-relevant-user-category.pipe';
import { UserCategoryIconColorPipe } from './pipes/user-category-icon-color.pipe';

const PROVIDERS = [UserTopicsApiService, UserTopicsService];

export const PIPES = [
    UserTopicIconPipe,
    UserCategoryIconPipe,
    MostRelevantUserCategoryPipe,
    UserCategoryIconColorPipe
];

const DECLARATIONS = [
    CategorySubjectSelectorComponent,
    SubjectItemComponent,
    SubjectSearchComponent,
    AutocompleteSuggestionItemComponent
];

@NgModule({
    imports: [
        CommonModule,
        ElementsModule,
        FormsModule,
        StoreModule.forFeature('CategoryCoreModule', categoryCoreReducer),
        EffectsModule.forFeature([CategoryEffects]),
        TranslateModule
    ],
    declarations: [...DECLARATIONS, ...PIPES],
    exports: [...DECLARATIONS, ...PIPES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CategoryCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CategoryCoreModule,
            providers: PROVIDERS
        };
    }
}
