import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IUnitModel, IUnitModelDraft } from '@zenhomes/domain/property';
import { sanitizeUnitModel } from '../utilities/property-sanitize.util';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    constructor(private http: HttpClient) {}

    getUnitById(unitId: string): Observable<IUnitModel> {
        return this.http.get(`/units/${unitId}`).pipe(map(sanitizeUnitModel));
    }

    saveUnit(unit: IUnitModelDraft): Observable<IUnitModel> {
        const action = !unit.id ? 'post' : 'put';

        const url = `/units` + (unit.id ? `/${unit.id}` : '');

        return this.http[action](url, unit).pipe(map(sanitizeUnitModel));
    }
}
