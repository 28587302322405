import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBuildingModel, IUnitModel, getPropertyTypesObj } from '@zenhomes/domain/property';

@Component({
    selector: 'zhm-property-icon',
    templateUrl: './property-icon.component.html',
    styleUrls: ['./property-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyIconComponent {
    @Input() building: IBuildingModel;
    @Input() unit: IUnitModel;

    buildingTypeMap = getPropertyTypesObj();

    getAddressIcon() {
        if (this.unit) {
            const typeMap = this.buildingTypeMap[this.unit.type];
            return typeMap ? typeMap.icon : '';
        }

        const typeMap = this.buildingTypeMap[this.building.buildingType];
        return typeMap ? typeMap.icon : '';
    }
}
