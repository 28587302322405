import { ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';
import * as fromUsagesReducer from './reducers/usages.reducer';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface UsagesModuleState {
    usages: fromUsagesReducer.IUsagesState;
}

export const USAGES_MODULE_INIT_STATE: UsagesModuleState = {
    usages: fromUsagesReducer.initialUsagesStoreState
};

export const reducers: ActionReducerMap<UsagesModuleState> = {
    usages: fromUsagesReducer.usagesReducer
};

// main selectors
export const getUsagesState = (state: any) => state.UsagesCoreModule.usages;

// selectors
export const getUsages = createSelector(
    getUsagesState,
    (state: fromUsagesReducer.IUsagesState) => state.usages
);
export const getTenancies = createSelector(
    getUsagesState,
    (state: fromUsagesReducer.IUsagesState) => state.tenancies
);
export const getTenanciesAreBeingLoaded = createSelector(
    getUsagesState,
    (state: fromUsagesReducer.IUsagesState) => state.tenanciesAreBeingLoaded
);
