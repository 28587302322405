import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MainModule } from './app/modules/main/main.module';
import { environment } from 'environments/environment';
import 'rxjs-imports';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(MainModule);
