import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    Inject
} from '@angular/core';
import { DocumentDownloadService } from '../../services/document-download.service';
import { IDocument } from '@zenhomes/domain/document';

@Component({
    selector: 'zhm-document-upload-item',
    templateUrl: './document-upload-item.component.html',
    styleUrls: ['./document-upload-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentUploadItemComponent implements OnInit {
    @Input() completed: boolean;
    @Input() progress: number;
    @Input() icon: string;
    @Input() name: string;
    @Input() dateFrom: string;
    @Input() dateTo: string;
    @Input() document: IDocument;

    @Output() cancel = new EventEmitter<Event>();
    @Output() download = new EventEmitter<Event>();
    @Output() preview = new EventEmitter<Event>();
    @Output() edit = new EventEmitter<Event>();
    @Output() delete = new EventEmitter<Event>();

    isActionSheetOpen: boolean;
    actions = [
        {
            id: 'download',
            label: 'documents.action.download',
            icon: 'icon-download',
            onClick: (event: Event) => this.onDownload(event)
        },
        {
            id: 'preview',
            label: 'documents.action.preview',
            icon: 'icon-sol_preview',
            onClick: (event: Event) => this.onPreview(event)
        },
        {
            id: 'edit',
            label: 'action.edit',
            icon: 'icon-pencil',
            onClick: (event: Event) => this.onEdit(event)
        },
        {
            id: 'delete',
            label: 'action.delete',
            icon: 'icon-trash',
            onClick: (event: Event) => this.onDelete(event)
        }
    ];

    constructor(private documentDownloadService: DocumentDownloadService) {}

    ngOnInit() {}

    trackById(i: number, item: any) {
        return item.id;
    }

    onDownload(event: Event) {
        event.stopPropagation();
        this.download.emit(event);

        const downloadUrl = this.documentDownloadService.getFullUrl(
            this.document.downloadUrl || this.document.url
        );

        this.documentDownloadService.downloadFile(downloadUrl);
    }

    onPreview(event: Event) {
        event.stopPropagation();
        this.preview.emit(event);

        const previewUrl = this.documentDownloadService.getFullUrl(this.document.url);

        this.documentDownloadService.previewFile(previewUrl);
    }

    onEdit(event: Event) {
        this.edit.emit(event);
    }

    onDelete(event: Event) {
        this.delete.emit(event);
    }

    onCancel(event: Event) {
        this.cancel.emit(event);
    }

    onClickCompletedItem(event: Event) {
        this.isActionSheetOpen = true;
    }
}
