import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IProperty, IBuildingModel, IUnitModel } from '@zenhomes/domain/property';
import { flatten, keyBy } from 'lodash';
import { Observable } from 'rxjs/Observable';
import * as fromPropertiesState from '../state/property-state';
import * as propertiesActions from '../state/actions/properties.actions';
import { getPropertyKeyOrderMap } from '../utilities/property-order.util';
import { filter, switchMapTo, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PropertiesService {
    properties$: Observable<IProperty[]> = this.store.select(fromPropertiesState.getPropertiesList);
    propertiesAreLoaded$: Observable<boolean> = this.store.select(
        fromPropertiesState.getPropertiesAreLoaded
    );
    propertiesAfterLoading$: Observable<IProperty[]>;

    allBuildings$: Observable<IBuildingModel[]>;
    allBuildingsDictionary$: Observable<{ [key: string]: IBuildingModel }>;
    allUnits$: Observable<IUnitModel[]>;
    allUnitsDictionary$: Observable<{ [key: string]: IUnitModel }>;
    buildingsDictionary: { [key: string]: IBuildingModel };
    unitsDictionary: { [key: string]: IUnitModel };
    propertyKeyOrderMap$: Observable<{ [propertyKey: string]: number }>;

    constructor(private store: Store<any>) {
        this.propertiesAfterLoading$ = this.propertiesAreLoaded$.pipe(
            filter(loaded => loaded),
            switchMapTo(this.properties$)
        );

        this.allBuildings$ = this.propertiesAfterLoading$.pipe(
            map(properties => properties.map(property => property.propertyObject))
        );
        this.allBuildingsDictionary$ = this.allBuildings$.pipe(
            map(buildings => keyBy(buildings, 'id'))
        );
        this.allUnits$ = this.allBuildings$.pipe(
            map(buildings => flatten(buildings.map(building => building.units)))
        );
        this.allUnitsDictionary$ = this.allUnits$.pipe(map(units => keyBy(units, 'id')));
        this.allBuildingsDictionary$.subscribe(buildings => (this.buildingsDictionary = buildings));
        this.allUnitsDictionary$.subscribe(units => (this.unitsDictionary = units));
        this.propertyKeyOrderMap$ = this.properties$.pipe(
            map(properties => getPropertyKeyOrderMap(properties))
        );
    }

    loadProperties() {
        this.store.dispatch(new propertiesActions.LoadProperties());
    }

    reset() {
        this.store.dispatch(new propertiesActions.Reset());
    }
}
