import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';

import {
    IBuildingModelDraft,
    IBuildingType,
    IUnitType,
    IUnitModel,
    PropertyTypeMap,
    getOwnershipBasedPropertyTypes,
    IBuildingOwnershipBasedType
} from '@zenhomes/domain/property';

@Component({
    selector: 'zhm-building-type-ownership-selector',
    templateUrl: './building-type-ownership-selector.component.html',
    styleUrls: ['./building-type-ownership-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingTypeOwnershipSelectorComponent implements OnInit {
    @Input() value: IBuildingModelDraft;
    @Output()
    valueChange: EventEmitter<IBuildingModelDraft> = new EventEmitter<IBuildingModelDraft>();

    buildingTypes: PropertyTypeMap[];
    selectedBuildingType: PropertyTypeMap[];

    ngOnInit() {
        this.buildingTypes = getOwnershipBasedPropertyTypes();
        this.selectedBuildingType = this.getSelectedBuildingType();
    }

    onSelectTypeAndProperty(buildingType: IBuildingType, isCondo: boolean) {
        // set unit type on unit if property is SFH
        const units =
            this.value.buildingType === IBuildingType.Single
                ? [
                      {
                          ...this.value.units[0],
                          type: IUnitType.Apartment
                      } as IUnitModel
                  ]
                : this.value.units;

        this.valueChange.emit({
            ...this.value,
            buildingType,
            isCondo,
            units
        });
    }

    isSelected(buildingType: PropertyTypeMap): boolean {
        return (
            buildingType.propertyType === this.value.buildingType &&
            buildingType.isCondo === this.value.isCondo
        );
    }

    trackById(index: number, buildingType: PropertyTypeMap) {
        return buildingType.id;
    }

    getHiddenBuildingTypes(buildingType?: any) {
        switch (buildingType[0].id) {
            case IBuildingOwnershipBasedType.Single:
                return [];
            case IBuildingOwnershipBasedType.Multi:
                return [IBuildingOwnershipBasedType.Single];
            case IBuildingOwnershipBasedType.MultiCondo:
                return [IBuildingOwnershipBasedType.Single];
        }
    }

    getSelectedBuildingType() {
        return this.buildingTypes.filter(type => this.isSelected(type));
    }

    isBuildingTypeHidden(selectedBuildingType: PropertyTypeMap, buildingType: PropertyTypeMap) {
        const hiddenTypes = this.getHiddenBuildingTypes(selectedBuildingType);

        return !!hiddenTypes.filter(type => type === buildingType.id).length;
    }
}
