import { NgModule, Optional, SkipSelf, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { LanguageService } from './services/language.service';

export function LocaleIdFactory(languageService: LanguageService) {
    return languageService.getLocale();
}

@NgModule({
    imports: []
})
export class LanguageSelectionModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: LanguageSelectionModule
    ) {
        if (parentModule) {
            throw new Error('LanguageSelectionModule is already loaded!');
        }
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LanguageSelectionModule,
            providers: [
                LanguageService,
                {
                    provide: LOCALE_ID,
                    deps: [LanguageService],
                    useFactory: LocaleIdFactory
                }
            ]
        };
    }
}
