import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { IUser } from '@zenhomes/domain/user';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UserApiService } from '../../services/user.api.service';
import * as userActions from '../actions/user.actions';
import { userActionTypes, UserAddFeatures } from '../actions/user.actions';
import { UserService } from '../../services/user.service';

@Injectable()
export class UserEffects {
    constructor(private actions$: Actions, private userApiService: UserApiService, private userService: UserService) {}

    @Effect()
    loadUser$: Observable<Action> = this.actions$.pipe(
        ofType(userActionTypes.USER_LOAD_USER, userActionTypes.USER_ADD_FEATURES_SUCCESS),
        switchMap(() => {
            return this.userApiService.loadUser().pipe(
                map((user: IUser) => new userActions.UserLoadUserSuccess(user)),
                catchError(() => of(new userActions.UserLoadUserFail()))
            );
        })
    );

    @Effect()
    addFeatures$: Observable<Action> = this.actions$.pipe(
        ofType<UserAddFeatures>(userActionTypes.USER_ADD_FEATURES),
        withLatestFrom(this.userService.user$),
        switchMap(([action, user]) => {
            return this.userApiService.addFeaturesToUser(user, action.features).pipe(
                map(() => new userActions.UserAddFeaturesSuccess()),
                catchError(() => of(new userActions.UserAddFeaturesFail()))
            );
        })
    );
}
