import { Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IWizardComponent } from '../types';
import { isEmpty } from 'lodash';

@Component({
    selector: 'zhm-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements IWizardComponent {
    confirm$ = new EventEmitter<boolean>();
    close$ = new EventEmitter<ConfirmationDialogComponent>();

    body: string = '';
    title: string = '';
    warningText: string = '';
    confirmButtonLabel: string = '';
    confirmButtonClass: string = '';
    cancelButtonLabel: string = '';
    numberOfTransaction: number;
    warningParams?: any;
    hideConfirmButton: boolean = false;

    constructor(private translate: TranslateService) {}

    confirm(isConfirmed: boolean) {
        this.confirm$.emit(isConfirmed);
    }

    getWizardTitle(): string {
        return this.title;
    }

    getBody(): string {
        return this.body;
    }

    getWarningText(): string {
        if (!this.numberOfTransaction && !this.warningParams) {
            return '';
        }

        let mess: string = '';
        let param = {
            numberOfTransaction: this.numberOfTransaction,
            ...(this.warningParams || {})
        };
        this.translate.get(this.warningText, param).subscribe((res: string) => {
            mess += res;
        });

        return mess;
    }

    getConfirmButtonClass(): string {
        return isEmpty(this.confirmButtonClass) ? `btn-danger` : this.confirmButtonClass;
    }

    getConfirmButtonLabel(): string {
        return this.confirmButtonLabel;
    }

    getCancelButtonLabel(): string {
        return this.cancelButtonLabel || 'action.cancel';
    }

    setWizardParams(params: any): void {}

    onHideWizard(wizardService: any): void {
        wizardService.destroyComponent(ConfirmationDialogComponent);
        this.close$.emit(this);
    }
}
