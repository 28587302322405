import { chain, keys, isEqual } from 'lodash';
import { BookmarkableFilterOptions } from '../interfaces';

export function isDefaultFilter(
    initialFilter: BookmarkableFilterOptions,
    currentFilter: BookmarkableFilterOptions,
    excludeList: string[] = []
) {
    return (
        !currentFilter ||
        !chain(keys(initialFilter))
            .filter((option: string) => excludeList.indexOf(option) === -1)
            .some((option: string) => !isEqual(currentFilter[option], initialFilter[option]))
            .value()
    );
}
