import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormControl,
    NG_VALIDATORS,
    Validator
} from '@angular/forms';
import { isNull, isEmpty } from 'lodash';
import { IMyOptions } from 'ngx-mydatepicker';
import * as format from 'date-fns/format';
import * as parse from 'date-fns/parse';
import * as getYear from 'date-fns/getYear';
import * as getMonth from 'date-fns/getMonth';
import * as getDate from 'date-fns/getDate';

import { DateService } from '../../services/date.service';
import { Subject } from 'rxjs/Subject';
import { filter, takeUntil } from 'rxjs/operators';

const INVALID_DATE = 'Invalid date';

@Component({
    selector: 'zhm-datepicker',
    templateUrl: 'datepicker.component.html',
    styleUrls: ['datepicker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ],
    host: {
        '(window:click)': 'hideDatepicker($event)'
    }
})
export class DatepickerComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    @Input() id: string;
    @Input() formControlName: string;
    @Input() ngClass: any;
    @Input() defaultDate: string;
    @Input() readonly: boolean;
    @Input() placeholder: string = '';
    @Input() control: FormControl;
    @Output() valueChanged = new EventEmitter();
    @ViewChild('dp', { static: false }) datePickerInput: ElementRef;

    @Input() inputOutputMode: boolean = false;
    @Input()
    set dateVal(date: string) {
        this.writeValue(date);
    }
    @Output() dateValChange = new EventEmitter<string>();

    formatError: boolean;
    isCalendarOpen: boolean;

    options: IMyOptions = {
        showTodayBtn: false,
        sunHighlight: false,
        alignSelectorRight: true,
        dateFormat: 'dd.mm.yyyy',
        dayLabels: { su: 'S', mo: 'M', tu: 'D', we: 'M', th: 'D', fr: 'F', sa: 'S' },
        monthLabels: {
            1: 'JAN',
            2: 'FEB',
            3: 'MRZ',
            4: 'APR',
            5: 'MAI',
            6: 'JUN',
            7: 'JUL',
            8: 'AUG',
            9: 'SEP',
            10: 'OKT',
            11: 'NOV',
            12: 'DEZ'
        },
        selectorWidth: '312px',
        selectorHeight: 'auto'
    };

    val: any;
    dateValue: string;

    propagateChange: any = (_: any) => {};

    protected componentDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private dateService: DateService,
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {}

    ngOnInit(): void {
        if (this.defaultDate) {
            this.writeValue(this.defaultDate);
        }

        // to clean datepicker's internal value
        if (this.control) {
            this.control.valueChanges
                .pipe(
                    filter(value => isNull(value) || isEmpty(value)),
                    takeUntil(this.componentDestroyed)
                )
                .subscribe(() => this.writeValue(''));
        }

        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    hideDatepicker(event: Event) {
        if (!this.isCalendarOpen) return;

        const input = this.elementRef;
        const dp: any = this.datePickerInput;

        const canCloseCalendar = dp && dp.closeCalendar;
        const clickedInsideDatepicker =
            input && input.nativeElement && input.nativeElement.contains(event.target);

        if (canCloseCalendar && !clickedInsideDatepicker) {
            dp.closeCalendar();
        }
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        if (value) {
            const date = new Date(value);
            this.val = {
                date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
            };
            this.dateValue = format(date, this.dateService.backendDateFormat);
            setTimeout(() => this.propagateChange(this.dateValue));
        } else if (value === '') {
            this.val = '';
            this.dateValue = '';
        }
    }

    onDateChange(e: any) {
        this.val = e.formatted;
        this.formatError = null;

        let date = this.dateService.fromDisplayToBackendFormat(e.jsdate);
        this.propagateChange(date);
        this.valueChanged.emit(date);

        this.dateValChange.emit(this.dateIsInvalid(date) ? null : date);
    }

    private dateIsInvalid(date: any) {
        return new RegExp(`^${INVALID_DATE}$`, 'i').test(date);
    }

    onValueChange(e: any) {
        if (this.inputOutputMode) return;
        if (!this.control || (this.control && this.control.dirty)) {
            this.val = e.value;
            this.formatError =
                !!this.val &&
                !e.valid &&
                !this.val.match(
                    /^((0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.](19|20)[0-9]{2})*$/
                );
            const isoDate = this.dateService.fromDisplayToBackendFormat(e.value);

            this.propagateChange(this.dateIsInvalid(isoDate) ? '' : isoDate);
        }
    }

    onDateInputChange(value: any) {
        if (!this.control || (this.control && this.control.dirty)) {
            this.val = value;
            this.dateValue = value;
            this.formatError = null;
            this.propagateChange(value);

            this.dateValChange.emit(this.dateIsInvalid(value) ? null : value);
        }
    }

    onCalendarToggle(event: number) {
        this.isCalendarOpen = event === 1;
    }

    validate(c: FormControl) {
        return this.val && this.formatError
            ? {
                  isoDate: {
                      valid: false
                  }
              }
            : null;
    }

    openCalendar() {
        if (!this.readonly) {
            const input: any = this.datePickerInput;

            if (this.val && typeof this.val === 'string') {
                const date = parse(this.val, this.dateService.displayDateFormat, new Date());
                this.val = {
                    date: { year: getYear(date), month: getMonth(date) + 1, day: getDate(date) }
                };
            }

            if (input.openCalendar) {
                setTimeout(() => {
                    input.openCalendar();
                    this.changeDetectorRef.markForCheck();
                });
            }

            setTimeout(() => {
                if (input.elem) {
                    input.elem.nativeElement.focus();
                }
            }, 10);
        }
    }
}
