import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserTopicsApiService } from '../../services/user-topics-api.service';
import * as storeActions from '../actions/category.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CategoryEffects {
    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private categoryApiService: UserTopicsApiService
    ) {}

    @Effect()
    dispatchLoadingAction$ = this.actions$.pipe(
        ofType(storeActions.CategoryCoreLoadUserTopics.TYPE),
        switchMap(() => {
            return this.categoryApiService.loadUserTopics().pipe(
                map(result => new storeActions.CategoryCoreLoadUserTopicsSuccess(result)),
                catchError(error => of(new storeActions.CategoryCoreLoadUserTopicsFail(error)))
            );
        })
    );
}
