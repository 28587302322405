import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowOnMobileOnlyDirective } from './directives/show-on-mobile-only.directive';
import { ShowOnDesktopOnlyDirective } from './directives/show-on-desktop-only.directive';
import { DeviceService } from './services/device.service';
import { RendererProxyService } from './services/renderer-proxy.service';
import { WindowMessagingService } from './services/window-messaging.service';
import { WindowRefService } from './services/window-ref.service';
import { StorageService } from './services/storage.service';

const DIRECTIVES = [ShowOnMobileOnlyDirective, ShowOnDesktopOnlyDirective];

@NgModule({
    declarations: [DIRECTIVES],
    imports: [CommonModule],
    exports: [DIRECTIVES],
    providers: []
})
export class DeviceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DeviceModule,
            providers: [
                DeviceService,
                WindowRefService,
                RendererProxyService,
                WindowMessagingService,
                StorageService
            ]
        };
    }
}
