import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IPaymentDragData } from '@payment-core/interfaces';
import { isClusteredPayment } from '@payment-core/utils/clustered-payments.util';
import { OUTGOING } from '../../payment-core.constants';
import { roundTwoSubtract } from '@zenhomes/sauron';
import { IClusteredPayment, IPayment, IPaymentItem, IPaymentType } from '@zenhomes/domain/payment';
import { get, reduce } from 'lodash';

@Component({
    selector: 'zhm-payment-list-item',
    templateUrl: './payment-list-item.component.html',
    styleUrls: ['./payment-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentListItemComponent {
    @Input() payment: IPayment | IClusteredPayment;
    @Input() paymentType: IPaymentType;
    @Input() overpayment: IPaymentDragData;

    constructor(private ref: ChangeDetectorRef) {}

    get isClusteredPayment(): boolean {
        return isClusteredPayment(this.payment);
    }

    get isSplit(): boolean {
        return this.numberOfInvoices > 1;
    }

    get numberOfInvoices(): number {
        return get(this.payment, 'payment.paymentItems.length');
    }

    get matchedAmount(): number {
        return reduce(
            get(this.payment, 'payment.paymentItems'),
            (accum: number, item: IPaymentItem) => accum + item.amount,
            0
        );
    }

    get contractorName(): string {
        return get(this.payment, 'counterpartyName');
    }

    get payments() {
        return get(this.payment, 'payments') as any[];
    }

    signedRemainingAmount(payment: IPayment): number {
        const signedAmount = this.signedAmount(payment);
        return this.overpayment
            ? roundTwoSubtract(signedAmount, this.overpayment.amount)
            : signedAmount;
    }

    signedAmount(payment: IPayment): number {
        return payment.direction === OUTGOING ? -payment.amount : payment.amount;
    }
}
