import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
    @Input() label: string = '';
}
