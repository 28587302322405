import {
    Directive,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    Input
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TeaserStatusService } from '../services/teaser-status.service';

interface Config {
    teaserName: string;
    showWhen: boolean;
}

@Directive({
    selector: '[zhmTeaserWrapper]'
})
export class TeaserWrapperDirectiveDirective implements OnInit, OnDestroy {
    @Input('zhmTeaserWrapper') config: Config;

    private subscription: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private teaserStatusService: TeaserStatusService
    ) {}

    ngOnInit() {
        const isTeaserActive$ = this.teaserStatusService.isTeaserActive$(this.config.teaserName);

        this.subscription = isTeaserActive$.subscribe(isTeaserActive => {
            const showContent = isTeaserActive === this.config.showWhen;

            if (showContent) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe();
    }
}
