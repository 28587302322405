import { Component, ComponentRef, Inject, ViewEncapsulation } from '@angular/core';
import { BlinkMessage } from '../services/blink-message.model';
import { IWizardComponent } from '../types';
import { DialogWizardServiceToken } from '../types/token';

@Component({
    selector: 'zhm-dialog-wizard',
    templateUrl: 'dialog-wizard.template.html',
    styleUrls: ['dialog-wizard.style.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogWizardComponent {
    isHidden: boolean = true;
    isHiddenBackButton: boolean = true;

    constructor(@Inject(DialogWizardServiceToken) private wizardService: any) {}

    backButton(): boolean {
        return this.wizardService.backButton;
    }

    isPrevStep(): boolean {
        return this.wizardService.isPreviousStep();
    }

    get title(): string {
        let step = this.wizardService.currentWizardStep;
        const componentRef = this.wizardService.getComponentRef(
            this.wizardService.visibleComponentOnTop[step]
        ) as ComponentRef<IWizardComponent>;
        return componentRef ? componentRef.instance.getWizardTitle() : 'untitled';
    }

    get blinkMessage(): BlinkMessage {
        return this.wizardService.getBlinkMessage();
    }

    isNextStep(): boolean {
        return this.wizardService.isNextStep();
    }

    previousStep() {
        this.wizardService.previousStep();
    }

    nextStep() {
        this.wizardService.nextStep();
    }

    hide() {
        this.isHidden = true;
        this.wizardService.backButton = false;
        this.wizardService.restoreScrollbar();
        let step = this.wizardService.currentWizardStep;
        const componentRef = this.wizardService.getComponentRef(
            this.wizardService.visibleComponentOnTop[step]
        ) as ComponentRef<IWizardComponent>;
        componentRef && componentRef.instance.onHideWizard(this.wizardService);
        this.wizardService.destroyWizard();
    }

    show() {
        this.isHidden = false;
        this.wizardService.hideScrollbar();
    }

    showBackButton() {
        return this.wizardService.isHiddenBackButton();
    }
}
