import { compose, join, split, map, head, defaultTo, curry } from 'ramda';
import { IAddressBookParty } from '@zenhomes/domain/contact';

interface Func1<T, R> {
    (t: T): R;
}

const getName = curry((contact: IAddressBookParty): string => {
    return contact.name || contact.fullName;
});

const getCompany = curry((contact: IAddressBookParty): string => {
    return contact.companyName;
});

export const transformToInitialsName: Func1<IAddressBookParty, string> = compose(
    join(''),
    map(head),
    split(' '),
    defaultTo(''),
    getName
);

export const transformToInitialsCompany: Func1<IAddressBookParty, string> = compose(
    join(''),
    map(head),
    split(' '),
    defaultTo(''),
    getCompany
);
