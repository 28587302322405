import { createSelector } from 'reselect';
import { IBankAccountsState } from './constants/bank-accounts.constants';
import { createReducer } from '@zenhomes/ngrx-actions';
import { BankAccountsStore } from './store/bank-accounts.store';

export function bankAccountsReducerForBankAccounts(state: any, action: any) {
    return createReducer(BankAccountsStore)(state, action);
}

export const bankAccountsReducer = {
    bankAccounts: bankAccountsReducerForBankAccounts
};

// main selectors
export const getBankAccountsState = (state: any) => state.BankAccountModule.bankAccounts;

// selectors
export const getBankAccountsList = createSelector(
    getBankAccountsState,
    (state: IBankAccountsState) => state.bankAccountsList
);
export const getBankAccountsAreLoaded = createSelector(
    getBankAccountsState,
    (state: IBankAccountsState) => state.bankAccountsAreLoaded
);
