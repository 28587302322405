import { Store, Action } from '@zenhomes/ngrx-actions';
import { map } from 'lodash';
import * as storeActions from '@payment-core/state/actions/payment.actions';
import { PaymentCoreState, PaymentListFilterOptions } from '@payment-core/interfaces';
import { initialPaymentCoreStoreState } from '@payment-core/state/constants/payment-state.constants';
import { IClusteredPayment } from '@zenhomes/domain/payment';
import { togglePayments } from '@payment-list/utils/payment-selection';
import { getPaymentsFromClusters } from '@payment-list/utils/payment-selection';
import { PageableClusteredPaymentsModel } from '@payment-core/interfaces';
import { isClusteredPaymentSelected } from '@payment-core/utils/payment.util';

@Store(initialPaymentCoreStoreState)
export class PaymentCoreStore {
    @Action(storeActions.PaymentCoreLoadPayments)
    loadPayments(state: PaymentCoreState, action: storeActions.PaymentCoreLoadPayments) {
        return Object.assign({}, state, {
            paymentsBeingLoaded: true
        });
    }

    @Action(storeActions.PaymentCoreReloadPayments)
    reloadPayments(state: PaymentCoreState, action: storeActions.PaymentCoreReloadPayments) {
        return {
            ...state,
            paymentsBeingLoaded: true
        };
    }

    @Action(storeActions.PaymentCoreLoadPaymentsSuccess)
    loadPaymentsSuccess(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreLoadPaymentsSuccess
    ) {
        const { items: newPayments, pagination } = new PageableClusteredPaymentsModel(
            action.payload,
            action.count
        );
        const { payments: oldPayments } = state;
        const payments =
            state.filterOptions.page === '1' ? newPayments : [...oldPayments, ...newPayments];

        return {
            ...state,
            payments,
            pagination,
            paymentsBeingLoaded: false
        };
    }

    @Action(storeActions.PaymentCoreLoadPaymentsFail)
    loadPaymentsFail(state: PaymentCoreState, action: storeActions.PaymentCoreLoadPaymentsFail) {
        return Object.assign({}, state, {
            paymentsBeingLoaded: false
        });
    }

    @Action(storeActions.PaymentCoreLoadPaymentSuccess)
    loadPaymentSuccess(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreLoadPaymentSuccess
    ) {
        return Object.assign({}, state, {
            payment: action.payload,
            paymentBeingLoaded: false
        });
    }

    @Action(storeActions.PaymentCoreUpdatePayments)
    updatePayments(state: PaymentCoreState, action: storeActions.PaymentCoreUpdatePayments) {
        return Object.assign({}, state, {
            pagination: { ...state.pagination, ...{ count: action.count } },
            payments: action.payload,
            paymentsBeingLoaded: false
        });
    }

    @Action(storeActions.PaymentCoreGetPayment)
    getPayment(state: PaymentCoreState, action: storeActions.PaymentCoreGetPayment) {
        return Object.assign({}, state, {
            paymentWaitingForBackend: false
        });
    }

    @Action(storeActions.PaymentCoreGetPaymentSuccess)
    getPaymentSuccess(state: PaymentCoreState, action: storeActions.PaymentCoreGetPaymentSuccess) {
        const mode = action.payload;
        return Object.assign({}, state, {
            paymentWaitingForBackend: false
        });
    }

    @Action(storeActions.PaymentCoreGetPaymentFail)
    getPaymentFail(state: PaymentCoreState, action: storeActions.PaymentCoreGetPaymentFail) {
        const mode = action.payload;
        return Object.assign({}, state, {
            paymentWaitingForBackend: false
        });
    }

    @Action(storeActions.PaymentCoreDeletePayment)
    deletePayment(state: PaymentCoreState, action: storeActions.PaymentCoreDeletePayment) {
        return Object.assign({}, state, {
            paymentWaitingForBackend: false
        });
    }

    @Action(storeActions.PaymentCoreDeletePaymentSuccess)
    deletePaymentSuccess(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreDeletePaymentSuccess
    ) {
        const mode = action.payload;
        return Object.assign({}, state, {
            paymentWaitingForBackend: false,
            payment: null
        });
    }

    @Action(storeActions.PaymentCoreDeletePaymentFail)
    deletePaymentFail(state: PaymentCoreState, action: storeActions.PaymentCoreDeletePaymentFail) {
        const mode = action.payload;
        return Object.assign({}, state, {
            paymentWaitingForBackend: false
        });
    }

    @Action(storeActions.PaymentCoreUpdateListFilters)
    updateListFilters(
        state: PaymentCoreState,
        { filterOptions, cleanPreviousPayments }: storeActions.PaymentCoreUpdateListFilters
    ): PaymentCoreState {
        const mergedFilterOptions: PaymentListFilterOptions = {
            ...state.filterOptions,
            ...filterOptions
        };
        const payments: IClusteredPayment[] = cleanPreviousPayments ? [] : state.payments;

        return {
            ...state,
            payments,
            filterOptions: mergedFilterOptions,
            paymentsBeingLoaded: true
        };
    }

    @Action(storeActions.PaymentCoreClearListFilters)
    clearListFilters(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreClearListFilters
    ): PaymentCoreState {
        const filterOptions = {
            ...state.filterOptions,
            ...initialPaymentCoreStoreState.filterOptions
        };
        return { ...state, filterOptions };
    }

    @Action(storeActions.PaymentCoreResetPayments)
    reset(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreResetPayments
    ): PaymentCoreState {
        return {
            ...initialPaymentCoreStoreState,
            uncategorizedPaymentStatsIsLoaded: state.uncategorizedPaymentStatsIsLoaded,
            uncategorizedPaymentStats: state.uncategorizedPaymentStats
        };
    }

    @Action(storeActions.PaymentCoreLoadTransferCounterparties)
    loadTransferCounterparties(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreLoadTransferCounterparties
    ): PaymentCoreState {
        return {
            ...state,
            transferCounterpartiesBeingLoaded: true
        };
    }

    @Action(storeActions.PaymentCoreLoadTransferCounterpartiesSuccess)
    loadTransferCounterpartiesSuccess(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreLoadTransferCounterpartiesSuccess
    ): PaymentCoreState {
        return {
            ...state,
            transferCounterparties: action.transferCounterparties,
            transferCounterpartiesBeingLoaded: false,
            transferCounterpartiesAreLoaded: true
        };
    }

    @Action(storeActions.PaymentCoreLoadTransferCounterpartiesFail)
    loadTransferCounterpartiesFail(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreLoadTransferCounterpartiesFail
    ): PaymentCoreState {
        return {
            ...state,
            transferCounterpartiesBeingLoaded: false
        };
    }

    @Action(storeActions.PaymentCoreMarkPaymentsAsHidden)
    markPaymentsAsHidden(
        state: PaymentCoreState,
        { payments: selectedPayments }: storeActions.PaymentCoreMarkPaymentsAsHidden
    ): PaymentCoreState {
        const payments = map(state.payments, payment => {
            if (isClusteredPaymentSelected(selectedPayments, payment)) {
                return { ...payment, __hidden: true };
            } else {
                return payment;
            }
        });

        return { ...state, payments };
    }

    @Action(storeActions.PaymentCoreTogglePayments)
    togglePayments(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreTogglePayments
    ): PaymentCoreState {
        return {
            ...state,
            paymentsSelected: togglePayments(state.paymentsSelected, action.payload)
        };
    }

    @Action(storeActions.PaymentCoreSelectAllPayments)
    selectAllPayments(state: PaymentCoreState): PaymentCoreState {
        const { payments } = state;

        return { ...state, paymentsSelected: getPaymentsFromClusters(payments) };
    }

    @Action(storeActions.PaymentCoreCleanPaymentsSelected)
    cleanPaymentsSelected(
        state: PaymentCoreState,
        action: storeActions.PaymentCoreTogglePayments
    ): PaymentCoreState {
        return { ...state, paymentsSelected: action.payload, shouldLoadMissingPayments: false };
    }

    @Action(storeActions.PaymentCoreLoadMissingPayments)
    loadMissingPayments(state: PaymentCoreState): PaymentCoreState {
        return {
            ...state,
            shouldLoadMissingPayments: true
        };
    }

    @Action(storeActions.PaymentCoreUpdateShouldLoadMissingPayments)
    updateShouldLoadMissingPayments(
        state: PaymentCoreState,
        {
            payload: shouldLoadMissingPayments
        }: storeActions.PaymentCoreUpdateShouldLoadMissingPayments
    ): PaymentCoreState {
        return {
            ...state,
            shouldLoadMissingPayments
        };
    }

    @Action(storeActions.PaymentCoreLoadUncategorizedPaymentsStatsSuccess)
    updateUncategorizedPaymentStats(
        state: PaymentCoreState,
        { stats }: storeActions.PaymentCoreLoadUncategorizedPaymentsStatsSuccess
    ): PaymentCoreState {
        return {
            ...state,
            uncategorizedPaymentStatsIsLoaded: true,
            uncategorizedPaymentStats: stats
        };
    }
}
