import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'zhm-edit-section-overlay',
    templateUrl: './edit-section-overlay.component.html',
    styleUrls: ['./edit-section-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSectionOverlayComponent implements OnInit {
    @Input() label: string = 'action.edit';

    constructor() {}

    ngOnInit() {}
}
