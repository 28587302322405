import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import {
    ICategorizationSubject,
    ICategorizationUserCategory,
    ICategorizationUserTopic
} from '@zenhomes/domain/category';
import { find } from 'lodash';

export enum CategoryBadgeMode {
    None = 'none',
    Accounting = 'accounting',
    User = 'user'
}

@Component({
    selector: 'zhm-subject-item',
    templateUrl: './subject-item.component.html',
    styleUrls: ['./subject-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectItemComponent implements OnInit, OnChanges {
    @Input() subject: ICategorizationSubject;
    @Input() selected: boolean;
    @Input() categoryBadgeMode: CategoryBadgeMode = CategoryBadgeMode.Accounting;
    @Input() isHeaderVisible: boolean = false;
    @Input() isCheckboxVisible: boolean = true;
    @Input() userTopics: ICategorizationUserTopic[];
    @Input() query: string;

    userTopic: ICategorizationUserTopic;
    userCategory: ICategorizationUserCategory;

    constructor() {}

    ngOnInit() {
        this.userTopic = this.getUserTopic();
        this.userCategory = this.getUserCategory();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.subject) {
            this.userTopic = this.getUserTopic();
            this.userCategory = this.getUserCategory();
        }
    }

    get accountingBadge() {
        if (
            this.subject &&
            this.subject.accountingMainCategory &&
            this.subject.accountingCategory
        ) {
            return `${this.subject.accountingMainCategory.title} > ${this.subject.accountingCategory.title}`;
        } else {
            return '';
        }
    }

    get userBadge() {
        if (this.userTopic && this.userCategory) {
            return `${this.userTopic.title} > ${this.userCategory.title}`;
        } else {
            return '';
        }
    }

    getUserTopic(): ICategorizationUserTopic {
        return find(this.userTopics, topic =>
            find(topic.userCategories, category => find(category.subjects, this.subject))
        );
    }

    getUserCategory(): ICategorizationUserCategory {
        if (!this.userTopic) return null;

        let userTopic = this.userTopic.userCategories;
        return find(userTopic, category => find(category.subjects, this.subject));
    }
}
