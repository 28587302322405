import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-thermal-characteristic-scale',
    templateUrl: './thermal-characteristic-scale.component.html',
    styleUrls: ['./thermal-characteristic-scale.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThermalCharacteristicScaleComponent {
    @Input() value: number = 0;
    @Input() maxValue: number = 250;
    @Input() isActive: boolean = false;
    @Input() isClassVisible: boolean;
    @Input() classNames: string;

    get percentage() {
        if (this.value >= this.maxValue) {
            return 100;
        } else {
            return Math.round((this.value / this.maxValue) * 100);
        }
    }

    get classes() {
        return this.classNames ? `thermal-container ${this.classNames}` : 'thermal-container';
    }
}
