import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BankAccountsService } from '../services/bank-accounts.service';
import { BankAccount } from '../interfaces';
import { EventRouter, RoutingEvent } from '@zenhomes/frontend-routing';

@Injectable()
export class BankAccountsResolver implements Resolve<BankAccount[]> {
    constructor(private bankAccountsService: BankAccountsService) {}

    resolve(): Observable<BankAccount[]> {
        this.bankAccountsService.loadBankAccounts();

        return this.bankAccountsService.bankAccountsAfterLoading$.take(1).catch(error => {
            // @TODO should be replaced by some sort of a general "unpredicted server error" modal
            EventRouter.dispatch(RoutingEvent.LandingPageOpened);
            return [error];
        });
    }
}
