import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { AUTH_MODULE_INIT_STATE, reducers } from './state/auth.state';
import { AuthEffects } from './state/effects/auth.effects';
import { AuthModuleConfig, AuthConfigToken } from './auth.config';

@NgModule({
    imports: [
        StoreModule.forFeature('AuthModule', reducers, {
            initialState: AUTH_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([AuthEffects])
    ]
})
export class AuthModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AuthModule
    ) {
        if (parentModule) {
            throw new Error('AuthModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config: AuthModuleConfig): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [
                { provide: AuthConfigToken, useValue: config },
                AuthService,
                AuthGuard,
                AuthEffects,
                TokenService
            ]
        };
    }
}
