import * as parse from 'date-fns/parse';
import * as min from 'date-fns/min';
import * as max from 'date-fns/max';
import * as format from 'date-fns/format';
import * as isAfter from 'date-fns/isAfter';
import { curry, isEmpty, isNil, anyPass, map, filter, complement } from 'ramda';
import { DATE_FORMAT_BACKEND } from '../constants';

interface Func1<T, R> {
    (t: T): R;
}

export const fromBackendFormatToDate: Func1<string, Date> = curry((date: string) =>
    parse(date, DATE_FORMAT_BACKEND, new Date())
);
export const fromBackendFormatToDates: Func1<string[], Date[]> = map(fromBackendFormatToDate);

const getNonEmptyStringDates = filter(complement(anyPass([isEmpty, isNil])));
const getNonEmptyDates = curry((dates: string[]): Date[] => {
    const nonEmptyDates = getNonEmptyStringDates(dates);
    return map(fromBackendFormatToDate, nonEmptyDates);
});

export const getMinDate: Func1<string[], string> = curry((dates: string[]): string => {
    const nonEmptyDates = getNonEmptyDates(dates);
    return nonEmptyDates && nonEmptyDates.length
        ? format(min(nonEmptyDates), DATE_FORMAT_BACKEND)
        : '';
});

export const getMaxDate: Func1<string[], string> = curry((dates: string[]): string => {
    const nonEmptyDates = getNonEmptyDates(dates);
    return nonEmptyDates && nonEmptyDates.length
        ? format(max(nonEmptyDates), DATE_FORMAT_BACKEND)
        : '';
});

export const fromDateToBackendFormat = curry((date: Date): string =>
    format(date, DATE_FORMAT_BACKEND)
);

export const dateIsInTheFuture = (dateStr: string) => {
    const date = fromBackendFormatToDate(dateStr);
    return isAfter(date, new Date());
};
