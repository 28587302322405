import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Select } from '@zenhomes/ngrx-actions';
import { path } from 'ramda';
import { Observable } from 'rxjs/Observable';
import { IDocumentMetadataFormState, IDocumentMetadataFormOpenParams } from '../interfaces';
import * as actions from '../state/actions/document-metadata-form.actions';
import { IDocument } from '@zenhomes/domain/document';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DocumentMetadataFormService {
    @Select(path(['DocumentMetadataFormModule', 'form', 'isOpen']))
    isOpen$: Observable<boolean>;

    open$: Observable<IDocumentMetadataFormOpenParams> = this.actions$.pipe(
        ofType(actions.Open.TYPE),
        map((action: actions.Open) => action.payload)
    );

    save$: Observable<IDocument> = this.actions$.pipe(
        ofType(actions.SaveSuccess.TYPE),
        map((action: actions.SaveSuccess) => action.document)
    );

    close$: Observable<any> = this.actions$.pipe(
        ofType(actions.Cancel.TYPE, actions.SaveSuccess.TYPE)
    );

    constructor(private store: Store<IDocumentMetadataFormState>, private actions$: Actions) {}

    open(params: IDocumentMetadataFormOpenParams) {
        this.store.dispatch(new actions.Open(params));
    }

    save(document: IDocument) {
        this.store.dispatch(new actions.SaveSuccess(document));
    }

    cancel() {
        this.store.dispatch(new actions.Cancel());
    }
}
