import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { PropertyUiModule } from '@zenhomes/property-ui';
import { TranslateModule } from '@ngx-translate/core';
import { ElementsModule } from '@zenhomes/elements';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DateModule } from '@zenhomes/date';

import { DocumentFormComponent } from './components/document-form/document-form.component';
import { DocumentsEditorComponent } from './components/documents-editor/documents-editor.component';
import { documentMetadataFormReducer } from './state/document-metadata-state';
import { DocumentMetadataFormDialogWrapperComponent } from './components/document-metadata-form-dialog-wrapper/document-metadata-form-dialog-wrapper.component';
import { IFrameHustlaUiModule } from '@zenhomes/iframe-hustla-ui';
import { UsagesCoreModule } from '@zenhomes/usages';
import { TenancySelectorDropdownComponent } from './components/tenancy-selector-dropdown/tenancy-selector-dropdown.component';

export const DECLARATIONS = [
    DocumentsEditorComponent,
    DocumentFormComponent,
    DocumentMetadataFormDialogWrapperComponent,
    TenancySelectorDropdownComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ElementsModule,
        PropertyUiModule,
        StoreModule.forFeature('DocumentMetadataFormModule', documentMetadataFormReducer),
        TranslateModule,
        BsDropdownModule,
        IFrameHustlaUiModule,
        DateModule,
        UsagesCoreModule
    ],
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    entryComponents: DECLARATIONS
})
export class DocumentMetadataFormModule {}
