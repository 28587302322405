import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { PhraseLoader } from './phrase-loader.service';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { TranslationsUpdated } from '../state/actions/module-orchestration.actions';

const deJson = require('../../../../i18n/de.json');

@Injectable()
export class TranslateHttpLoader {
    constructor(
        private http: HttpClient,
        private store: Store<any>,
        private phraseLoader: PhraseLoader
    ) {}

    getTranslation(locale: string) {
        const isLiveTranslation = environment.PHRASE.ENABLED_LIVE_TRANSLATIONS;

        if (isLiveTranslation) {
            this.phraseLoader.setLocale(locale);

            const cachedTranslation = this.phraseLoader.getCachedTranslation();
            const hasCachedTranslation = !!cachedTranslation;

            this.phraseLoader.tryRefreshTranslations(hasCachedTranslation).then(translations => {
                if (translations) {
                    this.store.dispatch(new TranslationsUpdated(locale, translations));
                }
            });

            if (hasCachedTranslation) {
                return of(cachedTranslation);
            }
        }

        return this.getTranslationCompiled(locale);
    }

    private getTranslationCompiled(locale: string) {
        if (locale === 'de') {
            return of(deJson);
        } else {
            return this.http.get(
                `${window.location.protocol}//${window.location.host}/i18n/${locale}.json`
            );
        }
    }
}
