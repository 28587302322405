import { getSelectorWrapper } from '@util/selector-wrapper.util';
import { createReducer } from '@zenhomes/ngrx-actions';
import { JournalViewState } from '../state/constants/journal-view.constants';
import { JournalViewStore } from '../state/store/journal-view.store';
import { JournalViewDocumentsState } from '../state/constants/journal-view-documents.constants';
import { JournalViewDocumentsStore } from '../state/store/journal-view-documents.store';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface JournalViewModuleState {
    journalView: JournalViewState;
    journalViewDocuments: JournalViewDocumentsState;
}

export function journalViewReducerForJournalView(state: any, action: any) {
    return createReducer(JournalViewStore)(state, action);
}

export function journalViewReducerForJournalViewDocuments(state: any, action: any) {
    return createReducer(JournalViewDocumentsStore)(state, action);
}

export const journalViewReducer = {
    journalView: journalViewReducerForJournalView,
    journalViewDocuments: journalViewReducerForJournalViewDocuments
};

// main selector
export const getJournalViewState = (state: any) => state.JournalViewModule.journalView;
export const selectorWrapper = getSelectorWrapper<JournalViewState>(getJournalViewState);

// custom selectors
export const getJournalViewInvoices = selectorWrapper('journalViewInvoices');
export const getJournalViewInvoicesSelected = selectorWrapper('journalViewInvoicesSelected');
export const getJournalViewShouldLoadMissingInvoices = selectorWrapper(
    'journalViewShouldLoadMissingInvoices'
);
export const getJournalViewBulkOperationMode = selectorWrapper('journalViewBulkOperationMode');
export const getJournalViewInvoicesBeingLoaded = selectorWrapper('journalViewInvoicesBeingLoaded');
export const getJournalViewInvoicesAreLoaded = selectorWrapper('journalViewInvoicesAreLoaded');
export const getFilterOptions = selectorWrapper('filterOptions');
export const getPagination = selectorWrapper('pagination');
export const getExpandedInvoice = selectorWrapper('expandedInvoice');

export const journalViewDocumentsReducer = {
    journalViewDocuments: journalViewReducerForJournalViewDocuments
};

// main documents selector
export const getJournalViewDocumentsState = (state: any) =>
    state.JournalViewModule.journalViewDocuments;
export const documentsSelectorWrapper = getSelectorWrapper<JournalViewDocumentsState>(
    getJournalViewDocumentsState
);

export const getDocumentsForExpandedInvoice = documentsSelectorWrapper('expandedInvoiceDocuments');
