import { IPropertyNavigationParams } from '@zenhomes/domain/property';
import { ActivatedRouteSnapshot } from '@angular/router';

export const PORTFOLIO_PARAM = 'portfolio';
export const PROPERTY_NAVIGATION_PARAM = 'propertyNavigationParam';

export const resolvePropertyRouteParams = (route: ActivatedRouteSnapshot) => {
    const propertyNavigationParam = getParamFromRouteOrRouteParent(
        route,
        PROPERTY_NAVIGATION_PARAM
    );

    return parsePropertyParam(propertyNavigationParam);
};

export const parsePropertyParam = (paramsCode: string) => {
    if (paramsCode === PORTFOLIO_PARAM) {
        return {};
    }

    const sections = paramsCode
        .split(',')
        .map(section => section.split(':'))
        .filter(sectionItems => sectionItems.length === 2)
        .map(sectionItems => ({ type: sectionItems[0], id: sectionItems[1] }));

    const buildingPart = sections.find(section => section.type === 'building');
    const unitPart = sections.find(section => section.type === 'unit');

    const result: IPropertyNavigationParams = {};

    if (buildingPart) result.propertyBuildingId = buildingPart.id;
    if (unitPart) result.propertyUnitId = unitPart.id;

    return result;
};

const getParamFromRouteOrRouteParent = (route: ActivatedRouteSnapshot, paramKey: string) => {
    return route.params[paramKey] || (route.parent && route.parent.params[paramKey]);
};
