/// <reference path="amator.d.ts" />

import { Injectable } from '@angular/core';
import animate from 'amator';

@Injectable()
export class DomService {
    private withinBounds(centerIfNeeded: any, value: any, min: any, max: any, extent: any) {
        if (false === centerIfNeeded || (max <= value + extent && value <= min + extent)) {
            return Math.min(max, Math.max(min, value));
        } else {
            return (min + max) / 2;
        }
    }

    private makeArea(left: any, top: any, width: any, height: any) {
        return {
            left: left,
            top: top,
            width: width,
            height: height,
            right: left + width,
            bottom: top + height,

            translate: (x: any, y: any) => {
                return this.makeArea(x + left, y + top, width, height);
            },

            relativeFromTo: (area: any, lhs: any, rhs: any) => {
                let newLeft = left,
                    newTop = top;
                lhs = lhs.offsetParent;
                rhs = rhs.offsetParent;

                if (lhs === rhs) {
                    return area;
                }

                for (; lhs; lhs = lhs.offsetParent) {
                    newLeft += lhs.offsetLeft + lhs.clientLeft;
                    newTop += lhs.offsetTop + lhs.clientTop;
                }

                for (; rhs; rhs = rhs.offsetParent) {
                    newLeft -= rhs.offsetLeft + rhs.clientLeft;
                    newTop -= rhs.offsetTop + rhs.clientTop;
                }

                return this.makeArea(newLeft, newTop, width, height);
            }
        };
    }

    scrollIntoView(
        element: HTMLElement,
        centerIfNeeded: boolean = true,
        options: any = null,
        finalElement: HTMLElement = null
    ) {
        if (!element) {
            throw new Error('Element is required in scrollIntoView');
        }

        let parent = null;
        let area = this.makeArea(
            element.offsetLeft,
            element.offsetTop,
            element.offsetWidth,
            element.offsetHeight
        );

        while ((parent = element.parentNode) instanceof HTMLElement && element !== finalElement) {
            let clientLeft = parent.offsetLeft + parent.clientLeft;
            let clientTop = parent.offsetTop + parent.clientTop;

            // Make area relative to parent's client area.
            area = area.relativeFromTo(area, element, parent).translate(-clientLeft, -clientTop);

            let scrollLeft = this.withinBounds(
                area,
                parent.scrollLeft,
                area.right - parent.clientWidth,
                area.left,
                parent.clientWidth
            );

            let scrollTop = this.withinBounds(
                area,
                parent.scrollTop,
                area.bottom - parent.clientHeight,
                area.top,
                parent.clientHeight
            );

            if (options) {
                animate(
                    parent,
                    {
                        scrollLeft: scrollLeft,
                        scrollTop: scrollTop
                    },
                    options
                );
            } else {
                parent.scrollLeft = scrollLeft;
                parent.scrollTop = scrollTop;
            }

            // Determine actual scroll amount by reading back scroll properties.
            area = area.translate(clientLeft - parent.scrollLeft, clientTop - parent.scrollTop);

            element = parent;
        }
    }
}
