import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-button-actions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'button-actions.component.html',
    styleUrls: ['./button-actions.component.scss']
})
export class ButtonActionsComponent {
    @Input() active: boolean;
}
