import { Pipe, PipeTransform } from '@angular/core';
import { ICategorizationUserCategory } from '@zenhomes/domain/category';
import { find, isEmpty } from 'lodash';
import { USER_CATEGORY_ICON } from '../data/user-category';

export const DEFAULT_ICON = 'icon-out_rent';

@Pipe({ name: 'userCategoryIcon' })
export class UserCategoryIconPipe implements PipeTransform {
    transform(category: ICategorizationUserCategory): string {
        if (!category) return DEFAULT_ICON;
        const icon = find(USER_CATEGORY_ICON, i => i.id === category.id);

        if (isEmpty(icon)) {
            return DEFAULT_ICON;
        } else {
            return icon.name;
        }
    }
}
