import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@zenhomes/auth';
import { MessagesService } from '@zenhomes/toast-messages';
import { throttle } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';

export const TOKEN_EXPIRED_HTTP_STATUS = 401;

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    dispatchEmailRequiredError: Function = throttle(
        () => this.messagesService.showMessage('errors.emailRequired', 'DANGER', true),
        5000,
        {
            trailing: false
        }
    );

    constructor(private messagesService: MessagesService, private tokenService: TokenService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(response => {
            if (this.isLogoutRequired(response)) {
                this.tokenService.logout();

                if (response.error.errors && response.error.errors['email.required']) {
                    this.dispatchEmailRequiredError();
                }
            }

            return throwError(response);
        });
    }

    isLogoutRequired(response: any) {
        return (
            response instanceof HttpErrorResponse && response.status === TOKEN_EXPIRED_HTTP_STATUS
        );
    }
}
