import { Action } from '@ngrx/store';
import { IUser, IUserFeature } from '@zenhomes/domain/user';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */
export const userActionTypes = {
    USER_LOAD_USER: '[User] Load user',
    USER_LOAD_USER_SUCCESS: '[User] Load user success',
    USER_LOAD_USER_FAIL: '[User] Load user fail',
    USER_RESET: '[User] Reset',
    USER_ADD_FEATURES: '[User] Add features to user',
    USER_ADD_FEATURES_SUCCESS: '[User] Add features to user success',
    USER_ADD_FEATURES_FAIL: '[User] Add features to user fail'
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class UserLoadUser implements Action {
    readonly type = userActionTypes.USER_LOAD_USER;
}

export class UserLoadUserSuccess implements Action {
    readonly type = userActionTypes.USER_LOAD_USER_SUCCESS;
    constructor(public payload: IUser) {}
}

export class UserLoadUserFail implements Action {
    readonly type = userActionTypes.USER_LOAD_USER_FAIL;
}

export class UserReset implements Action {
    readonly type = userActionTypes.USER_RESET;
}

export class UserAddFeatures implements Action {
    readonly type = userActionTypes.USER_ADD_FEATURES;
    constructor(public features: IUserFeature[]) {}
}

export class UserAddFeaturesSuccess implements Action {
    readonly type = userActionTypes.USER_ADD_FEATURES_SUCCESS;
}

export class UserAddFeaturesFail implements Action {
    readonly type = userActionTypes.USER_ADD_FEATURES_FAIL;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type UserActionsType = [
    UserLoadUser,
    UserLoadUserSuccess,
    UserLoadUserFail,
    UserReset,
    UserAddFeatures,
    UserAddFeaturesSuccess,
    UserAddFeaturesFail
];

export const UserActions = {
    UserLoadUser,
    UserLoadUserSuccess,
    UserLoadUserFail,
    UserReset,
    UserAddFeatures,
    UserAddFeaturesSuccess,
    UserAddFeaturesFail
};
