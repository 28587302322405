import { InjectionToken } from '@angular/core';

export const AuthConfigToken = new InjectionToken<AuthModuleConfig>('auth.module.config');

export interface AuthModuleConfig {
    authTokenBlackList?: string[];
    auth0Domain: string;
    auth0ClientID: string;
    isEnvironmentProduction: boolean;
}
