import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { DocumentCategoriesApiService } from '../../services/document-categories.api.service';
import { DocumentCategoriesService } from '../../services/document-categories.service';
import { documentCategoriesActionTypes } from '../actions/document-categories.actions';
import * as documentCategoriesActions from '../actions/document-categories.actions';
import { empty, of } from 'rxjs';
import { switchMapTo, take, switchMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class DocumentCategoriesEffects {
    constructor(
        private documentCategoriesApiService: DocumentCategoriesApiService,
        private documentCategoriesService: DocumentCategoriesService,
        private actions$: Actions
    ) {}

    @Effect()
    loadDocumentCategories$: Observable<Action> = this.actions$.pipe(
        ofType(documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES),
        switchMapTo(
            this.documentCategoriesService.documentCategoriesAreLoaded$.pipe(
                take(1),
                switchMap(loaded => {
                    if (loaded) return empty();

                    return this.documentCategoriesApiService.loadDocumentCategories().pipe(
                        map(
                            documentCategories =>
                                new documentCategoriesActions.DocumentsLoadDocumentCategoriesSuccess(
                                    documentCategories
                                )
                        ),
                        catchError(() =>
                            of(new documentCategoriesActions.DocumentsLoadDocumentCategoriesFail())
                        )
                    );
                })
            )
        )
    );
}
