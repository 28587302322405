import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { IPaymentDragData } from '@payment-core/interfaces';

@Component({
    selector: 'zhm-overpayment-list-item',
    templateUrl: './overpayment-list-item.component.html',
    styleUrls: ['./overpayment-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverpaymentListItemComponent implements OnInit {
    @Input() overpayment: IPaymentDragData;

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {}
}
