import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { chain, findIndex, some } from 'lodash';

import { propertyNavigationActionTypes } from '../actions/property-navigation.actions';
import { PropertyNavigationService } from '../../services/property-navigation.service';
import { PropertiesService } from '@zenhomes/property-core';
import { NavigationHelpersService } from '../../services/navigation-helpers.service';
import { INavigationParams } from '../../interfaces/navigation-params.interface';
import { IContentNavigationParams } from '../../interfaces/content-navigation-params.interface';
import * as propertyNavigationActions from '../actions/property-navigation.actions';
import {
    isMultiBuildingUtil,
    isSingleBuildingUtil,
    hasRolePropertyManager,
    IUnitType,
    IBuildingModel,
    IUnitModel,
    IPropertyNavigationParams
} from '@zenhomes/domain/property';
import { IPropertyNavigationSelection, IContentNavigationSelection } from '../../interfaces';
import { DeviceService } from '@zenhomes/device';
import { getPropertyNavigationParamsForProperty } from '../../utilities/navigate.util';
import { toPayload } from '@util/to-payload.util';

@Injectable()
export class PropertyNavigationEffects {
    constructor(
        private actions$: Actions,
        private propertyNavigationService: PropertyNavigationService,
        private navigationHelpersService: NavigationHelpersService,
        private deviceService: DeviceService,
        private propertiesService: PropertiesService
    ) {}

    @Effect()
    navigateToProperty = this.actions$
        .pipe(ofType(propertyNavigationActionTypes.PROPERTY_NAVIGATION_NAVIGATE_TO_PROPERTY))
        .filter(
            ({ property }: propertyNavigationActions.PropertyNavigationNavigateToProperty) =>
                !!property
        )
        .map(({ property }: propertyNavigationActions.PropertyNavigationNavigateToProperty) => {
            const propertyNavigationParams = getPropertyNavigationParamsForProperty(property);
            return new propertyNavigationActions.PropertyNavigationSelectProperty(
                propertyNavigationParams
            );
        });

    @Effect()
    selectProperty$: Observable<Action> = this.actions$
        .pipe(ofType(propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PROPERTY))
        .map(toPayload)
        .switchMap((payload: IPropertyNavigationParams) => {
            return this.propertiesService.propertiesAfterLoading$.first().map(properties => {
                const property = this.navigationHelpersService.determinePropertyNavigationSelection(
                    properties,
                    payload
                );
                return new propertyNavigationActions.PropertyNavigationSelectPropertyNavigationItem(
                    property
                );
            });
        });

    @Effect()
    selectContent$: Observable<Action> = this.actions$
        .pipe(ofType(propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_CONTENT))
        .map(toPayload)
        .switchMap((payload: IContentNavigationParams) => {
            return this.propertyNavigationService.contentNavigationItems$
                .first()
                .map(contentItems => {
                    const content = this.navigationHelpersService.determineContentNavigationSelection(
                        contentItems,
                        payload
                    );
                    return new propertyNavigationActions.PropertyNavigationSelectContentNavigationItem(
                        content
                    );
                });
        });

    @Effect()
    selectBoth$: Observable<Action> = this.actions$
        .pipe(ofType(propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_BOTH))
        .map(toPayload)
        .switchMap((payload: INavigationParams) => {
            return Observable.combineLatest(
                this.propertyNavigationService.contentNavigationItems$,
                this.propertiesService.propertiesAfterLoading$
            )
                .first()
                .map(([contentItems, properties]) => {
                    const content = this.navigationHelpersService.determineContentNavigationSelection(
                        contentItems,
                        payload
                    );
                    const property = this.navigationHelpersService.determinePropertyNavigationSelection(
                        properties,
                        payload
                    );

                    const selection = { ...content, ...property };

                    return new propertyNavigationActions.PropertyNavigationSelectBothNavigationItems(
                        selection
                    );
                });
        });

    @Effect({ dispatch: false })
    changePropertyNavigationItem$: Observable<any> = this.actions$
        .pipe(
            ofType(
                propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PROPERTY_NAVIGATION_ITEM
            )
        )
        .map(toPayload)
        .switchMap((propertyNavigationSelection: IPropertyNavigationSelection) => {
            return this.propertyNavigationService.contentNavigationSelection$
                .take(1)
                .map((contentNavigationSelection: IContentNavigationSelection) => ({
                    ...propertyNavigationSelection,
                    ...contentNavigationSelection
                }));
        })
        .do((navigationSelection: IPropertyNavigationSelection & IContentNavigationSelection) => {
            this.navigationHelpersService.navigateWithSelection(navigationSelection);
        });

    @Effect({ dispatch: false })
    changeNavigationItem$: Observable<any> = this.actions$
        .pipe(
            ofType(
                propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_CONTENT_SELECTION_ITEM,
                propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_BOTH_SELECTION_ITEMS
            )
        )
        .do(() => {
            this.propertyNavigationService.navigationSelection$.take(1).subscribe(selection => {
                this.navigationHelpersService.navigateWithSelection(selection);
            });
        });

    @Effect()
    selectNavigationItemsInitially$: Observable<Action> = this.actions$
        .pipe(ofType(propertyNavigationActionTypes.PROPERTY_NAVIGATION_DETERMINE_NAVIGATION_ITEMS))
        .map(toPayload)
        .switchMap((payload: INavigationParams) => {
            return Observable.combineLatest(
                this.propertyNavigationService.contentNavigationItems$,
                this.propertiesService.propertiesAfterLoading$
            )
                .first()
                .map(([contentItems, properties]) => {
                    const content = this.navigationHelpersService.determineContentNavigationSelection(
                        contentItems,
                        payload
                    );
                    const property = this.navigationHelpersService.determinePropertyNavigationSelection(
                        properties,
                        payload
                    );

                    const selection = { ...content, ...property };

                    return new propertyNavigationActions.PropertySelectNavigationItems(selection);
                });
        });

    @Effect()
    selectPreviousPropertyNavigationItem$: Observable<Action> = this.actions$
        .pipe(
            ofType(
                propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PREVIOUS_PROPERTY_NAVIGATION_ITEM
            )
        )
        .switchMap(() => {
            return Observable.combineLatest(
                this.propertyNavigationService.navigationSelection$,
                this.propertiesService.propertiesAfterLoading$
            )
                .first()
                .map(([{ building, unit }, properties]) => {
                    const params = this.getParamsToGoNextOrPrevPropertyNavigation(
                        building,
                        unit,
                        properties,
                        this.getPreviousIndexOfArray
                    );

                    return new propertyNavigationActions.PropertyNavigationSelectProperty(params);
                });
        });

    @Effect()
    selectNextPropertyNavigationItem$: Observable<Action> = this.actions$
        .pipe(
            ofType(
                propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_NEXT_PROPERTY_NAVIGATION_ITEM
            )
        )
        .switchMap(() => {
            return Observable.combineLatest(
                this.propertyNavigationService.navigationSelection$,
                this.propertiesService.propertiesAfterLoading$
            )
                .first()
                .map(([{ building, unit }, properties]) => {
                    const params = this.getParamsToGoNextOrPrevPropertyNavigation(
                        building,
                        unit,
                        properties,
                        this.getNextIndexOfArray
                    );

                    return new propertyNavigationActions.PropertyNavigationSelectProperty(params);
                });
        });

    private getParamsToGoNextOrPrevPropertyNavigation(
        building: any,
        unit: any,
        properties: any,
        getIndexFunction: any
    ): IPropertyNavigationParams {
        if (!this.isItUnitLevel(building, unit) || !building) {
            const itemsToSelect = [
                {}, // My portfolio
                ...chain(properties)
                    .map('propertyObject')
                    .filter((building: IBuildingModel) => {
                        return (
                            isSingleBuildingUtil(building) ||
                            (isMultiBuildingUtil(building) && !building.isCondo) ||
                            some(
                                building.units,
                                (unit: IUnitModel) =>
                                    this.isItCondoUnit(unit, building) &&
                                    !this.hasPMRoleOnBuilding(building)
                            )
                        );
                    })
                    .map((building: IBuildingModel) => {
                        if (this.isItSingleOrMultiBuildingButNotCondo(building)) {
                            return building;
                        } else if (
                            this.isItCondoBuilding(building) &&
                            !this.hasPMRoleOnBuilding(building) &&
                            some(building.units, (unit: IUnitModel) =>
                                this.isItCondoUnit(unit, building)
                            )
                        ) {
                            return building.units;
                        }
                    })
                    .flatten()
                    .value()
            ];

            let indexOfCurrentItem =
                unit || building
                    ? findIndex(itemsToSelect, { id: unit ? unit.id : building.id })
                    : 0;
            const nextItem: any =
                itemsToSelect[getIndexFunction(indexOfCurrentItem, itemsToSelect)];

            let params: IPropertyNavigationParams = {};

            if (nextItem.id) {
                params = {
                    propertyBuildingId: nextItem.building || nextItem.id
                };

                if (nextItem.building) {
                    params = { ...params, propertyUnitId: nextItem.id };
                }
            }

            return params;
        } else {
            const units = building.units;

            const indexOfCurrentUnit = findIndex(units, { id: unit.id });
            const previousUnit = units[getIndexFunction(indexOfCurrentUnit, units)] as IUnitModel;

            return {
                propertyBuildingId: building.id,
                propertyUnitId: previousUnit.id
            };
        }
    }

    private hasPMRoleOnBuilding(building: any): boolean {
        return hasRolePropertyManager(building);
    }

    private isItCondoUnit(unit: any, building: any): boolean {
        return unit && building.isCondo && unit.type === IUnitType.Apartment;
    }

    private isItUnitLevel(building: any, unit: any): boolean {
        return (
            unit &&
            ((isMultiBuildingUtil(building) && !building.isCondo) ||
                (this.isItCondoUnit(unit, building) && this.hasPMRoleOnBuilding(building)))
        );
    }

    private isItSingleOrMultiBuildingButNotCondo(building: any): boolean {
        return (
            isSingleBuildingUtil(building) || (isMultiBuildingUtil(building) && !building.isCondo)
        );
    }

    private isItCondoBuilding(building: any): boolean {
        return isMultiBuildingUtil(building) && building.isCondo;
    }

    private getPreviousIndexOfArray(index: number, array: any[]): number {
        if (index <= 0) {
            return array.length - 1;
        } else {
            return index - 1;
        }
    }

    private getNextIndexOfArray(index: number, array: any[]): number {
        if (index >= array.length - 1) {
            return 0;
        } else {
            return index + 1;
        }
    }
}
