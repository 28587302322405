import { IExpenseContractPeriod } from '../';
import { RENTAL_CONTRACT } from '../../contract/constants/contract.constants';
import { IExpenseContract } from '../interfaces/new-expense-contract.interface';
import { IRentalContract } from '../../contract/interfaces/rental-contract.interface';
import { IExpenseContractType } from '../enums/new-expense-contracts.enum';

export const isRentalContract = (contract: any): contract is IRentalContract => {
    return contract && (contract.type === RENTAL_CONTRACT || !!contract.leases);
};

export const isExpenseContractType = (contract: any): contract is IExpenseContract => {
    return !!contract && contract.type === IExpenseContractType.ExpenseContract;
};

export const isExpenseContractPeriod = (a: any): a is IExpenseContractPeriod => {
    return !!a && !!a.invoices && !!a.items;
};
