import { NgModule, ModuleWithProviders, Optional, SkipSelf, Inject } from '@angular/core';
import { AnalyticsService } from './services/analytics.service';
import { CookieService } from './services/cookie.service';
import { IAnalyticsConfig, AnalyticsConfigToken } from './analytics.config';
import { EffectsModule } from '@ngrx/effects';
import { AnalyticsEffects } from './state/effects/analytics.effects';
import { Analytics } from '@zenhomes/analytics-utils';
import { environment } from 'environments/environment';
import { getParentFrameDomain } from '../main/util/get-parent-frame-domain.util';

@NgModule({
    imports: [EffectsModule.forFeature([AnalyticsEffects])]
})
export class AnalyticsModule {
    static forRoot(config: IAnalyticsConfig): ModuleWithProviders {
        return {
            ngModule: AnalyticsModule,
            providers: [
                { provide: AnalyticsConfigToken, useValue: config },
                AnalyticsService,
                CookieService
            ]
        };
    }

    constructor(
        cookieService: CookieService,
        @Inject(AnalyticsConfigToken) config: IAnalyticsConfig,
        @Optional()
        @SkipSelf()
        parentModule: AnalyticsModule
    ) {
        if (parentModule) throw new Error('AnalyticsModule is already loaded!');

        Analytics.install({
            parentIFrameDomain: getParentFrameDomain()
        });

        if (
            ![
                window.location.host,
                'vermietet.de',
                'www.vermietet.de',
                'portal.vermietet.de'
            ].filter(function(domain) {
                return document.referrer.match(new RegExp('^(https?://)?' + domain + '(/.*)?$'));
            }).length
        ) {
            cookieService.set(
                'zhm-ref',
                document.referrer,
                90 /*new Date('Tue, 31 Dec 2030 12:00:00 UTC')*/,
                '/',
                window.location.host.match(
                    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                )
                    ? window.location.host
                    : '.' + window.location.host.split('.').splice(-2)
            );
        }
    }
}
