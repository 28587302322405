<div class="modal-content-height">
    <zhm-modal-dialog-header class="custom-header">
        <div class="modal-title flex flex-column">
            <button type="button" class="close" aria-label="Close" (click)="onCloseClick($event)">
                <i aria-hidden="true" class="vicon icon-remove-thin text-xsmall-dark"></i>
            </button>
            <h5 class="text-bold title" translate>documents.editFileInformation</h5>
            <div class="document-file row align-items-center py-3 mt-3 mb-4">
                <div class="text-small-dark flex align-items-center col-10">
                    <i class="vicon document-icon mr-3" [ngClass]="getDocIconClass()"></i>
                    <span class="overflow-ellipsis">{{ fileName() }}</span>
                </div>
                <div class="col-2 flex justify-content-end">
                    <button
                        class="btn btn-outline-primary p-0 position-flex-center"
                        (click)="preview($event)"
                    >
                        <i class="icon_operations icon-sol_preview"></i>
                    </button>
                </div>
            </div>
        </div>
    </zhm-modal-dialog-header>

    <zhm-modal-dialog-body class="w-80 body-no-padding">
        <div class="file-attributes">
            <document-form
                [document]="document"
                [disabled]="false"
                [docTypeIsOptional]="true"
                [showActionButtons]="false"
                (onSaveDocument)="onSaveDocument.emit(document)"
                (onCancelDocument)="onCancelDocument.emit()"
            >
            </document-form>
        </div>
    </zhm-modal-dialog-body>

    <zhm-modal-dialog-footer>
        <div class="flex justify-content-between justify-content-md-end flex-1 h-100">
            <button
                type="button"
                (click)="onCloseClick($event)"
                class="btn btn-outline-secondary btn-square mr-0 mr-md-4"
                translate
            >
                action.cancel
            </button>
            <button
                type="button"
                (click)="onSaveClick($event)"
                class="btn btn-success btn-square"
                translate
            >
                action.save
            </button>
        </div>
    </zhm-modal-dialog-footer>
</div>
