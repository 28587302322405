import { IBuildingModel, IPropertySelection } from '@zenhomes/domain/property';
import { isApartmentBuilding, isCondoBuildingPM } from '@zenhomes/domain/property';
import { isEmpty, isString } from 'lodash';

export function isBuildingAndUnitSelectable(building: IBuildingModel): boolean {
    if (isEmpty(building)) return false;
    return isApartmentBuilding(building) || isCondoBuildingPM(building);
}

export function getPropertySelectionKey(p: IPropertySelection) {
    return p.unit ? `${p.building.id}-${p.unit.id}` : p.building.id;
}

export function parsePropertyKey(propertyKey: string) {
    const splitted = isString(propertyKey) ? propertyKey.split('-') : [];

    switch (true) {
        case splitted.length === 2:
            return { buildingId: splitted[0], unitId: splitted[1] };
        case splitted.length === 1 && !isEmpty(splitted[0]):
            return { buildingId: splitted[0], unitId: null };
        default:
            return null;
    }
}
