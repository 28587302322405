import {
    IPropertyNavigationParams,
    IProperty,
    isSingleBuildingUtil
} from '@zenhomes/domain/property';
import { isBuildingAndUnitSelectable } from '@zenhomes/property-core';

export function getPropertyNavigationParamsForProperty(
    property: IProperty
): IPropertyNavigationParams {
    const result: IPropertyNavigationParams = { propertyBuildingId: property.propertyObject.id };

    switch (true) {
        case isSingleBuildingUtil(property.propertyObject): {
            return result;
        }
        case isBuildingAndUnitSelectable(property.propertyObject): {
            return result;
        }
        default: {
            return { ...result, propertyUnitId: property.propertyObject.units[0].id };
        }
    }
}
