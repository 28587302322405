import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ExpenseContractsPeriodsApiService {
    constructor(private http: HttpClient) {}

    deletePeriod(contractId: string, periodId: string): Observable<void> {
        return this.http.delete<void>(`/contracts/${contractId}/periods/${periodId}`);
    }
}
