import { ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';
import * as fromPropertyNavigationReducer from './reducers/property-navigation.reducer';

export interface IMainModuleState {
    propertyNavigation: fromPropertyNavigationReducer.IPropertyNavigationState;
}

export const NAVIGATION_MODULE_INIT_STATE: IMainModuleState = {
    propertyNavigation: fromPropertyNavigationReducer.initialPropertyNavigationStoreState
};

export const reducers: ActionReducerMap<IMainModuleState> = {
    propertyNavigation: fromPropertyNavigationReducer.propertyNavigationReducer
};

// main selectors
export const getPropertyNavigationState = (state: any) => state.NavigationModule.propertyNavigation;

// selectors
export const getContentNavigationItems = createSelector(
    getPropertyNavigationState,
    (state: fromPropertyNavigationReducer.IPropertyNavigationState) => state.contentSelection.items
);
export const getContentNavigationSelectedItem = createSelector(
    getPropertyNavigationState,
    (state: fromPropertyNavigationReducer.IPropertyNavigationState) =>
        state.contentSelection.selectedItem
);
export const getPropertyNavigationSelectedProperty = createSelector(
    getPropertyNavigationState,
    (state: fromPropertyNavigationReducer.IPropertyNavigationState) =>
        state.propertySelection.selectedProperty
);
export const getPropertyNavigationSelectedBuilding = createSelector(
    getPropertyNavigationState,
    (state: fromPropertyNavigationReducer.IPropertyNavigationState) =>
        state.propertySelection.selectedBuilding
);
export const getPropertyNavigationSelectedUnit = createSelector(
    getPropertyNavigationState,
    (state: fromPropertyNavigationReducer.IPropertyNavigationState) =>
        state.propertySelection.selectedUnit
);
