import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { get } from 'lodash';
import { IContact } from '@zenhomes/domain/contact';
import { ContactsService } from '../../services/contacts.service';
import { fillAtLeaseOne } from '../../validators/fill-at-least-one-field.validator';
import { UserService, UserApiService } from '@zenhomes/user-core';
import { IUser } from '@zenhomes/domain/user';
import { Subject } from 'rxjs/Subject';
import { switchMap, tap, takeUntil, finalize, take } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';

@Component({
    selector: 'contact-form',
    templateUrl: 'contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {
    @Input() contact: any;
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<null> = new EventEmitter();
    @Output() onSaveModel = new EventEmitter<any>();

    form: FormGroup;
    isLoading: boolean;

    model: IContact;
    itIsContactForm: boolean;

    validationMessages = {
        companyName: {
            fillAtLeaseOne: 'errors.addressBook.companyNameOrNameAreRequired'
        },
        default: 'errors.default'
    };

    protected componentDestroyed = new Subject<void>();
    user: IUser;

    constructor(
        private formBuilder: FormBuilder,
        private contactsService: ContactsService,
        private userApiService: UserApiService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.model = this.contact ? this.contact : {};
        this.itIsContactForm = !this.contact || (this.contact && !this.contact.customerId);

        this.form = this.formBuilder.group({
            email: [
                {
                    value: get(this.contact, 'email'),
                    disabled: !this.itIsContactForm
                }
            ],
            name: [get(this.contact, 'name')],
            iban: [get(this.contact, 'iban')],
            bic: [get(this.contact, 'bic')],
            webSiteUrl: [get(this.contact, 'webSiteUrl')],
            phone: [get(this.contact, 'phone')],
            address: this.formBuilder.group({
                zip: [get(this.contact, 'address.zip')],
                city: [get(this.contact, 'address.city')],
                street: [get(this.contact, 'address.street')],
                house: [get(this.contact, 'address.house')],
                countryCode: [get(this.contact, 'address.countryCode') || 'DE']
            })
        });

        this.form.addControl(
            'companyName',
            new FormControl(get(this.contact, 'companyName'), fillAtLeaseOne(this.form.get('name')))
        );

        // we need this to update validity of companyName field because it depends on name control's value
        this.form
            .get('name')
            .valueChanges.takeUntil(this.componentDestroyed)
            .subscribe(() => this.form.get('companyName').updateValueAndValidity());

        const savingObservable: Observable<any> = !this.itIsContactForm
            ? this.handleSavingOfUser(this.onSaveModel)
            : this.handleSavingOfContact(this.onSaveModel);

        savingObservable
            .pipe(
                finalize(() => (this.isLoading = false)),
                takeUntil(this.componentDestroyed)
            )
            .subscribe((data: any) => this.onSave.emit(data));

        this.userService.user$
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(user => (this.user = user));
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    switchNameAndCompanyName() {
        const companyName = this.form.value.companyName || '';
        const name = this.form.value.name || '';

        const switchPatchValue = {
            companyName: name,
            name: companyName
        };

        this.form.patchValue(switchPatchValue);
    }

    get isUserTheContact() {
        return this.contact && this.user.customerId === this.contact.customerId;
    }

    showErrorsByField(field: string): any {
        const control: any = get(this.form.controls, field);

        switch (field) {
            case 'companyName': {
                return !!get(control, 'errors.fillAtLeaseOne')
                    ? 'errors.addressBook.companyNameOrNameAreRequired'
                    : null;
            }
            default:
                return null;
        }
    }

    onSubmit($event?: Event): void {
        if (this.form.invalid) {
            $event.preventDefault();
            return;
        }

        const result = { ...this.model, ...this.form.value };

        this.onSaveModel.emit(result);
    }

    private handleSavingOfUser(observable: Observable<any>) {
        return observable.pipe(
            switchMap((user: IUser) => {
                return this.userApiService.updateUser(user);
            }),
            tap(() => {
                this.userService.loadUser();
            })
        );
    }

    private handleSavingOfContact(observable: Observable<any>) {
        return observable.pipe(
            tap((contact: IContact) => {
                const method = contact.id ? 'updateContact' : 'createContact';

                this.contactsService[method](contact);
            }),
            switchMap(() => {
                return merge(
                    this.contactsService.contactCreatedSuccessfully$,
                    this.contactsService.contactUpdatedSuccessfully$
                ).pipe(take(1));
            })
        );
    }
}
