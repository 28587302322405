import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IInvoice } from '@zenhomes/domain/invoice';
import { BookmarkableFilterOptions } from '@zenhomes/filter-core';
import { ofAction } from '@zenhomes/ngrx-actions';
import { Observable } from 'rxjs/Observable';
import { InvoiceState } from '../constants/invoice-state.constants';
import * as fromState from '../state';
import * as InvoiceActions from '../state/actions/invoice.actions';
import { toPayload } from '@util/to-payload.util';

@Injectable()
export class InvoiceService {
    constructor(private store: Store<InvoiceState>, private actions$: Actions) {}

    filterOptions$: Observable<BookmarkableFilterOptions> = this.store.select(
        fromState.getFilterOptions
    );

    invoiceDeleteSuccess$: Observable<IInvoice> = ofAction(
        InvoiceActions.InvoiceCoreDeleteInvoiceSuccess
    )(this.actions$).map(toPayload);

    deleteInvoice(id: string) {
        this.store.dispatch(new InvoiceActions.InvoiceCoreDeleteInvoice(id));
    }

    updateNoteText(text: string, invoiceId: string) {
        this.store.dispatch(new InvoiceActions.UpdateNoteText({ text, invoiceId }));
    }

    reset() {
        this.store.dispatch(new InvoiceActions.InvoiceCoreResetInvoices());
    }
}
