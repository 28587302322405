import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

// fix for date pipes in Safari https://github.com/angular/angular/issues/3333
import 'intl';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/en';

import { map } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '@zenhomes/language-selection';

@Injectable({
    providedIn: 'root'
})
export class LocaleSetupService {
    constructor(private translate: TranslateService, private languageService: LanguageService) {}

    setupLocale() {
        registerLocaleData(localeDe, 'de-EU', localeDeExtra);
    }

    configureTranslations() {
        this.translate.addLangs(<string[]>map(this.languageService.languages, 'language'));
        this.translate.setDefaultLang(this.languageService.languages[0].language);
        this.translate.use(this.languageService.getLanguage());
    }
}
