import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

@Pipe({
    name: 'decimal'
})
export class DecimalPipe implements PipeTransform {
    transform(value: number | string): string {
        let result = '';

        if (!isNull(value) && !isUndefined(value)) {
            result = value.toString().replace(/\./g, ',');
        }

        return result;
    }
}
