import { curry, eqBy, symmetricDifferenceWith, prop } from 'ramda';

import { IInvoice } from '@zenhomes/domain/invoice';

const eqByBankTransferId = eqBy(prop('id'));

export const isInvoiceSelected = curry((invoices: IInvoice[], invoice: IInvoice) => {
    return invoices.some(eqByBankTransferId(invoice));
});

export const toggleInvoice = (invoicesSelected: IInvoice[], invoice: IInvoice): IInvoice[] =>
    symmetricDifferenceWith(eqByBankTransferId, invoicesSelected, [invoice]);
