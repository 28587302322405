export class BlinkMessage {
    visible: boolean;
    text: string;
    type: 'EMPTY' | 'OK' | 'ERROR';

    constructor() {
        this.text = null;
        this.type = 'EMPTY';
        this.visible = false;
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    showForSeconds(seconds: number) {
        this.show();
        setTimeout(() => {
            this.hide();
        }, seconds * 1000);
    }
}
