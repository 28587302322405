import { IBuildingModel, IUnitModel, IPropertyUsage } from '@zenhomes/domain/property';
import { isObject, map } from 'lodash';

export function sanitizeBuildingModel(building: IBuildingModel): IBuildingModel {
    if (!isObject(building)) return building;

    const address = building.address || {};
    const units = map(building.units || [], sanitizeUnitModel);

    return { ...building, address, units };
}

export function sanitizeUnitModel(unit: IUnitModel): IUnitModel {
    if (!isObject(unit)) return unit;

    const usages = map(unit.usages || [], sanitizeUsageModel);

    return { ...unit, usages };
}

export function sanitizeUsageModel(lease: IPropertyUsage): IPropertyUsage {
    if (!isObject(lease)) return lease;

    const tenants = lease.tenants || [];

    return { ...lease, tenants };
}
