import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Analytics } from '@zenhomes/analytics-utils';
import { IUser } from '../../../modules/interfaces';

@Injectable()
export class AnalyticsService {
    identifyUser(user: IUser) {
        if (isEmpty(user) || isEmpty(user.customerId)) return;

        Analytics.identify(user.customerId, {
            is_scout_user: user.origin === 'IS24',
            immoscout_customer_id: user.immoScoutCustomerId
        });
    }

    trackEvent(event: string, payload: any) {
        Analytics.trackEvent(event, payload);
    }

    onPathChange() {
        Analytics.trackPage();
    }
}
