import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ElementsModule } from '@zenhomes/elements';

import { EditSectionOverlayComponent } from './components/edit-section-overlay/edit-section-overlay.component';
import { SectionEditorHeaderComponent } from './components/side-editor-header/section-editor-header.component';
import { HoverActionsOverlayComponent } from './components/hover-actions-overlay/hover-actions-overlay.component';
import { ShowFullscreenIframeComponent } from './components/show-fullscreen-iframe/show-fullscreen-iframe.component';

const COMPONENTS = [
    EditSectionOverlayComponent,
    SectionEditorHeaderComponent,
    HoverActionsOverlayComponent,
    ShowFullscreenIframeComponent
];

@NgModule({
    imports: [CommonModule, ElementsModule, TranslateModule.forChild()],
    declarations: [COMPONENTS],
    exports: [COMPONENTS]
})
export class IFrameHustlaUiModule {}
