import { Action } from '@ngrx/store';
import { typeWithNamespace } from '@util/type-cache.util';
import { IUncategorizedPaymentStats } from '@payment-core/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentListFilterOptions } from '@payment-core/interfaces';
import { ParamMap } from '@angular/router';
import { PageableClusteredPaymentsModel } from '@payment-core/interfaces';
import { IClusteredPayment, IPayment } from '@zenhomes/domain/payment';

const PAYMENT_CORE = 'PaymentCore';

export class PaymentCoreLoadPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payments');
    readonly type = PaymentCoreLoadPayments.TYPE;

    constructor() {}
}

export class PaymentCoreReloadPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Reload Payments');
    readonly type = PaymentCoreReloadPayments.TYPE;

    constructor() {}
}

export class PaymentCoreLoadMorePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load More Payments');
    readonly type = PaymentCoreLoadMorePayments.TYPE;

    constructor() {}
}

export class PaymentCoreLoadMissingPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Missing Payments');
    readonly type = PaymentCoreLoadMissingPayments.TYPE;

    constructor() {}
}

export class PaymentCoreLoadPaymentsSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payments Success');
    readonly type = PaymentCoreLoadPaymentsSuccess.TYPE;

    constructor(public payload: PageableClusteredPaymentsModel, public count: number) {}
}

export class PaymentCoreCheckPaymentsCanBeLoaded implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Check If Payments Can Be Loaded');
    readonly type = PaymentCoreCheckPaymentsCanBeLoaded.TYPE;

    constructor(
        public payments: PageableClusteredPaymentsModel,
        public count: number,
        public filterOptions: PaymentListFilterOptions
    ) {}
}

export class PaymentCoreLoadPaymentsFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payments Fail');
    readonly type = PaymentCoreLoadPaymentsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreLoadPayment implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payment');
    readonly type = PaymentCoreLoadPayment.TYPE;

    constructor(public payload: IPayment) {}
}

export class PaymentCoreLoadPaymentSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payment Success');
    readonly type = PaymentCoreLoadPaymentSuccess.TYPE;

    constructor(public payload: IPayment) {}
}

export class PaymentCoreLoadPaymentFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Payment Fail');
    readonly type = PaymentCoreLoadPaymentFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreUpdatePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Update payments');
    readonly type = PaymentCoreUpdatePayments.TYPE;

    constructor(public payload: IClusteredPayment[], public count: number) {}
}

export class PaymentCoreUpdatePaymentsDebounced implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Update payments debounced');
    readonly type = PaymentCoreUpdatePaymentsDebounced.TYPE;

    constructor(public payload: IClusteredPayment[], public count: number) {}
}

export class PaymentCoreGetPayment implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Get Payment');
    readonly type = PaymentCoreGetPayment.TYPE;

    constructor() {}
}

export class PaymentCoreGetPaymentSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Get Payment Success');
    readonly type = PaymentCoreGetPaymentSuccess.TYPE;

    constructor(public payload: IPayment) {}
}

export class PaymentCoreGetPaymentFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Get Payment Fail');
    readonly type = PaymentCoreGetPaymentFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreDeletePayment implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Delete Payment');
    readonly type = PaymentCoreDeletePayment.TYPE;

    constructor(public payload: string) {}
}

export class PaymentCoreDeletePaymentSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Delete Payment Success');
    readonly type = PaymentCoreDeletePaymentSuccess.TYPE;

    constructor(public payload: IPayment) {}
}

export class PaymentCoreDeletePaymentFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Delete Payment Fail');
    readonly type = PaymentCoreDeletePaymentFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreUpdateListFilters implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Update list filters');
    readonly type = PaymentCoreUpdateListFilters.TYPE;
    constructor(
        public filterOptions: PaymentListFilterOptions,
        public cleanPreviousPayments: boolean = true
    ) {}
}

export class PaymentCoreClearListFilters implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Clear list filters');
    readonly type = PaymentCoreClearListFilters.TYPE;
    constructor() {}
}

export class PaymentCoreUpdateParseFiltersFromQueryString implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Parse filters from query string');
    readonly type = PaymentCoreUpdateParseFiltersFromQueryString.TYPE;
    constructor(public paramMap: ParamMap) {}
}

export class PaymentCoreRefreshQueryString implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Refresh query string');
    readonly type = PaymentCoreRefreshQueryString.TYPE;
    constructor() {}
}

export class PaymentCoreResetPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Reset Payments');
    readonly type = PaymentCoreResetPayments.TYPE;

    constructor() {}
}

export class PaymentCoreLoadTransferCounterparties implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Transfer Counterparties');
    readonly type = PaymentCoreLoadTransferCounterparties.TYPE;
    constructor() {}
}

export class PaymentCoreLoadTransferCounterpartiesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Transfer Counterparties Success');
    readonly type = PaymentCoreLoadTransferCounterpartiesSuccess.TYPE;

    constructor(public transferCounterparties: string[]) {}
}

export class PaymentCoreLoadTransferCounterpartiesFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load Transfer Counterparties Fail');
    readonly type = PaymentCoreLoadTransferCounterpartiesFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreSnoozePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Snooze payments');
    readonly type = PaymentCoreSnoozePayments.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreUnsnoozePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Unsnooze payments');
    readonly type = PaymentCoreUnsnoozePayments.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreIgnorePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Ignore payments');
    readonly type = PaymentCoreIgnorePayments.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreUnignorePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Unignore payments');
    readonly type = PaymentCoreUnignorePayments.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreTogglePayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Toggle payments');
    readonly type = PaymentCoreTogglePayments.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreSelectAllPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Select all payments');
    readonly type = PaymentCoreSelectAllPayments.TYPE;

    constructor() {}
}

export class PaymentCoreCleanPaymentsSelected implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Clean payments selected');
    readonly type = PaymentCoreCleanPaymentsSelected.TYPE;

    constructor(public payload: IPayment[]) {}
}

export class PaymentCoreSnoozePaymentsSelected implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Snooze payments selected');
    readonly type = PaymentCoreSnoozePaymentsSelected.TYPE;

    constructor() {}
}

export class PaymentCoreIgnorePaymentsSelected implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Ignore payments selected');
    readonly type = PaymentCoreIgnorePaymentsSelected.TYPE;

    constructor() {}
}

export class PaymentCoreUncategorizePaymentsSelected implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Uncategorize payments selected');
    readonly type = PaymentCoreUncategorizePaymentsSelected.TYPE;

    constructor() {}
}

export class PaymentCoreUncategorizePaymentsSuccess implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Uncategorize Payments Success');
    readonly type = PaymentCoreUncategorizePaymentsSuccess.TYPE;

    constructor() {}
}

export class PaymentCoreUncategorizePaymentsFail implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Uncategorize Payments Fail');
    readonly type = PaymentCoreUncategorizePaymentsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreResetPaymentsSelected implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Reset payments selected');
    readonly type = PaymentCoreResetPaymentsSelected.TYPE;

    constructor() {}
}

export class PaymentCoreUpdateShouldLoadMissingPayments implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Update should load missing payments');
    readonly type = PaymentCoreUpdateShouldLoadMissingPayments.TYPE;

    constructor(public payload: boolean) {}
}

export class PaymentCoreLoadUncategorizedPaymentsStats implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Load uncategorized payments stats');
    readonly type = PaymentCoreLoadUncategorizedPaymentsStats.TYPE;

    constructor() {}
}

export class PaymentCoreLoadUncategorizedPaymentsStatsSuccess implements Action {
    static readonly TYPE = typeWithNamespace(
        PAYMENT_CORE,
        'Load uncategorized payments stats Success'
    );
    readonly type = PaymentCoreLoadUncategorizedPaymentsStatsSuccess.TYPE;

    constructor(public stats: IUncategorizedPaymentStats) {}
}

export class PaymentCoreLoadUncategorizedPaymentsStatsFail implements Action {
    static readonly TYPE = typeWithNamespace(
        PAYMENT_CORE,
        'Load uncategorized payments stats Fail'
    );
    readonly type = PaymentCoreLoadUncategorizedPaymentsStatsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class PaymentCoreMarkPaymentsAsHidden implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Mark payments as hidden');
    readonly type = PaymentCoreMarkPaymentsAsHidden.TYPE;

    constructor(public payments: IPayment[]) {}
}

export class PaymentCoreSaveInitialPaymentSelection implements Action {
    static readonly TYPE = typeWithNamespace(PAYMENT_CORE, 'Save initial payment selection');
    readonly type = PaymentCoreSaveInitialPaymentSelection.TYPE;

    constructor(public payload: IPayment[]) {}
}
