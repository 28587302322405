import { Pipe, PipeTransform } from '@angular/core';

export const DEFAULT_PLACEHOLDER = '-';

@Pipe({ name: 'zhmFloorNumber' })
export class FloorNumberPipe implements PipeTransform {
    transform(floorNumber: number, defaultPlaceholder: string = DEFAULT_PLACEHOLDER): string {
        switch (true) {
            case floorNumber < 0: {
                return `${Math.abs(floorNumber)} UG`;
            }
            case floorNumber === 0: {
                return 'EG';
            }
            case floorNumber > 0: {
                return `${floorNumber} OG`;
            }
            default: {
                return defaultPlaceholder;
            }
        }
    }
}
