import { BookmarkableFilterOptions } from '../interfaces';
import { FilterSectionType } from '../constants/filter.constants';

export function clearFilterSection(
    filterSection: FilterSectionType,
    options: BookmarkableFilterOptions,
    defaultOptions: BookmarkableFilterOptions
): BookmarkableFilterOptions {
    switch (filterSection) {
        case 'category': {
            return {
                ...options,
                subjectSelection: defaultOptions.subjectSelection,
                userCategorySelection: defaultOptions.userCategorySelection
            };
        }
        case 'property': {
            return { ...options, propertySelection: defaultOptions.propertySelection };
        }
        case 'counterparty': {
            return { ...options, counterpartySelection: defaultOptions.counterpartySelection };
        }
        case 'payment': {
            return {
                ...options,
                amountFrom: defaultOptions.amountFrom,
                amountTo: defaultOptions.amountTo,
                status: defaultOptions.status,
                transactionType: defaultOptions.transactionType
            };
        }
        case 'dates': {
            return {
                ...options,
                startDate: defaultOptions.startDate,
                endDate: defaultOptions.endDate
            };
        }
        case 'subject': {
            return { ...options, query: defaultOptions.query };
        }
        default: {
            return { ...options };
        }
    }
}
