import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { PropertyNavigationService } from './property-navigation.service';
import { NavigationHelpersService } from './navigation-helpers.service';

@Injectable()
export class NavigationModuleInitializer {
    constructor(
        private router: Router,
        private navigationHelpersService: NavigationHelpersService,
        private propertyNavigationService: PropertyNavigationService
    ) {}

    init() {
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe((event: NavigationEnd) => {
                this.onNavigationEnd(event);
            });
    }

    onNavigationEnd(event: NavigationEnd) {
        const commands = event.urlAfterRedirects
            .split('/')
            .filter(s => s.length > 0)
            .map(s => s.split('#')[0]);

        const params = this.navigationHelpersService.parseRouterCommands({ commands });

        this.propertyNavigationService.determineNavigationItems(params);
    }
}
