import { InjectionToken } from '@angular/core';

export const DocumentsCoreConfigToken = new InjectionToken<DocumentsCoreConfig>(
    'documents-core.module.config'
);

export interface DocumentsCoreConfig {
    uploadDocumentUrl: string;
    apiHost: string;
}
