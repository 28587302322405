import { CONTENT_SELECTION_ITEMS } from '../constants/content-selection-items';
import { IProperty } from '@zenhomes/domain/property';
import { IBuildingModel } from '@zenhomes/domain/property';
import { IUnitModel } from '@zenhomes/domain/property';
import { IContentNavigationItem } from '../../interfaces/content-selection-item.interface';
import { propertyNavigationActionTypes } from '../../state/actions/property-navigation.actions';

export interface IPropertyNavigationState {
    propertySelection: {
        selectedProperty: IProperty;
        selectedBuilding: IBuildingModel;
        selectedUnit: IUnitModel;
    };
    contentSelection: {
        items: IContentNavigationItem[];
        selectedItem: IContentNavigationItem;
    };
}

export const initialPropertyNavigationStoreState: IPropertyNavigationState = {
    propertySelection: {
        selectedProperty: null,
        selectedBuilding: null,
        selectedUnit: null
    },
    contentSelection: {
        items: CONTENT_SELECTION_ITEMS,
        selectedItem: null
    }
};

export function propertyNavigationReducer(
    state: IPropertyNavigationState = initialPropertyNavigationStoreState,
    action: any
): IPropertyNavigationState {
    switch (action.type) {
        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_CONTENT_SELECTION_ITEM: {
            let { item } = action.payload;

            return Object.assign({}, state, {
                contentSelection: Object.assign({}, state.contentSelection, {
                    selectedItem: item
                })
            });
        }

        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_PROPERTY_NAVIGATION_ITEM: {
            let { property, building, unit } = action.payload;

            let selectedItem =
                state.contentSelection.selectedItem ||
                initialPropertyNavigationStoreState.contentSelection.items[0];

            return Object.assign({}, state, {
                contentSelection: Object.assign({}, state.contentSelection, {
                    selectedItem: selectedItem
                }),
                propertySelection: Object.assign({}, state.propertySelection, {
                    selectedProperty: property,
                    selectedBuilding: building,
                    selectedUnit: unit
                })
            });
        }

        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_SELECT_BOTH_SELECTION_ITEMS:
        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_UPDATE_NAVIGATION_ITEMS: {
            let { property, building, unit, item } = action.payload;

            return Object.assign({}, state, {
                propertySelection: Object.assign({}, state.propertySelection, {
                    selectedProperty: property,
                    selectedBuilding: building,
                    selectedUnit: unit
                }),
                contentSelection: Object.assign({}, state.contentSelection, {
                    selectedItem: item
                })
            });
        }

        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING_SUCCESS: {
            return Object.assign({}, state, {
                propertySelection: Object.assign({}, state.propertySelection, {
                    selectedBuilding: action.payload
                })
            });
        }

        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT_SUCCESS: {
            return Object.assign({}, state, {
                propertySelection: Object.assign({}, state.propertySelection, {
                    selectedUnit: action.payload
                })
            });
        }

        case propertyNavigationActionTypes.PROPERTY_NAVIGATION_RESET: {
            return initialPropertyNavigationStoreState;
        }

        default: {
            return state;
        }
    }
}
