import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { IBuildingAddress } from '@zenhomes/domain/property';
import { IMapsModuleConfig, MapsConfigToken } from '../../maps.config';
import { createStaticMapImgUrl } from '../../util/google-map-image.url';

@Component({
    selector: 'zhm-address-map',
    templateUrl: './address-map.component.html',
    styleUrls: ['./address-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressMapComponent implements OnInit {
    @Input() address: IBuildingAddress;
    @Input() hideAddress: boolean;

    constructor(@Inject(MapsConfigToken) private config: IMapsModuleConfig) {}

    ngOnInit() {}

    getStaticMapUrl() {
        return createStaticMapImgUrl(
            this.address,
            this.config.googleApiKey,
            this.config.publicImgUrl
        );
    }

    getDefaultMap(evt: any) {
        evt.target.src = 'img/earth-map.png';
    }
}
