import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDocumentCategory, IDocumentType } from '@zenhomes/domain/document';
import { chain } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { first, map, switchMapTo } from 'rxjs/operators';
import * as fromDocumentsState from '../state/document-core-state';

@Injectable()
export class DocumentCategoriesService {
    documentsCategories$: Observable<IDocumentCategory[]> = this.store.select(
        fromDocumentsState.getDocumentCategoriesList
    );
    documentsTypes$: Observable<IDocumentType[]>;

    documentCategoriesAreLoaded$: Observable<boolean> = this.store.select(
        fromDocumentsState.getDocumentCategoriesAreLoaded
    );
    documentCategoriesAreLoading$: Observable<boolean> = this.store.select(
        fromDocumentsState.getDocumentCategoriesAreLoading
    );
    documentCategoriesAfterLoading$: Observable<IDocumentCategory[]>;

    constructor(private store: Store<any>) {
        this.documentCategoriesAfterLoading$ = this.documentCategoriesAreLoading$.pipe(
            switchMapTo(this.documentsCategories$),
            first()
        );

        this.documentsTypes$ = this.documentsCategories$.pipe(
            map(categories => {
                return <IDocumentType[]>chain(categories)
                    .map(category => category.documentTypes)
                    .flatten()
                    .uniqBy((type: IDocumentType) => type.id)
                    .value();
            })
        );
    }
}
