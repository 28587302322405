import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IDocument } from '@zenhomes/domain/document';
import { IDocumentGroup } from '@zenhomes/domain/document';
import { IDocumentsLoadingParams } from '../interfaces/documents-loading-params.interface';
import { convertObjectToHttpParams } from '../utils/convert-object-to-http-params.util';
import { IPageableElementsModel } from '@zenhomes/domain/pagination';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentsApiService {
    constructor(private http: HttpClient) {}

    getDocuments(
        searchParams: IDocumentsLoadingParams = {}
    ): Observable<IPageableElementsModel<IDocument>> {
        const params = convertObjectToHttpParams(searchParams);

        return this.http
            .get<IDocument[]>('/documents', { params })
            .pipe(map(result => new PageableElementsModel(result)));
    }

    getDocumentGroups(searchParams: IDocumentsLoadingParams = {}): Observable<IDocumentGroup[]> {
        const params = convertObjectToHttpParams(searchParams);

        return this.http.get<IDocumentGroup[]>('/documents/grouped', { params });
    }

    getExposeUnitImages(unitId: string): Observable<IDocument[]> {
        return this.http.get<IDocument[]>(`/units/${unitId}/images`);
    }

    getExposeBuildingImages(buildingId: string): Observable<IDocument[]> {
        return this.http.get<IDocument[]>(`/buildings/${buildingId}/images`);
    }

    getExposeUnitFloorPlans(unitId: string): Observable<IDocument[]> {
        return this.http.get<IDocument[]>(`/units/${unitId}/floor-plans`);
    }

    getExposeBuildingFloorPlans(buildingId: string): Observable<IDocument[]> {
        return this.http.get<IDocument[]>(`/buildings/${buildingId}/floor-plans`);
    }

    getBuildingMapImage(buildingId: string): Observable<IDocument> {
        return this.http.get<IDocument>(`/buildings/${buildingId}/images/map`);
    }

    saveDocument(document: IDocument): Observable<IDocument> {
        return this.http.put<IDocument>('/documents/' + document.id, document);
    }

    deleteDocument(document: IDocument): Observable<any> {
        return this.http.delete('/documents/' + document.id);
    }

    reorderDocuments(documents: IDocument[]): Observable<void> {
        return this.http.post<void>(
            '/documents/reorder',
            documents.map(document => ({ id: document.id }))
        );
    }
}
