import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
    constructor() {}

    list(prefix: string = null) {
        let ret = {};
        let arr = document.cookie
            .trim()
            .split(';')
            .filter(i => {
                return !prefix || i.trim().indexOf(prefix) === 0;
            });

        for (let i = 0; i < arr.length; i++) {
            let tmp = arr[i].split('=');
            let key = tmp.shift().trim();
            if ((tmp.length === 1 && tmp[0].trim().length) || tmp.length > 1) {
                ret[key] = tmp.join('=');
            }
        }

        return ret;
    }

    get(name: string) {
        let arr: Array<string> = document.cookie.split(';');
        let key = name + '=';
        let tmp: string;
        let ret: string = '';

        for (let i: number = 0; i < arr.length; i++) {
            tmp = arr[i].replace(/^\s\+/g, '').trim();
            if (tmp.indexOf(key) === 0) {
                ret = tmp.substring(key.length, tmp.length);
            }
        }

        return ret;
    }

    set(
        name: string,
        value: string,
        expireDays: number,
        path: string = '/',
        domain: string = null
    ) {
        let exp: Date = new Date();

        exp.setTime(exp.getTime() + expireDays * 24 * 60 * 60 * 1000);

        document.cookie =
            name +
            '=' +
            value +
            '; ' +
            'expires=' +
            exp.toUTCString() +
            (domain && domain.length > 0 ? '; domain=' + domain : '') +
            (path && path.length > 0 ? '; path=' + path : '');
    }

    drop(name: any) {
        this.set(name, '', -1);
    }
}
