import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-hover-actions-overlay',
    templateUrl: './hover-actions-overlay.component.html',
    styleUrls: ['./hover-actions-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoverActionsOverlayComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
