import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { IContact } from '@zenhomes/domain/contact';
import { ListFilterOptions, IPageableElementsModel } from '@zenhomes/domain/pagination';
import { convertObjectToHttpParams } from '../utilities/convert-object-to-http-params.util';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ContactsApiService {
    constructor(private http: HttpClient) {}

    loadContacts(
        filterOptions: ListFilterOptions = {}
    ): Observable<IPageableElementsModel<IContact>> {
        const params = convertObjectToHttpParams(filterOptions || {});

        return this.http.get<any>('/parties', { params }).pipe(
            map(result => {
                return new PageableElementsModel(result);
            })
        );
    }

    createContact(contact: IContact): Observable<IContact> {
        return this.http
            .post<IContact>('/third_parties', contact)
            .pipe(map(result => result as IContact));
    }

    updateContact(contact: IContact): Observable<IContact> {
        return this.http
            .put<IContact>('/third_parties/' + contact.id, contact)
            .pipe(map(result => result as IContact));
    }

    deleteContact(contact: IContact): Observable<any> {
        return this.http.delete('/third_parties/' + contact.id);
    }
}
