export const getPropertyParams = (buildingId: string | null, unitId: string | null) => {
    switch (true) {
        case !!buildingId && !!unitId: {
            return `?buildingId=${buildingId}&unitId=${unitId}`;
        }
        case !!buildingId && !unitId: {
            return `?buildingId=${buildingId}`;
        }
        case !buildingId && !unitId: {
            return '';
        }
    }
};
