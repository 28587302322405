import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { convertObjectToHttpParams } from '@util/convert-object-to-http-params.util';
import { IUncategorizedPaymentStats, PaymentListFilterOptions } from '@payment-core/interfaces';
import { INCOMING, OUTGOING } from '../payment-core.constants';
import { PaymentMatchingStatusType } from '@zenhomes/domain/invoice';
import { IClusteredPayment, IPayment, IPersistablePayment } from '@zenhomes/domain/payment';
import { formAmountParams, formTransactionType } from '@zenhomes/filter-core';
import { includes } from 'lodash';
import { Observable } from 'rxjs/Observable';

export const DEFAULT_PAGE_SIZE = 10;
export const MAX_PAGE_SIZE = 100;

@Injectable()
export class PaymentApiService {
    constructor(private http: HttpClient) {}

    loadPayments(
        filterOptions: PaymentListFilterOptions
    ): Observable<PageableElementsModel<IClusteredPayment>> {
        const params = formGetPaymentsParams(filterOptions);
        return this.http.get<any>(`/payments`, { params });
    }

    countPayments(filterOptions: PaymentListFilterOptions): Observable<{ count: number }> {
        const params = formGetPaymentsParams(filterOptions);
        return this.http.get<any>(`/payments/count`, { params });
    }

    getPayment(payment: IPayment): Observable<IPayment> {
        return this.http.get<IPayment>(`/payments/${payment.id}`);
    }

    updatePayment(payload: {
        id: string;
        payment: IPersistablePayment;
    }): Observable<IPersistablePayment> {
        return this.http.put<IPersistablePayment>(
            `/payments/${payload.id}/payment-items`,
            payload.payment
        );
    }

    deletePayment(paymentId: string): Observable<IPayment> {
        return this.http.delete<IPayment>(`/payments/${paymentId}`);
    }

    ignorePayments(transferIds: string[]): Observable<string[]> {
        return this.http.post<string[]>(`/payments/ignored`, transferIds);
    }

    unignorePayments(transferIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/payments/ignored`, transferIds);
    }

    snoozePayments(transferIds: string[]): Observable<string[]> {
        return this.http.post<string[]>(`/payments/snoozed`, transferIds);
    }

    unsnoozePayments(transferIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/payments/snoozed`, transferIds);
    }

    uncategorizePayments(ids: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/payments/uncategorize`, { ids });
    }

    resetPayments(transferIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/payments/reset`, { ids: transferIds });
    }

    getTransferCounterparties(): Observable<string[]> {
        return this.http.get<string[]>(`/transfers/counter-parties`);
    }

    getUncategorizedPaymentStats(): Observable<IUncategorizedPaymentStats> {
        return this.http.get<IUncategorizedPaymentStats>(`/payments/statistics`);
    }

    saveInitialPaymentSelection(payments: IPayment[]): Observable<any> {
        return this.http.put<IPayment[]>(`/users/fe-map/INITIAL_PAYMENT_SELECTION`, payments);
    }
}

function formGetPaymentsParams(filterOptions: PaymentListFilterOptions): HttpParams {
    const {
        page = '1',
        size = DEFAULT_PAGE_SIZE.toString(),
        search = '',
        paymentType,
        query,
        startDate,
        endDate,
        bankAccountIbanHash,
        bankAccountNumberHash,
        listView
    } = filterOptions;

    const counterpartyName = filterOptions.counterpartySelection;
    const { amountFrom, amountTo } = formAmountParams(filterOptions);
    const direction = formTransactionType(filterOptions || {}, INCOMING, OUTGOING);
    const matchingStatusFlags = formMatchingStatusFlags(filterOptions.matchingStatus || []);

    const params = convertObjectToHttpParams({
        page,
        size,
        search,
        query,
        counterpartyName,
        startDate,
        endDate,
        amountFrom,
        amountTo,
        direction,
        type: paymentType,
        listView,
        bankAccountIbanHash,
        bankAccountNumberHash,
        ...matchingStatusFlags
    });

    return params;
}

function formMatchingStatusFlags(statuses: PaymentMatchingStatusType[]) {
    const ignored = includes(statuses, 'IGNORED') ? true : null;
    const snoozed = includes(statuses, 'SNOOZED') ? true : null;
    const categorized = formCategorizedStatusFlag(statuses);

    return { ignored, snoozed, categorized };
}

function formCategorizedStatusFlag(statuses: PaymentMatchingStatusType[]) {
    const hasCategorized = includes(statuses, 'CATEGORIZED');
    const hasUncategorized = includes(statuses, 'UNCATEGORIZED');

    switch (true) {
        case hasCategorized && !hasUncategorized:
            return true;
        case !hasCategorized && hasUncategorized:
            return false;
        default:
            return null;
    }
}
