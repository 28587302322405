import { range, map, last, find, pick } from 'lodash';
import * as startOfYear from 'date-fns/startOfYear';
import * as endOfYear from 'date-fns/endOfYear';
import * as format from 'date-fns/format';
import { DATE_FORMAT_BACKEND } from '@zenhomes/date';

export interface LabeledFilters {
    id: string;
    label: string;
    filters: {
        startDate: string;
        endDate: string;
    };
}

export function createRecentYearFiltersArray(currentDate: Date): LabeledFilters[] {
    const year = currentDate.getFullYear();
    const yearDates = range(year, year - 3).map(year => new Date(year, 1, 1));

    const yearRanges = map(yearDates, yearDate => ({
        id: `${yearDate.getFullYear()}`,
        label: `${yearDate.getFullYear()}`,
        filters: {
            startDate: format(startOfYear(yearDate), DATE_FORMAT_BACKEND),
            endDate: format(endOfYear(yearDate), DATE_FORMAT_BACKEND)
        }
    })) as LabeledFilters[];

    const allYears = {
        id: `yearSelect.all`,
        label: `yearSelect.all`,
        filters: {
            startDate: null,
            endDate: null
        }
    } as LabeledFilters;

    const olderYears = {
        id: `yearSelect.older`,
        label: `yearSelect.older`,
        filters: {
            startDate: null,
            endDate: format(endOfYear(new Date(year - 3, 1, 1)), DATE_FORMAT_BACKEND)
        }
    } as LabeledFilters;

    return [allYears, ...yearRanges, olderYears];
}
