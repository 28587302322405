import { Pipe, PipeTransform } from '@angular/core';

export enum DecimalNumberPart {
    Integer = 'INTEGER',
    Fractional = 'FRACTIONAL'
}

const DEFAULT_INTEGER_PART = '0';
const DEFAULT_FRACTIONAL_PART = '00';

@Pipe({
    name: 'decimalPart'
})
export class DecimalPartPipe implements PipeTransform {
    transform(value: string = '0,00', part: DecimalNumberPart = DecimalNumberPart.Integer): string {
        const parts = value ? value.split(',') : [];
        return part === DecimalNumberPart.Integer
            ? parts[0] || DEFAULT_INTEGER_PART
            : parts[1] || DEFAULT_FRACTIONAL_PART;
    }
}
