import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output
} from '@angular/core';

@Component({
    selector: 'zhm-single-icon-container',
    templateUrl: './single-icon-container.component.html',
    styleUrls: ['./single-icon-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleIconContainerComponent implements OnInit {
    @Input() icon: string;
    @Input() iconColor: string;
    @Input() label: string;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    onSelect() {
        this.valueChange.emit(!this.value);
    }
}
