import { Injectable } from '@angular/core';

const STORAGE_MAIN_KEY = 'zhm-app';

@Injectable()
export class StorageService {
    // @TODO make factory to configure local or session storage
    // @TODO catch errors on json parse
    storage: Storage;
    inMemoryStorage: any;

    localStorageSupported: boolean;

    constructor() {
        this.storage = localStorage;
        this.inMemoryStorage = {};

        this.createRootDataIfNotExists();
    }

    isLocalStorageSupported() {
        try {
            localStorage.setItem('isLocalStorageSupported', '');
            localStorage.removeItem('isLocalStorageSupported');
            return true;
        } catch (err) {
            return false;
        }
    }

    private createRootDataIfNotExists() {
        this.localStorageSupported = this.isLocalStorageSupported();

        if (this.localStorageSupported) {
            !this.rootData && this.storage.setItem(STORAGE_MAIN_KEY, JSON.stringify({}));
        } else {
            this.inMemoryStorage[STORAGE_MAIN_KEY] || (this.inMemoryStorage[STORAGE_MAIN_KEY] = {});
        }
    }

    private get rootData(): Object {
        if (this.localStorageSupported) {
            this.storage.getItem(STORAGE_MAIN_KEY) ||
                this.storage.setItem(STORAGE_MAIN_KEY, JSON.stringify({}));
            return JSON.parse(this.storage.getItem(STORAGE_MAIN_KEY));
        } else {
            this.inMemoryStorage[STORAGE_MAIN_KEY] || (this.inMemoryStorage[STORAGE_MAIN_KEY] = {});
            return this.inMemoryStorage[STORAGE_MAIN_KEY];
        }
    }

    private set rootData(value: Object) {
        if (this.localStorageSupported) {
            this.storage.setItem(STORAGE_MAIN_KEY, JSON.stringify(value));
        } else {
            this.inMemoryStorage[STORAGE_MAIN_KEY] = value;
        }
    }

    setItem(key: string, value: string | Object): this {
        const rootData = this.rootData;
        const values = { [key]: value };

        if (rootData[key]) {
            this.deleteItem(key);
        }

        this.rootData = Object.assign({}, rootData, values);

        return this;
    }

    clearAll(): this {
        this.rootData = {};
        return this;
    }

    getItem(key: string): string | Object | null {
        return this.rootData[key] || null;
    }

    deleteItem(key: string): boolean {
        let rootData = this.rootData;

        if (rootData.hasOwnProperty(key)) {
            delete rootData[key];
            this.rootData = rootData;

            return true;
        }

        return false;
    }
}
