import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges,
    OnDestroy
} from '@angular/core';
import { debounce } from 'lodash';

const DEBOUNCE_INTERVAL = 150;

@Directive({
    selector: '[zhmScrollToContent]'
})
export class ScrollToContentDirective implements OnInit, OnChanges, OnDestroy {
    @Input('zhmScrollToContent') enabled = true;
    @Input('zhmScrollToContentPadding') padding: number = 20;
    @Input('zhmScrollableContainerId') scrollableContainerId: string;

    resizeListener: () => void;
    animationTimer: any;
    animationDuration: number = 300;
    currentTime: number = 0;
    incrementOfTime: number = 20;

    constructor(private elementRef: ElementRef) {
        this.resizeListener = debounce(this.scrollIfEnabled.bind(this), DEBOUNCE_INTERVAL);
    }

    scrollIfEnabled() {
        if (!this.enabled || !this.elementRef.nativeElement) return;

        this.scrollToElement();
    }

    ngOnInit() {
        this.scrollIfEnabled();
        window.addEventListener('resize', this.resizeListener);
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeListener);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            this.scrollableContainerId &&
            changes.enabled &&
            changes.enabled.currentValue === true
        ) {
            this.scrollToElement();
        }
    }

    private scrollToElement() {
        this.currentTime = 0;
        this.scrollToTop();
    }

    scrollToTop() {
        const scrollableContainerElement = document.getElementById(this.scrollableContainerId);

        if (!scrollableContainerElement) return;

        const {
            scrollTop: startPosition,
            clientHeight: containerHeight
        } = scrollableContainerElement;
        const { clientHeight: elementHeight } = this.elementRef.nativeElement;

        const {
            bottom: containerBottom,
            top: containerTop
        } = scrollableContainerElement.getBoundingClientRect();
        const {
            bottom: elementBottom,
            top: elementTop
        } = this.elementRef.nativeElement.getBoundingClientRect();

        const distanceToTravel =
            elementHeight > containerHeight
                ? elementTop - containerTop - this.padding
                : elementBottom - containerBottom + this.padding;

        this.animateScroll(startPosition, distanceToTravel);
    }

    animateScroll(startPosition: number, distanceToTravel: number) {
        const scrollableContainerElement = document.getElementById(this.scrollableContainerId);

        if (!scrollableContainerElement) return;

        this.currentTime += this.incrementOfTime;
        const scrollTopChange = this.easeInOutQuad(
            this.currentTime,
            startPosition,
            distanceToTravel,
            this.animationDuration
        );
        scrollableContainerElement.scrollTop = scrollTopChange;

        if (this.currentTime < this.animationDuration) {
            clearTimeout(this.animationTimer);
            this.animationTimer = setTimeout(
                this.animateScroll.bind(this, startPosition, distanceToTravel),
                this.incrementOfTime
            );
        }
    }

    // Animation code, in case of change refer to: https://gist.github.com/gre/1650294
    easeInOutQuad(time: number, startPosition: number, changeInPosition: number, duration: number) {
        time /= duration / 2;
        if (time < 1) return (changeInPosition / 2) * time * time + startPosition;
        time--;
        return (-changeInPosition / 2) * (time * (time - 2) - 1) + startPosition;
    }
}
