import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges } from '@angular/core';
import { fadeIn } from '../../animations/fading.animation';
import { bounce } from '../../animations/bouncing.animation';

@Component({
    selector: 'zhm-status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeIn(), bounce()]
})
export class StatusBadgeComponent implements OnInit {
    @Input() label: string;
    @Input() info: boolean = false;
    @Input() danger: boolean = false;
    @Input() isAnimated: boolean = false;

    animationState: boolean = false;

    constructor() {}

    ngOnInit() {}

    getClass() {
        const badgeClass = {
            info: this.info,
            danger: this.danger
        };
        return badgeClass;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.label) {
            const currentValue = Number(changes.label.currentValue);
            const previousValue = Number(changes.label.previousValue);
            if (currentValue > previousValue) {
                this.animationState = !this.animationState;
            }
        }
    }
}
