import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentStatus } from '@zenhomes/domain/property';
import { PricePipe } from '.';
import { isEmpty, includes } from 'lodash';

export const DEFAULT_PLACEHOLDER = '-';

const PARTIAL_OR_OVERPAID_STATUSES = ['OVERPAID', 'PARTIAL_PAID', 'OVERDUE_PARTIAL_PAID'];

@Pipe({ name: 'paymentStatusValue' })
export class PaymentStatusValuePipe implements PipeTransform {
    private pricePipe: PricePipe;

    constructor() {
        this.pricePipe = new PricePipe();
    }

    transform(status: IPaymentStatus) {
        if (isEmpty(status)) return DEFAULT_PLACEHOLDER;

        const isPartialOrOverpaid = includes(PARTIAL_OR_OVERPAID_STATUSES, status.code);

        const valueToDisplay = isPartialOrOverpaid ? status.amount : status.totalAmount;

        return this.pricePipe.transform(valueToDisplay, true);
    }
}
