import { Injectable } from '@angular/core';
import { find, remove } from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Message } from '../models/message.model';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    readonly TIMEOUT = 15;

    private messagesStateChangeSubject = new Subject<any>();

    messages: Array<Message> = [];
    messagesStateChange$ = this.messagesStateChangeSubject.asObservable();

    showMessage(
        text: string,
        type: 'SUCCESS' | 'INFO' | 'WARNING' | 'DANGER' = 'SUCCESS',
        translate: boolean = false
    ): Message {
        const message = new Message();
        message.text = text;
        message.type = type;
        message.translate = translate;
        message.stateChangeCallback = () => this.onStateChange();

        if (this.isMessageAlreadyOnList(message)) {
            return;
        }

        this.messages.push(message);

        message
            .showForSeconds(this.TIMEOUT)
            .pipe(take(1))
            .subscribe(() => {
                this.clearRemovedMessages();
            });

        return message;
    }

    removeMessage(message: Message) {
        message
            .removeFadeOut()
            .pipe(take(1))
            .subscribe(() => {
                this.clearRemovedMessages();
            });
    }

    private onStateChange() {
        this.messagesStateChangeSubject.next();
    }

    private clearRemovedMessages() {
        remove(this.messages, { removed: true });
        this.onStateChange();
    }

    private isMessageAlreadyOnList(message: Message) {
        return !!find(this.messages, {
            text: message.text,
            type: message.type,
            translate: message.translate
        });
    }
}
