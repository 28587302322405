import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../services/token.service';
import { EventRouter, RoutingEvent } from '@zenhomes/frontend-routing';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private tokenService: TokenService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const accessToken = await this.tokenService.tokenPromise;
        const isAuthenticated = accessToken && this.isAuthenticated();

        if (!isAuthenticated) {
            EventRouter.dispatch(RoutingEvent.UserLoginExpired);
            return false;
        }

        return true;
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    isAuthenticated() {
        return this.tokenService.itHasValidToken;
    }
}
