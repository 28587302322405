import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BankAccount } from '@zenhomes/bank-account-core';
import { IPayment } from '@zenhomes/domain/payment';
import { get } from '@zenhomes/sauron';
import { getBankAccountOfTransfer } from '../../utils/payment.util';

@Component({
    selector: 'zhm-payment-bank-transfer',
    templateUrl: './payment-bank-transfer.component.html',
    styleUrls: ['./payment-bank-transfer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentBankTransferComponent implements OnInit {
    @Input() bankAccounts: BankAccount[];
    @Input() payment: IPayment;

    constructor() {}

    ngOnInit() {}

    getBankAccount() {
        return getBankAccountOfTransfer(this.bankAccounts, this.payment);
    }

    get bankAccountName() {
        return get(this.getBankAccount(), 'displayName');
    }

    get bankAccountNumber() {
        return get(this.getBankAccount(), 'ibanMasked');
    }

    get bankAccountBalance() {
        return get(this.getBankAccount(), 'balance');
    }
}
