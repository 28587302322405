import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from '../../models/message.model';
import { MessagesService } from '../../services/messages.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'zhm-messages',
    templateUrl: 'messages.component.html',
    styleUrls: ['messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {
    private sub: Subscription;

    constructor(private messagesService: MessagesService, private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.ref.detach();

        this.sub = this.messagesService.messagesStateChange$.subscribe(() => {
            this.ref.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.sub && this.sub.unsubscribe();
    }

    trackById(index: number, item: any) {
        return item.id;
    }

    remove(message: Message) {
        this.messagesService.removeMessage(message);
    }

    get messages() {
        return this.messagesService.messages;
    }
}
