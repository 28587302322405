import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { IDocumentCategory } from '@zenhomes/domain/document';

@Injectable()
export class DocumentCategoriesApiService {
    constructor(private http: HttpClient) {}

    loadDocumentCategories(): Observable<IDocumentCategory[]> {
        return this.http.get<IDocumentCategory[]>('/documents/categories');
    }
}
