import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { includes, some } from 'lodash';
import { TokenService } from '../services/token.service';
import { AuthConfigToken, AuthModuleConfig } from '../auth.config';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    headerName: string = 'Authorization';
    headerPrefix: string = 'Bearer';

    constructor(
        @Inject(AuthConfigToken) private config: AuthModuleConfig,
        private tokenService: TokenService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (some(this.config.authTokenBlackList, item => includes(request.url, item))) {
            return next.handle(request);
        }

        return from(this.tokenService.tokenPromise).pipe(
            map(token => {
                return request.clone({
                    headers: request.headers.set(this.headerName, `${this.headerPrefix} ${token}`)
                });
            }),
            switchMap(changedRequest => {
                return next.handle(changedRequest).pipe(
                    catchError(response => {
                        return Observable.throw(response);
                    })
                );
            })
        );
    }
}
