import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-list-icon-item',
    templateUrl: 'list-icon-item.component.html',
    styleUrls: ['./list-icon-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListIconItemComponent {
    @Input() title: string;
    @Input() icon: string;
    @Input() iconRight: string = 'icon-angle-right';
    @Input() subTitle: string;
    @Input() current: boolean;
    @Input() disabled: boolean;
    @Input() customBody: boolean = false;
    @Input() status: string;
    @Output() click: EventEmitter<null> = new EventEmitter();

    onClick($event: any) {
        $event.stopPropagation();
        this.click.emit($event);
    }
}
