export enum IPropertyRoleType {
    Owner = 'PROPERTY_OWNER',
    Manager = 'PROPERTY_MANAGER'
}

export enum IPropertyManagerType {
    Full = 'FULL',
    WEG = 'WEG',
    SE = 'SE'
}
