import { Tenancy, UsageList } from '../../../usages/interfaces';
import { usagesActionTypes } from '../actions/usages.actions';

export interface IUsagesState {
    tenancies: Tenancy[];
    tenanciesAreBeingLoaded: boolean;
    usages: UsageList[];
}

export const initialUsagesStoreState: IUsagesState = {
    tenancies: [],
    tenanciesAreBeingLoaded: false,
    usages: []
};

export function usagesReducer(
    state: IUsagesState = initialUsagesStoreState,
    action: any
): IUsagesState {
    switch (action.type) {
        case usagesActionTypes.LOAD_TENANCIES: {
            return Object.assign({}, state, {
                tenanciesAreBeingLoaded: true
            });
        }

        case usagesActionTypes.LOAD_TENANCIES_SUCCESS: {
            return Object.assign({}, state, {
                tenancies: action.payload,
                tenanciesAreBeingLoaded: false
            });
        }

        case usagesActionTypes.LOAD_TENANCIES_FAIL: {
            return Object.assign({}, state, {
                tenanciesAreBeingLoaded: false
            });
        }

        case usagesActionTypes.LOAD_USAGES_SUCCESS: {
            return Object.assign({}, state, {
                usages: action.payload
            });
        }

        default: {
            return state;
        }
    }
}
