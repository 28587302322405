import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as authActions from '../state/actions/auth.actions';

@Injectable()
export class AuthService {
    constructor(private store: Store<any>) {}

    checkInitialState() {
        this.store.dispatch(new authActions.AuthCheckInitialState());
    }

    reset() {
        this.store.dispatch(new authActions.AuthReset());
    }
}
