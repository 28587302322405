import { Injectable } from '@angular/core';
import { CONTENT_NAVIGATION_OPTIONS } from '../navigation.constants';
import {
    INavigationParams,
    IContentNavigationItem,
    IContentNavigationParams,
    IPropertyNavigationSelection,
    IContentNavigationSelection
} from '../interfaces';
import { parsePropertyParam } from '@zenhomes/property-core';
import { IProperty, IPropertyNavigationParams } from '@zenhomes/domain/property';
import { empty } from 'rxjs';

export type INavigationRouterCommands = { commands: string[] };

@Injectable()
export class NavigationHelpersService {
    parseRouterCommands(routerParams: INavigationRouterCommands): INavigationParams {
        const root = routerParams.commands[0];
        const routes = CONTENT_NAVIGATION_OPTIONS;
        const isRouteStateManaged = routes.filter(route => route === root).length > 0;

        if (isRouteStateManaged) {
            const content = root as any;

            let propertyBuildingId = null;
            let propertyUnitId = null;

            if (routerParams.commands.length >= 2) {
                const params = parsePropertyParam(routerParams.commands[1]);
                propertyBuildingId = params.propertyBuildingId;
                propertyUnitId = params.propertyUnitId;
            }

            return { propertyBuildingId, propertyUnitId, content };
        } else {
            return { propertyBuildingId: null, propertyUnitId: null, content: null };
        }
    }

    determineContentNavigationSelection(
        contentNavigationItems: IContentNavigationItem[],
        contentNavigationParams: IContentNavigationParams
    ): IContentNavigationSelection {
        let item =
            contentNavigationItems.find(item => contentNavigationParams.content === item.path) ||
            null;

        const result = { item };

        return result;
    }

    determinePropertyNavigationSelection(
        properties: IProperty[],
        navParams: IPropertyNavigationParams
    ): IPropertyNavigationSelection {
        const property = properties.find(p => p.propertyObject.id === navParams.propertyBuildingId);

        let result: IPropertyNavigationSelection = {
            property
        };

        if (navParams.propertyBuildingId && property && property.propertyObject) {
            const building = property.propertyObject;
            result = { ...result, building };
        }

        if (navParams.propertyUnitId && property && property.propertyObject) {
            const unit = property.propertyObject.units.find(
                item => item.id === navParams.propertyUnitId
            );
            result = { ...result, unit };
        }

        return result;
    }

    navigateWithSelection(selection: IPropertyNavigationSelection & IContentNavigationSelection) {
        return empty();
    }
}
