import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthActions } from '@zenhomes/auth';
import { CategoryCoreActions } from '@zenhomes/category-core';
import { PropertyActions } from '@zenhomes/property-core';
import { MessagesService } from '@zenhomes/toast-messages';
import { UserActions, userActionTypes } from '@zenhomes/user-core';
import { map, mapTo, tap } from 'rxjs/operators';
import { DocumentCategoriesActions } from '@zenhomes/document-core';
import { IframeHustlaInitService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsUpdated } from '../actions/module-orchestration.actions';

@Injectable()
export class ModuleOrchestrationEffects {
    constructor(
        private actions$: Actions,
        private messagesService: MessagesService,
        private iframeHustlaInitService: IframeHustlaInitService,
        private translate: TranslateService
    ) {}

    @Effect()
    loadUserOnAuthenticate$ = this.actions$.pipe(
        ofType(AuthActions.authActionTypes.AUTH_SET_TOKEN_SUCCESS),
        mapTo(new UserActions.UserLoadUser())
    );

    @Effect()
    cleanUserOnLogout$ = this.actions$.pipe(
        ofType(
            AuthActions.authActionTypes.AUTH_LOGOUT,
            AuthActions.authActionTypes.CLEAN_USER_DATA
        ),
        mapTo(new UserActions.UserReset())
    );

    @Effect()
    loadUserTopicsOnLogin$ = this.actions$.pipe(
        ofType(userActionTypes.USER_LOAD_USER),
        map(() => new CategoryCoreActions.CategoryCoreLoadUserTopics())
    );

    @Effect()
    loadDocumentCategoriesOnLogin$ = this.actions$.pipe(
        ofType(userActionTypes.USER_LOAD_USER),
        map(() => new DocumentCategoriesActions.DocumentsLoadDocumentCategories())
    );

    @Effect()
    loadPropertiesOnAuth$ = this.actions$.pipe(
        ofType(AuthActions.authActionTypes.AUTH_SET_TOKEN_SUCCESS),
        mapTo(new PropertyActions.LoadProperties())
    );

    @Effect({ dispatch: false })
    showNotyOnError$ = this.actions$.pipe(
        ofType(PropertyActions.propertiesActionTypes.LOAD_PROPERTIES_FAIL),
        tap(() => this.messagesService.showMessage('errors.default', 'DANGER', true))
    );

    @Effect({ dispatch: false })
    reloadProperties = this.actions$.pipe(
        ofType(PropertyActions.propertiesActionTypes.RELOAD_PROPERTIES),
        tap(() => {
            this.iframeHustlaInitService.sendReloadPropertiesMessage();
        })
    );

    @Effect({ dispatch: false })
    reloadTranslations = this.actions$.pipe(
        ofType<TranslationsUpdated>(TranslationsUpdated.TYPE),
        tap(({ locale, translations }) => {
            if (translations) {
                this.translate.setTranslation(locale, translations);
            }
        })
    );
}
