import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'zhm-property-type-button',
    templateUrl: './property-type-button.component.html',
    styleUrls: ['./property-type-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyTypeButtonComponent implements OnInit {
    @Input() label: string;
    @Input() icon: string;
    @Input() selected: boolean;
    @Input() disabled: boolean;

    constructor() {}

    ngOnInit() {}
}
