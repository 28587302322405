import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IUnitModel } from '@zenhomes/domain/property';

@Component({
    selector: 'units-dropdown',
    templateUrl: './units-dropdown.component.html',
    styleUrls: ['./units-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitsDropdownComponent {
    @Input() units: IUnitModel[];
    @Input() model: IUnitModel;
    @Input() emptyPlaceholder: string = 'placeholder.noSelection';
    @Input() disabled: boolean = false;

    @Output() onSelect: EventEmitter<IUnitModel> = new EventEmitter();

    select(unit: IUnitModel) {
        this.onSelect.emit(unit);

        this.model = unit;
    }
}
