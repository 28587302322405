import { Action } from '@ngrx/store';
import { IDocumentCategory } from '@zenhomes/domain/document';

export const documentCategoriesActionTypes = {
    DOCUMENTS_LOAD_DOCUMENT_CATEGORIES: '[Documents] Load document categories',
    DOCUMENTS_LOAD_DOCUMENT_CATEGORIES_SUCCESS: '[Documents] Load document categories success',
    DOCUMENTS_LOAD_DOCUMENT_CATEGORIES_FAIL: '[Documents] Load document categories fail',
    DOCUMENTS_DOCUMENT_CATEGORIES_RESET: '[Documents] Categories reset'
};

export class DocumentsLoadDocumentCategories implements Action {
    readonly type = documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES;
}

export class DocumentsLoadDocumentCategoriesSuccess implements Action {
    readonly type = documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES_SUCCESS;

    constructor(public payload: IDocumentCategory[]) {}
}

export class DocumentsLoadDocumentCategoriesFail implements Action {
    readonly type = documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES_FAIL;
}

export class DocumentCategoriesReset implements Action {
    readonly type = documentCategoriesActionTypes.DOCUMENTS_DOCUMENT_CATEGORIES_RESET;
}

export type DocumentsActions = [
    DocumentsLoadDocumentCategories,
    DocumentsLoadDocumentCategoriesSuccess,
    DocumentsLoadDocumentCategoriesFail,
    DocumentCategoriesReset
];
