import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/never';
import 'rxjs/add/observable/of';

import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';

import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

const DEFAULT_SPEED = 30;
const DEFAULT_CUTOFF = 0.75;

export interface IDragScrollSettings {
    speed: number;
    cutoff: number;
}

@Directive({
    selector: '[zhmDragScroll]'
})
export class DragScrollDirective implements OnInit, OnDestroy {
    @Input('zhmDragScroll')
    scrollSettings: IDragScrollSettings = {
        speed: DEFAULT_SPEED,
        cutoff: DEFAULT_CUTOFF
    };

    sub: Subscription;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        const isDragging$: Observable<boolean> = Observable.fromEvent(
            document,
            'dragstart'
        ).switchMap((event: DragEvent) => {
            const dragStart$ = Observable.of(true);

            const dragEnd$ = Observable.fromEvent(event.target, 'dragend')
                .take(1)
                .mapTo(false);

            return Observable.merge(dragStart$, dragEnd$);
        });

        const dragTick$ = isDragging$.switchMap(isDragging =>
            isDragging ? Observable.interval() : Observable.never()
        );

        const dragOffset$ = Observable.fromEvent(this.elementRef.nativeElement, 'dragover').map(
            (event: DragEvent) => this.getScrollDiffForDrag(event)
        );

        this.sub = dragTick$.withLatestFrom(dragOffset$).subscribe(([tick, dragOffset]) => {
            this.elementRef.nativeElement.scrollTop += dragOffset;
        });
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }

    getScrollDiffForDrag(event: DragEvent) {
        const target = event.currentTarget as Element;

        const rect = target.getBoundingClientRect();

        const normalizedPosY =
            (event.clientY - rect.top) / (Math.min(rect.bottom, window.innerHeight) - rect.top);

        const scrollDiff = this.getScrollDiff(normalizedPosY, this.scrollSettings);

        return scrollDiff;
    }

    getScrollDiff(normalizedPosY: number, settings: IDragScrollSettings) {
        const scrollAmount = 2 * normalizedPosY - 1;
        const scrollAmountSign = scrollAmount > 0 ? +1 : -1;
        const scrollAmountAbs = Math.abs(scrollAmount);

        const sigScrollAmount =
            scrollAmountAbs > settings.cutoff ? scrollAmountAbs - settings.cutoff : 0;

        const scrollDiff = settings.speed * scrollAmountSign * sigScrollAmount;

        return scrollDiff;
    }
}
