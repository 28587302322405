import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// Services
import { PropertyNavigationParamsResolver } from './resolvers/property-navigation-params.resolver';
import { NavigationHelpersService } from './services/navigation-helpers.service';
import { NavigationModuleInitializer } from './services/navigation-module-initializer.service';
import { PropertyNavigationService } from './services/property-navigation.service';

// State
import { NAVIGATION_MODULE_INIT_STATE, reducers } from './state';
import { PropertyNavigationLifecycleEffects } from './state/effects/property-navigation-lifecycle.effects';
import { PropertyNavigationEffects } from './state/effects/property-navigation.effects';

@NgModule({
    imports: [
        StoreModule.forFeature('NavigationModule', reducers, {
            initialState: NAVIGATION_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([PropertyNavigationEffects, PropertyNavigationLifecycleEffects])
    ],
    declarations: []
})
export class NavigationModule {
    constructor(private initializer: NavigationModuleInitializer) {
        this.initializer.init();
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: NavigationModule,
            providers: [
                PropertyNavigationEffects,
                PropertyNavigationLifecycleEffects,
                PropertyNavigationService,
                NavigationHelpersService,
                PropertyNavigationParamsResolver,
                NavigationModuleInitializer
            ]
        };
    }
}
