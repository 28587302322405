import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { ConfirmationDialogOptions } from '../types';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { DialogWizardService } from '../services/dialog-wizard.service';
import { merge, mergeMap, takeUntil, filter, switchMap } from 'rxjs/operators';
import { fromEvent, of, never } from 'rxjs';

@Directive({
    selector: '[confirmationDialog]'
})
export class ConfirmationDialogDirective implements OnInit, OnDestroy {
    @Output() confirmation$ = new EventEmitter<MouseEvent>();
    confirmationDialogOptions: any;
    number: number;
    @Input('confirmationDialog')
    set confirmationDialogOptionsSetter(value: any) {
        this.number = value.numberOfTransaction;
        this.confirmationDialogOptions = value;
    }

    @Input() disabled: boolean = false;

    directiveDestroyed: Subject<void> = new Subject<void>();

    constructor(private el: ElementRef, private dialogWizardService: DialogWizardService) {}

    ngOnInit() {
        let mouseEvent: MouseEvent;
        const click$ = fromEvent(this.el.nativeElement, 'click').pipe(filter(() => !this.disabled));

        const options = this.confirmationDialogOptions || {};

        const {
            body = '',
            warningText = '',
            confirmButton = '',
            cancelButton = '',
            title = '',
            warningParams = null
        } = options as ConfirmationDialogOptions;

        const confirm$ = click$.pipe(
            switchMap((event: MouseEvent) => {
                const confirmationDialogInstance = this.dialogWizardService
                    .loadConfirmationDialog()
                    .getComponentRef<ConfirmationDialogComponent>(ConfirmationDialogComponent)
                    .instance;

                mouseEvent = event;
                confirmationDialogInstance.body = body;
                confirmationDialogInstance.warningText = warningText;
                confirmationDialogInstance.confirmButtonLabel = confirmButton;
                confirmationDialogInstance.cancelButtonLabel = cancelButton;
                confirmationDialogInstance.title = title;
                confirmationDialogInstance.numberOfTransaction = this.number;
                confirmationDialogInstance.warningParams = warningParams;

                this.dialogWizardService.openConfirmationDialog();

                return confirmationDialogInstance.confirm$;
            })
        );

        click$
            .pipe(
                merge(confirm$),
                mergeMap((data: boolean | MouseEvent) => {
                    return typeof data === 'boolean' ? of(data) : never();
                }),
                takeUntil(this.directiveDestroyed)
            )
            .subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    this.confirmation$.emit(mouseEvent);
                }

                this.dialogWizardService.closeWizard();
            });
    }

    ngOnDestroy() {
        this.directiveDestroyed.next();
        this.directiveDestroyed.complete();
    }
}
