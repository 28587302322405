import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxComponent {
    @Input() message: string;

    @Input() actionLabel: string;
    @Output() actionClick = new EventEmitter<Event>();

    onActionClick(event: Event) {
        this.actionClick.emit(event);
    }
}
