import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    IClusteredPaymentsPaginationModel,
    IUncategorizedPaymentStats,
    PaymentCoreState,
    PaymentListFilterOptions
} from '@payment-core/interfaces';
import * as fromPaymentCoreState from '@payment-core/state';
import * as paymentCoreActions from '@payment-core/state/actions/payment.actions';
import { initialPaymentCoreStoreState } from '@payment-core/state/constants/payment-state.constants';
import { includesInString } from '@zenhomes/sauron';
import { IClusteredPayment, IPayment } from '@zenhomes/domain/payment';
import { isDefaultFilter } from '@zenhomes/filter-core';
import { get, isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PaymentService {
    state$: Observable<PaymentCoreState> = this.store.select(
        fromPaymentCoreState.getPaymentCoreState
    );
    payments$: Observable<IClusteredPayment[]> = this.store.select(
        fromPaymentCoreState.getPayments
    );
    paymentsSelected$: Observable<IPayment[]> = this.store.select(
        fromPaymentCoreState.getPaymentsSelected
    );
    pagination$: Observable<IClusteredPaymentsPaginationModel> = this.store.select(
        fromPaymentCoreState.getPagination
    );
    payment$: Observable<IPayment> = this.store.select(fromPaymentCoreState.getPayment);
    filterOptions$: Observable<PaymentListFilterOptions> = this.store.select(
        fromPaymentCoreState.getFilterOptions
    );
    paymentsAreBeingLoaded$: Observable<boolean> = this.store.select(
        fromPaymentCoreState.getPaymentsAreBeingLoaded
    );
    shouldLoadMissingPayments$: Observable<boolean> = this.store.select(
        fromPaymentCoreState.getShouldLoadMissingPayments
    );
    canLoadMorePayments$: Observable<boolean>;
    uncategorizedPaymentStats$: Observable<IUncategorizedPaymentStats> = this.store.select(
        fromPaymentCoreState.getUncategorizedPaymentStats
    );
    uncategorizedPaymentStatsIsLoaded$: Observable<boolean> = this.store.select(
        fromPaymentCoreState.getUncategorizedPaymentStatsIsLoaded
    );

    isDefaultBankAccountFilterState$: Observable<boolean>;
    isDefaultFilterState$: Observable<boolean>;
    transferCounterparties$: Observable<string[]> = this.store.select(
        fromPaymentCoreState.getTransferCounterparties
    );

    constructor(private store: Store<PaymentCoreState>, private actions$: Actions) {
        this.canLoadMorePayments$ = this.state$.map(state => {
            return (
                !isEmpty(state.payments) &&
                !state.paymentsBeingLoaded &&
                !get(state, 'pagination.last')
            );
        });

        this.isDefaultBankAccountFilterState$ = this.filterOptions$.map(filterOptions => {
            return isDefaultFilter(initialPaymentCoreStoreState.filterOptions, filterOptions, [
                'bankAccountId',
                'bankAccountIbanHash',
                'bankAccountNumberHash',
                'matchingStatus',
                'listView'
            ]);
        });

        this.isDefaultFilterState$ = this.filterOptions$.map(filterOptions => {
            return isDefaultFilter(initialPaymentCoreStoreState.filterOptions, filterOptions, [
                'bankAccountId'
            ]);
        });
    }

    loadPayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreLoadPayments());
    }

    reloadPayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreReloadPayments());
    }

    loadMorePayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreLoadMorePayments());
    }

    loadMissingPayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreLoadMissingPayments());
    }

    loadPayment(payment: IPayment) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreLoadPayment(payment));
    }

    updateFilters(filterOptions: PaymentListFilterOptions = {}) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreUpdateListFilters(filterOptions));
    }

    clearFilters() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreClearListFilters());
    }

    resetPayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreResetPayments());
    }

    deletePayment(payment: IPayment): void {
        this.store.dispatch(new paymentCoreActions.PaymentCoreDeletePayment(payment.id));
    }

    loadTransferCounterparties() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreLoadTransferCounterparties());
    }

    snoozePayments(payments: IPayment[]) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreSnoozePayments(payments));
    }

    unsnoozePayments(payments: IPayment[]) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreUnsnoozePayments(payments));
    }

    ignorePayments(payments: IPayment[]) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreIgnorePayments(payments));
    }

    unignorePayments(payments: IPayment[]) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreUnignorePayments(payments));
    }

    findCounterparties(query: string = ''): Observable<string[]> {
        return this.transferCounterparties$.map((counterparties: string[]) => {
            if (isEmpty(query)) {
                return counterparties;
            } else {
                return counterparties.filter(item => includesInString(item, query));
            }
        });
    }

    togglePayments(payments: IPayment[]) {
        this.store.dispatch(new paymentCoreActions.PaymentCoreTogglePayments(payments));
    }

    selectAllPayments() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreSelectAllPayments());
    }

    cleanPaymentsSelected(paymentsInitiallySelected: IPayment[] = []) {
        this.store.dispatch(
            new paymentCoreActions.PaymentCoreCleanPaymentsSelected(paymentsInitiallySelected)
        );
    }

    snoozePaymentsSelected() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreSnoozePaymentsSelected());
    }

    ignorePaymentsSelected() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreIgnorePaymentsSelected());
    }

    uncategorizePaymentsSelected() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreUncategorizePaymentsSelected());
    }

    resetPaymentsSelected() {
        this.store.dispatch(new paymentCoreActions.PaymentCoreResetPaymentsSelected());
    }

    saveInitialPaymentSelection(transferIds: IPayment[]) {
        this.store.dispatch(
            new paymentCoreActions.PaymentCoreSaveInitialPaymentSelection(transferIds)
        );
    }
}
