import { IProperty } from '@zenhomes/domain/property';
import { chain } from 'lodash';
import { getPropertySelectionKey } from './property.utils';

export function getPropertyKeyOrderMap(properties: IProperty[]): { [propertyKey: string]: number } {
    const orderMap = chain(properties)
        .map(property => property.propertyObject)
        .flatMap(building => {
            return [{ building, unit: null }, ...building.units.map(unit => ({ building, unit }))];
        })
        .map((propertySelection, index) => ({
            index,
            key: getPropertySelectionKey(propertySelection)
        }))
        .keyBy(({ key, index }) => key)
        .mapValues(({ key, index }) => index)
        .value();

    return orderMap;
}
