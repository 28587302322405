import { IPaymentDragData } from '@payment-core/interfaces';
import { INCOMING, OUTGOING } from '@payment-core/payment-core.constants';
import { DATE_FORMAT_BACKEND } from '@zenhomes/date';
import { IInvoice, INCOME } from '@zenhomes/domain/invoice';
import { IPaymentItem } from '@zenhomes/domain/payment';
import * as parse from 'date-fns/parse';
import {
    allPass,
    any,
    complement,
    compose,
    converge,
    curry,
    defaultTo,
    equals,
    identity,
    ifElse,
    last,
    map,
    negate,
    prop,
    propEq,
    sortBy,
    useWith
} from 'ramda';

const invoiceIsBalanced: Predicate1<IInvoice> = propEq('balance', 0);
const invoiceIsUnbalanced: Predicate1<IInvoice> = complement(invoiceIsBalanced);
const defaultEmptyObject = defaultTo({});
const invoiceIsIncoming: Predicate1<IInvoice> = compose(
    propEq('type', INCOME),
    defaultEmptyObject
);
const paymentIsIncoming: Predicate1<IPaymentItem> = compose(
    propEq('direction', INCOMING),
    defaultEmptyObject
);

const invoiceAndPaymentDirectionMatch: Predicate2<IPaymentItem, IInvoice> = useWith(equals, [
    paymentIsIncoming,
    invoiceIsIncoming
]);

const getPaymentTimestamp: (payment: IPaymentItem) => number = curry((payment: IPaymentItem) => {
    const date = parse(payment.date, DATE_FORMAT_BACKEND, new Date());
    return date.valueOf();
});

export const sortPaymentsByDateAsc: Func1<IPaymentItem[], IPaymentItem[]> = sortBy(
    getPaymentTimestamp
);

const getLastInvoicePayment: Func1<IInvoice, IPaymentItem> = compose(
    last,
    sortPaymentsByDateAsc,
    prop('payments')
);

const lastInvoicePaymentHasMatchingDirection: Predicate1<IInvoice> = converge(
    invoiceAndPaymentDirectionMatch,
    [getLastInvoicePayment, identity]
);

const getSignedPaymentAmount = ifElse(
    paymentIsIncoming,
    prop('amount'),
    compose(
        negate,
        prop('amount')
    )
);

const balanceAmountNotInPayments: Predicate1<IInvoice> = curry((invoice: IInvoice) => {
    const amounts = map(getSignedPaymentAmount, prop('payments', invoice));

    return !any(equals(invoice.balance))(amounts);
});

const absBalanceLessThanLastPayment: Predicate1<IInvoice> = curry((invoice: IInvoice) => {
    const lastPayment: IPaymentItem = defaultEmptyObject(
        getLastInvoicePayment(invoice)
    ) as IPaymentItem;

    return Math.abs(invoice.balance) < lastPayment.amount;
});

const balanceAndLastPaymentHaveMatchingDirection: Predicate1<IInvoice> = curry(
    (invoice: IInvoice) => {
        const lastPayment: IPaymentItem = defaultEmptyObject(
            getLastInvoicePayment(invoice)
        ) as IPaymentItem;

        return (
            (invoice.balance < 0 && lastPayment.direction === OUTGOING) ||
            (invoice.balance > 0 && lastPayment.direction === INCOMING)
        );
    }
);

export const invoiceHasOverpayment: Predicate1<IInvoice> = allPass([
    invoiceIsUnbalanced,
    lastInvoicePaymentHasMatchingDirection,
    balanceAmountNotInPayments,
    absBalanceLessThanLastPayment,
    balanceAndLastPaymentHaveMatchingDirection
]);

export const getOverpaymentObject: Func1<IInvoice, IPaymentDragData> = curry(
    (invoice: IInvoice) => {
        const lastPayment: IPaymentItem = getLastInvoicePayment(invoice);

        return {
            invoice: invoice,
            paymentItem: lastPayment,
            amount: invoice.balance
        };
    }
);
