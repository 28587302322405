import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './state/effects/user.effects';
import { reducers, USER_MODULE_INIT_STATE } from './state/user.state';

@NgModule({
    imports: [
        StoreModule.forFeature('UserCoreModule', reducers, {
            initialState: USER_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([UserEffects])
    ]
})
export class UserCoreModule {}
