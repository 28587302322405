import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, get } from 'lodash';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {
    transform(contact: string): string {
        if (!isEmpty(contact)) {
            const names = contact.split(' ');

            return this.toUpperCaseFirstLetter(names[0]) + this.toUpperCaseFirstLetter(names[1]);
        } else {
            return '';
        }
    }

    private toUpperCaseFirstLetter(name: string) {
        return get(name, 0, '').toUpperCase();
    }
}
