import { IElementsPaginationModel } from '@zenhomes/domain/pagination';
import { IInvoice } from '@zenhomes/domain/invoice';
import {
    BookmarkableFilterOptions,
    FILTER_AMOUNT_MAX,
    FILTER_AMOUNT_MIN
} from '@zenhomes/filter-core';

export interface AccountingReport {
    id: string;
    documentId: string;
}

export enum YearDates {
    THIS_YEAR = 'THIS_YEAR',
    LAST_YEAR = 'LAST_YEAR'
}

export interface AccountingReportFilters {
    startDate?: string;
    endDate?: string;
    buildingId?: string;
    unitId?: string;
}

export interface DownloadType {
    label: string;
    icon: string;
    type: string;
}

export interface ReportInfo {
    id: string;
    documentId: string;
    url: string;
    downloadUrl: string;
}
export interface JournalViewState {
    journalViewInvoices: IInvoice[];
    journalViewInvoicesSelected: IInvoice[];
    journalViewInvoicesAreLoaded: boolean;
    journalViewInvoicesBeingLoaded: boolean;
    journalViewShouldLoadMissingInvoices: boolean;
    journalViewBulkOperationMode: boolean;
    pagination: IElementsPaginationModel;
    filterOptions: BookmarkableFilterOptions;
    expandedInvoice: IInvoice;
    AccountingReportFilters: AccountingReportFilters;
    AccountingReport: AccountingReport;
}

export const initialJournalViewStoreState: JournalViewState = {
    journalViewInvoices: [],
    journalViewInvoicesSelected: [],
    journalViewInvoicesAreLoaded: false,
    journalViewInvoicesBeingLoaded: false,
    journalViewShouldLoadMissingInvoices: false,
    journalViewBulkOperationMode: false,
    pagination: {} as any,
    filterOptions: {
        page: '1',
        query: '',
        startDate: null,
        endDate: null,
        propertySelection: [],
        counterpartySelection: [],
        subjectSelection: [],
        userCategorySelection: [],
        status: [],
        matchingStatus: [],
        transactionType: [],
        amountFrom: FILTER_AMOUNT_MIN,
        amountTo: FILTER_AMOUNT_MAX,
        individual: null,
        recurring: null,
        invoiceType: []
    },
    expandedInvoice: null,
    AccountingReportFilters: null,
    AccountingReport: null
};
