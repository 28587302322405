import { Store, Action } from '@zenhomes/ngrx-actions';
import * as actions from '../actions/document-metadata-form.actions';
import { replaceByProp } from '@zenhomes/sauron';
import { initialDocumentMetadataFormState } from '../constants/document-metadata-form-state.constant';
import { IDocumentMetadataFormState } from '../../interfaces';

@Store(initialDocumentMetadataFormState)
export class DocumentMetadataFormStore {
    @Action(actions.Open)
    open(state: IDocumentMetadataFormState, action: actions.Open) {
        return { ...state, isOpen: true };
    }

    @Action(actions.Cancel, actions.SaveFail, actions.SaveSuccess)
    close(state: IDocumentMetadataFormState, action: any) {
        return { ...state, isOpen: false };
    }
}
