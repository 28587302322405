import {
    Component,
    OnInit,
    Input,
    SimpleChanges,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { map, isEmpty } from 'lodash';
import { IMAGE_PLACEHOLDER_URL } from '@util/core.constants';
import { ProductSlug } from '@zenhomes/shop-product-teasers';

export interface ICarouselItem {
    url: string;
    thumbnailUrl: string;
    id: string;
    name: string;
}

@Component({
    selector: 'zhm-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit {
    @ViewChild('container', { static: true })
    carouselContainer: ElementRef;

    @Input() items: ICarouselItem[];
    @Input() thumbnailsToShow: number = 5;
    @Input() placeholderImg: string = IMAGE_PLACEHOLDER_URL;

    childIndex: number = 0;
    scrollAmount: number = 0;
    selectedItem: ICarouselItem;
    thumbnailPlaceholderImg: string = IMAGE_PLACEHOLDER_URL;
    emptyThumbnailsPlacehoders: any = map(
        [...Array(this.thumbnailsToShow)],
        x => (x = this.thumbnailPlaceholderImg)
    );

    teaserSlug = ProductSlug.PhotoService;
    teaserName = 'expose-photos-overview';

    constructor() {}

    ngOnInit() {
        this.items = this.items || [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.scrollAmount = 0;
            this.childIndex = 0;
            this.selectedItem = this.items && this.items[0];
        }
    }

    get areItemsProvided(): boolean {
        return !isEmpty(this.items);
    }

    get mainImage(): string {
        return this.selectedItem ? this.selectedItem.url : this.placeholderImg;
    }

    get hasAnyImage() {
        return this.selectedItem && !!this.selectedItem.url;
    }

    trackById(index: number, item: any) {
        return item.id;
    }

    selectItem(event: any, item: ICarouselItem, i: number, elem: any) {
        event.preventDefault();
        event.stopPropagation();

        this.childIndex = i;
        this.selectedItem = item;
    }

    scroll(event: any, forward: boolean, elem: any) {
        event.preventDefault();
        event.stopPropagation();

        this.childIndex = this.changeIndex(this.childIndex, forward ? +1 : -1, this.items.length);

        this.selectedItem = this.items[this.childIndex];
        this.scrollAmount = -this.calcScroll(elem);
    }

    changeIndex(index: number, indexChange: number, maxIndex: number) {
        const newIndex = index + indexChange;

        switch (true) {
            case newIndex >= maxIndex:
                return 0;
            case newIndex < 0:
                return maxIndex - 1;
            default:
                return newIndex;
        }
    }

    calcScroll(itemsContainer: any) {
        let offset = 0;

        for (let i = this.childIndex - 1; i >= 0; i--) {
            const el = itemsContainer.children[i];
            const counterForElement = this.getElemWidth(el);
            offset += counterForElement;
        }

        const result = Math.min(offset, this.getMaxOffset(itemsContainer));

        return result;
    }

    getMaxOffset(itemsContainer: any) {
        const containerWidth = this.getElemWidth(this.carouselContainer.nativeElement);
        const allItemsWidth = this.getElemWidth(itemsContainer);
        const singleItemWidth = this.getElemWidth(
            itemsContainer && itemsContainer.children && itemsContainer.children[0]
        );

        if (allItemsWidth < containerWidth) return 0;

        const maxOffset = allItemsWidth - containerWidth + singleItemWidth;

        return maxOffset;
    }

    getElemWidth(el: any) {
        const style = el.currentStyle || window.getComputedStyle(el);
        const width =
            el.offsetWidth + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
        return width;
    }

    stopPropagation(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }
}
