import { OnInit, Input, Component, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';

import { slideFromVoid } from '../../animations/sliding.animation';
import { trigger, transition, query, animateChild } from '@angular/animations';
import { RendererProxyService } from '@zenhomes/device';

@Component({
    selector: 'zhm-animated-overlay-container',
    templateUrl: './animated-overlay-container.component.html',
    styleUrls: ['./animated-overlay-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        slideFromVoid('translateX(100%)', 'translateX(0)', '1', '1'),
        trigger('slidingTransition', [
            transition(':enter, :leave', [query('@slideFromVoid', animateChild())])
        ])
    ]
})
export class AnimatedOverlayContainer implements OnInit {
    @Input() visible: boolean;

    ngOnInit() {}

    constructor(private rendererProxyService: RendererProxyService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.visible) {
            const html = document.getElementsByTagName('html')[0];

            if (this.visible) {
                this.rendererProxyService.renderer.setStyle(html, 'overflow', 'hidden');
            } else {
                this.rendererProxyService.renderer.removeStyle(html, 'overflow');
            }
        }
    }
}
