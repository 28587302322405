import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { CloseIconSizes, Colors } from '@zenhomes/ui';

@Component({
    selector: 'zhm-section-editor-header',
    templateUrl: './section-editor-header.component.html',
    styleUrls: ['./section-editor-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionEditorHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() saveVisible: boolean = true;
    @Input() saveLabel: string = 'action.save';
    @Input() saveDisabled: boolean = false;
    @Input() isSaving: boolean = false;

    @Output() save = new EventEmitter<Event>();
    @Output() close = new EventEmitter<Event>();

    closeIconColor: string = Colors.blackBase900;
    closeIconSize: string = CloseIconSizes.medium;

    constructor() {}

    ngOnInit() {}

    onClickSave(event: Event) {
        if (this.saveDisabled) return;

        this.save.emit(event);
    }

    onClickClose(event: Event) {
        this.close.emit(event);
    }
}
