import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface PhraseTranslationObject {
    content: string;
    key: {
        id: string;
        name: string;
    };
    locale: {
        code: string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class PhraseLoader {
    private locale: string;
    private cacheKey: string;
    private versionKey: string;

    setLocale(locale: string) {
        this.locale = locale;
        this.cacheKey = `zhm-phrase-cache-${environment.PHRASE.APP_NAME}-${this.locale}`;
        this.versionKey = `zhm-phrase-version-${environment.PHRASE.APP_NAME}`;
    }

    getCachedTranslation() {
        const cacheValue = localStorage.getItem(this.cacheKey);

        if (!!cacheValue && cacheValue !== 'undefined') {
            try {
                return JSON.parse(cacheValue);
            } catch {
                return null;
            }
        } else {
            return null;
        }
    }

    async tryRefreshTranslations(hasCachedTranslation: boolean) {
        const { isTranslationUpdated, currentVersionDate } = await this.isPhraseUpdated();

        if (!hasCachedTranslation || isTranslationUpdated) {
            const translations = await this.getPhraseTranslations(this.locale);
            localStorage.setItem(this.versionKey, currentVersionDate);
            localStorage.setItem(this.cacheKey, JSON.stringify(translations));

            return translations;
        }
    }

    private async isPhraseUpdated(): Promise<{
        isTranslationUpdated: boolean;
        currentVersionDate: string;
    }> {
        const versionResponse = await this.getPhraseVersion();

        const currentVersionDateString = versionResponse && versionResponse.updated_at;
        const currentVersionDate =
            !!currentVersionDateString && typeof currentVersionDateString === 'string'
                ? new Date(currentVersionDateString)
                : null;

        const cachedVersionDateString = localStorage.getItem(this.versionKey);
        const cachedVersionDate =
            !!cachedVersionDateString && typeof cachedVersionDateString === 'string'
                ? new Date(cachedVersionDateString)
                : null;

        if (!cachedVersionDate || !currentVersionDate) {
            return { isTranslationUpdated: true, currentVersionDate: currentVersionDateString };
        } else {
            return {
                isTranslationUpdated: currentVersionDate > cachedVersionDate,
                currentVersionDate: currentVersionDateString
            };
        }
    }

    private async getPhraseVersion() {
        const phraseResponse = await fetch(
            `https://api.phrase.com/v2/projects/${environment.PHRASE.PROJECT_ID}?&access_token=${environment.PHRASE.STATIC_KEY}`
        );

        if (!phraseResponse.ok) {
            console.error(phraseResponse && phraseResponse.status);
            return;
        }

        const json = await phraseResponse.json();

        return json;
    }

    private async getPhraseTranslations(locale: string) {
        let allTranslations: PhraseTranslationObject[] = [];
        let page: number = 1;
        const pageSize = 100;

        while (page) {
            try {
                const phraseResponse = await fetch(
                    `https://api.phrase.com/v2/projects/${environment.PHRASE.PROJECT_ID}/locales/${locale}/translations?per_page=${pageSize}&page=${page}&access_token=${environment.PHRASE.STATIC_KEY}`
                );
                if (!phraseResponse.ok) {
                    console.error(phraseResponse && phraseResponse.status);
                    return;
                }
                const phraseResponseJson = await phraseResponse.json();
                allTranslations = allTranslations.concat(phraseResponseJson);
                page = phraseResponseJson.length === pageSize ? page + 1 : 0;
            } catch (error) {
                console.error(error);
                return;
            }
        }

        return this.parsePhraseTranslations(allTranslations);
    }

    private parsePhraseTranslations(data: PhraseTranslationObject[]) {
        return data
            .map(item => ({ key: item.key.name, value: item.content }))
            .reduce((acc, currentItem) => {
                let keys = currentItem.key.split('.');
                let currentAcc: any = acc;
                keys.forEach((key, i) => {
                    if (i < keys.length - 1) {
                        currentAcc[key] =
                            currentAcc[key] && typeof currentAcc[key] === 'object'
                                ? currentAcc[key]
                                : {};
                        currentAcc = currentAcc[key];
                    } else {
                        currentAcc[key] = currentItem.value;
                    }
                });
                return acc;
            }, {});
    }
}
