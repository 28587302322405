import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ElementsModule } from '@zenhomes/elements';

import { ProductTeaserSmallComponent } from './components/product-teaser-small/product-teaser-small.component';
import { TeaserWrapperComponent } from './components/teaser-wrapper/teaser-wrapper.component';
import { ProductTeaserRowComponent } from './components/product-teaser-row/product-teaser-row.component';
import { TeaserWrapperDirectiveDirective } from './directives/teaser-wrapper-directive.directive';

const DECLARATIONS = [
    TeaserWrapperComponent,
    ProductTeaserSmallComponent,
    ProductTeaserRowComponent,
    TeaserWrapperDirectiveDirective
];

@NgModule({
    imports: [CommonModule, TranslateModule, ElementsModule],
    declarations: DECLARATIONS,
    exports: DECLARATIONS
})
export class ShopProductTeasersModule {}
