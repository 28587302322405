import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ValuationApiService } from './services/valuation-api.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
    providers: []
})
export class ValuationCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ValuationCoreModule,
            providers: [ValuationApiService]
        };
    }
}
