import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { typeWithNamespace } from '@util/type-cache.util';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IDocument } from '@zenhomes/domain/document';

const JOURNAL_VIEW_DOCUMENTS = 'JournalViewDocuments';

export class GetExpandedInvoiceDocuments implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Get expanded invoice documents'
    );
    readonly type = GetExpandedInvoiceDocuments.TYPE;

    constructor(public invoice: IInvoice) {}
}

export class GetExpandedInvoiceDocumentsSuccess implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Get expanded invoice documents success'
    );
    readonly type = GetExpandedInvoiceDocumentsSuccess.TYPE;

    constructor(public documents: IDocument[]) {}
}

export class GetExpandedInvoiceDocumentsFail implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Get expanded invoice documents fail'
    );
    readonly type = GetExpandedInvoiceDocumentsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class DeleteDocument implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW_DOCUMENTS, 'Delete invoice document');
    readonly type = DeleteDocument.TYPE;

    constructor(public document: IDocument) {}
}

export class DeleteDocumentSuccess implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Delete invoice document success'
    );
    readonly type = DeleteDocumentSuccess.TYPE;

    constructor(public document: IDocument) {}
}

export class DeleteDocumentFail implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Delete invoice document fail'
    );
    readonly type = DeleteDocumentFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class UpdateInvoiceDocument implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW_DOCUMENTS, 'Update invoice document');
    readonly type = UpdateInvoiceDocument.TYPE;

    constructor(public document: IDocument) {}
}

export class UpdateInvoiceDocumentSuccess implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Update invoice document success'
    );
    readonly type = UpdateInvoiceDocumentSuccess.TYPE;

    constructor(public document: IDocument) {}
}

export class UpdateInvoiceDocumentFail implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW_DOCUMENTS,
        'Update invoice document fail'
    );
    readonly type = UpdateInvoiceDocumentFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}
