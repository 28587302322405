import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { some, includes } from 'lodash';

@Injectable()
export class ApiNoCacheInterceptor implements HttpInterceptor {
    blackList: string[] = ['/i18n/', 'googleapis'];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (some(this.blackList, item => includes(request.url, item))) {
            return next.handle(request);
        }

        let changedRequest;
        if (request.method === 'GET') {
            changedRequest = request.clone({
                headers: request.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
                    .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            });
        }
        return next.handle(changedRequest || request);
    }
}
