import {
    Directive,
    ElementRef,
    EventEmitter,
    Output,
    Input,
    AfterViewChecked,
    OnDestroy,
    OnInit
} from '@angular/core';
import { isElementVerticallyVisible } from '../utilities/element-visible.util';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

const INITIAL_LOAD_DELAY = 500;

@Directive({ selector: '[zhmInfiniteList]' })
export class NguiInfiniteListDirective implements OnInit, OnDestroy, AfterViewChecked {
    @Input() enableWindowScroll: boolean = false;
    @Input() autoScrollOnInit: boolean = true;
    @Input() offset: number = 0;
    @Output() endVisible = new EventEmitter<boolean>();

    element: HTMLElement;
    endElement: HTMLElement;
    visibilityCheckSubscription: Subscription;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.element = this.elementRef.nativeElement;

        if (!this.element.querySelector) {
            this.element = this.element.parentElement || (this.element.parentNode as any);
        }

        this.endElement =
            this.element && <HTMLElement>this.element.querySelector('[data-zhmInfiniteListEnd]');

        if (!this.endElement) {
            throw 'Invalid data-zhmInfiniteListEnd';
        }

        if (this.autoScrollOnInit) {
            this.scrollListener();
        }

        this.addScrollListeners();

        this.visibilityCheckSubscription = Observable.interval(INITIAL_LOAD_DELAY).subscribe(() => {
            const outerElement = this.enableWindowScroll ? window : this.element;
            const visible = isElementVerticallyVisible(this.endElement, outerElement, this.offset);

            if (visible.top || visible.bottom) {
                this.endVisible.emit(true);
            }
        });
    }

    ngAfterViewChecked() {}

    ngOnDestroy() {
        this.removeScrollListeners();
    }

    scrollListener = () => {
        const outerElement = this.enableWindowScroll ? window : this.element;

        const visible = isElementVerticallyVisible(this.endElement, outerElement, this.offset);

        if (visible.top || visible.bottom) {
            this.endVisible.emit(true);
        }
    };

    addScrollListeners() {
        if (this.enableWindowScroll) {
            window.addEventListener('scroll', this.scrollListener);
        } else {
            this.element.addEventListener('scroll', this.scrollListener);
        }

        window.addEventListener('resize', this.scrollListener);
    }

    removeScrollListeners() {
        if (this.enableWindowScroll) {
            window.removeEventListener('scroll', this.scrollListener);
        } else {
            this.element.removeEventListener('scroll', this.scrollListener);
        }

        window.removeEventListener('resize', this.scrollListener);
    }
}
