import { BankAccount } from '../../interfaces';

export interface IBankAccountsState {
    bankAccountsList: BankAccount[];
    bankAccountsAreLoaded: boolean;
    bankAccountsAreBeingLoaded: boolean;
    latestUpdateDate: string;
    pollingIsGoing: boolean;
    isDeleteBlocked: boolean;
    bankAccountSelected: BankAccount;
    pendingBankAccounts: {
        [bankAccountId: string]: boolean;
    };
}

export const initialBankAccountsStoreState: IBankAccountsState = {
    bankAccountsList: [],
    bankAccountsAreLoaded: false,
    bankAccountsAreBeingLoaded: false,
    latestUpdateDate: null,
    pollingIsGoing: false,
    isDeleteBlocked: false,
    bankAccountSelected: null,
    pendingBankAccounts: {}
};
