import { find, some, includes, map } from 'lodash';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IExpenseContract } from '@zenhomes/domain/contract';

export function getContractPeriodForRecurringInvoice(
    invoice: IInvoice,
    contract: IExpenseContract
) {
    const contractItemIds = map(invoice.items, item => item.contractItemId);

    return find(contract.periods, period => {
        return some(period.items, item => includes(contractItemIds, item.id));
    });
}
