import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { IContact } from '@zenhomes/domain/contact';
import { includes, isEmpty, isEqual } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ContactsService } from '../../services/contacts.service';

@Component({
    selector: 'zhm-contact-multi-selector',
    templateUrl: './contact-multi-selector.component.html',
    styleUrls: ['./contact-multi-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactMultiSelectorComponent implements OnInit {
    @Input() showTags: boolean = true;
    @Input() value: IContact[] = [];
    @Output() valueChange = new EventEmitter<IContact[]>();

    currentContact: IContact;
    currentCounterparty: string;

    constructor(private contactsService: ContactsService) {}

    ngOnInit() {}

    trackById(index: number, item: any) {
        return item.id;
    }

    get selectedContactsIds() {
        return this.value.map(contact => contact.id);
    }

    getFilledContact(contact: IContact) {
        if (isEmpty(contact.name) && isEmpty(contact.companyName)) {
            return this.contactsService.contactsAfterLoading$.map(contacts =>
                contacts.find(c => c.id === contact.id)
            );
        } else {
            return Observable.of(contact);
        }
    }

    onSelectContact(contact: IContact) {
        this.currentContact = contact;

        if (isEmpty(contact)) return;

        const isSelected = includes(this.selectedContactsIds, contact.id);

        let newValue: IContact[];
        if (isSelected) {
            newValue = this.value.filter(c => c.id !== contact.id);
        } else {
            newValue = [...this.value, contact];
        }

        this.valueChange.emit(newValue as any);
    }

    removeSelection(item: IContact) {
        const newValue = this.value.filter(c => !isEqual(c.id, item.id));
        this.valueChange.emit(newValue as any);
    }
}
