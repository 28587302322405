import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
// Services
import { ExpenseContractsApiService } from './services/expense-contracts-api.service';
import { ExpenseContractsPeriodsApiService } from './services/expense-contracts-periods-api.service';

@NgModule({
    imports: [CommonModule],
    providers: []
})
export class ExpenseContractsCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ExpenseContractsCoreModule,
            providers: [ExpenseContractsApiService, ExpenseContractsPeriodsApiService]
        };
    }
}
