import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagesService } from '@zenhomes/toast-messages';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { throttle } from 'lodash';

export const ACTION_LOCKED_HTTP_STATUS = 423;

@Injectable()
export class ActionLockedInterceptor implements HttpInterceptor {
    constructor(private messagesService: MessagesService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(response => {
            if (this.isRequestLocked(response)) {
                this.showActionLockedErrorMessage();
            }

            return throwError(response);
        });
    }

    isRequestLocked(response: any) {
        return (
            response instanceof HttpErrorResponse && response.status === ACTION_LOCKED_HTTP_STATUS
        );
    }

    showActionLockedErrorMessage: Function = throttle(
        () => this.messagesService.showMessage('errors.actionLockedMessage', 'DANGER', true),
        2500,
        {
            trailing: false
        }
    );
}
