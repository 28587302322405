import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'normalizeDate'
})
export class NormalizeDatePipe implements PipeTransform {
    transform(value: string = ''): string {
        return value.replace(/\s/g, 'T');
    }
}
