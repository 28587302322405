import { Injectable, Inject } from '@angular/core';
import { DocumentsCoreConfigToken, DocumentsCoreConfig } from '../documents-core.config';
import { formatUrl } from '@zenhomes/sauron';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

@Injectable({
    providedIn: 'root'
})
export class DocumentDownloadService {
    constructor(@Inject(DocumentsCoreConfigToken) private config: DocumentsCoreConfig) {}

    downloadFile(url: string): void {
        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = url;
        link.download = '';

        // workaround to fix ie11 download issue;
        const isIE11 = !!window['MSInputMethodContext'] && !!document['documentMode'];

        if (!isIE11) {
            link.target = '_blank';
        }

        link.click();
        document.body.removeChild(link);
    }

    previewFile(url: string): void {
        IFrameHustla.sendMessage({
            type: 'CHILD_PARENT_REDIRECTION_REQUESTED',
            url,
            target: '_blank'
        });
    }

    getFullUrl(url: string) {
        return formatUrl(url, this.config.apiHost);
    }
}
