import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    IProperty,
    IBuildingModel,
    IPropertySelection,
    IUnitModel
} from '@zenhomes/domain/property';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import {
    IContentNavigationItem,
    IContentNavigationSelection,
    INavigationParams,
    IPropertyNavigationSelection
} from '../interfaces';
import * as propertyNavigationActions from '../state/actions/property-navigation.actions';
import * as fromMainModuleState from '../state/index';
import { normalizeUnitSelection } from '../utilities/property-selection.util';

@Injectable()
export class PropertyNavigationService {
    contentNavigationItems$: Observable<IContentNavigationItem[]> = this.store.select(
        fromMainModuleState.getContentNavigationItems
    );
    contentNavigationSelectedItem$: Observable<IContentNavigationItem> = this.store.select(
        fromMainModuleState.getContentNavigationSelectedItem
    );
    propertyNavigationSelectedProperty$: Observable<IProperty> = this.store.select(
        fromMainModuleState.getPropertyNavigationSelectedProperty
    );
    propertyNavigationSelectedBuilding$: Observable<IBuildingModel> = this.store.select(
        fromMainModuleState.getPropertyNavigationSelectedBuilding
    );
    propertyNavigationSelectedUnit$: Observable<IUnitModel> = this.store.select(
        fromMainModuleState.getPropertyNavigationSelectedUnit
    );
    propertySelection$: Observable<IPropertySelection>;
    contentNavigationSelection$: Observable<IContentNavigationSelection>;
    navigationSelection$: Observable<IContentNavigationSelection & IPropertyNavigationSelection>;

    constructor(private store: Store<fromMainModuleState.IMainModuleState>) {
        this.propertySelection$ = Observable.combineLatest(
            this.propertyNavigationSelectedBuilding$,
            this.propertyNavigationSelectedUnit$
        ).map(([building, unit]) => {
            if (!building) return null;
            return { building, unit: normalizeUnitSelection(building, unit) };
        });

        this.contentNavigationSelection$ = this.contentNavigationSelectedItem$.map(item => ({
            item
        }));

        this.navigationSelection$ = Observable.combineLatest(
            this.contentNavigationSelectedItem$,
            this.propertyNavigationSelectedProperty$,
            this.propertyNavigationSelectedBuilding$,
            this.propertyNavigationSelectedUnit$
        ).map(([item, property, building, unit]) => {
            return { item, property, building, unit };
        });
    }

    determineNavigationItems(params: INavigationParams) {
        this.store.dispatch(new propertyNavigationActions.PropertyDetermineNavigationItems(params));
    }

    reset() {
        this.store.dispatch(new propertyNavigationActions.PropertyNavigationReset());
    }
}
