import { curry } from 'lodash';

const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
    if (typeCache[<string>label]) {
        throw new Error(`Action type '${label}' is not unqiue'`);
    }

    typeCache[<string>label] = true;

    return <T>label;
}

export const typeWithNamespace = curry((namespace: string, label: string) => {
    return type(`[${namespace}] ${label}`);
});
