import { Action } from '@ngrx/store';
import { Tenancy, UsageProperty, UsageList } from '../../interfaces';

export const usagesActionTypes = {
    LOAD_USAGES: '[Usages] Load usages',
    LOAD_USAGES_SUCCESS: '[Usages] Load usages success',
    LOAD_USAGES_FAIL: '[Usages] Load usages fail',
    LOAD_TENANCIES: '[Usages] Load tenancies',
    LOAD_TENANCIES_SUCCESS: '[Usages] Load tenancies success',
    LOAD_TENANCIES_FAIL: '[Usages] Load tenancies fail'
};

export class LoadUsages implements Action {
    readonly type = usagesActionTypes.LOAD_USAGES;
    constructor(public payload?: UsageProperty) {}
}

export class LoadUsagesSuccess implements Action {
    readonly type = usagesActionTypes.LOAD_USAGES_SUCCESS;

    constructor(public payload: UsageList[]) {}
}

export class LoadUsagesFail implements Action {
    readonly type = usagesActionTypes.LOAD_USAGES_FAIL;
}

export class LoadTenancies implements Action {
    readonly type = usagesActionTypes.LOAD_TENANCIES;
    constructor(public payload: UsageProperty) {}
}

export class LoadTenanciesSuccess implements Action {
    readonly type = usagesActionTypes.LOAD_TENANCIES_SUCCESS;

    constructor(public payload: Tenancy[]) {}
}

export class LoadTenanciesFail implements Action {
    readonly type = usagesActionTypes.LOAD_TENANCIES_FAIL;
}
