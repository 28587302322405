import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    Input,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'zhm-add-document-button',
    templateUrl: './add-document-button.component.html',
    styleUrls: ['./add-document-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDocumentButtonComponent implements OnInit {
    @Input() addButtonText: string;
    @Output() onAddDocument = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    onAddDocumentClick() {
        this.onAddDocument.emit();
    }
}
