import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';
import { EventRouterContext } from '@zenhomes/frontend-routing';
import { getParentFrameDomain } from '../util/get-parent-frame-domain.util';
import { TokenService } from '@zenhomes/auth';

@Injectable({
    providedIn: 'root'
})
export class IframeHustlaInitService {
    constructor(private router: Router, private tokenService: TokenService) {}

    init() {
        const parentFrameDomain = getParentFrameDomain();

        EventRouterContext.initContext({
            parentFrameDomain
        });

        IFrameHustla.parentFrameDomain = parentFrameDomain;

        IFrameHustla.tryGetAuthTokenFromUrl();

        IFrameHustla.subscribeToMessage(({ type, path }) => {
            switch (type) {
                case 'PARENT_CHILD_REDIRECTION_REQUESTED': {
                    IFrameHustla.sendMessage({
                        type: 'CHILD_PARENT_REDIRECTION_ACKNOWLEDGED',
                        path
                    });

                    this.router.navigateByUrl(path, { skipLocationChange: true });
                }
            }
        });
    }

    /** we inform the parent frame to reload its properties */
    sendReloadPropertiesMessage() {
        IFrameHustla.sendMessage({ type: 'CHILD_PROPERTIES_UPDATED' });
    }
}
