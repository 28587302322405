import {
    Directive,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { DeviceService } from '../services/device.service';
import { Subscription } from 'rxjs/Subscription';

@Directive({ selector: '[zhmShowOnDesktopOnly]' })
export class ShowOnDesktopOnlyDirective implements OnInit, OnDestroy {
    private subscription: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private changeDetectorRef: ChangeDetectorRef,
        private deviceService: DeviceService
    ) {}

    ngOnInit() {
        this.subscription = this.deviceService.isMobile$.subscribe(isMobile => {
            if (!isMobile) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe();
    }
}
