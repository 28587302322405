export function getDocumentExtensionsIconClassName(extension: any) {
    if (extension === 'pdf') {
        return 'icon-file-pdf-new';
    } else if (extension.match('doc') || extension === 'odt') {
        return 'icon-file-word-new';
    } else if (extension.match('ppt') || extension === 'odp') {
        return 'icon-file-powerpoint-new';
    } else if (extension.match('xls') || extension === 'ods') {
        return 'icon-file-excel-new';
    } else if (
        extension === 'jpeg' ||
        extension === 'jpg' ||
        extension === 'png' ||
        extension === 'tiff'
    ) {
        return 'icon-file-image-new';
    } else {
        return 'icon-file-new';
    }
}

export function getFileSize(size: number): string {
    if (size >= 1024 * 1024) {
        return parseFloat((size / (1024 * 1024)).toFixed(2)).toLocaleString() + ' MB';
    } else {
        return parseFloat((size / 1024).toFixed(2)).toLocaleString() + ' kB';
    }
}
