import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { JournalViewService } from './services/journal-view.service';

import { journalViewReducer } from './state';
import { JournalViewEffects } from './state/effects/journal-view.effects';
import { JournalViewDocumentsEffects } from './state/effects/journal-view-documents.effects';

@NgModule({
    imports: [
        StoreModule.forFeature('JournalViewModule', journalViewReducer),
        EffectsModule.forFeature([JournalViewEffects, JournalViewDocumentsEffects])
    ]
})
export class JournalViewCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: JournalViewCoreModule,
            providers: [JournalViewService]
        };
    }
}
