import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-selection-tag',
    templateUrl: './selection-tag.component.html',
    styleUrls: ['./selection-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionTagComponent {
    @Input() label: string = '';
    @Output() remove: EventEmitter<null> = new EventEmitter();

    onClick($event: any) {
        $event.stopPropagation();
        this.remove.emit($event);
    }
}
