import { authActionTypes } from '../actions/auth.actions';

export interface IAuthState {
    token: string;
}

export const initialAuthStoreState: IAuthState = {
    token: null
};

export function authReducer(state: IAuthState = initialAuthStoreState, action: any): IAuthState {
    switch (action.type) {
        case authActionTypes.AUTH_SET_TOKEN: {
            return Object.assign({}, state, {
                token: action.payload
            });
        }

        case authActionTypes.AUTH_RESET: {
            return Object.assign({}, initialAuthStoreState);
        }

        default: {
            return state;
        }
    }
}
