import { Pipe, PipeTransform } from '@angular/core';
import { IBuildingModel, getPropertySubtypeLabel } from '@zenhomes/domain/property';

@Pipe({
    name: 'propertySubtypeLabel'
})
export class PropertySubtypeLabelPipe implements PipeTransform {
    transform(building: IBuildingModel): string {
        return building ? getPropertySubtypeLabel(building) : '';
    }
}
