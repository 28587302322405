import { Action, Store } from '@zenhomes/ngrx-actions';
import {
    initialJournalViewDocumentsStoreState,
    JournalViewDocumentsState
} from '../../state/constants/journal-view-documents.constants';
import * as actions from '../actions/journal-view-documents.actions';

@Store(initialJournalViewDocumentsStoreState)
export class JournalViewDocumentsStore {
    @Action(actions.GetExpandedInvoiceDocumentsSuccess)
    setExpandedInvoiceDocuments(
        state: JournalViewDocumentsState,
        { documents }: actions.GetExpandedInvoiceDocumentsSuccess
    ): JournalViewDocumentsState {
        return { ...state, expandedInvoiceDocuments: documents };
    }
}
