<div class="document-form">
    <div class="row pt-3 pb-4">
        <div class="col-12 mb-3">
            <label class="text-small-uppercase text-bold mb-3" translate
                >documentsEditor.documentType</label
            >
            <input
                type="text"
                class="form-control"
                [(ngModel)]="document.name"
                placeholder="{{ 'documents.docName' | translate }}"
            />

            <div *ngIf="hasError('name')" class="errors">
                <span translate>documents.errors.docNameIsRequired</span>
            </div>
        </div>

        <!--        <div class="col-12 col-md-6 pr-md-4 margin-bottom-1">-->
        <!--            <div dropdown class="form-control dropdown">-->
        <!--                <div dropdownToggle>-->
        <!--                    <span>{{ selectedDocumentCategoryName() | translate }}</span>-->
        <!--                    <i-->
        <!--                        class="icon_navigation icon-sol_caret-down pull-right"-->
        <!--                        aria-hidden="true"-->
        <!--                    ></i>-->
        <!--                </div>-->
        <!--                <ng-container *ngIf="documentCategories && documentCategories.length">-->
        <!--                    <ul *dropdownMenu class="dropdown-menu">-->
        <!--                        <li-->
        <!--                            *ngFor="let category of documentCategories"-->
        <!--                            (click)="selectDocumentCategory(category)"-->
        <!--                        >-->
        <!--                            <span class="dropdown-item">{{ category.name }}</span>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </ng-container>-->
        <!--            </div>-->
        <!--            <div *ngIf="hasError('category')" class="errors">-->
        <!--                <span translate>documents.errors.docCategoryIsRequired</span>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="col-12 col-md-6 pl-md-4">-->
        <!--            <div dropdown class="form-control dropdown">-->
        <!--                <div dropdownToggle>-->
        <!--                    <span>{{ selectedDocumentTypeName() | translate }}</span>-->
        <!--                    <i-->
        <!--                        class="icon_navigation icon-sol_caret-down pull-right"-->
        <!--                        aria-hidden="true"-->
        <!--                    ></i>-->
        <!--                </div>-->
        <!--                <ul *dropdownMenu class="dropdown-menu">-->
        <!--                    <li-->
        <!--                        *ngFor="let docType of selectedDocumentCategoryTypes()"-->
        <!--                        (click)="selectDocumentType(docType)"-->
        <!--                    >-->
        <!--                        <span class="dropdown-item">{{ docType.name }}</span>-->
        <!--                    </li>-->
        <!--                </ul>-->
        <!--            </div>-->
        <!--            <div *ngIf="hasError('type')" class="errors">-->
        <!--                <span translate>documents.errors.docTypeIsRequired</span>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

    <!-- DATE -->
    <div class="row py-4">
        <div class="col-12">
            <label class="text-small-uppercase text-bold mb-3" translate>documents.docDate</label>
        </div>
        <div class="col-12" [ngClass]="{ 'col-md-6 pr-md-4': !!this.document?.dateTo }">
            <zhm-datepicker
                placeholder="01.01.2017"
                [inputOutputMode]="true"
                [defaultDate]="document?.dateFrom"
                [dateVal]="document?.dateFrom"
                (dateValChange)="setDocumentFromDate($event)"
            >
            </zhm-datepicker>
            <div *ngIf="hasError('dateFrom')" class="errors">
                <span translate>documents.errors.buidlingIsRequired</span>
            </div>
        </div>
        <ng-container *ngIf="!!this.document?.dateTo">
            <div class="col-12 col-md-6 pl-md-4">
                <zhm-datepicker
                    placeholder="01.01.2017"
                    [inputOutputMode]="true"
                    [defaultDate]="document?.dateTo"
                    [dateVal]="document?.dateTo"
                    (dateValChange)="setDocumentToDate($event)"
                >
                </zhm-datepicker>
            </div>
        </ng-container>
        <div class="col-12 flex">
            <zhm-ng-checkbox
                [value]="!!this.document?.dateTo"
                (valueChange)="toggleTimePeriod()"
                class="blue-square-checkmark"
            ></zhm-ng-checkbox>
            <label class="text-small-dark" translate>documents.timePeriod</label>
        </div>
    </div>

    <!-- BUILDINGS -->
    <div class="row py-4">
        <div class="col-12">
            <label class="text-small-uppercase text-bold mb-3" translate
                >documentsEditor.belongsTo</label
            >
        </div>

        <div class="col-12 mb-2">
            <label class="text-xsmall-light mb-1" translate>documentsEditor.property</label>
            <buildings-dropdown
                [buildings]="buildings"
                [model]="selectedBuilding"
                (onSelect)="selectBuilding($event)"
            >
            </buildings-dropdown>
        </div>

        <div class="col-12 col-md-6 pr-md-4 margin-bottom-1">
            <label class="text-xsmall-light mb-1" translate>documentsEditor.unit</label>
            <units-dropdown
                [units]="getAvailableUnitsForSelectedBuilding()"
                [model]="selectedUnit"
                (onSelect)="selectUnit($event)"
            >
            </units-dropdown>
        </div>

        <div class="col-12 col-md-6 pl-md-4">
            <label class="text-xsmall-light mb-1" translate>documentsEditor.tenant</label>
            <zhm-tenancy-selector-dropdown
                [tenancies]="selectedUnitTenancies()"
                [model]="selectedTenancy"
                (onSelect)="selectTenancy($event)"
            >
            </zhm-tenancy-selector-dropdown>
        </div>
    </div>

    <!-- ACTIONS -->
    <ng-container *ngIf="showActionButtons">
        <div class="row py-4">
            <div class="col-6">
                <button type="button" class="btn" (click)="cancel()" translate>
                    action.cancel
                </button>
            </div>
            <div class="col-6 text-right">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="save()"
                    [disabled]="disabled"
                    translate
                >
                    action.save
                </button>
            </div>
        </div>
    </ng-container>
</div>
