import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

interface Field {
    id: any;
    label: string;
    icon?: string;
}

@Component({
    selector: 'zhm-checkbox-dropdown',
    templateUrl: './checkbox-dropdown.component.html',
    styleUrls: ['./checkbox-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxDropdownComponent {
    @Input() allFields: Field[] = [];
    @Input() selectedFields: string[] = [];
    @Input() titleText: string;
    @Input() titleIcon: string;
    @Output() selectedFieldsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    isExpanded: boolean = false;

    get numberOfSelectedFields() {
        return this.selectedFields.length || 0;
    }

    get isTitleNumberShown() {
        return this.numberOfSelectedFields > 0;
    }

    get dropdownExpandIcon() {
        return this.isExpanded ? 'icon-angle-up' : 'icon-angle-down';
    }

    fieldTrackBy(_: number, field: Field) {
        return field.id;
    }

    isFieldSelected(field: Field): boolean {
        return this.selectedFields.indexOf(field.id) !== -1;
    }

    emitNewToggle(selectedFields: string[]) {
        this.selectedFieldsChange.emit(selectedFields);
    }

    toggleCheckbox(fieldId: string) {
        const { selectedFields } = this;
        let updatedFields: string[];

        if (selectedFields.indexOf(fieldId) !== -1) {
            updatedFields = selectedFields.filter(currentCheckbox => currentCheckbox !== fieldId);
        } else {
            updatedFields = [...selectedFields, fieldId];
        }

        this.emitNewToggle(updatedFields);
    }

    onDropdownIconClick() {
        this.isExpanded = !this.isExpanded;
    }

    handleClick($event: any, field: Field) {
        $event.stopPropagation();
        this.toggleCheckbox(field.id);
    }

    onValueChange(field: Field) {
        this.toggleCheckbox(field.id);
    }
}
