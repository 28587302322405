import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { convertObjectToHttpParams } from '@util/convert-object-to-http-params.util';
import { EXPENSE, IInvoice, IInvoicePeriod, INCOME } from '@zenhomes/domain/invoice';
import {
    BookmarkableFilterOptions,
    formAmountParams,
    formCounterpartyIdsParams,
    formInvoicesStatusFilter,
    formPropertySelectionParams,
    formTransactionType,
    getIndividualFilterParam
} from '@zenhomes/filter-core';
import { map } from 'lodash';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class InvoiceApiService {
    constructor(private http: HttpClient) {}

    loadInvoices(
        filterOptions: BookmarkableFilterOptions
    ): Observable<PageableElementsModel<IInvoice>> {
        const params = formGetInvoicesParams(filterOptions);
        return this.http
            .get<any>(`/invoices`, { params })
            .map(response => new PageableElementsModel(response));
    }

    getInvoiceById(invoiceId: string): Observable<IInvoice> {
        return this.http.get<IInvoice>(`/invoices/${invoiceId}`);
    }

    getInvoice(invoice: IInvoice): Observable<IInvoice> {
        return this.http.get<IInvoice>(`/invoices/${invoice.id}`);
    }

    createInvoice(invoice: IInvoice): Observable<IInvoice> {
        return this.http.post<IInvoice>(`/invoices`, invoice);
    }

    updateInvoice(invoice: IInvoice): Observable<IInvoice> {
        return this.http.put<IInvoice>(`/invoices/${invoice.id}`, invoice);
    }

    deleteInvoice(invoiceId: string): Observable<IInvoice> {
        return this.http.delete<IInvoice>(`/invoices/${invoiceId}`);
    }

    updateNoteText(
        text: string,
        invoiceId: string
    ): Observable<{ text: string; updatedOn: string }> {
        return this.http.put<{ text: string; updatedOn: string }>(`/invoices/${invoiceId}/note`, {
            text
        });
    }

    payInvoices(invoiceIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/invoices/paid`, { invoiceIds });
    }

    unpayInvoices(invoiceIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/invoices/unpaid`, { invoiceIds });
    }

    deleteInvoices(invoiceIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/invoices/deleted`, { invoiceIds });
    }

    resetInvoices(invoiceIds: string[]): Observable<string[]> {
        return this.http.put<string[]>(`/invoices/reset`, { invoiceIds });
    }

    loadInvoiceClustersByPeriod(
        filterOptions: BookmarkableFilterOptions
    ): Observable<IInvoicePeriod[]> {
        const params = convertObjectToHttpParams(filterOptions);
        return this.http.get<any>(`/accounting-populator/invoices/clustered-by-period`, { params });
    }
}

function formGetInvoicesParams(filterOptions: BookmarkableFilterOptions): HttpParams {
    const {
        page = '1',
        size = '10',
        contractId = '',
        search = '',
        category = '',
        recurring = undefined,
        balanced = undefined,
        query = '',
        startDate = '',
        endDate = '',
        excludeUnits = false,
        topicId = undefined
    } = filterOptions || {};

    const { buildingId, unitId } = formPropertySelectionParams(filterOptions || {});
    const { amountFrom, amountTo } = formAmountParams(filterOptions || {});
    const { counterpartyId } = formCounterpartyIdsParams(filterOptions || {});
    const status = formInvoicesStatusFilter(filterOptions || {});
    const type = formTransactionType(filterOptions || {}, INCOME, EXPENSE);
    const subjectId = filterOptions && map(filterOptions.subjectSelection, subject => subject.id);
    const individual = getIndividualFilterParam(filterOptions || {});

    const params = convertObjectToHttpParams({
        page,
        size,
        buildingId,
        unitId,
        contractId,
        counterpartyId,
        amountFrom,
        amountTo,
        search,
        category,
        status,
        type,
        query,
        startDate,
        endDate,
        excludeUnits,
        recurring,
        balanced,
        subjectId,
        topicId,
        individual
    });

    return params;
}
