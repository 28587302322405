import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiNoCacheInterceptor } from './interceptors/api-no-cache.interceptor';
import { ApiUrlInterceptor } from './interceptors/api-url.interceptor';
import { NotifyUserInterceptor } from './interceptors/notify-user.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { ForbiddenInterceptor } from './interceptors/forbidden.interceptor';
import { CaseConverterInterceptor } from './interceptors/case-converter.interceptor';
import { PrefillDataInterceptor } from './interceptors/prefill-data.interceptor';
import { AuthTokenInterceptor } from '@zenhomes/auth';
import { InterceptorsConfig, InterceptorsConfigToken } from './interceptors.config';
import { ActionLockedInterceptor } from './interceptors/action-locked.interceptor';
import { WithCredentialsInterceptor } from './interceptors/with-credentials.interceptor';

@NgModule({
    imports: []
})
export class InterceptorsModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: InterceptorsModule
    ) {
        if (parentModule) {
            throw new Error(
                'InterceptorsModule is already loaded. Import it in the AppModule only'
            );
        }
    }

    static forRoot(config: InterceptorsConfig): ModuleWithProviders {
        return {
            ngModule: InterceptorsModule,
            providers: [
                { provide: InterceptorsConfigToken, useValue: config },
                { provide: HTTP_INTERCEPTORS, useClass: ApiNoCacheInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: PrefillDataInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: CaseConverterInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: NotifyUserInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ActionLockedInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true }
            ]
        };
    }
}
