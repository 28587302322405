import { Injectable } from '@angular/core';
import { DeviceService } from '@zenhomes/device';
import {
    ConfirmationDialogOptions,
    ConfirmationService as ConfirmationServiceParent
} from '@zenhomes/dialog-wizard';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';
import { Observable } from 'rxjs';

export { ConfirmationDialogOptions };

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {
    constructor(
        private deviceService: DeviceService,
        private confirmationServiceParent: ConfirmationServiceParent
    ) {}

    confirm(config: ConfirmationDialogOptions): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            let isCloseIframeCalled = false;

            const closeIframe = () => {
                if (!isCloseIframeCalled) {
                    isCloseIframeCalled = true;
                    this.sendIframeMessage(false);
                }
            };

            this.sendIframeMessage(true);

            const subscription = this.confirmationServiceParent.confirm(config).subscribe(
                value => {
                    subscriber.next(value);
                    subscriber.complete();
                    closeIframe();
                },
                closeIframe,
                () => {
                    subscriber.complete();
                    closeIframe();
                }
            );

            return () => {
                subscription.unsubscribe();
            };
        });
    }

    sendIframeMessage(isOpen: boolean) {
        if (isOpen) {
            IFrameHustla.sendMessage({
                type: 'CHILD_DIALOG_OPENED',
                dialogName: 'confirmation-dialog'
            });
        } else {
            IFrameHustla.sendMessage({
                type: 'CHILD_DIALOG_CLOSED',
                dialogName: 'confirmation-dialog'
            });
        }
    }
}
