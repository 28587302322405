import { BankAccount } from '../interfaces';

export const getUpdatedTime = (updatedTime: string, previousUpdatedDate: string) => {
    const newTimestamp = new Date(updatedTime).getTime();
    const prevTimestamp = new Date(previousUpdatedDate).getTime();
    return newTimestamp > prevTimestamp ? updatedTime : previousUpdatedDate;
};

export const getLatestUpdatedTime = (bankAccounts: BankAccount[]) => {
    return bankAccounts.reduce((lastUpdated, bankAccount) => {
        if (bankAccount) {
            if (lastUpdated === '') {
                lastUpdated = isValidDateString(bankAccount.lastUpdatedAt)
                    ? bankAccount.lastUpdatedAt
                    : lastUpdated;
            } else {
                lastUpdated = getUpdatedTime(bankAccount.lastUpdatedAt, lastUpdated);
            }
        }
        return lastUpdated;
    }, '');
};

const isValidDateString = (date: string) => !Number.isNaN(Date.parse(date));
