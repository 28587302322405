export enum ContractTimeInterval {
    OneWeek = 'ONE_WEEK',
    TwoWeeks = 'TWO_WEEKS',
    ThreeWeeks = 'THREE_WEEKS',
    FiveWeeks = 'FIVE_WEEKS',
    SixWeeks = 'SIX_WEEKS',
    OneMonth = 'ONE_MONTH',
    TwoMonths = 'TWO_MONTHS',
    ThreeMonths = 'THREE_MONTHS',
    FourMonths = 'FOUR_MONTHS',
    FiveMonths = 'FIVE_MONTHS',
    SixMonths = 'SIX_MONTHS',
    SevenMonths = 'SEVEN_MONTHS',
    EightMonths = 'EIGHT_MONTHS',
    NineMonths = 'NINE_MONTHS',
    TenMonths = 'TEN_MONTHS',
    ElevenMonths = 'ELEVEN_MONTHS',
    TwelveMonths = 'TWELVE_MONTHS',
    EighteenMonths = 'EIGHTEEN_MONTHS',
    TwentyfourMonths = 'TWENTYFOUR_MONTHS',
    ThirtyMonths = 'THIRTY_MONTHS',
    ThirtysixMonths = 'THIRTYSIX_MONTHS',
    FortyeightMonths = 'FORTYEIGHT_MONTHS'
}
