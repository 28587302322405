import { find, get, reduce, map, every } from 'lodash';
import { either, complement, all, allPass, none, eqBy, path } from 'ramda';

import { IPayment, IClusteredPayment } from '@zenhomes/domain/payment';
import { isLengthMoreThanOne } from '@zenhomes/sauron';
import { isClusteredPayment } from '@payment-core/utils/clustered-payments.util';
import { BankAccount } from '@zenhomes/bank-account-core';

export function isPayment(payment: any): payment is IPayment {
    return !!payment && !!payment.paymentItems;
}

export function isPaymentItem(paymentItem: any): paymentItem is IPayment {
    return !!paymentItem && !!paymentItem.payment;
}

export function getBankAccountOfTransfer(bankAccounts: BankAccount[], payment: IPayment) {
    return find(bankAccounts, (bankAccount: BankAccount) => {
        const ibanHash = bankAccount && bankAccount.ibanHash;
        return get(payment, 'bankTransfer.accountIbanHash') === ibanHash;
    });
}

export const isUncategorizedClusteredPayment = (item: any): item is IClusteredPayment => {
    const clusteredPayment = item as IClusteredPayment;

    return (
        !!clusteredPayment &&
        !!clusteredPayment.from &&
        isLengthMoreThanOne(clusteredPayment.payments)
    );
};

export const isCategorizedPayment = (item: any): item is IPayment => {
    const payment = item as IPayment;

    return !!payment && !!payment.id;
};

export const isUncategorizedSinglePayment = (item: any): item is IPayment => {
    const payment = item as IPayment;

    return !!payment && !!payment.direction && !payment.id;
};

export const isSnoozedPayment = (item: any): item is IPayment => {
    const payment = item as IPayment;

    return !!payment && !!payment.bankTransfer && !!payment.bankTransfer.snoozed;
};

export const isIgnoredPayment = (item: any): item is IPayment => {
    const payment = item as IPayment;

    return !!payment && !!payment.bankTransfer && !!payment.bankTransfer.ignore;
};

type IBooleanPredicate = (item: any) => boolean;

export const isNotUncategorizedClusteredPayment: IBooleanPredicate = complement(
    isUncategorizedClusteredPayment
);
export const isUncategorizedPayment: IBooleanPredicate = either(
    isUncategorizedSinglePayment,
    isUncategorizedClusteredPayment as any
);
export const isOnlyUncategorizedPayment: IBooleanPredicate = allPass([
    either(isUncategorizedSinglePayment, isUncategorizedClusteredPayment as any),
    complement(isSnoozedPayment),
    complement(isIgnoredPayment)
]);
export const isSinglePayment: IBooleanPredicate = either(
    isUncategorizedSinglePayment,
    isCategorizedPayment
);

export const areAllCategorizedPayments: IBooleanPredicate = all(isCategorizedPayment);
export const areAllUncategorizedPayments: IBooleanPredicate = all(isUncategorizedPayment);
export const areAllOnlyUncategorizedPayments: IBooleanPredicate = all(isOnlyUncategorizedPayment);
export const areAllUncategorizedOrIgnoredPayments: IBooleanPredicate = all(
    either(isOnlyUncategorizedPayment, isIgnoredPayment)
);
export const areAllUncategorizedOrSnoozedPayments: IBooleanPredicate = all(
    either(isOnlyUncategorizedPayment, isSnoozedPayment)
);
export const areAllIgnoredOrSnoozedPayments: IBooleanPredicate = all(
    either(isIgnoredPayment, isSnoozedPayment)
);

export const eqByBankTransfer: Predicate2<IPayment, IPayment> = eqBy(path(['bankTransfer', 'id']));

export const getClusteredPaymentKey: Func1<
    IClusteredPayment | IPayment[],
    string
> = clusteredPayment => {
    const payments = isClusteredPayment(clusteredPayment)
        ? clusteredPayment.payments
        : clusteredPayment;

    const clusterKey = map(payments, payment => payment.bankTransfer.id).join('');

    return clusterKey;
};

export function isClusteredPaymentSelected(
    payments: IPayment[],
    clusteredPayment: IClusteredPayment
) {
    return every(clusteredPayment.payments, paymentInCluster => {
        return !!find(payments, payment => eqByBankTransfer(paymentInCluster, payment));
    });
}
