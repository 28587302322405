import { ErrorHandler } from '@angular/core';
import { datadogRum } from '../datadog';
import { environment } from 'environments/environment';

export class MonitoringErrorHandler implements ErrorHandler {
    private defaultErrorHandler = new ErrorHandler();
    private enabled = environment.DATADOG_RUM.ENABLED;

    handleError(err: any): void {
        if (this.enabled) {
            datadogRum.addError(err);
        }

        this.defaultErrorHandler.handleError(err);
    }
}
