import {
    Injectable,
    ComponentRef,
    ApplicationRef,
    EventEmitter,
    Type,
    RendererFactory2,
    Renderer2
} from '@angular/core';
import { includes } from 'lodash';
import { Observable } from 'rxjs/Observable';

import { DialogWizardComponent } from '../components/dialog-wizard.component';
import { BlinkMessage } from './blink-message.model';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog.component';
import { ComponentRefFactory } from './component-ref-factory.service';
import { IWizardComponent } from '../types';
import { of } from 'rxjs';

@Injectable()
export class DialogWizardService {
    wizardComponentRef: ComponentRef<DialogWizardComponent>;
    wizardDestroyed$ = new EventEmitter();
    componentRefs: WeakMap<Object, Object> = new WeakMap();
    currentWizardStep: string;
    componentsStack: Array<Object> = [];
    visibleComponents: Object = {};
    visibleComponentOnTop: Object = {};
    blinkMessage: BlinkMessage;
    backButton: boolean = false;
    renderer: Renderer2;

    constructor(
        private componentRefFactory: ComponentRefFactory,
        private appRef: ApplicationRef,
        private rendererFactory: RendererFactory2
    ) {
        this.blinkMessage = new BlinkMessage();
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    createComponentRef<T>(component: Type<T>) {
        return this.componentRefFactory.createComponentRef(component);
    }

    private instantiateWizardComponent() {
        if (this.wizardComponentRef) {
            return;
        }

        this.wizardComponentRef = this.createComponentRef(DialogWizardComponent);
        this.appRef.attachView(this.wizardComponentRef.hostView);

        this.wizardComponentRef.onDestroy(() => {
            this.appRef.detachView(this.wizardComponentRef.hostView);
        });

        this.appendWizardComponentRef();
    }

    appendWizardComponentRef() {
        const app = document.getElementsByTagName('app')[0];
        this.renderer.appendChild(app, this.wizardComponentRef.location.nativeElement);
    }

    setParams(component: Function, params: any): this {
        if (this.componentRefs.has(component)) {
            const componentRef = this.componentRefs.get(component) as ComponentRef<
                IWizardComponent
            >;
            componentRef.instance.setWizardParams(params);
        }

        return this;
    }

    destroyWizard() {
        if (this.wizardComponentRef) {
            this.wizardComponentRef.destroy();
            this.wizardComponentRef = null;
        }

        this.destroyAllComponents();
        this.restoreScrollbar();
    }

    closeWizard() {
        this.wizardComponentRef.instance.hide();
    }

    getBlinkMessage(): BlinkMessage {
        return this.blinkMessage;
    }

    showMessage(text: string, type: 'OK' | 'ERROR' = 'OK') {
        this.blinkMessage.text = text;
        this.blinkMessage.type = type;
        this.blinkMessage.showForSeconds(1);
    }

    isPreviousStep(): boolean {
        if (!this.visibleComponentOnTop[this.currentWizardStep]) {
            return false;
        }

        let step = this.currentWizardStep;
        let index = this.visibleComponents[this.currentWizardStep].indexOf(
            this.visibleComponentOnTop[this.currentWizardStep]
        );

        if (this.currentWizardStep !== 'default' && index - 1 === -1) {
            step = 'default';
            index = this.visibleComponents[step].length - 1;
        } else {
            index = index - 1;
        }

        return !!(index > -1 && this.visibleComponents[step][index]);
    }

    isNextStep(): boolean {
        if (!this.visibleComponentOnTop[this.currentWizardStep]) {
            return false;
        }

        const index = this.visibleComponents[this.currentWizardStep].indexOf(
            this.visibleComponentOnTop[this.currentWizardStep]
        );
        return !!(index > -1 && this.visibleComponents[this.currentWizardStep][index + 1]);
    }

    previousStep() {
        let currentVisibleComponent = this.visibleComponentOnTop[this.currentWizardStep];

        if (!currentVisibleComponent) {
            return;
        }

        let step = this.currentWizardStep;
        let index = this.visibleComponents[this.currentWizardStep].indexOf(
            this.visibleComponentOnTop[this.currentWizardStep]
        );

        if (this.currentWizardStep !== 'default' && index - 1 === -1) {
            step = 'default';
            index = this.visibleComponents[step].length - 1;
        } else {
            index = index - 1;
        }

        if (index > -1 && this.visibleComponents[step][index]) {
            this.openForm<Object>(this.visibleComponents[step][index], '', null, step);
            this.destroyComponent(currentVisibleComponent);
        }
    }

    nextStep() {
        if (!this.visibleComponentOnTop[this.currentWizardStep]) {
            return;
        }

        const index = this.visibleComponents[this.currentWizardStep].indexOf(
            this.visibleComponentOnTop[this.currentWizardStep]
        );

        if (index > -1 && this.visibleComponents[this.currentWizardStep][index + 1]) {
            this.openForm<Object>(this.visibleComponents[this.currentWizardStep][index + 1]);
        }
    }

    destroyComponent(component: any) {
        this.destroyComponentRef(component);
        this.removeComponentFromLists(component);

        let numberofComponents = Object.keys(this.visibleComponents).length;

        if (numberofComponents === 0) {
            this.wizardDestroyed$.next();
        }
    }

    private destroyAllComponents() {
        this.visibleComponents = {};

        if (!this.componentsStack.length) {
            return;
        }

        for (let component of this.componentsStack) {
            this.destroyComponent(component);
        }

        this.destroyAllComponents();
    }

    private removeComponentFromStack(component: any) {
        const index = this.componentsStack.indexOf(component);

        if (index > -1) {
            this.componentsStack.splice(index, 1);
        }
    }

    private removeComponentFromVisible(component: any) {
        Object.keys(this.visibleComponents).forEach(step => {
            if (!this.visibleComponents[step]) return;

            const index = this.visibleComponents[step].indexOf(component);

            if (index > -1) {
                this.visibleComponents[step].splice(index, 1);
            }
        });
    }

    removeComponentFromLists(component: any) {
        if (component === this.visibleComponentOnTop[this.currentWizardStep]) {
            this.visibleComponentOnTop[this.currentWizardStep] = null;
            this.wizardComponentRef && this.wizardComponentRef.instance.hide();
        }

        this.removeComponentFromStack(component);
        this.removeComponentFromVisible(component);
    }

    destroyComponentRef(component: any) {
        if (this.componentRefs.has(component)) {
            let componentRef = this.componentRefs.get(component) as ComponentRef<Object>;
            this.componentRefs.delete(component);
            componentRef.destroy();

            componentRef = null;
        }
    }

    loadForm(formComponent: any): this {
        if (this.componentRefs.has(formComponent)) {
            return this;
        }

        this.instantiateComponent(formComponent);
        return this;
    }

    loadConfirmationDialog(): this {
        return this.loadForm(ConfirmationDialogComponent);
    }

    openConfirmationDialog(): ComponentRef<ConfirmationDialogComponent> {
        return this.openForm<ConfirmationDialogComponent>(
            ConfirmationDialogComponent,
            'Confirmation'
        );
    }

    openForm<C>(
        formComponent: any,
        dialogName: string = '',
        params: any = null,
        step = 'default'
    ): ComponentRef<C> {
        this.instantiateWizardComponent();
        this.wizardComponentRef.instance.show();
        !this.visibleComponents[step] && (this.visibleComponents[step] = []);
        !this.visibleComponentOnTop[step] && (this.visibleComponentOnTop[step] = null);
        this.currentWizardStep = step;

        if (this.componentRefs.has(formComponent)) {
            const componentRef = this.componentRefs.get(formComponent) as ComponentRef<C>;

            Object.keys(this.visibleComponentOnTop).forEach(step => {
                if (
                    this.visibleComponentOnTop[step] &&
                    this.visibleComponentOnTop[step] !== formComponent
                ) {
                    if (this.visibleComponentOnTop[step]) {
                        const componentRef = this.getComponentRef<Object>(
                            this.visibleComponentOnTop[step]
                        );

                        if (componentRef && componentRef.location.nativeElement) {
                            componentRef.location.nativeElement.style.display = 'none';
                        }
                    }
                }
            });

            if (params) {
                this.setParams(formComponent, params);
            }

            this.visibleComponentOnTop[step] = formComponent;

            if (!includes(this.visibleComponents[step], formComponent)) {
                this.visibleComponents[step].push(formComponent);

                const dialogWizardElem = document.getElementsByClassName('dialog-wizard')[0];

                if (dialogWizardElem) {
                    this.updateDialogName(dialogName);
                    this.renderer.appendChild(
                        dialogWizardElem,
                        componentRef.location.nativeElement
                    );
                } else {
                    console.error('Dialog wizard element not found!');
                }
            }

            let componentNativeElem = this.getComponentRef<Object>(this.visibleComponentOnTop[step])
                .location.nativeElement;

            if (componentNativeElem) {
                componentNativeElem.style.display = '';
            } else {
                console.warn('Components native element not found!');
            }

            return this.componentRefs.get(formComponent) as ComponentRef<C>;
        } else {
            this.instantiateComponent(formComponent);
            if (params) {
                this.setParams(formComponent, params);
            }

            return this.openForm<C>(formComponent, dialogName, null, step);
        }
    }

    openFormRx<C>(
        formComponent: any,
        dialogName: string = '',
        params: any = null,
        step = 'default'
    ): Observable<C> {
        const form = this.openForm<C>(formComponent, dialogName, params, step);
        return of(form.instance);
    }

    updateDialogName(dialogName: string) {
        const dialogWizardElem = document.getElementsByClassName('dialog-wizard')[0];

        if (dialogWizardElem) {
            this.renderer.setAttribute(dialogWizardElem, 'dlg', dialogName);
        }
    }

    getComponentRef<C>(component: any): ComponentRef<C> {
        return this.componentRefs.get(component) as ComponentRef<C>;
    }

    hideScrollbar() {
        const html = document.getElementsByTagName('html')[0];
        this.renderer.setStyle(html, 'overflow', 'hidden');
    }

    restoreScrollbar() {
        const html = document.getElementsByTagName('html')[0];
        this.renderer.setStyle(html, 'overflow', 'auto');
    }

    isHiddenBackButton(): boolean {
        return this.backButton;
    }

    private instantiateComponent(component: any) {
        const componentRef = this.createComponentRef(component);

        this.appRef.attachView(componentRef.hostView);

        componentRef.onDestroy(() => {
            this.appRef.detachView(componentRef.hostView);
        });

        this.componentRefs.set(component, componentRef);
        this.componentsStack.push(component);
    }
}
