import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankAccount } from '../interfaces';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class BankAccountsApiService {
    constructor(private http: HttpClient) {}

    loadBankAccounts() {
        return this.http.get<BankAccount[]>('/transfersgw/automatic/bank-accounts').pipe(
            map(response => response || ([] as BankAccount[])),
            publishReplay(1),
            refCount()
        );
    }
}
