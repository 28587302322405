import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    ViewChild,
    Inject
} from '@angular/core';
import { TokenService } from '@zenhomes/auth';
import { camelcaseKeysDeep } from '@zenhomes/case-convert';
import { DATE_FORMAT_BACKEND } from '@zenhomes/date';
import uuid from 'uuid/v1';
import { IDocument, IDocumentPostParameters } from '@zenhomes/domain/document';
import * as format from 'date-fns/format';
import { find, map } from 'lodash';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload/ng2-file-upload';
import { createMultipartParamPayload } from '../../utils/multipart-param.util';
import { DocumentsCoreConfigToken, DocumentsCoreConfig } from '../../documents-core.config';

export interface IDocumentUploadItem {
    uuid: string;
    fileItem: FileItem;
    document: IDocument;
}

export interface IDocumentDropSectionComponent {
    openFileInput(): void;
    cancelUploadItem(uploadItem: IDocumentUploadItem): void;
}

@Component({
    selector: 'documents-drop-section',
    templateUrl: './documents-drop-section.component.html',
    styleUrls: ['./documents-drop-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsDropSectionComponent
    implements OnInit, OnDestroy, OnChanges, IDocumentDropSectionComponent {
    @ViewChild('fileInput', { static: true })
    private fileInput: ElementRef;

    @Input() accept: string;
    @Input() documentParams: IDocumentPostParameters;

    @Output() fileOver = new EventEmitter<boolean>();
    @Output() uploadStart = new EventEmitter<IDocumentUploadItem[]>();
    @Output() uploadUpdate = new EventEmitter<IDocumentUploadItem[]>();
    @Output() uploadComplete = new EventEmitter<IDocumentUploadItem>();
    @Output() uploadError = new EventEmitter<any>();
    @Output() progress = new EventEmitter<number>();

    uploader: FileUploader;
    uploadItems: IDocumentUploadItem[];

    constructor(
        @Inject(DocumentsCoreConfigToken) config: DocumentsCoreConfig,
        private ref: ChangeDetectorRef,
        private tokenService: TokenService
    ) {
        this.uploader = new FileUploader({ url: config.uploadDocumentUrl });
        this.uploadItems = [];
    }

    ngOnInit(): void {
        const documentParams = this.createInitialDocumentParams();

        this.uploader.setOptions({
            headers: [this.tokenService.createAuthHeader()],
            additionalParameter: createMultipartParamPayload(documentParams),
            allowedMimeType: this.accept ? this.accept.split(',') : undefined
        });

        this.uploader.onErrorItem = (item, response, status, headers) => () => {
            this.uploadError.emit(item);
            this.ref.markForCheck();
        };

        this.uploader.onProgressAll = progress => {
            this.progress.emit(progress);
            this.ref.markForCheck();
        };

        this.uploader.onSuccessItem = (item, response, status, headers) => {
            this.onUploadSuccess(item, response, status, headers);
            this.ref.markForCheck();
        };

        this.uploader.onBeforeUploadItem = item => {
            item.withCredentials = false;
            this.ref.markForCheck();
        };
    }

    ngOnDestroy() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.documentParams && !changes.documentParams.firstChange) {
            const documentParams = this.createInitialDocumentParams();

            this.uploader.setOptions({
                headers: [this.tokenService.createAuthHeader()],
                additionalParameter: createMultipartParamPayload(documentParams),
                allowedMimeType: this.accept ? this.accept.split(',') : undefined
            });
        }
    }

    openFileInput() {
        this.fileInput.nativeElement.click();
    }

    cancelUploadItem(uploadItem: IDocumentUploadItem) {
        uploadItem.fileItem.cancel();
        this.uploader.removeFromQueue(uploadItem.fileItem);

        this.uploadItems = this.getCurrentUploadItems();
        this.uploadUpdate.emit(this.uploadItems);
    }

    onUploadFiles(event: any = null) {
        this.uploader.uploadAll();

        this.uploadItems = this.getCurrentUploadItems();
        this.uploadStart.emit(this.uploadItems);
    }

    onUploadSuccess(
        fileItem: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders
    ): any {
        this.uploader.removeFromQueue(fileItem);

        const document: IDocument = camelcaseKeysDeep(JSON.parse(response));
        const completedUploadItem: IDocumentUploadItem = { uuid: uuid(), document, fileItem };
        this.uploadComplete.emit(completedUploadItem);

        this.uploadItems = this.getCurrentUploadItems();
        this.uploadUpdate.emit(this.uploadItems);
    }

    onFileOver(event: any) {
        this.fileOver.emit(event);
    }

    private getCurrentUploadItems(): IDocumentUploadItem[] {
        return map(this.uploader.queue, fileItem => {
            const existingUploadItem = find(this.uploadItems, item => item.fileItem === fileItem);

            if (existingUploadItem) {
                return existingUploadItem;
            }

            const document = { name: fileItem.file.name } as any;

            return { uuid: uuid(), document, fileItem };
        });
    }

    private createInitialDocumentParams(): IDocumentPostParameters {
        return {
            dateFrom: format(new Date(), DATE_FORMAT_BACKEND),
            ...(this.documentParams || {})
        };
    }
}
