import { find } from 'lodash';
import { IBuildingModelDraft, IUnitModelDraft } from '../interfaces';
import { IUnitType, IBuildingType, IBuildingOwnershipBasedType } from '../enums';

type Func0<TResult> = () => TResult;
type Func1<T1, TResult> = (t1: T1) => TResult;
type Func2<T1, T2, TResult> = (t1: T1, t2: T2) => TResult;

type Predicate1<T1> = (t1: T1) => boolean;
type Predicate2<T1, T2> = (t1: T1, t2: T2) => boolean;

export interface IUnitSubType {
    id: string;
    label: string;
}

export interface PropertyTypeMap {
    id: string;
    label: string;
    propertyType: string;
    unitType: IUnitType;
    icon: string;
    subTypes?: IUnitSubType[];
    unitSubTypeAttribute?: string;
    buildingSubTypeAttribute?: string;
    isCondo: boolean;
}

const PROPERTY_SUBTYPES: { id: string; label: string }[] = [
    { id: 'TERRACE', label: 'expose.buildingsubtype.terrace' },
    { id: 'ENDUNIT', label: 'expose.buildingsubtype.endunit' },
    { id: 'MID_TERRACE', label: 'expose.buildingsubtype.midterrace' },
    { id: 'END_TERRACE', label: 'expose.buildingsubtype.endterrace' },
    { id: 'SEMIDETACHED_HOUSE', label: 'expose.buildingsubtype.semidetached' },
    { id: 'DETACHED_HOUSE', label: 'expose.buildingsubtype.detached' },
    { id: 'TOWNHOUSE', label: 'expose.buildingsubtype.townhouse' },
    { id: 'BUNGALOW', label: 'expose.buildingsubtype.bungalow' },
    { id: 'VILLA', label: 'expose.buildingsubtype.villa' },
    { id: 'FARMHOUSE', label: 'expose.buildingsubtype.farmhouse' },
    { id: 'FARM', label: 'expose.buildingsubtype.farm' },
    { id: 'COUNTRYHOUSE', label: 'expose.buildingsubtype.countryhouse' },
    { id: 'CASTLE', label: 'expose.buildingsubtype.castle' },
    { id: 'TWOFAMILY', label: 'expose.buildingsubtype.twofamily' },
    { id: 'MULTIFAMILY', label: 'expose.buildingsubtype.multifamily' },
    { id: 'HOLIDAYLODGE', label: 'expose.buildingsubtype.holidaylodge' },
    { id: 'MOUNTAINHUT', label: 'expose.buildingsubtype.mountainhut' },
    { id: 'CHALET', label: 'expose.buildingsubtype.chalet' },
    { id: 'BEACHHOUSE', label: 'expose.buildingsubtype.beachhouse' },
    { id: 'SUMMERHOUSE', label: 'expose.buildingsubtype.summerhouse' },
    {
        id: 'APARTMENTBUILDING',
        label: 'expose.buildingsubtype.apartmentbuilding'
    },
    { id: 'FORTRESS', label: 'expose.buildingsubtype.fortress' },
    { id: 'MANORHOUSE', label: 'expose.buildingsubtype.manorhouse' },
    { id: 'FINCA', label: 'expose.buildingsubtype.finca' },
    { id: 'COTTAGE', label: 'expose.buildingsubtype.cottage' },
    { id: 'PREFABRICATED', label: 'expose.buildingsubtype.prefabricated' },
    { id: 'LIGHTHOUSE', label: 'expose.buildingsubtype.lighthouse' },
    { id: 'OTHER', label: 'expose.buildingsubtype.other' }
];

const PROPERTY_TYPE_MAPS: { [key: string]: PropertyTypeMap } = {
    [IBuildingType.Single]: {
        id: IBuildingType.Single,
        label: 'propertyForm.type.single',
        propertyType: IBuildingType.Single,
        unitType: undefined,
        icon: 'icon-out_house_basic',
        buildingSubTypeAttribute: 'houseType',
        isCondo: false,
        subTypes: PROPERTY_SUBTYPES
    },
    [IBuildingType.Multi]: {
        id: IBuildingType.Multi,
        label: 'propertyForm.type.multi',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.Apartment,
        icon: 'icon-out_apartment',
        isCondo: false
    },
    [IUnitType.Apartment]: {
        id: IUnitType.Apartment,
        label: 'propertyForm.type.condominium',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.Apartment,
        icon: 'icon-out_condominium',
        unitSubTypeAttribute: 'apartmentType',
        isCondo: true,
        subTypes: [
            { id: 'APARTMENT', label: 'expose.apartmentsubtype.apartment' },
            {
                id: 'ATTIC_APARTMENT',
                label: 'expose.apartmentsubtype.atticapartment'
            },
            { id: 'MAISONETTE', label: 'expose.apartmentsubtype.maisonette' },
            { id: 'LOFT', label: 'expose.apartmentsubtype.loft' },
            { id: 'PENTHOUSE', label: 'expose.apartmentsubtype.penthouse' },
            { id: 'TERRACE_APARTMENT', label: 'expose.apartmentsubtype.terraced' },
            { id: 'GROUND_FLOOR', label: 'expose.apartmentsubtype.groundfloor' },
            {
                id: 'RAISED_GROUND_FLOOR',
                label: 'expose.apartmentsubtype.raisedgroundfloor'
            },
            { id: 'HALF_BASEMENT', label: 'expose.apartmentsubtype.halfbasement' },
            {
                id: 'SMALL_APARTMENT',
                label: 'expose.apartmentsubtype.smallapartment'
            },
            {
                id: 'HOLIDAY_APARTMENT',
                label: 'expose.apartmentsubtype.holidayapartment'
            },
            { id: 'GALLERY', label: 'expose.apartmentsubtype.gallery' },
            { id: 'ATTIC', label: 'expose.apartmentsubtype.attic' },
            {
                id: 'PENTHOUSE_APARTMENT',
                label: 'expose.apartmentsubtype.penthouseapartment'
            },
            { id: 'OTHER', label: 'expose.apartmentsubtype.other' }
        ]
    },
    [IUnitType.CommercialUnit]: {
        id: IUnitType.CommercialUnit,
        label: 'propertyForm.type.commercial',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.CommercialUnit,
        icon: 'icon-commercial',
        unitSubTypeAttribute: 'commercialUnitType',
        isCondo: true,
        subTypes: [
            { id: 'OFFICE', label: 'expose.commercialsubtype.office' },
            { id: 'SHOP', label: 'expose.commercialsubtype.shop' },
            { id: 'STORE', label: 'expose.commercialsubtype.store' },
            { id: 'OTHER', label: 'expose.commercialsubtype.other' }
        ]
    },
    [IUnitType.Garage]: {
        id: IUnitType.Garage,
        label: 'propertyForm.type.garage',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.Garage,
        icon: 'icon-out_garage',
        unitSubTypeAttribute: 'garageType',
        isCondo: true,
        subTypes: [
            { id: 'STREET_PARKING', label: 'expose.garagesubtype.streetparking' },
            { id: 'CARPORT', label: 'expose.garagesubtype.carport' },
            { id: 'DOUBLE_GARAGE', label: 'expose.garagesubtype.doublegarage' },
            { id: 'DUPLEX', label: 'expose.garagesubtype.duplex' },
            { id: 'BOAT_MOORING', label: 'expose.garagesubtype.boatmooring' },
            { id: 'SINGLE_GARAGE', label: 'expose.garagesubtype.singlegarage' },
            { id: 'CAR_PARK', label: 'expose.garagesubtype.carpark' },
            { id: 'UNDERGROUND_PARKING', label: 'expose.garagesubtype.underground' },
            { id: 'PARKING_CHARGING', label: 'expose.garagesubtype.parkingcharging' },
            { id: 'OTHER', label: 'expose.garagesubtype.other' }
        ]
    },
    [IUnitType.Other]: {
        id: IUnitType.Other,
        label: 'propertyForm.type.other',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.Other,
        icon: 'icon-other-key',
        unitSubTypeAttribute: 'otherType',
        isCondo: true,
        subTypes: [
            { id: 'CELLAR', label: 'expose.othersubtype.cellar' },
            { id: 'ATTIC', label: 'expose.othersubtype.attic' },
            { id: 'GARDEN', label: 'expose.othersubtype.garden' },
            { id: 'OTHER', label: 'expose.othersubtype.other' }
        ]
    },
    [IBuildingOwnershipBasedType.MultiCondo]: {
        id: IBuildingOwnershipBasedType.MultiCondo,
        label: 'expose.buildingtype.condobuilding',
        propertyType: IBuildingType.Multi,
        unitType: IUnitType.Apartment,
        icon: 'icon-condo-building',
        isCondo: true
    }
};
export const getPropertyTypes: Func0<PropertyTypeMap[]> = () => {
    return [
        PROPERTY_TYPE_MAPS[IBuildingType.Single],
        PROPERTY_TYPE_MAPS[IBuildingType.Multi],
        PROPERTY_TYPE_MAPS[IUnitType.Apartment],
        PROPERTY_TYPE_MAPS[IUnitType.CommercialUnit],
        PROPERTY_TYPE_MAPS[IUnitType.Garage],
        PROPERTY_TYPE_MAPS[IUnitType.Other]
    ];
};

export const getOwnershipBasedPropertyTypes = () => {
    return [
        PROPERTY_TYPE_MAPS[IBuildingOwnershipBasedType.Single],
        PROPERTY_TYPE_MAPS[IBuildingOwnershipBasedType.Multi],
        PROPERTY_TYPE_MAPS[IBuildingOwnershipBasedType.MultiCondo]
    ];
};

export const getPropertyTypesObj: () => {
    [key: string]: PropertyTypeMap;
} = () => {
    return getPropertyTypes().reduce((acc: any, item: PropertyTypeMap) => {
        acc[item.id] = item;
        return acc;
    }, {});
};

export const getUnitTypes: Func1<IBuildingModelDraft, PropertyTypeMap[]> = (
    building: IBuildingModelDraft
) => {
    // if a building is selected, filter the list removing SINGLE and MULTI
    const unitTypes = getPropertyTypes().filter(
        item => item.id !== IBuildingType.Single && item.id !== IBuildingType.Multi
    );

    if (!building.isCondo) {
        return unitTypes.map(item => {
            // change apartment/condominum label when a !isCondo building is selected;
            if (item.id === IUnitType.Apartment) {
                return { ...item, label: 'propertyForm.type.apartment' };
            }
            return item;
        });
    }

    return unitTypes;
};

export const getPropertyTypeLabel: Func2<IBuildingModelDraft, IUnitModelDraft, string> = (
    building: IBuildingModelDraft,
    unit?: IUnitModelDraft
) => {
    return unit ? getUnitTypeLabel(unit, building) : getBuildingTypeLabel(building);
};

export const getBuildingTypeLabel: Func1<IBuildingModelDraft, string> = (
    building: IBuildingModelDraft
) => {
    switch (true) {
        case building.buildingType === IBuildingType.Single:
            return 'propertyForm.type.single';
        case building.isCondo:
            return 'expose.buildingtype.condobuilding';
        default:
            return 'propertyForm.type.multi';
    }
};

export const getUnitTypeLabel: Func2<IUnitModelDraft, IBuildingModelDraft, string> = (
    unit: IUnitModelDraft,
    building: IBuildingModelDraft
) => {
    const typeMap = getPropertyTypesObj()[unit.type];

    if (typeMap && typeMap.id === IUnitType.Apartment) {
        return building.isCondo ? 'propertyForm.type.condominium' : 'propertyForm.type.apartment';
    }

    return typeMap ? typeMap.label : '-';
};

export const getUnitSubType: Func1<IUnitModelDraft, IUnitSubType> = (unit: IUnitModelDraft) => {
    const buildingType = getPropertyTypesObj()[unit.type];
    const subTypeKey = buildingType.unitSubTypeAttribute;

    return find(buildingType.subTypes, (item: any) => item.id === (unit as any)[subTypeKey]);
};

export const getUnitSubTypeId: Func1<IUnitModelDraft, string> = (unit: IUnitModelDraft) => {
    const subType = getUnitSubType(unit);
    return subType ? subType.id : '';
};

export const getUnitSubTypeLabel: Func1<IUnitModelDraft, string> = (unit: IUnitModelDraft) => {
    const subType = getUnitSubType(unit);
    return subType ? subType.label : undefined;
};

export const getPropertySubtypeLabel: Func1<IUnitModelDraft, string> = ({
    buildingSubType
}: IBuildingModelDraft): string => {
    const subtType = find(PROPERTY_SUBTYPES, ({ id }) => id === buildingSubType);
    return subtType ? subtType.label : '';
};
