import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagesService } from '@zenhomes/toast-messages';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { throttle } from 'lodash';

export const FORBIDDEN_HTTP_STATUS = 403;

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
    constructor(private messagesService: MessagesService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(response => {
            if (this.isRequestForbidden(response)) {
                this.showForbiddenErrorMessage();
            }

            return throwError(response);
        });
    }

    isRequestForbidden(response: any) {
        return response instanceof HttpErrorResponse && response.status === FORBIDDEN_HTTP_STATUS;
    }

    showForbiddenErrorMessage: Function = throttle(
        () => this.messagesService.showMessage('errors.forbiddenRequestMessage', 'DANGER', true),
        2500,
        {
            trailing: false
        }
    );
}
