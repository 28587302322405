import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { includes } from 'lodash';

import { MessagesService } from '@zenhomes/toast-messages';

@Injectable()
export class NotifyUserInterceptor implements HttpInterceptor {
    static errorHttpStatusesToNotify = [500];

    constructor(private messagesService: MessagesService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(response => {
            if (
                response instanceof HttpErrorResponse &&
                includes(NotifyUserInterceptor.errorHttpStatusesToNotify, response.status)
            ) {
                const apiCall = response.url.split('/').pop();

                if (apiCall === 'properties') {
                    this.messagesService.showMessage(
                        'errors.unknownErrorWhileGettingProperties',
                        'DANGER',
                        true
                    );
                } else {
                    this.messagesService.showMessage(
                        'errors.unknownErrorPleaseContactUs',
                        'DANGER',
                        true
                    );
                }
            }

            return throwError(response);
        });
    }
}
