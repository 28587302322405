import { IContentNavigationRoute } from './interfaces';

export const PROPERTY_NAVIGATION_PARAM = 'propertyNavigationParam';
export const CONTENT_NAVIGATION_OPTIONS = [
    'dashboard',
    'property-portfolio',
    'tenants',
    'information',
    'accounting',
    'contracts'
];

export const CONTENT_ITEM_DASHBOARD_ID: string = '#dashboard-root';
export const CONTENT_ITEM_TENANTS_ID: string = '#tenants-root';
export const CONTENT_ITEM_PROPERTY_PORTFOLIO_ID: string = '#property-portfolio-root';
export const CONTENT_ITEM_INFORMATION_ID: string = '#information-root';
export const CONTENT_ITEM_ACCOUNTING_ID: string = '#accounting-root';
export const CONTENT_ITEM_INCIDENTS_ID: string = '#incidents';

export const CONTENT_ITEM_DASHBOARD_PATH: IContentNavigationRoute = 'dashboard';
export const CONTENT_ITEM_TENANTS_PATH: IContentNavigationRoute = 'tenants';
export const CONTENT_ITEM_PROPERTY_PORTFOLIO_PATH: IContentNavigationRoute = 'property-portfolio';
export const CONTENT_ITEM_INFORMATION_PATH: IContentNavigationRoute = 'information';
export const CONTENT_ITEM_ACCOUNTING_PATH: IContentNavigationRoute = 'accounting';
export const CONTENT_ITEM_INCIDENTS_PATH: IContentNavigationRoute = 'incidents/incidents';
export const CONTENT_ITEM_PROPERTY_INFORMATION: IContentNavigationRoute = 'property-information';
