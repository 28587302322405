import { Injectable } from '@angular/core';
import {
    ContractType,
    EXPENSE_CONTRACT,
    IContract,
    RENTAL_CONTRACT
} from '@zenhomes/domain/contract';
import { ExpenseContractsApiService } from '@zenhomes/expense-contracts-core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ContractProviderService {
    constructor(private expenseContractsApiService: ExpenseContractsApiService) {}

    getContract(contractId: string, type: ContractType): Observable<IContract> {
        const isExpenseContract = type === EXPENSE_CONTRACT;
        const isRentalContract = type === RENTAL_CONTRACT;

        // New Tenancy contract have type RENTAL_CONTRACT however they are stored in Contracts-BE and can be fetched from /contracts endpoint (same as expense contracts)
        // Previous Rental contracts (old tenancy) is stored in Housekeepa and are fetched from /rental-contracts endpoint. For that reason we need to check for new tenancy flag and then fetch rental contract accordingly

        switch (true) {
            case isExpenseContract:
                return this.expenseContractsApiService.getContract(contractId) as any;
            case isRentalContract:
                return this.expenseContractsApiService.getContract(contractId) as any;
            default:
                return Observable.empty();
        }
    }
}
