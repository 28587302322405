import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DisclaimerService } from '../../services/disclaimer.service';

@Component({
    selector: 'zhm-disclaimer-container',
    templateUrl: './disclaimer-container.component.html',
    styleUrls: ['./disclaimer-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerContainerComponent implements OnInit {
    @Input() disclaimerTitle: string;
    @Input() disclaimerName: string;
    @Input() collapsible: boolean;
    @Input() icon: string;
    @Input() isCollapsed: boolean;
    @Input() text: string;

    constructor(private disclaimerService: DisclaimerService) {}

    ngOnInit() {}

    toggleCollapsed(event: Event) {
        this.isCollapsed = !this.isCollapsed;

        if (this.collapsible && this.isCollapsed) {
            this.disclaimerService.onDisclaimerCollapsed(this.disclaimerName);
        }
    }

    get isCollapsible() {
        return this.collapsible;
    }
}
