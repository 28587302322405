import { Action } from '@ngrx/store';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */
export const authActionTypes = {
    AUTH_CHECK_INITIAL_STATE: '[Auth] Check initial state',
    AUTH_THERE_IS_TOKEN_IN_THE_STORAGE: '[Auth] There is token in the storage',
    AUTH_THERE_IS_NO_TOKEN_IN_THE_STORAGE: '[Auth] There is no token in the storage',
    AUTH_THERE_IS_TOKEN_IN_THE_URL: '[Auth] There is token in the url',
    AUTH_THERE_IS_NO_TOKEN_IN_THE_URL: '[Auth] There is no token in the url',
    AUTH_SET_TOKEN: '[Auth] Set token',
    AUTH_SET_TOKEN_SUCCESS: '[Auth] Set token success',
    AUTH_LOGOUT: '[Auth] Logout',
    AUTH_RESET: '[Auth] Reset',
    CLEAN_USER_DATA: '[Auth] Clean user data'
};

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class AuthCheckInitialState implements Action {
    readonly type = authActionTypes.AUTH_CHECK_INITIAL_STATE;
}

export class AuthThereIsTokenInTheStorage implements Action {
    readonly type = authActionTypes.AUTH_THERE_IS_TOKEN_IN_THE_STORAGE;

    constructor(public payload: string) {}
}

export class AuthThereIsNoTokenInTheStorage implements Action {
    readonly type = authActionTypes.AUTH_THERE_IS_NO_TOKEN_IN_THE_STORAGE;
}

export class AuthThereIsTokenInTheUrl implements Action {
    readonly type = authActionTypes.AUTH_THERE_IS_TOKEN_IN_THE_URL;

    constructor(public payload: string) {}
}

export class AuthThereIsNoTokenInTheUrl implements Action {
    readonly type = authActionTypes.AUTH_THERE_IS_NO_TOKEN_IN_THE_URL;
}

export class AuthSetToken implements Action {
    readonly type = authActionTypes.AUTH_SET_TOKEN;

    constructor(public payload: string) {}
}

export class AuthSetTokenSuccess implements Action {
    readonly type = authActionTypes.AUTH_SET_TOKEN_SUCCESS;

    constructor() {}
}

export class AuthLogout implements Action {
    readonly type = authActionTypes.AUTH_LOGOUT;
    constructor() {}
}

export class AuthReset implements Action {
    readonly type = authActionTypes.AUTH_RESET;
}

export class CleanUserData implements Action {
    readonly type = authActionTypes.CLEAN_USER_DATA;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type AuthActions = [
    AuthCheckInitialState,
    AuthThereIsTokenInTheStorage,
    AuthThereIsNoTokenInTheStorage,
    AuthThereIsTokenInTheUrl,
    AuthThereIsNoTokenInTheUrl,
    AuthSetToken,
    AuthLogout,
    AuthReset,
    CleanUserData
];
