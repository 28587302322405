import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'zhm-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent {
    @Input() step: number = 1;
    @Input() isNegativeValueAllowed: boolean = false;
    @Input() minValue: number;
    @Input() maxValue: number;

    @Input() displayValue: string;
    @Input() value: number;
    @Output() valueChange = new EventEmitter<number>();
    @Output() increment = new EventEmitter<number>();
    @Output() decrement = new EventEmitter<number>();

    constructor() {}

    setDefaultValueIfNecessary() {
        this.value = this.value || 0;
    }

    onDecrement() {
        this.setDefaultValueIfNecessary();

        if (!this.canBeDecremented) return;

        const newValue = this.value - this.step;

        this.valueChange.emit(newValue);
        this.decrement.emit(newValue);
    }

    onIncrement() {
        if (!this.canBeIncremented) return;

        const newValue = isNil(this.value) ? 0 : this.value + this.step;

        this.valueChange.emit(newValue);
        this.increment.emit(newValue);
    }

    get canBeIncremented() {
        const hasMaxValue = !isNil(this.maxValue);
        if (!hasMaxValue) return true;

        return this.value < this.maxValue;
    }

    get canBeDecremented() {
        const hasMinValue = !isNil(this.minValue);
        if (this.isNegativeValueAllowed && !hasMinValue) return true;

        return this.value > (this.minValue || 0);
    }

    get hasDisplayValue() {
        return !isEmpty(this.displayValue);
    }
}
