import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DomService } from '../services/dom.service';

const DEFAULT_TIMEOUT = 100;
const DEFAULT_SCROLL_DURATION = 100;

@Directive({
    selector: '[zhmScrollTo]'
})
export class ScrollToDirective implements OnInit {
    constructor(private elementRef: ElementRef, private domService: DomService) {}

    @Input('zhmScrollTo') enabled = true;
    @Input('zhmScrollToTimeout') timeout = DEFAULT_TIMEOUT;

    ngOnInit() {
        if (!this.enabled || !this.elementRef.nativeElement) return;

        setTimeout(() => this.scrollToElement(), this.timeout);
    }

    private scrollToElement() {
        this.domService.scrollIntoView(this.elementRef.nativeElement, true, {
            duration: DEFAULT_SCROLL_DURATION,
            easing: 'linear'
        });
    }
}
