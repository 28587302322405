import { Injectable } from '@angular/core';
import { PropertyNavigationService } from '../../services/property-navigation.service';
import * as fromNavigationActions from '../actions/property-navigation.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PropertiesService } from '@zenhomes/property-core';
import * as fromPropertiesActions from '@zenhomes/property-core';
import { userActionTypes } from '@zenhomes/user-core';
import { IBuildingModel, IUnitModel } from '@zenhomes/domain/property';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PropertyNavigationLifecycleEffects {
    constructor(
        private actions$: Actions,
        private propertyNavigationService: PropertyNavigationService,
        private propertiesService: PropertiesService
    ) {}

    @Effect({ dispatch: false })
    resetPropertiesOnLogout$: Observable<any> = this.actions$
        .pipe(ofType(userActionTypes.USER_RESET))
        .do(() => {
            this.propertyNavigationService.reset();
            this.propertiesService.reset();
        });

    @Effect()
    reloadSelectedProperties$: Observable<Action> = this.actions$
        .pipe(ofType(fromPropertiesActions.propertiesActionTypes.LOAD_PROPERTIES_SUCCESS))
        .mergeMap(() => {
            return [
                new fromNavigationActions.PropertyNavigationReloadSelectedBuildingAction(),
                new fromNavigationActions.PropertyNavigationReloadSelectedUnitAction()
            ];
        });

    @Effect()
    reloadSelectedBuilding$: Observable<Action> = this.actions$
        .pipe(
            ofType(
                fromNavigationActions.propertyNavigationActionTypes
                    .PROPERTY_NAVIGATION_RELOAD_SELECTED_BUILDING
            )
        )
        .switchMap(() => {
            return this.propertyNavigationService.propertyNavigationSelectedBuilding$
                .take(1)
                .switchMap((building: IBuildingModel) => {
                    return this.propertiesService.allBuildingsDictionary$
                        .take(1)
                        .map(
                            buildings =>
                                new fromNavigationActions.PropertyNavigationReloadSelectedBuildingSuccessAction(
                                    buildings[building.id]
                                )
                        )
                        .catch(() =>
                            Observable.of(
                                new fromNavigationActions.PropertyNavigationReloadSelectedBuildingFailAction()
                            )
                        );
                });
        });

    @Effect()
    reloadSelectedUnit$: Observable<Action> = this.actions$
        .pipe(
            ofType(
                fromNavigationActions.propertyNavigationActionTypes
                    .PROPERTY_NAVIGATION_RELOAD_SELECTED_UNIT
            )
        )
        .switchMap(() => {
            return Observable.combineLatest(
                this.propertyNavigationService.propertyNavigationSelectedBuilding$,
                this.propertyNavigationService.propertyNavigationSelectedUnit$.filter(
                    unit => !isEmpty(unit)
                )
            )
                .take(1)
                .switchMap(([building, unit]: [IBuildingModel, IUnitModel]) => {
                    return this.propertiesService.allUnitsDictionary$
                        .take(1)
                        .map(
                            units =>
                                new fromNavigationActions.PropertyNavigationReloadSelectedUnitSuccessAction(
                                    units[unit.id]
                                )
                        )
                        .catch(() =>
                            Observable.of(
                                new fromNavigationActions.PropertyNavigationReloadSelectedUnitFailAction()
                            )
                        );
                });
        });
}
