import { Pipe, PipeTransform } from '@angular/core';
import { isNull, size } from 'lodash';

@Pipe({
    name: 'numeric'
})
export class NumericPipe implements PipeTransform {
    transform(
        value: number,
        options: Intl.NumberFormatOptions = {},
        locale: string = 'de-DE'
    ): string {
        let result = '';
        let numberFormatOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            ...options
        };

        if (!isNull(value) && typeof value !== 'undefined') {
            result = value.toLocaleString(locale, numberFormatOptions).replace('-', '- ');

            let splittedString = result.split(',');

            if (
                splittedString[1] &&
                size(splittedString[1]) === 1 &&
                numberFormatOptions.maximumFractionDigits > 1
            ) {
                result += '0';
            }
        }

        return result;
    }
}
