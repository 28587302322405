import { Pipe, PipeTransform } from '@angular/core';
import { isString, isEmpty } from 'lodash';
import { IBuildingAddress } from '@zenhomes/domain/property';

export const DEFAULT_EMPTY_PLACEHOLDER = '---';

export type BuildingAddressPipeMode =
    | 'DEFAULT'
    | 'SCZ'
    | 'SHC'
    | 'SHCZ'
    | 'SHZC'
    | 'SH'
    | 'SHCZC'
    | 'CZC';

@Pipe({
    name: 'buildingAddress'
})
export class BuildingAddressPipe implements PipeTransform {
    transform(
        address: IBuildingAddress,
        mode: BuildingAddressPipeMode = 'DEFAULT',
        emptyPlaceholder: string = DEFAULT_EMPTY_PLACEHOLDER
    ): string {
        switch (true) {
            case isEmpty(address) || isString(address):
                return emptyPlaceholder;
            case mode === 'SCZ': // STREET-CITY-ZIP
                return `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                    address.city
                )} ${this.showIfNotEmpty(address.zip)}`;
            case mode === 'SH': // STREET-HOUSE
                return `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                    address.house
                )}`.trim();
            case mode === 'SHC': // STREET-HOUSE-CITY
                const addressPart = `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                        address.house
                    )}`.trim(),
                    cityPart = `${this.showIfNotEmpty(address.city)}`.trim();
                return `${addressPart}, ${cityPart}`.trim();
            case mode === 'SHCZ': {
                // STREET-HOUSE-CITY-ZIP
                const addressPart = `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                    address.house
                )}`.trim();
                const cityPart = `${this.showIfNotEmpty(address.city)} ${this.showIfNotEmpty(
                    address.zip
                )}`.trim();

                return `${addressPart}, ${cityPart}`;
            }
            case mode === 'SHCZC': {
                // STREET-HOUSE-CITY-ZIP-COUNTRY
                const addressPart = `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                    address.house
                )}`.trim();
                const cityPart = `${this.showIfNotEmpty(address.zip)} ${this.showIfNotEmpty(
                    address.city
                )}`.trim();
                const countryPart = this.showIfNotEmpty(address.countryCode)
                    .trim()
                    .toUpperCase();
                if (countryPart !== 'DE' && countryPart !== null) {
                    return `${addressPart}, ${cityPart}, ${countryPart}`;
                } else {
                    return `${addressPart}, ${cityPart}`;
                }
            }
            case mode === 'CZC': {
                // CITY-ZIP-COUNTRY
                const cityPart = `${this.showIfNotEmpty(address.zip)} ${this.showIfNotEmpty(
                    address.city
                )}`.trim();
                const countryPart = this.showIfNotEmpty(address.countryCode)
                    .trim()
                    .toUpperCase();
                return countryPart !== 'DE' && countryPart !== null
                    ? `${cityPart}, ${countryPart}`
                    : cityPart;
            }
            default: {
                // DEFAULT, STREET-HOUSE-ZIP-CITY
                const addressPart = `${this.showIfNotEmpty(address.street)} ${this.showIfNotEmpty(
                    address.house
                )}`.trim();
                const cityPart = `${this.showIfNotEmpty(address.zip)} ${this.showIfNotEmpty(
                    address.city
                )}`.trim();

                return `${addressPart}, ${cityPart}`;
            }
        }
    }

    private showIfNotEmpty(value: string) {
        return !isEmpty(value) ? value : '';
    }
}
