import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
    @Input() value: boolean;
    @Input() dash: boolean;
    @Input() disabled: boolean;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get checkboxClasses() {
        if (this.value || this.dash) {
            return 'selected';
        } else if (this.disabled) {
            return 'disabled';
        }

        return '';
    }

    get showCheckBox() {
        return this.value || this.dash || this.disabled;
    }

    handleClick($event: Event) {
        $event.stopPropagation();
        if (!this.disabled) {
            this.valueChange.emit(!this.value);
        }
    }
}
