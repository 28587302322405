<div dropdown class="dropdown">
    <div dropdownToggle class="form-control label">
        <span>{{
            model?.tenants ? (model.tenants | tenantsNames) : (emptyPlaceholder | translate)
        }}</span>
        <i class="icon_navigation icon-sol_caret-down pull-right" aria-hidden="true"></i>
    </div>

    <ul *dropdownMenu class="dropdown-menu">
        <li (click)="select(null)">
            <span class="dropdown-item" [ngClass]="{ active: !model }">{{
                emptyPlaceholder | translate
            }}</span>
        </li>

        <li *ngFor="let tenancy of tenancies; trackBy: tenancyTrackBy" (click)="select(tenancy)">
            <span class="dropdown-item" [ngClass]="{ active: model?.id === tenancy.id }">{{
                tenancy.tenants | tenantsNames
            }}</span>
        </li>
    </ul>
</div>
