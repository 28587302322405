import { SUBJECT_ID_DEPOSIT } from '@zenhomes/category-core';
import { isRentalContract } from '@zenhomes/domain/contract';
import { IInvoice, IInvoiceItemSplitItem } from '@zenhomes/domain/invoice';
import {
    all,
    any,
    allPass,
    anyPass,
    compose,
    defaultTo,
    path,
    prop,
    propEq,
    filter,
    lt,
    length,
    innerJoin,
    eqProps,
    curry,
    head,
    findIndex,
    CurriedFunction2,
    pathSatisfies
} from 'ramda';
import { IInvoicePeriod } from '@zenhomes/domain/invoice';
import { isExpenseOrLoanContractType } from './expense-loan-contract.util';

export const isNotPaidInvoice: Predicate1<IInvoice> = compose(
    anyPass([
        propEq('status', 'NOT_PAID'),
        propEq('status', 'NOT_PAID_OVERDUE'),
        propEq('status', 'PARTIAL_PAID'),
        propEq('status', 'PARTIAL_PAID_OVERDUE')
    ]),
    defaultTo({})
);

export const isPaidInvoice: Predicate1<IInvoice> = compose(
    anyPass([
        propEq('status', 'PAID'),
        propEq('status', 'PARTIAL_PAID'),
        propEq('status', 'PARTIAL_PAID_OVERDUE'),
        propEq('status', 'OVERPAID')
    ]),
    defaultTo({})
);

export const isRecurringInvoice: Predicate1<IInvoice> = compose(
    propEq('recurring', true),
    defaultTo({})
);
export const isNonRecurringInvoice: Predicate1<IInvoice> = compose(
    propEq('recurring', false),
    defaultTo({})
);
export const isGeneratedInvoice: Predicate1<IInvoice> = compose(
    propEq('generated', true),
    defaultTo({})
);

export const areAllPaidInvoices = all(isPaidInvoice);
export const areAllNotPaidInvoices = all(isNotPaidInvoice);
export const areAllNonRecurringInvoices = all(isNonRecurringInvoice);

const eqInvoice: CurriedFunction2<IInvoice, IInvoice, boolean> = eqProps('id');
const joinInvoicesById: CurriedFunction2<IInvoice[], IInvoice[], IInvoice[]> = innerJoin(eqInvoice);

const periodIncludesInvoices: CurriedFunction2<IInvoice[], IInvoicePeriod, boolean> = curry(
    (invoices: IInvoice[], period: IInvoicePeriod) => {
        return compose(
            lt(0),
            length,
            joinInvoicesById(invoices),
            prop('invoices')
        )(period);
    }
);

export const getPeriodForInvoiceSelection: Func2<IInvoicePeriod[], IInvoice[], IInvoicePeriod> = (
    periods: IInvoicePeriod[],
    invoices: IInvoice[]
) => {
    const periodsSelected = filter(periodIncludesInvoices(invoices), periods);
    return head(periodsSelected);
};

const invoicesSelectedConsecutiveInPeriod: Func2<IInvoicePeriod, IInvoice[], boolean> = (
    period: IInvoicePeriod,
    invoices: IInvoice[]
) => {
    const isEqToFirstInvoice = eqInvoice(head(invoices));

    let indexOfInvoice: number = compose(findIndex(isEqToFirstInvoice))(period.invoices) - 1;

    return invoices.every((invoice: IInvoice) => {
        indexOfInvoice++;

        return pathSatisfies(eqInvoice(invoice), ['invoices', indexOfInvoice], period);
    });
};

export const areAllInvoicesSelectedConsecutiveAndSamePeriod: Func2<
    IInvoicePeriod[],
    IInvoice[],
    boolean
> = (periods: IInvoicePeriod[], invoices: IInvoice[]) => {
    const periodsSelected = filter(periodIncludesInvoices(invoices), periods);

    if (periodsSelected.length !== 1) return false;

    const period = head(periodsSelected);
    return invoicesSelectedConsecutiveInPeriod(period, invoices);
};

const getFirstInvoiceSplitItem: (invoice: IInvoice) => IInvoiceItemSplitItem = path([
    'items',
    0,
    'splitItems',
    0
]);
const hasDepositSplitItem: Predicate1<IInvoice> = compose(
    propEq('subjectId', SUBJECT_ID_DEPOSIT),
    getFirstInvoiceSplitItem
);

export const isInvoiceOfRentalContract: Predicate1<IInvoice> = compose(
    isRentalContract,
    prop('contract'),
    defaultTo({})
);
export const isInvoiceOfExpenseContract: Predicate1<IInvoice> = compose(
    isExpenseOrLoanContractType,
    prop('contract'),
    defaultTo({})
);

export const isGeneratedDepositInvoice: Predicate1<IInvoice> = allPass([
    isGeneratedInvoice,
    isNonRecurringInvoice,
    isInvoiceOfRentalContract,
    hasDepositSplitItem
]);
