import { IncomeExpenseType } from '../interfaces';

export const PAYMENT_STATUS_PAID = 'PAID';
export const PAYMENT_STATUS_NOTPAID = 'NOT_PAID';
export const PAYMENT_STATUS_NOTPAID_OVERDUE = 'NOT_PAID_OVERDUE';
export const PAYMENT_STATUS_PARTIALPAID = 'PARTIAL_PAID';
export const PAYMENT_STATUS_PARTIALPAID_OVERDUE = 'PARTIAL_PAID_OVERDUE';
export const PAYMENT_STATUS_OVERPAID = 'OVERPAID';

export const PAYMENT_STATUS_CATEGORIZED = 'CATEGORIZED';
export const PAYMENT_STATUS_UNCATEGORIZED = 'UNCATEGORIZED';
export const PAYMENT_STATUS_SNOOZED = 'SNOOZED';
export const PAYMENT_STATUS_IGNORED = 'IGNORED';

export const TRANSACTION_TYPE_OUT = 'OUT';
export const TRANSACTION_TYPE_IN = 'IN';

export const EXPENSE: IncomeExpenseType = 'EXPENSE';
export const INCOME: IncomeExpenseType = 'INCOME';
