import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'zhm-room-count-selector',
    templateUrl: './room-count-selector.component.html',
    styleUrls: ['./room-count-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomCountSelectorComponent implements OnInit {
    @Input() value: number;
    @Output() valueChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    onChange(value: number) {
        this.valueChange.emit(value < 1 ? 1 : value);
    }
}
