import { Inject, Injectable } from '@angular/core';
import { IMapsModuleConfig, MapsConfigToken } from '../maps.config';

declare const require: any;
const GoogleMapsLoader = require('google-maps');

@Injectable()
export class GoogleMapsLoaderService {
    private loadPromise: Promise<any>;

    constructor(@Inject(MapsConfigToken) config: IMapsModuleConfig) {
        this.loadPromise = new Promise(resolve => {
            GoogleMapsLoader.KEY = config.googleApiKey;
            GoogleMapsLoader.LIBRARIES = ['places'];

            GoogleMapsLoader.load((google: any) => {
                resolve(google);
            });
        });
    }

    load(): Promise<any> {
        return this.loadPromise;
    }
}
