import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'zhm-ng-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit {
    @Input() value: boolean;
    @Input() disabled: boolean;
    @Output() valueChange = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    onValueChange($event: Event) {
        $event.stopPropagation();
        if (!this.disabled) {
            this.valueChange.emit(!this.value);
        }
    }
}
