import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-footer-button',
    templateUrl: './footer-button.component.html',
    styleUrls: ['./footer-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterButtonComponent {
    @Input() type: 'save' | 'next' | 'back' | 'delete' | 'custom';
    @Input() disabled: boolean;
    @Input() isLoading: boolean;
    @Input() customClass: string;
    @Output() click: EventEmitter<null> = new EventEmitter();

    onClick($event: any) {
        $event.stopPropagation();
        if (!this.disabled) {
            this.click.emit($event);
        }
    }

    getClass() {
        const buttonClass = {
            'btn-primary': this.type === 'next' || this.type === 'save',
            back: this.type === 'back',
            'btn-outline-danger': this.type === 'delete',
            loading: this.isLoading,
            'btn-default': this.type === 'back'
        };
        return buttonClass;
    }
}
