import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    OnInit
} from '@angular/core';
import { PropertiesService } from '@zenhomes/property-core';
import { DateService } from '@zenhomes/date';
import { IRentalContract } from '@zenhomes/domain/contract';
import { IDocument, IDocumentCategory, IDocumentType } from '@zenhomes/domain/document';
import { IUsageType } from '@zenhomes/domain/lease';
import { IBuildingModel, IPropertyUsage, IUnitModel } from '@zenhomes/domain/property';
import * as format from 'date-fns/format';
import { chain, isEmpty, map, find } from 'lodash';
import { FileItem } from 'ng2-file-upload/ng2-file-upload';
import { DocumentCategoriesService } from '@zenhomes/document-core';
import { getId } from '@zenhomes/sauron';
import { get } from '@zenhomes/sauron';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Tenancy, UsagesService } from '@zenhomes/usages';

const LEASE_TYPE_LEASE: IUsageType = 'LEASE';

export interface IDocumentFormDefaults {
    dateFrom?: string;
    dateTo?: string;
    category?: IDocumentCategory;
    type?: IDocumentType;
    building?: IBuildingModel;
    buildings?: IBuildingModel[];
    unit?: IUnitModel;
    units?: IUnitModel[];
    rentalContract?: IRentalContract;
}

@Component({
    selector: 'document-form',
    templateUrl: './document-form.component.html',
    styleUrls: ['./document-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFormComponent implements OnInit, OnDestroy, OnChanges {
    private errors: string[] = [];
    private doc: IDocument;

    defaults: IDocumentFormDefaults = {};

    @Input()
    set document(value: IDocument) {
        this.doc = value;

        if (this.building) {
            this.defaults.building = this.building;
        }

        if (this.selectedBuildings) {
            this.defaults.buildings = this.selectedBuildings;
        }

        if (this.unit) {
            this.defaults.unit = this.unit;
        }

        if (this.selectedUnits) {
            this.defaults.units = this.selectedUnits;
        }

        if (this.rentalContract) {
            this.defaults.rentalContract = this.rentalContract;
        }

        if (this.category) {
            this.defaults.category = this.category;
        }

        if (this.doc) {
            this.updateDocumentDefaults();
        } else {
            this.doc = <IDocument>{ relations: {} };
        }
    }

    get document(): IDocument {
        return this.doc;
    }

    @Input() disabled: boolean;
    @Input() category: IDocumentCategory;
    @Input() file: FileItem;
    @Input() building: IBuildingModel;
    @Input() selectedBuildings: IBuildingModel[];
    @Input() unit: IUnitModel;
    @Input() selectedUnits: IUnitModel[];
    @Input() rentalContract: IRentalContract;
    @Input() docTypeIsOptional: boolean;
    @Input() showActionButtons: boolean = true;
    @Output() onCancelDocument = new EventEmitter<IDocument>();
    @Output() onSaveDocument = new EventEmitter<IDocument>();

    buildings: IBuildingModel[];
    documentCategories: IDocumentCategory[];
    currentDateString: string = format(new Date(), this.dateService.backendDateFormat);

    selectedUnit: IUnitModel;
    selectedBuilding: IBuildingModel;
    selectedRentalContract: IRentalContract;
    tenancies: Tenancy[];
    selectedTenancy: Tenancy;

    private componentDestroyed = new Subject<void>();

    constructor(
        private dateService: DateService,
        private changeDetectionRef: ChangeDetectorRef,
        private propertiesService: PropertiesService,
        private documentCategoriesService: DocumentCategoriesService,
        private usagesService: UsagesService
    ) {}

    ngOnInit() {
        this.propertiesService.allBuildings$
            .takeUntil(this.componentDestroyed)
            .subscribe(allBuildings => {
                const buildingsWithLeases = allBuildings.map(building => {
                    const units = building.units.map(unit => {
                        const usages = unit.usages.filter(usage => usage.type === LEASE_TYPE_LEASE);
                        return { ...unit, usages };
                    });

                    return { ...building, units };
                });

                this.buildings = buildingsWithLeases;
                this.changeDetectionRef.markForCheck();
            });

        this.documentCategoriesService.documentsCategories$
            .takeUntil(this.componentDestroyed)
            .subscribe(documentCategories => {
                this.documentCategories = documentCategories;
                this.changeDetectionRef.markForCheck();
            });

        this.usagesService.tenancies$
            .takeUntil(this.componentDestroyed)
            .subscribe((tenancies: Tenancy[]) => {
                this.tenancies = tenancies;
            });

        this.loadTenancies();
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!isEmpty(changes.building) && !isEmpty(changes.building.currentValue)) {
            this.selectBuilding(changes.building.currentValue);
        }

        if (
            !isEmpty(changes.selectedBuildings) &&
            !isEmpty(changes.selectedBuildings.currentValue)
        ) {
            this.selectBuildings(changes.selectedBuildings.currentValue);
        }

        if (!isEmpty(changes.unit) && !isEmpty(changes.unit.currentValue)) {
            this.selectUnit(changes.unit.currentValue);
        }

        if (!isEmpty(changes.selectedUnits) && !isEmpty(changes.selectedUnits.currentValue)) {
            this.selectUnits(changes.selectedUnits.currentValue);
        }

        if (!isEmpty(changes.rentalContract) && !isEmpty(changes.rentalContract.currentValue)) {
            this.selectRentalContract(changes.rentalContract.currentValue);
        }

        if (!isEmpty(changes.category) && !isEmpty(changes.category.currentValue)) {
            this.selectDocumentCategory(changes.category.currentValue);
        }
    }

    loadTenancies() {
        this.usagesService.loadTenancies(null, null);
    }

    updateDocumentDefaults() {
        if (this.defaults.dateFrom) {
            this.document.dateFrom = this.defaults.dateFrom;
        }

        if (this.defaults.dateTo) {
            this.document.dateTo = this.defaults.dateTo;
        }

        if (!get(this.document, 'category', 'id') && this.defaults.category) {
            this.document.category = this.defaults.category;
        }

        if (!get(this.document, 'type', 'id') && this.defaults.type) {
            this.document.type = this.defaults.type;
        }

        if (!this.document.relations.buildings || !this.document.relations.buildings.length) {
            if (!isEmpty(this.defaults.buildings)) {
                this.document.relations.buildings = map(this.defaults.buildings, getId);
            } else if (this.defaults.building) {
                this.document.relations.buildings = [getId(this.defaults.building)];
            }
        }

        if (!this.document.relations.units || !this.document.relations.units.length) {
            if (!isEmpty(this.defaults.units)) {
                this.document.relations.units = map(this.defaults.units, getId);
            } else if (this.defaults.unit) {
                this.document.relations.units = [getId(this.defaults.unit)];
            }
        }

        if (
            (!this.document.relations.rentalContracts ||
                !this.document.relations.rentalContracts.length) &&
            this.defaults.rentalContract
        ) {
            this.document.relations.rentalContracts = [getId(this.defaults.rentalContract)];
        }

        this.tick();
    }

    updateSelections() {
        this.selectedBuilding = this.getSelectedBuilding();
        this.selectedUnit = this.getSelectedUnit();
        this.selectedRentalContract = this.getSelectedRentalContract();
        this.selectedTenancy = this.getSelectedTenancy();
    }

    toggleTimePeriod() {
        if (!this.document.dateTo) {
            this.setDocumentToDate(this.currentDateString);
        } else {
            this.setDocumentToDate(null);
        }
    }

    setDocumentFromDate(date: any) {
        this.document.dateFrom = date;
        this.clearErrors();

        this.defaults.dateFrom = date;

        this.tick();
    }

    setDocumentToDate(date: any) {
        this.document.dateTo = date;
        this.defaults.dateTo = date;

        this.tick();
    }

    selectDocumentCategory(category: IDocumentCategory) {
        this.document.category = category;
        this.document.type = null;
        this.clearErrors();

        this.defaults.category = category;

        this.tick();
    }

    selectedDocumentCategory(): IDocumentCategory {
        return this.document.category;
    }

    selectedDocumentCategoryName(): string {
        if (this.document.category) {
            return this.document.category.name;
        } else {
            return 'documents.docCategory';
        }
    }

    selectedDocumentCategoryTypes(): IDocumentType[] {
        if (this.document.category) {
            let category = this.documentCategories.find(
                cat => cat.id === this.document.category.id
            );
            if (category) {
                return category.documentTypes || [];
            }
        }

        return [];
    }

    selectDocumentType(docType: IDocumentType) {
        this.document.type = docType;
        this.clearErrors();

        this.defaults.type = docType;

        this.tick();
    }

    selectedDocumentTypeName(): string {
        if (this.document.type) {
            return this.document.type.name;
        } else {
            return 'documents.docType';
        }
    }

    // ============
    // Building
    // ============

    isBuildingSelected() {
        const building = get(this.document, 'relations', 'buildings', 0);

        return !isEmpty(building);
    }

    getSelectedBuilding(): IBuildingModel {
        if (this.isBuildingSelected()) {
            return find(this.buildings, { id: this.document.relations.buildings[0] });
        }

        return null;
    }

    selectBuilding(building: IBuildingModel) {
        this.document.relations.buildings = building ? [getId(building)] : [];
        this.document.relations.units = [];
        this.document.relations.rentalContracts = [];
        this.defaults.building = building;

        this.tick();
    }

    selectBuildings(buildings: IBuildingModel[]) {
        this.document.relations.buildings = map(buildings, getId) || [];
        this.document.relations.units = [];
        this.document.relations.rentalContracts = [];
        this.defaults.buildings = buildings;

        this.tick();
    }

    // ============
    // Unit
    // ============

    getSelectedUnit() {
        const unitId = get(this.document, 'relations', 'units', 0);

        if (!isEmpty(unitId)) {
            const availableUnits = this.getAvailableUnitsForSelectedBuilding();
            return find(availableUnits, { id: unitId });
        } else {
            return null;
        }
    }

    getAvailableUnitsForSelectedBuilding(): IUnitModel[] {
        const buildingId = get(this.document, 'relations', 'buildings', 0);

        if (!isEmpty(buildingId)) {
            const buildingInTheStore = this.propertiesService.buildingsDictionary[buildingId];

            return buildingInTheStore ? buildingInTheStore.units : [];
        }

        return [];
    }

    selectUnit(unit: IUnitModel) {
        this.document.relations.units = unit ? [getId(unit)] : [];
        this.document.relations.rentalContracts = [];
        this.defaults.unit = unit;

        this.tick();
    }

    selectUnits(units: IUnitModel[]) {
        this.document.relations.units = map(units, getId) || [];
        this.document.relations.rentalContracts = [];
        this.defaults.units = units;

        this.tick();
    }

    // ============
    // Usages
    // ============

    getSelectedRentalContract(): IRentalContract {
        if (
            this.document.relations &&
            this.document.relations.rentalContracts &&
            this.document.relations.rentalContracts.length
        ) {
            const rentalContractId = this.document.relations.rentalContracts[0];

            const selectedUnit = this.getSelectedUnit();

            const usageForRentalContract =
                selectedUnit && find(selectedUnit.usages, { rentalContractId });

            return usageForRentalContract && usageForRentalContract.rentalContract;
        } else {
            return null;
        }
    }

    selectedUnitRentalContracts(): IRentalContract[] {
        const unitId = get(this.document, 'relations', 'units', 0);

        let usages: IPropertyUsage[];
        let result: IRentalContract[];

        if (!isEmpty(unitId)) {
            const unitInTheStore = this.propertiesService.unitsDictionary[unitId];

            usages = unitInTheStore ? unitInTheStore.usages : [];
        } else {
            let buildingUnits = this.getAvailableUnitsForSelectedBuilding();

            usages = buildingUnits.reduce((acc, unit) => {
                return acc.concat(unit.usages);
            }, []);
        }

        result = <IRentalContract[]>chain(usages)
            .filter(usage => usage.type === LEASE_TYPE_LEASE)
            .map('rentalContract')
            .compact()
            .value();

        return result;
    }

    selectRentalContract(rentalContract: IRentalContract) {
        this.document.relations.rentalContracts = rentalContract ? [getId(rentalContract)] : [];

        if (rentalContract) {
            const unit = this.findUnitInSelectedBuildingByRentalContract(rentalContract);

            if (unit) {
                this.document.relations.units = [getId(unit)];
            }
        }

        this.defaults.rentalContract = rentalContract;

        this.tick();
    }

    // ============
    // New Tenancies
    // ============

    getSelectedTenancy(): Tenancy {
        if (
            this.document.relations &&
            this.document.relations.rentalContracts &&
            this.document.relations.rentalContracts.length
        ) {
            const rentalContractId = this.document.relations.rentalContracts[0];
            return find(this.tenancies, { id: rentalContractId });
        } else {
            return null;
        }
    }

    selectedUnitTenancies() {
        const unitId = get(this.document, 'relations', 'units', 0);
        if (!isEmpty(unitId) && !isEmpty(this.tenancies)) {
            return this.tenancies.filter(
                (tenancy: Tenancy) => tenancy.properties[0].unitId === unitId
            );
        } else {
            return [];
        }
    }

    selectTenancy(tenancy: Tenancy) {
        this.document.relations.rentalContracts = tenancy ? [getId(tenancy)] : [];
        if (tenancy) {
            const unitId = tenancy.properties[0].unitId;
            if (unitId) {
                this.document.relations.units = [unitId];
            }
        }

        this.defaults.rentalContract = tenancy as any;

        this.tick();
    }

    // ============
    // ============

    isInvalid(): string[] {
        this.errors = [];

        if (!this.document.name) {
            this.errors.push('name');
        }

        if (!this.document.category) {
            this.errors.push('category');
        }

        if (!this.docTypeIsOptional && !this.document.type) {
            this.errors.push('type');
        }

        if (!this.document.dateFrom) {
            this.errors.push('dateFrom');
        }

        if (this.errors.length) {
            return this.errors;
        } else {
            return null;
        }
    }

    clearDefaults() {
        this.defaults = {};

        this.tick();
    }

    clearErrors() {
        this.errors = [];

        this.tick();
    }

    hasError(field: string): boolean {
        return this.errors.indexOf(field) > -1;
    }

    cancel() {
        this.onCancelDocument.emit(this.document);
    }

    save() {
        this.changeDetectionRef.markForCheck();

        if (!this.isInvalid()) {
            this.onSaveDocument.emit(this.document);
        }
    }

    private tick() {
        this.updateSelections();
        this.changeDetectionRef.markForCheck();
    }

    private findUnitInSelectedBuildingByRentalContract(rentalContract: IRentalContract) {
        return this.getAvailableUnitsForSelectedBuilding().find(un => {
            return !!un.usages.find((usage: IPropertyUsage) => {
                return usage.rentalContractId === rentalContract.id;
            });
        });
    }
}
