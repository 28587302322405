import { Component, Output, Input, EventEmitter, ViewChild, Inject } from '@angular/core';
import { IDocument } from '@zenhomes/domain/document';
import { IDocumentCategory } from '@zenhomes/domain/document';
import { DocumentDownloadService } from '@zenhomes/document-core';
import { getDocumentExtensionsIconClassName, getFileSize } from '@zenhomes/document-core';
import { IBuildingModel } from '@zenhomes/domain/property';
import { DocumentFormComponent } from '../document-form/document-form.component';

@Component({
    selector: 'documents-editor',
    templateUrl: './documents-editor.component.html',
    styleUrls: ['./documents-editor.component.scss']
})
export class DocumentsEditorComponent {
    @ViewChild(DocumentFormComponent, { static: true })
    documentForm: DocumentFormComponent;

    @Input() document: IDocument;
    @Input() documentCategories: IDocumentCategory[];
    @Input() buildings: IBuildingModel[];
    @Output() onCancelDocument: EventEmitter<null> = new EventEmitter();
    @Output() onSaveDocument: EventEmitter<IDocument> = new EventEmitter();

    constructor(private documentDownloadService: DocumentDownloadService) {}

    onCloseClick(event: Event) {
        this.onCancelDocument.emit();
    }

    setParams(params: any) {
        this.document = params.document;
        this.documentCategories = params.documentCategories;
        this.buildings = params.buildings;
    }

    getDocIconClass() {
        return getDocumentExtensionsIconClassName(this.fileType());
    }

    onSaveClick(event: Event) {
        this.documentForm.save();
    }

    preview($event: any) {
        $event.stopPropagation();
        if (this.document && this.document.url) {
            const downloadUrl = this.documentDownloadService.getFullUrl(this.document.url);

            this.documentDownloadService.previewFile(downloadUrl);
        }
    }

    fileName(): string {
        if (this.document) {
            return this.document.name;
        } else {
            return null;
        }
    }

    fileSize(): string {
        if (this.document) {
            let fileSize = this.document.size;
            return getFileSize(fileSize);
        } else {
            return null;
        }
    }

    fileType(): string {
        if (this.document) {
            return this.document.extension;
        }

        return '';
    }
}
