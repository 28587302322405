import { createReducer } from '@zenhomes/ngrx-actions';
import { ICategoryCoreState } from '../interfaces';
import { CategoryCoreStore } from './stores/category.store';
import { createSelector } from 'reselect';

export function categoryCoreReducerForCategoryCore(state: any, action: any) {
    return createReducer(CategoryCoreStore)(state, action);
}

export const categoryCoreReducer = {
    category: categoryCoreReducerForCategoryCore
};

// main selector
export const getCategoryCoreState = (state: any) => state.CategoryCoreModule.category;

// custom selectors
export const getUserTopics = createSelector(
    getCategoryCoreState,
    state => state.userTopics
);
export const getUserTopicsAreLoading = createSelector(
    getCategoryCoreState,
    state => state.userTopicsAreLoading
);
export const getUserTopicsAreLoaded = createSelector(
    getCategoryCoreState,
    state => state.userTopicsAreLoaded
);
export const getSubjectDictionary = createSelector(
    getCategoryCoreState,
    state => state.subjectDictionary
);
export const getUserCategoryDictionary = createSelector(
    getCategoryCoreState,
    state => state.userCategoryDictionary
);
