import { Action } from '@ngrx/store';
import { typeWithNamespace } from '@util/type-cache.util';
import { TrackEventOptions, TrackingPayload } from '@zenhomes/analytics-utils';

const ANALYTICS = 'Analytics';

export class TrackEvent implements Action {
    static readonly TYPE = typeWithNamespace(ANALYTICS, 'TrackEvent');
    readonly type = TrackEvent.TYPE;
    constructor(
        public event: string,
        public data?: TrackingPayload,
        public options?: TrackEventOptions
    ) {}
}
