import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IValuation } from '@zenhomes/domain/valuation';
import { convertObjectToHttpParams } from '@util/convert-object-to-http-params.util';

@Injectable({
    providedIn: 'root'
})
export class ValuationApiService {
    constructor(private http: HttpClient) {}

    requestValuationForBuilding(propertyId: string): Observable<IValuation> {
        const params = { propertyId, propertyType: 'BUILDING' };

        return this.http.put<IValuation>(`/valuations/property`, params);
    }

    requestValuationForUnit(propertyId: string): Observable<IValuation> {
        const params = { propertyId, propertyType: 'UNIT' };

        return this.http.put<IValuation>(`/valuations/property`, params);
    }

    getValuationHistoryForBuilding(propertyId: string): Observable<IValuation> {
        return this.http.get<IValuation>(`/valuation/building/${propertyId}`);
    }

    getValuationHistoryForUnit(propertyId: string): Observable<IValuation> {
        return this.http.get<IValuation>(`/valuation/unit/${propertyId}`);
    }

    getTotalValuation(buildingId: string): Observable<{ valuations: IValuation[] }> {
        const params = convertObjectToHttpParams({ buildingId });

        return this.http.get<{ valuations: IValuation[] }>(`/valuation`, { params });
    }

    setUserDefinedValuationForBuilding(propertyId: string, purchaseValue: number): Observable<any> {
        const params = { propertyId, propertyType: 'BUILDING', purchaseValue };

        return this.http.post<IValuation>(`/valuation/user-defined`, params);
    }

    updateUserDefinedValuationForBuilding(
        propertyId: string,
        purchaseValue: number
    ): Observable<any> {
        const params = { purchaseValue };

        return this.http.put<IValuation>(`/valuation/building/${propertyId}/user-defined`, params);
    }

    deleteUserDefinedValuationForBuilding(propertyId: string): Observable<any> {
        return this.http.delete<IValuation>(`/valuation/building/${propertyId}/user-defined`);
    }

    setUserDefinedValuationForUnit(propertyId: string, purchaseValue: number): Observable<any> {
        const params = { propertyId, propertyType: 'UNIT', purchaseValue };

        return this.http.post<IValuation>(`/valuation/user-defined`, params);
    }

    updateUserDefinedValuationForUnit(propertyId: string, purchaseValue: number): Observable<any> {
        const params = { purchaseValue };

        return this.http.put<IValuation>(`/valuation/unit/${propertyId}/user-defined`, params);
    }

    deleteUserDefinedValuationForUnit(propertyId: string): Observable<any> {
        return this.http.delete<IValuation>(`/valuation/unit/${propertyId}/user-defined`);
    }
}
