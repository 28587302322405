import { createReducer } from '@zenhomes/ngrx-actions';
import { DocumentMetadataFormStore } from './store/document-metadata-form-state.store';
import { IDocumentMetadataFormState } from '../interfaces';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface IDocumentMetadataModuleState {
    form: IDocumentMetadataFormState;
}

export function reducerForForm(state: any, action: any) {
    return createReducer(DocumentMetadataFormStore)(state, action);
}

export const documentMetadataFormReducer = {
    form: reducerForForm
};
