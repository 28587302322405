import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DEFAULT_PAGE_SIZE, MAX_PAGE_SIZE } from '@payment-core/services/payment-api.service';
import { PaymentDragAndDropService } from '@payment-core/services/payment-drag-and-drop.service';
import * as fromPaymentCoreActions from '@payment-core/state/actions/payment.actions';
import { ConfirmationService } from '@zenhomes/iframe-hustla-ui';
import { IInvoice } from '@zenhomes/domain/invoice';
import {
    BookmarkableFilterOptions,
    mapBookmarkToFilterState,
    mapFilterStateToBookmark,
    replaceQueryString
} from '@zenhomes/filter-core';
import {
    IDropPaymentToInvoice,
    InvoiceApiService,
    InvoiceCoreActions
} from '@zenhomes/invoice-core';
import { ofAction } from '@zenhomes/ngrx-actions';
import { Observable } from 'rxjs/Observable';
import { JournalViewService } from '../../services/journal-view.service';
import { JournalViewState } from '../../state/constants/journal-view.constants';
import * as actions from '../actions/journal-view.actions';
import * as documentActions from '../actions/journal-view-documents.actions';
import { PropertyActions } from '@zenhomes/property-core';
import {
    debounceTime,
    switchMap,
    withLatestFrom,
    catchError,
    map,
    switchMapTo,
    filter,
    distinctUntilChanged,
    tap
} from 'rxjs/operators';
import { getAllElementsUntilPage } from '../../utils/pagination-observable.util';
import { isJournalViewRoute } from '../../utils/journal-view-bookmark.uti';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

const RELOAD_INVOICES_ON_FILTER_DEBOUNCE = 100;
const LOAD_MORE_DEBOUNCE = 300;

@Injectable()
export class JournalViewEffects {
    constructor(
        private invoiceApiService: InvoiceApiService,
        private journalViewService: JournalViewService,
        private actions$: Actions,
        private location: Location,
        private router: Router,
        private confirmationService: ConfirmationService,
        private paymentDragAndDropService: PaymentDragAndDropService
    ) {}

    isJournalViewRoute$: Observable<boolean> = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => isJournalViewRoute(event.urlAfterRedirects))
    );

    @Effect()
    journalViewNavigation$: Observable<Action> = this.router.events
        .filter(event => event instanceof NavigationEnd)
        .filter((event: NavigationEnd) => isJournalViewRoute(event.urlAfterRedirects))
        .map((event: NavigationEnd) => {
            const routerStateRoot = this.router.routerState.snapshot.root;
            return routerStateRoot.queryParamMap;
        })
        .mergeMap(paramMap => {
            if (paramMap.keys.length > 0) {
                return [new actions.JournalViewUpdateParseFiltersFromQueryString(paramMap)];
            } else {
                return [
                    new actions.JournalViewUpdateParseFiltersFromQueryString(paramMap),
                    new actions.JournalViewRefreshQueryString()
                ];
            }
        });

    @Effect()
    updateFiltersFromQueryString$: Observable<Action> = ofAction(
        actions.JournalViewUpdateParseFiltersFromQueryString
    )(this.actions$)
        .map((action: actions.JournalViewUpdateParseFiltersFromQueryString) => action.paramMap)
        .map(paramMap => {
            return mapBookmarkToFilterState(paramMap);
        })
        .map(filterOptions => {
            return new actions.JournalViewUpdateListFilters(filterOptions);
        });

    @Effect({ dispatch: false })
    reflectFiltersInQueryString = ofAction(
        actions.JournalViewClearListFilters,
        actions.JournalViewUpdateListFilters,
        actions.JournalViewRefreshQueryString
    )(this.actions$).pipe(
        withLatestFrom(this.journalViewService.filterOptions$),
        map(([_, filterOptions]) => {
            const filterOptionsWithoutProperty: BookmarkableFilterOptions = {
                ...filterOptions,
                propertySelection: null
            };
            return mapFilterStateToBookmark(filterOptionsWithoutProperty);
        }),
        map(paramMap => paramMap.toString()),
        distinctUntilChanged(),
        withLatestFrom(this.isJournalViewRoute$),
        tap(([queryString, isJournalViewRoute]) => {
            if (!isJournalViewRoute) {
                replaceQueryString(this.location, queryString);
            } else {
                IFrameHustla.sendMessage({
                    type: 'CHILD_PARENT_NAVIGATE_REQUESTED',
                    search: !!queryString ? queryString : null
                });
            }
        })
    );

    @Effect()
    dispatchLoadingAction$: Observable<Action> = ofAction(actions.JournalViewLoadInvoices)(
        this.actions$
    ).pipe(
        switchMapTo(this.journalViewService.filterOptionsWithSubjects$.take(1)),
        switchMap(filterOptions => {
            return this.invoiceApiService.loadInvoices(filterOptions).pipe(
                map(result => new actions.JournalViewLoadInvoicesSuccess(result)),
                catchError(error => Observable.of(new actions.JournalViewLoadInvoicesFail(error)))
            );
        })
    );

    @Effect()
    changeFilters$: Observable<Action> = ofAction(
        actions.JournalViewUpdateListFilters,
        actions.JournalViewClearListFilters
    )(this.actions$)
        .debounceTime(RELOAD_INVOICES_ON_FILTER_DEBOUNCE)
        .map(() => {
            return new actions.JournalViewLoadInvoices();
        });

    @Effect()
    payInvoicesSelected: Observable<Action> = ofAction(actions.JournalViewPayInvoicesSelected)(
        this.actions$
    )
        .withLatestFrom(this.journalViewService.state$)
        .switchMap(([action, state]) => {
            const invoiceIds = state.journalViewInvoicesSelected.map(invoice => invoice.id);

            return this.confirmationService
                .confirm({
                    body: 'confirmation.payInvoices.body',
                    confirmButton: 'confirmation.paySelectedInvoices',
                    title: 'confirmation.payInvoices.title',
                    confirmButtonClass: 'btn-primary'
                })
                .switchMap((confirmed: boolean) => {
                    return confirmed
                        ? this.invoiceApiService.payInvoices(invoiceIds)
                        : Observable.never();
                })
                .map(() => new actions.JournalViewPayInvoicesSuccess())
                .catch(error => Observable.of(new actions.JournalViewPayInvoicesFail(error)));
        });

    @Effect()
    unpayInvoicesSelected: Observable<Action> = ofAction(actions.JournalViewUnpayInvoicesSelected)(
        this.actions$
    )
        .withLatestFrom(this.journalViewService.state$)
        .switchMap(([action, state]) => {
            const invoiceIds = state.journalViewInvoicesSelected.map(invoice => invoice.id);

            return this.confirmationService
                .confirm({
                    body: 'confirmation.unpayInvoices.body',
                    confirmButton: 'confirmation.unpayInvoices.confirmUnpayInvoices',
                    title: 'confirmation.unpayInvoices.title',
                    confirmButtonClass: 'btn-primary'
                })
                .switchMap((confirmed: boolean) => {
                    return confirmed
                        ? this.invoiceApiService.unpayInvoices(invoiceIds)
                        : Observable.never();
                })
                .map(() => new actions.JournalViewUnpayInvoicesSuccess())
                .catch(error => Observable.of(new actions.JournalViewUnpayInvoicesFail(error)));
        });

    @Effect()
    deleteInvoicesSelected: Observable<Action> = ofAction(
        actions.JournalViewDeleteInvoicesSelected
    )(this.actions$)
        .withLatestFrom(this.journalViewService.state$)
        .switchMap(([action, state]) => {
            const invoiceIds = state.journalViewInvoicesSelected.map(invoice => invoice.id),
                numberOfInvoices = invoiceIds.length;

            return this.confirmationService
                .confirm({
                    body: 'confirmation.deleteInvoices.body',
                    confirmButton: 'confirmation.deleteButtonLabel',
                    title: 'confirmation.deleteInvoices.title',
                    warningText: 'confirmation.deleteInvoices.warningText',
                    warningParams: { numberOfInvoices }
                })
                .switchMap((confirmed: boolean) => {
                    return confirmed
                        ? this.invoiceApiService.deleteInvoices(invoiceIds)
                        : Observable.never();
                })
                .map(() => new actions.JournalViewReloadInvoices());
        });

    @Effect()
    resetInvoicesSelected: Observable<Action> = ofAction(actions.JournalViewResetInvoicesSelected)(
        this.actions$
    )
        .withLatestFrom(this.journalViewService.state$)
        .switchMap(([action, state]) => {
            const invoiceIds = state.journalViewInvoicesSelected.map(invoice => invoice.id),
                numberOfInvoices = invoiceIds.length;

            return this.confirmationService
                .confirm({
                    body: 'confirmation.resetInvoices.body',
                    confirmButton: 'confirmation.resetInvoices.confirm',
                    title: 'confirmation.resetInvoices.title',
                    warningText: 'confirmation.resetInvoices.warningText',
                    warningParams: { numberOfInvoices }
                })
                .switchMap((confirmed: boolean) => {
                    return confirmed
                        ? this.invoiceApiService.resetInvoices(invoiceIds)
                        : Observable.never();
                })
                .map(() => new actions.JournalViewReloadInvoices());
        });

    @Effect()
    reloadInvoices: Observable<Action> = ofAction(
        InvoiceCoreActions.InvoiceCoreDeleteInvoiceSuccess,
        InvoiceCoreActions.InvoiceCoreDeleteInvoiceFail,
        actions.JournalViewDropPaymentToInvoiceSuccess,
        actions.JournalViewDropPaymentToInvoiceFail,
        actions.JournalViewPayInvoicesSuccess,
        actions.JournalViewUnpayInvoicesSuccess,
        InvoiceCoreActions.UpdateNoteTextSuccess
    )(this.actions$).map(() => {
        return new actions.JournalViewReloadInvoices();
    });

    @Effect()
    reloadProperties: Observable<Action> = ofAction(
        actions.JournalViewPayInvoicesSuccess,
        actions.JournalViewUnpayInvoicesSuccess,
        actions.JournalViewDropPaymentToInvoiceSuccess
    )(this.actions$).map(() => new PropertyActions.ReloadProperties());

    @Effect()
    dispatchLoadMoreAction$: Observable<Action> = ofAction(
        actions.JournalViewLoadMoreInvoices,
        actions.JournalViewLoadMissingInvoices
    )(this.actions$).pipe(
        debounceTime(LOAD_MORE_DEBOUNCE),
        switchMapTo(this.journalViewService.filterOptionsWithSubjects$.take(1)),
        withLatestFrom(this.journalViewService.pagination$),
        switchMap(([filterOptions, pagination]) => {
            const filterOptionsForNextPage = {
                ...filterOptions,
                page: pagination.number + 1
            } as any;
            const canLoadMoreInvoices: boolean =
                pagination && pagination.totalPages > pagination.number;

            if (!canLoadMoreInvoices) return Observable.never();

            return this.invoiceApiService.loadInvoices(filterOptionsForNextPage).pipe(
                map(result => {
                    return new actions.JournalViewLoadMoreInvoicesSuccess(result);
                }),
                catchError(error =>
                    Observable.of(new actions.JournalViewLoadMoreInvoicesFail(error))
                )
            );
        })
    );

    @Effect()
    checkIfCannotLoadMoreInvoices: Observable<Action> = ofAction(
        actions.JournalViewLoadMoreInvoices,
        actions.JournalViewLoadMissingInvoices
    )(this.actions$)
        .withLatestFrom(this.journalViewService.pagination$)
        .switchMap(([action, pagination]) => {
            const canLoadMoreInvoices: boolean =
                pagination && pagination.totalPages > pagination.number;

            return !canLoadMoreInvoices
                ? Observable.of(new actions.JournalViewUpdateShouldLoadMissingInvoices(false))
                : Observable.never();
        });

    @Effect()
    dispatchReloadingAction$: Observable<Action> = ofAction(actions.JournalViewReloadInvoices)(
        this.actions$
    ).pipe(
        switchMapTo(this.journalViewService.filterOptionsWithSubjects$.take(1)),
        withLatestFrom(this.journalViewService.pagination$),
        switchMap(([filterOptions, pagination]) => {
            const numberOfElementsToLoad = Number(pagination.number || 1) * DEFAULT_PAGE_SIZE;

            const allInvoicesUntilCurrentPage = getAllElementsUntilPage<IInvoice>(
                pageOptions =>
                    this.invoiceApiService.loadInvoices({ ...filterOptions, ...pageOptions }),
                numberOfElementsToLoad,
                MAX_PAGE_SIZE
            );

            return allInvoicesUntilCurrentPage.map(result => {
                return new actions.JournalViewLoadInvoicesSuccess(result);
            });
        })
    );

    @Effect({ dispatch: false })
    cleanSelectedInvoicesInReloadAndFiltersChange: any = ofAction(
        actions.JournalViewReloadInvoices,
        actions.JournalViewUpdateListFilters,
        actions.JournalViewClearListFilters
    )(this.actions$)
        .withLatestFrom(this.journalViewService.state$)
        .map(([action, state]) => {
            const { journalViewShouldLoadMissingInvoices } = state as JournalViewState;

            if (journalViewShouldLoadMissingInvoices) return;

            return this.journalViewService.cleanInvoicesSelected([]);
        });

    @Effect()
    cleanSelectedInvoicesOnBulkOperationModeClose$: Observable<Action> = ofAction(
        actions.JournalViewToggleBulkOperationMode
    )(this.actions$)
        .withLatestFrom(this.journalViewService.state$)
        .map(([action, state]) => {
            const { journalViewBulkOperationMode } = state as JournalViewState;

            if (journalViewBulkOperationMode) return Observable.never();
        })
        .switchMap(() => {
            return Observable.of(new actions.JournalViewCleanInvoicesSelected([]));
        });

    @Effect({ dispatch: false })
    loadMissingInvoicesWhenSelectingAll: any = ofAction(actions.JournalViewSelectAllInvoices)(
        this.actions$
    )
        .withLatestFrom(this.journalViewService.state$)
        .do(([action, state]) => {
            this.journalViewService.loadMissingInvoices();
        });

    @Effect({ dispatch: false })
    keepLoadingMissingInvoicesIfNeeded: any = ofAction(
        actions.JournalViewLoadInvoicesSuccess,
        actions.JournalViewLoadMoreInvoicesSuccess
    )(this.actions$)
        .withLatestFrom(this.journalViewService.state$)
        .do(([action, state]) => {
            const {
                journalViewShouldLoadMissingInvoices,
                journalViewInvoices: invoices
            } = state as JournalViewState;

            if (!journalViewShouldLoadMissingInvoices) return;

            this.journalViewService.cleanInvoicesSelected(invoices);
            this.journalViewService.loadMissingInvoices();
        });

    @Effect()
    dispatchDropPaymentItemAction$: Observable<Action> = ofAction(
        actions.JournalViewDropPaymentToInvoice
    )(this.actions$)
        .map(action => action.payload)
        .filter((dropData: IDropPaymentToInvoice) => !dropData.amount)
        .switchMap(({ sourceInvoice, targetInvoice, paymentItem }) => {
            return this.paymentDragAndDropService
                .dropPaymentToInvoice(sourceInvoice, targetInvoice, paymentItem)
                .map(updatedPayment => {
                    return new actions.JournalViewDropPaymentToInvoiceSuccess();
                })
                .catch(error => Observable.of(new actions.JournalViewDropPaymentToInvoiceFail()));
        });

    @Effect()
    dispatchSplitDropPaymentItemAction$: Observable<Action> = ofAction(
        actions.JournalViewDropPaymentToInvoice
    )(this.actions$)
        .map(action => action.payload)
        .filter((dropData: IDropPaymentToInvoice) => !!dropData.amount)
        .switchMap(({ sourceInvoice, targetInvoice, paymentItem, amount }) => {
            return this.paymentDragAndDropService
                .dropSplitPaymentToInvoice(sourceInvoice, targetInvoice, paymentItem, amount)
                .map(() => {
                    return new actions.JournalViewDropPaymentToInvoiceSuccess();
                })
                .catch((error: any) =>
                    Observable.of(new actions.JournalViewDropPaymentToInvoiceFail())
                );
        });

    @Effect()
    dropPaymentOptimisticUpdate$: Observable<Action> = ofAction(
        actions.JournalViewDropPaymentToInvoice
    )(this.actions$)
        .map(action => action.payload)
        .map(({ sourceInvoice, targetInvoice, paymentItem }) => {
            return new actions.JournalViewAddPaymentItemOptimistic({
                targetInvoiceId: targetInvoice.id,
                paymentItem
            });
        });

    @Effect()
    removePaymentOptimisticUpdate$: Observable<Action> = ofAction(
        fromPaymentCoreActions.PaymentCoreDeletePayment
    )(this.actions$)
        .map(action => action.payload)
        .map(paymentId => {
            return new actions.JournalViewDeletePaymentItemOptimistic(paymentId);
        });

    @Effect()
    expandedInvoiceChanged$ = this.actions$
        .pipe(ofType(actions.SetExpandedInvoice.TYPE))
        .map(
            ({ invoice }: actions.SetExpandedInvoice) =>
                new documentActions.GetExpandedInvoiceDocuments(invoice)
        );
}
