import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ng2-dnd';
import { DomInteractionModule } from '@zenhomes/dom-interaction';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DeviceModule } from '@zenhomes/device';

// Components
import { ButtonCircleComponent } from './components/button-circle/button-circle.component';
import { ButtonActionsComponent } from './components/button-actions/button-actions.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ListIconItemComponent } from './components/list-icon-item/list-icon-item.component';
import { InfoCircleComponent } from './components/info-circle/info-circle.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { NumericSpinnerWidgetComponent } from './components/widget.numeric_spinner/numeric-spinner.widget.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';
import { ButtonPillComponent } from './components/button-pill/button-pill.component';
import { OptionsToggleComponent } from './components/options-toggle/options-toggle.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CounterComponent } from './components/counter/counter.component';
import { ModalDialogBodyComponent } from './components/modal-dialog/modal-dialog-body/modal-dialog-body.component';
import { ModalDialogHeaderComponent } from './components/modal-dialog/modal-dialog-header/modal-dialog-header.component';
import { ModalDialogFooterComponent } from './components/modal-dialog/modal-dialog-footer/modal-dialog-footer.component';
import { ActionSheetModalComponent } from './components/action-sheet-modal/action-sheet-modal.component';
import { FooterButtonComponent } from './components/footer-button/footer-button.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { ModalProxyComponent } from './components/modal-proxy/modal-proxy.component';
import { AnimatedOverlayContainer } from './components/animated-overlay-container/animated-overlay-container.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { TipBoxComponent } from './components/tip-box/tip-box.component';
import { EmptyFilteredListMessageComponent } from './components/empty-filtered-list-message/empty-filtered-list-message.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { IconTileSelectorComponent } from './components/icon-tile-selector/icon-tile-selector.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { SelectionTagComponent } from './components/selection-tag/selection-tag.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';

// Pipes
import { BuildingAddressPipe } from './pipes/building-address.pipe';
import { TenantsNamesPipe } from './pipes/tenants-names.pipe';
import { NormalizeDatePipe } from './pipes/normalize-date.pipe';
import { InitialsPipe } from './pipes/tenants-initials.pipe';
import { HighLightPipe } from './pipes/highlight.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ContactTitlePipe } from './pipes/contact-title.pipe';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { AbsValPipe } from './pipes/abs-val.pipe';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { IsNilPipe } from './pipes/is-nil.pipe';
import { NumericPipe } from './pipes/numeric.pipe';
import { NumericSignPipe } from './pipes/numeric-sign.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { PricePipe } from './pipes/price.pipe';
import { DecimalPartPipe } from './pipes/decimal-part.pipe';
import { PaymentStatusLabelPipe } from './pipes/payment-status-label.pipe';
import { DecimalDigitsPipe } from './pipes/decimal-digits.pipe';
import { PaymentStatusValuePipe } from './pipes/payment-status-value.pipe';

import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/do';

export const ELEMENTS_PIPES = [
    NumericPipe,
    NumericSignPipe,
    DecimalPipe,
    PricePipe,
    BuildingAddressPipe,
    HighLightPipe,
    TenantsNamesPipe,
    InitialsPipe,
    CapitalizePipe,
    ContactTitlePipe,
    IsNumberPipe,
    PaymentStatusLabelPipe,
    PaymentStatusValuePipe,
    AbsValPipe,
    NormalizeDatePipe,
    FilterByPipe,
    SanitizerPipe,
    IsNilPipe,
    DecimalPartPipe,
    DecimalDigitsPipe
];

export const ELEMENTS_COMPONENTS = [
    NumericSpinnerWidgetComponent,
    ValidationErrorsComponent,
    ButtonPillComponent,
    OptionsToggleComponent,
    DropdownComponent,
    ToggleComponent,
    CounterComponent,
    ModalDialogBodyComponent,
    ModalDialogHeaderComponent,
    ModalDialogFooterComponent,
    ActionSheetModalComponent,
    FooterButtonComponent,
    MessageBoxComponent,
    ModalProxyComponent,
    AnimatedOverlayContainer,
    TooltipDirective,
    RadioButtonGroupComponent,
    PopupMenuComponent,
    TipBoxComponent,
    EmptyFilteredListMessageComponent,
    StatusBadgeComponent,
    CustomDropdownComponent,
    IconTileSelectorComponent,
    ButtonCircleComponent,
    ButtonActionsComponent,
    CardItemComponent,
    CheckboxComponent,
    ListIconItemComponent,
    InfoCircleComponent,
    RadioGroupComponent,
    SpinnerOverlayComponent,
    ButtonToggleComponent,
    SelectionTagComponent,
    CloseButtonComponent
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        DndModule.forRoot(),
        BsDropdownModule.forRoot(),
        DomInteractionModule,
        DeviceModule
    ],
    declarations: [...ELEMENTS_COMPONENTS, ...ELEMENTS_PIPES],
    exports: [...ELEMENTS_COMPONENTS, ...ELEMENTS_PIPES]
})
export class ElementsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ElementsModule,
            providers: [...ELEMENTS_PIPES]
        };
    }
}
