import { Pipe, PipeTransform } from '@angular/core';
import { getPropertyIcon } from '../utils/icons.util';
import { IBuildingModel, IUnitModel } from '@zenhomes/domain/property';
import { isArray, isEmpty, isObject, map } from 'lodash';

interface IPropertyInput {
    building?: IBuildingModel;
    unit?: IUnitModel;
}

@Pipe({ name: 'propertyIcon' })
export class PropertyIconPipe implements PipeTransform {
    transform(
        propertyInput: IPropertyInput | IPropertyInput[],
        defaultClass: string = 'icon-properties'
    ): string | string[] {
        switch (true) {
            case isArray(propertyInput): {
                return map(propertyInput as any[], p =>
                    this.transformPropertyInputIntoIconString(p, defaultClass)
                );
            }
            case isObject(propertyInput): {
                return this.transformPropertyInputIntoIconString(
                    propertyInput as any,
                    defaultClass
                );
            }
            default: {
                return defaultClass;
            }
        }
    }

    transformPropertyInputIntoIconString(
        propertyInput: IPropertyInput,
        defaultClass: string = 'icon-properties'
    ): string {
        if (isEmpty(propertyInput)) defaultClass;

        const { building, unit } = propertyInput;

        return getPropertyIcon(building, unit, defaultClass);
    }

    getItem<T>(map: { [id: string]: T }, item: T): T {
        switch (true) {
            case isObject(item):
                return item as T;
            default:
                return null;
        }
    }
}
