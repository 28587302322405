import { IPropertySelection } from '@zenhomes/domain/property';
import { IProperty } from '@zenhomes/domain/property';
import { get, isArray, isEmpty } from 'lodash';

export function findPropertySelection(
    properties: IProperty[],
    buildingId: string,
    unitId: string
): IPropertySelection {
    let property;

    if (properties && isArray(properties)) {
        property = properties.find(property => property.propertyObject.id === buildingId);
    }

    const building = property && property.propertyObject ? property.propertyObject : null;
    const unit =
        building && building.units ? building.units.find(unit => unit.id === unitId) : null;

    return { building, unit };
}

export function isSameBuildingSelected(selection: IPropertySelection, ref: IPropertySelection) {
    const selectionBuildingId = get(selection, 'building.id');
    const refBuildingId = get(ref, 'building.id');

    return (
        !isEmpty(selection) &&
        !isEmpty(ref) &&
        !isEmpty(selectionBuildingId) &&
        !isEmpty(refBuildingId) &&
        selectionBuildingId === refBuildingId
    );
}

export function isSameUnitSelected(selection: IPropertySelection, ref: IPropertySelection) {
    const selectionUnitId = get(selection, 'unit.id');
    const refUnitId = get(ref, 'unit.id');

    return (
        !isEmpty(selection) &&
        !isEmpty(ref) &&
        !isEmpty(selectionUnitId) &&
        !isEmpty(refUnitId) &&
        selectionUnitId === refUnitId
    );
}

export function isSamePropertySelection(selection: IPropertySelection, ref: IPropertySelection) {
    if (isEmpty(selection) || isEmpty(ref)) return false;

    const areBothUnitsEmpty = isEmpty(selection.unit) && isEmpty(ref.unit);

    return (
        isSameBuildingSelected(selection, ref) &&
        (areBothUnitsEmpty || isSameUnitSelected(selection, ref))
    );
}

export function isPropertySelected(
    selection: IPropertySelection,
    buildingId: string,
    unitId?: string
) {
    const isSameBuilding = selection.building.id === buildingId;
    return unitId
        ? isSameBuilding && selection.unit && selection.unit.id === unitId
        : isSameBuilding;
}
