import { Injectable } from '@angular/core';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IPaymentItem, IPayment } from '@zenhomes/domain/payment';
import { PaymentApiService } from '@payment-core/services/payment-api.service';
import { filter, find, omit, pick, get } from 'lodash';
import { roundTwoSubtract } from '@zenhomes/sauron';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PaymentDragAndDropService {
    constructor(private paymentApiService: PaymentApiService) {}

    dropPaymentToInvoice(
        sourceInvoice: IInvoice,
        targetInvoice: IInvoice,
        paymentItem: IPaymentItem
    ) {
        const paymentId = paymentItem.payment.id;
        const payment$ = this.getPayment(paymentItem.payment);

        return payment$.pipe(
            switchMap((sourcePayment: IPayment) => {
                const updatedPayment = this.createPayment(
                    targetInvoice,
                    paymentItem,
                    sourcePayment
                );
                return this.paymentApiService.updatePayment({
                    id: paymentId,
                    payment: updatedPayment
                });
            })
        );
    }

    getPayment(payment: IPayment) {
        return this.paymentApiService.getPayment(payment);
    }

    createPayment(targetInvoice: IInvoice, paymentItem: IPaymentItem, sourcePayment: IPayment) {
        const unmodifiedPaymentItems = this.getUnmodifiedPaymentItems(paymentItem, sourcePayment);
        const paymentItemToSave = this.createPaymentItem(
            sourcePayment,
            find(sourcePayment.paymentItems, { id: paymentItem.id })
        );

        const newPaymentItemsWithInvoices = [{ ...paymentItemToSave, invoiceId: targetInvoice.id }];

        return { payments: [...unmodifiedPaymentItems, ...newPaymentItemsWithInvoices] };
    }

    private getUnmodifiedPaymentItems(paymentItem: IPaymentItem, sourcePayment: IPayment) {
        const unmodifiedPaymentItems = filter(
            sourcePayment.paymentItems,
            (pItem: IPaymentItem) => pItem.id !== paymentItem.id
        );

        return unmodifiedPaymentItems.map((pItem: IPaymentItem) =>
            this.createPaymentItem(sourcePayment, pItem)
        );
    }

    private createPaymentItem(sourcePayment: IPayment, paymentItem: IPaymentItem): IPaymentItem {
        const paymentItemToSave = {
            ...pick(sourcePayment, 'counterpartyName', 'comment', 'date', 'direction', 'type'),
            ...omit(paymentItem, 'id', 'autoGenerated', 'matchedAt'),
            transferId: get(sourcePayment, 'bankTransfer.id')
        } as IPaymentItem;

        return paymentItemToSave;
    }

    dropSplitPaymentToInvoice(
        sourceInvoice: IInvoice,
        targetInvoice: IInvoice,
        paymentItem: IPaymentItem,
        amount: number
    ) {
        const paymentId = paymentItem.payment.id;
        const payment$ = this.getPayment(paymentItem.payment);

        return payment$.pipe(
            switchMap((sourcePayment: IPayment) => {
                const updatedPayment = this.createSplitPayment(
                    sourceInvoice,
                    targetInvoice,
                    paymentItem,
                    amount,
                    sourcePayment
                );

                return this.paymentApiService.updatePayment({
                    id: paymentId,
                    payment: updatedPayment
                });
            })
        );
    }

    createSplitPayment(
        sourceInvoice: IInvoice,
        targetInvoice: IInvoice,
        paymentItem: IPaymentItem,
        amount: number,
        sourcePayment: IPayment
    ) {
        const unmodifiedPaymentItems = this.getUnmodifiedPaymentItems(paymentItem, sourcePayment);
        const paymentItemToSave = this.createPaymentItem(
            sourcePayment,
            find(sourcePayment.paymentItems, { id: paymentItem.id })
        );
        const remainingAmount = roundTwoSubtract(
            Math.abs(paymentItemToSave.amount),
            Math.abs(amount)
        );

        const newPaymentItemsWithInvoices = [
            { ...paymentItemToSave, amount: remainingAmount, invoiceId: sourceInvoice.id },
            { ...paymentItemToSave, amount: Math.abs(amount), invoiceId: targetInvoice.id }
        ];

        return { payments: [...unmodifiedPaymentItems, ...newPaymentItemsWithInvoices] };
    }
}
