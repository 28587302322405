import { BookmarkableFilterOptions } from '@zenhomes/filter-core';
import { Action, Store } from '@zenhomes/ngrx-actions';
import {
    initialInvoiceCoreStoreState,
    InvoiceState
} from '../../constants/invoice-state.constants';
import * as actions from '../actions/invoice.actions';

@Store(initialInvoiceCoreStoreState)
export class InvoiceCoreStore {
    @Action(actions.InvoiceCoreLoadInvoices)
    loadInvoices(state: InvoiceState, action: actions.InvoiceCoreLoadInvoices) {
        return { ...state, invoicesBeingLoaded: true };
    }

    @Action(actions.InvoiceCoreLoadInvoicesSuccess)
    loadInvoicesSuccess(state: InvoiceState, { payload }: actions.InvoiceCoreLoadInvoicesSuccess) {
        return { ...state, invoicesBeingLoaded: false, invoicesAreLoaded: true, invoices: payload };
    }

    @Action(actions.InvoiceCoreLoadInvoicesFail)
    loadInvoicesFail(state: InvoiceState, { payload }: actions.InvoiceCoreLoadInvoicesFail) {
        return { ...state, invoicesBeingLoaded: false, invoicesAreLoaded: false };
    }

    @Action(actions.InvoiceCoreGetInvoice, actions.InvoiceCoreDeleteInvoice)
    waitingForBackendStart(state: InvoiceState, action: any) {
        return { ...state, invoiceWaitingForBackend: true };
    }

    @Action(actions.InvoiceCoreGetInvoiceSuccess)
    getCreateOrUpdate(state: InvoiceState, action: any) {
        return { ...state, invoiceWaitingForBackend: false, invoice: action.payload };
    }

    @Action(actions.InvoiceCoreGetInvoiceFail, actions.InvoiceCoreDeleteInvoiceFail)
    getCreateOrUpdateFail(state: InvoiceState, action: any): InvoiceState {
        return { ...state, invoiceWaitingForBackend: false };
    }

    @Action(actions.InvoiceCoreDeleteInvoiceSuccess)
    deleteInvoiceSuccess(state: InvoiceState, action: any): InvoiceState {
        return { ...state, invoiceWaitingForBackend: false, invoice: null };
    }

    @Action(actions.InvoiceCoreResetInvoices)
    resetInvoices(state: InvoiceState, action: actions.InvoiceCoreResetInvoices): InvoiceState {
        return { ...initialInvoiceCoreStoreState };
    }

    @Action(actions.InvoiceCoreUpdateListFilters)
    updateListFilters(
        state: InvoiceState,
        { filterOptions }: actions.InvoiceCoreUpdateListFilters
    ): InvoiceState {
        const mergedFilterOptions: BookmarkableFilterOptions = {
            ...state.filterOptions,
            ...filterOptions
        };
        return { ...state, filterOptions: mergedFilterOptions };
    }

    @Action(actions.InvoiceCoreClearListFilters)
    resetListFilters(state: InvoiceState): InvoiceState {
        const filterOptions = {
            ...state.filterOptions,
            ...initialInvoiceCoreStoreState.filterOptions
        };
        return { ...state, filterOptions };
    }
}
