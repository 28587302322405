import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { UsagesApiService } from '../../services/usages.api.service';
import * as actions from '../actions/usages.actions';
import { of } from 'rxjs';

@Injectable()
export class UsagesEffects {
    constructor(private actions$: Actions, private usagesApiService: UsagesApiService) {}

    @Effect()
    loadUsages$: Observable<Action> = this.actions$.pipe(
        ofType(actions.usagesActionTypes.LOAD_USAGES),
        switchMap(({ payload }: actions.LoadUsages) => {
            const { buildingId, unitId } = payload;

            return this.usagesApiService.loadUsages(buildingId, unitId).pipe(
                mergeMap((response: any) => {
                    return [new actions.LoadUsagesSuccess(response)];
                }),
                catchError(() => of(new actions.LoadUsagesFail()))
            );
        })
    );
    @Effect()
    loadTenancies$: Observable<Action> = this.actions$.pipe(
        ofType(actions.usagesActionTypes.LOAD_TENANCIES),
        switchMap(({ payload }: actions.LoadTenancies) => {
            const { buildingId, unitId } = payload;
            return this.usagesApiService.loadTenancies(buildingId, unitId).pipe(
                mergeMap((response: any) => {
                    return [new actions.LoadTenanciesSuccess(response)];
                }),
                catchError(() => of(new actions.LoadTenanciesFail()))
            );
        })
    );
}
