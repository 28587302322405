import {
    IExpenseContract,
    IExpenseContractItem
} from '../interfaces/new-expense-contract.interface';

export function hasContractPortfolioItems(contract: IExpenseContract): boolean {
    if (!contract || !contract.periods) return false;

    return contract.periods.some(period => period.items.some(isPortfolioItem));
}

function isPortfolioItem(item: IExpenseContractItem) {
    return !item.buildingId && !item.unitId;
}
