import { ListFilterOptions } from '@zenhomes/domain/pagination';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { chain, last, range, reduce } from 'lodash';
import { Observable } from 'rxjs/Observable';

export function getAllElementsUntilPage<T>(
    requestWithOptions: (page: ListFilterOptions) => Observable<PageableElementsModel<T>>,
    numberOfLoadedItems: number,
    maxPageSize: number
): Observable<PageableElementsModel<T>> {
    const numberOfPagesToLoad = Math.ceil(numberOfLoadedItems / maxPageSize);
    const pageSizeForEachRequest = numberOfPagesToLoad > 1 ? maxPageSize : numberOfLoadedItems;

    const apiRequests = chain(range(numberOfPagesToLoad))
        .map(pageNumber => {
            return {
                size: pageSizeForEachRequest.toString(),
                page: (pageNumber + 1).toString()
            };
        })
        .map(pageFilterOptions => {
            return requestWithOptions(pageFilterOptions);
        })
        .value();

    return Observable.zip(...apiRequests).map(responses => {
        const items: T[] = reduce(responses, (acc, response) => [...acc, ...response.items], []);
        const pagination = last(responses).pagination;
        return { items, pagination };
    });
}
