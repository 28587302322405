import { IAddressBookParty } from '../../contact';
import { IDocument } from '../../document';
import { IRentIncreaseAgreementType, ISubTransaction, ITerminationPeriod } from '../../lease';
import { IObjectBalance, IPaymentStatus } from '../../property';
import { ContractType } from './contract-type';

export interface IRentalContract {
    id?: string;
    contractNumber?: string;
    from?: string;
    to?: string;
    deposit: number;
    depositDueDate?: string;
    depositType?: string;
    depositPaymentType?: string;
    noticeDate?: string;
    terminationDate?: string;
    minimalContractDuration?: string;
    handoverInDate?: string;
    handoverOutDate?: string;
    terminationPeriod?: ITerminationPeriod;
    rent?: number;
    rentPayday?: number;
    type?: ContractType;
    note: INote;
    balance?: IObjectBalance;
    tenants?: IAddressBookParty[];
    leases: IRentalContractLease[];
    status?: IPaymentStatus;
    rentHistory?: IRentalContractHistoryItem[];
    rentIncreaseAgreement?: IRentIncreaseAgreement;
    documents: IDocument[];
    reminder: IReminder;
}

export enum ReminderLevelType {
    Reminder = 'REMINDER',
    Termination = 'TERMINATION'
}

export type IReminder = {
    onlyPmCanDownload: boolean;
    type: ReminderLevelType;
};

export interface INote {
    updatedOn?: string;
    text?: string;
}

export interface IRentalContractHistoryItem {
    amount?: number;
    startDate?: string;
    rents: IRent[];
    subTransactions: ISubTransaction[];
}

export interface IRent {
    id?: string;
    leaseId?: string;
    amount?: number;
    payday?: number;
    startDate?: string;
    subTransactions?: ISubTransaction[];
}

export interface IRentalContractLease {
    building: string;
    unit: string;
    rent?: IRent;
    [key: string]: any;
}

export interface IRentIncreaseAgreement {
    type: IRentIncreaseAgreementType;
    minimalIncreaseIndex?: number;
}
