import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { setButtonIcon } from '../../utilities/button-icons.util';

@Component({
    selector: 'zhm-button-circle',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'button-circle.component.html',
    styleUrls: ['button-circle.component.scss']
})
export class ButtonCircleComponent {
    @Input() type: string;
    @Input() disabled: boolean;
    @Input() loading: boolean;
    @Output() buttonClick: EventEmitter<null> = new EventEmitter();

    setIcon() {
        return setButtonIcon(this.type);
    }

    onButtonClick($event: any) {
        if (!this.disabled) {
            this.buttonClick.emit($event);
        }
    }
}
