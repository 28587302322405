import { Action, Store } from '@zenhomes/ngrx-actions';
import { ICategoryCoreState } from '../../interfaces';
import * as storeActions from '../actions/category.actions';
import { initialCategoryCoreStoreState } from '../constants/category-state.constants';
import {
    createSubjectDictionary,
    createUserCategoryDictionary
} from '../../utilities/categorization-grouping.util';

@Store(initialCategoryCoreStoreState)
export class CategoryCoreStore {
    @Action(storeActions.CategoryCoreLoadUserTopics)
    loadCategories(
        state: ICategoryCoreState,
        action: storeActions.CategoryCoreLoadUserTopics
    ): ICategoryCoreState {
        return {
            ...state,
            userTopicsAreLoading: true
        };
    }

    @Action(storeActions.CategoryCoreLoadUserTopicsSuccess)
    loadCategoriesSuccess(
        state: ICategoryCoreState,
        { userTopics }: storeActions.CategoryCoreLoadUserTopicsSuccess
    ): ICategoryCoreState {
        const subjectDictionary = createSubjectDictionary(userTopics);
        const userCategoryDictionary = createUserCategoryDictionary(userTopics);

        return {
            ...state,
            userTopics,
            userTopicsAreLoading: false,
            userTopicsAreLoaded: true,
            subjectDictionary,
            userCategoryDictionary
        };
    }

    @Action(storeActions.CategoryCoreLoadUserTopicsFail)
    loadCategoriesFail(
        state: ICategoryCoreState,
        action: storeActions.CategoryCoreLoadUserTopicsFail
    ): ICategoryCoreState {
        return {
            ...state,
            userTopicsAreLoading: false
        };
    }
}
