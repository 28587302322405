import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategorizationUserTopic } from '@zenhomes/domain/category';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class UserTopicsApiService {
    constructor(private http: HttpClient) {}

    loadUserTopics(): Observable<ICategorizationUserTopic[]> {
        return this.http.get<any>(`/accounting/categories/user-topics`);
    }
}
