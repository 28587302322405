import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FocusWithTimeoutDirective } from './directives/focus-with-timeout.directive';
import { NguiInfiniteListDirective } from './directives/infinite-list.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { ScrollToContentDirective } from './directives/scroll-to-content.directive';
import { DomService } from './services/dom.service';
import { DragScrollDirective } from './directives/drag-scroll.directive';

const DIRECTIVES = [
    FocusWithTimeoutDirective,
    NguiInfiniteListDirective,
    ScrollToDirective,
    DragScrollDirective,
    ScrollToContentDirective
];

@NgModule({
    declarations: [DIRECTIVES],
    imports: [CommonModule],
    exports: [DIRECTIVES],
    providers: []
})
export class DomInteractionModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DomInteractionModule,
            providers: [DomService]
        };
    }
}
