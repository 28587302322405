export interface IPageableElementsModel<T> {
    pagination: IElementsPaginationModel;
    items: T[];
}

export interface IElementsPaginationModel {
    totalPages: number;
    totalElements: number;
    size: number;
    first?: boolean;
    last?: boolean;
    number: number;
    numberOfElements: number;
}

export type ListFilterOptions = {
    page?: string;
    size?: string;
    limit?: string;
    type?: 'IN' | 'OUT';
    search?: string;
    matchingStatus?: any[];
    sortTransferDate?: string;
    balanceFrom?: string;
    autoMatchable?: boolean;
    excludeUnits?: boolean;
    orPropertyFilter?: boolean;
    balanced?: boolean;
    recurring?: boolean;
    category?: string;
    bankAccountId?: string;
    contractId?: string;
    propertyUnitId?: string;
    propertyBuildingId?: string;
};

export class PageableElementsModel<T> implements IPageableElementsModel<T> {
    pagination: IElementsPaginationModel;
    items: T[];

    constructor(data: any) {
        if (!(data instanceof PageableElementsModel)) {
            (<any>Object).assign(this, {
                items: [].concat(data.items || data.content),
                pagination: data
            });
        } else {
            (<any>Object).assign(this, data);
        }
    }
}
