import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserCredentialsUpdate } from '../interfaces';
import { IUser, IUserFeature } from '@zenhomes/domain/user';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserApiService {
    constructor(private http: HttpClient) {}

    addFeaturesToUser(user: IUser, features: IUserFeature[]): Observable<{ data: IUserFeature[] }> {
        return this.http.post<{ data: IUserFeature[] }>(`/users/features`, { data: features });
    }

    loadUser(): Observable<IUser> {
        return this.http.get<IUser>('/user').pipe(
            map(result => {
                return Object.assign({}, result, { id: result.customerId });
            })
        );
    }

    updateUser(user: IUser): Observable<IUser> {
        return this.http.put<IUser>(`/users/${user.customerId}`, user);
    }

    updatePassword(userId: string, payload: IUserCredentialsUpdate): Observable<IUser> {
        return this.http.put<IUser>(`/users/${userId}/password`, payload);
    }

    updateEmail(userId: string, payload: IUserCredentialsUpdate): Observable<IUser> {
        return this.http.put<IUser>(`/users/${userId}/email`, payload);
    }
}
