import { BookmarkableFilterOptions } from '../interfaces';

export function getIndividualFilterParam(filterOptions: BookmarkableFilterOptions) {
    if (!filterOptions.invoiceType) return undefined;

    const hasIndividualType = filterOptions.invoiceType.some(invoice => invoice === 'INDIVIDUAL');
    const hasRecurringType = filterOptions.invoiceType.some(invoice => invoice === 'CONTRACT');

    if (hasIndividualType && filterOptions.invoiceType.length === 1) {
        return true;
    } else if (
        (hasRecurringType && hasIndividualType) ||
        (!hasIndividualType && !hasRecurringType)
    ) {
        return undefined;
    } else {
        return false;
    }
}
