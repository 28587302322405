import { userActionTypes } from '../actions/user.actions';
import { IUser } from '@zenhomes/domain/user';

export interface IUserState {
    user: IUser;
    userIsLoaded: boolean;
    userIsBeingLoaded: boolean;
    isAddingFeatures: boolean;
}

export const initialUserStoreState: IUserState = {
    user: null,
    userIsLoaded: false,
    userIsBeingLoaded: false,
    isAddingFeatures: false
};

export function userReducer(state: IUserState = initialUserStoreState, action: any): IUserState {
    switch (action.type) {
        case userActionTypes.USER_LOAD_USER: {
            return Object.assign({}, state, {
                userIsBeingLoaded: true
            });
        }

        case userActionTypes.USER_ADD_FEATURES: {
            return Object.assign({}, state, {
                isAddingFeatures: true
            });
        }

        case userActionTypes.USER_ADD_FEATURES_SUCCESS: {
            return Object.assign({}, state, {
                isAddingFeatures: false
            });
        }

        case userActionTypes.USER_ADD_FEATURES_FAIL: {
            return Object.assign({}, state, {
                isAddingFeatures: false
            });
        }

        case userActionTypes.USER_LOAD_USER_SUCCESS: {
            return Object.assign({}, state, {
                user: action.payload,
                userIsLoaded: true,
                userIsBeingLoaded: false
            });
        }

        case userActionTypes.USER_LOAD_USER_FAIL: {
            return Object.assign({}, state, {
                userIsBeingLoaded: false
            });
        }

        case userActionTypes.USER_RESET: {
            return Object.assign({}, initialUserStoreState);
        }

        default: {
            return state;
        }
    }
}
