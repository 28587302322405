import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isEmpty } from 'lodash';

export const fillAtLeaseOne = (controlToCompare: AbstractControl): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        return isEmpty(control.value) && isEmpty(controlToCompare.value)
            ? { fillAtLeaseOne: true }
            : null;
    };
};
