import {
    ICategorizationSubject,
    ICategorizationUserCategory,
    ICategorizationUserTopic
} from '../../category';
import { IContract, IExpenseContractPeriod } from '../../contract';
import { IPaymentItem } from '../../payment';
import { IBuildingModel, IUnitModel } from '../../property';
import { PaymentStatusType } from './payment-type.interface';

export interface IInvoice {
    id?: string;
    userId?: string;
    dueDate?: string;
    effectiveFrom?: string;
    effectiveTo?: string;
    settledDate?: string;
    amount?: number;
    signedAmount?: number;
    balance?: number;
    counterparty?: any;
    note?: { text?: string; updatedOn?: string };
    contract?: IPartialContract;
    items?: IInvoiceItem[];
    payments?: IPaymentItem[];
    status?: PaymentStatusType;
    type?: string;
    recurring?: boolean;
    generated?: boolean;
    invoiceDate?: string;
}

export interface IInvoiceCluster {
    contract?: IContract;
    invoices: IInvoice[];
    [key: string]: any;
}

export interface IPartialContract {
    id?: string;
    type?: string;
}

export interface IInvoiceItem {
    contractItemId?: string;
    amount?: number;
    signedAmount?: number;
    building?: IBuildingModel;
    unit?: IUnitModel;
    splitItems?: IInvoiceItemSplitItem[];
    payments?: IInvoiceItemPayment[];
}

export interface IInvoiceItemSplitItem {
    amount?: number;
    signedAmount?: number;
    negatedSignedAmount?: number;
    subjectId?: string;
    subject?: ICategorizationSubject;
    userCategory?: ICategorizationUserCategory;
    userTopic?: ICategorizationUserTopic;
    uccCategoryId?: string;
    allocatable?: boolean;
}

export interface IInvoiceItemPayment {
    date?: string;
    amount?: number;
    counterpartyName?: string;
    comment?: string;
    transfer?: { id?: string };
}

export interface IInvoicePeriod extends IExpenseContractPeriod {
    invoices: IInvoice[];
    type: IInvoicePeriodType;
}

export enum IInvoicePeriodType {
    Recurring = 'recurring',
    NonRecurring = 'non-recurring'
}
