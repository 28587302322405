import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentStatus } from '@zenhomes/domain/property';
import { TranslateService } from '@ngx-translate/core';
import { isString, omit, isEmpty } from 'lodash';
import { NumericPipe, PricePipe } from '.';

export const DEFAULT_PLACEHOLDER = '-';

const LABEL_MAP_LONG = {
    NOT_FOR_RENT: 'property.paymentStatus.notForRent',
    SOME_NOT_USED: 'property.paymentStatus.notRented',
    NOT_USED: 'property.paymentStatus.notRentedMissing',
    SELF_USAGE: 'property.paymentStatus.selfUsage',
    VACANCY: 'property.paymentStatus.vacancy',
    NO_RENT: 'property.paymentStatus.noRent',
    PAID: 'property.paymentStatus.paid',
    PARTIAL_PAID: 'property.paymentStatus.partialPaid',
    OVERPAID: 'property.paymentStatus.overpaid',
    NOT_PAID: 'property.paymentStatus.notPaid',
    OVERDUE_PARTIAL_PAID: 'property.paymentStatus.overduePartialPaid',
    OVERDUE: 'property.paymentStatus.overdue',
    NOT_ACTIVE: 'property.paymentStatus.notActive'
};

const LABEL_MAP_SHORT = {
    NOT_FOR_RENT: 'property.paymentStatus.notForRent',
    SOME_NOT_USED: 'property.paymentStatus.notRented',
    NOT_USED: 'property.paymentStatus.notRentedMissing',
    SELF_USAGE: 'property.paymentStatus.selfUsage',
    VACANCY: 'property.paymentStatus.vacancy',
    NO_RENT: 'property.paymentStatus.noRent',
    PAID: 'property.paymentStatus.paidShort',
    PARTIAL_PAID: 'property.paymentStatus.partialPaidShort',
    OVERPAID: 'property.paymentStatus.overpaidShort',
    NOT_PAID: 'property.paymentStatus.notPaidShort',
    OVERDUE_PARTIAL_PAID: 'property.paymentStatus.overduePartialPaidShort',
    OVERDUE: 'property.paymentStatus.overdueShort',
    NOT_ACTIVE: 'property.paymentStatus.notActive'
};

const LABEL_MAP_TAG = {
    NOT_FOR_RENT: 'property.paymentStatus.notForRent',
    SOME_NOT_USED: 'property.paymentStatus.notRented',
    NOT_USED: 'property.paymentStatus.notRentedMissing',
    SELF_USAGE: 'property.paymentStatus.selfUsage',
    VACANCY: 'property.paymentStatus.vacancy',
    NO_RENT: 'property.paymentStatus.noRent',
    PAID: 'property.paymentStatus.paidTag',
    PARTIAL_PAID: 'property.paymentStatus.partialPaidTag',
    OVERPAID: 'property.paymentStatus.overpaidTag',
    NOT_PAID: 'property.paymentStatus.notPaidTag',
    OVERDUE_PARTIAL_PAID: 'property.paymentStatus.overduePartialPaidTag',
    OVERDUE: 'property.paymentStatus.overdueTag',
    NOT_ACTIVE: 'property.paymentStatus.notActive'
};

type LabelType = 'long' | 'short' | 'tag';

@Pipe({ name: 'paymentStatusLabel' })
export class PaymentStatusLabelPipe implements PipeTransform {
    readonly numericFormat: Intl.NumberFormatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };
    private pricePipe: PricePipe;

    constructor(private translateService: TranslateService) {
        this.pricePipe = new PricePipe();
    }

    transform(status: IPaymentStatus, type: LabelType = 'long'): string {
        if (isEmpty(status)) return DEFAULT_PLACEHOLDER;

        const map = this.getLabelMap(type);

        const tag = map[status.code];

        const interpolateParams = {
            unusedCount: status.unusedCount,
            amount: this.pricePipe.transform(status.amount, true),
            amountAbs: this.pricePipe.transform(Math.abs(status.amount), true),
            totalAmount: this.pricePipe.transform(status.totalAmount, true),
            totalAmountAbs: this.pricePipe.transform(Math.abs(status.totalAmount), true)
        };

        const label = !isEmpty(tag)
            ? this.translateService.instant(tag, interpolateParams)
            : DEFAULT_PLACEHOLDER;

        return label;
    }

    getLabelMap(type: LabelType) {
        switch (type) {
            case 'long':
                return LABEL_MAP_LONG;
            case 'short':
                return LABEL_MAP_SHORT;
            case 'tag':
            default:
                return LABEL_MAP_TAG;
        }
    }
}
