import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InvoiceApiService } from './services/invoice-api.service';
import { InvoiceService } from './services/invoice.service';
import { InvoiceEffects } from './state/effects/invoice.effects';
import { invoiceCoreReducer } from './state/index';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('InvoiceCoreModule', invoiceCoreReducer),
        EffectsModule.forFeature([InvoiceEffects])
    ],
    declarations: [],
    providers: [],
    exports: []
})
export class InvoiceCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: InvoiceCoreModule,
            providers: [InvoiceApiService, InvoiceService]
        };
    }
}
