import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import { IUser } from '@zenhomes/domain/user';
import { ContactTitlePriority } from '@zenhomes/elements';

@Component({
    selector: 'zhm-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardComponent implements OnInit {
    @Input() user: IUser;
    @Input() contactTitlePriority: ContactTitlePriority = 'name';
    @Output() edit = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    onClickEditButton() {
        this.edit.emit();
    }
}
