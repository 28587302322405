import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContactTitlePriority } from '@zenhomes/elements';
import { IContact } from '@zenhomes/domain/contact';
import { includes, isEmpty, isString } from 'lodash';
import { ContactsService } from '../../services/contacts.service';
import { Subscription } from 'rxjs/Subscription';

export interface IContactCardParty {
    partyId: string;
    fullName: string;
}

@Component({
    selector: 'contact-card',
    templateUrl: 'contact-card.component.html',
    styleUrls: ['./contact-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactCardComponent),
            multi: true
        }
    ]
})
export class ContactCardComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() contact: IContact = null;
    @Input() autocompletePlaceholder: string;
    @Input() hasDeleteButton: boolean;
    @Input() openMobileAddPopupOnInit: boolean;
    @Input() selectedContactsIds: string[] = [];
    @Input() inputStyle: string;
    @Input() contactTitlePriority: ContactTitlePriority = 'name';
    @Input() displayContactCard: boolean = true;
    @Input() displayEmptyCard: boolean = true;
    @Input() disabled: boolean = false;
    @Output() clear: EventEmitter<void> = new EventEmitter();

    contactAutocompleteControl: FormControl;
    foundContacts: IContact[] = [];
    propagateChange = (_: IContactCardParty) => {};
    registerOnTouched() {}
    autocompleteQuery: string = '';
    emitValueFalseOptions = { emitEvent: false };
    defaultPartyObject: any = {
        partyId: null,
        fullName: ''
    };

    private sub: Subscription;

    constructor(private ref: ChangeDetectorRef, private contactsService: ContactsService) {}

    get showContactCard() {
        return (
            this.contact &&
            (!isEmpty(this.contact.id) || !isEmpty(this.autocompleteQuery)) &&
            this.displayContactCard
        );
    }

    get isDeleteButtonClickable() {
        return !!this.contact || !!this.autocompleteQuery;
    }

    ngOnInit() {
        this.contactAutocompleteControl = new FormControl();

        this.sub = this.contactAutocompleteControl.valueChanges.subscribe(contact =>
            this.selectAutocompleteContact(contact)
        );
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }

    selectAutocompleteContact(contact: IContact) {
        this.contact = contact;

        this.triggerPropagateChange(
            !isEmpty(contact) ? this.contactToAddressBookParty(contact) : this.defaultPartyObject
        );
    }

    setContact(contact: any) {
        this.contact = contact;

        this.contactAutocompleteControl.setValue(
            this.valueIncludesQuery(contact.fullName) ? contact.fullName : contact.companyName,
            this.emitValueFalseOptions
        );

        this.triggerPropagateChange(contact ? this.contactToAddressBookParty(contact) : contact);

        this.writeValue(contact ? this.contactToAddressBookParty(contact) : null);

        this.ref.markForCheck();
    }

    writeValue(value: IContactCardParty) {
        if (!isEmpty(value) && !isEmpty(value.partyId) && isString(value.partyId)) {
            this.contactsService.findContactById(value.partyId).subscribe((contact: IContact) => {
                this.contact = contact;

                this.contactAutocompleteControl.setValue(contact, this.emitValueFalseOptions);
                this.ref.markForCheck();
            });
        } else if (!isEmpty(value) && !isEmpty(value.fullName)) {
            // this.contact = value as any;
            this.contactAutocompleteControl.setValue(
                { name: value.fullName },
                this.emitValueFalseOptions
            );
        } else {
            this.contactAutocompleteControl.setValue(null, this.emitValueFalseOptions);
            this.contact = value as null;
        }

        this.ref.markForCheck();
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    saveAutocompleteQuery(query: string) {
        this.autocompleteQuery = query;

        if (!this.contact) {
            this.triggerPropagateChange({
                ...this.defaultPartyObject,
                fullName: query,
                name: query
            });
        }
    }

    triggerPropagateChange(party: IContactCardParty) {
        this.propagateChange(party);
    }

    valueIncludesQuery(value: string): boolean {
        return includes(value, this.autocompleteQuery);
    }

    resetTenant() {
        this.autocompleteQuery = '';

        this.clear.emit();
        this.writeValue(null);
    }

    contactToAddressBookParty(contact: IContact) {
        return {
            partyId: contact.id,
            fullName: contact.name,
            name: contact.name,
            companyName: contact.companyName
        };
    }
}
