import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    EventEmitter,
    Output
} from '@angular/core';
import { IAddressBookParty } from '@zenhomes/domain/contact';
import { get } from '@zenhomes/sauron';
import { IBuildingAddress } from '@zenhomes/domain/property';
import {
    transformToInitialsName,
    transformToInitialsCompany
} from '../../utilities/get-contact-name-initials.util';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'new-contact-card-item',
    templateUrl: 'new-contact-card-item.component.html',
    styleUrls: ['./new-contact-card-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewContactCardItemComponent implements OnInit {
    @Input() contact: IAddressBookParty;
    @Output() onSave: EventEmitter<null> = new EventEmitter();
    @Output() onEdit: EventEmitter<null> = new EventEmitter();

    ngOnInit() {}

    get contactName(): string {
        return get(this.contact, 'name') || get(this.contact, 'fullName');
    }

    get companyName(): string {
        return get(this.contact, 'companyName');
    }

    get contactEmail(): string {
        return get(this.contact, 'email');
    }

    get contactPhone(): string {
        return get(this.contact, 'phone');
    }

    get contactAdress(): IBuildingAddress {
        return get(this.contact, 'address');
    }

    get isNameAvailable(): boolean {
        return !isNil(this.contactName);
    }

    get isCompanyNameAvailable(): boolean {
        return !isNil(get(this.contact, 'companyName'));
    }

    isAddressValid() {
        const address = this.contactAdress;
        return address && (!isEmpty(address.street) || !isEmpty(address.city));
    }

    get nameTag(): string {
        return this.contact ? transformToInitialsName(this.contact) : '';
    }

    get companyNameTag(): string {
        return this.contact ? transformToInitialsCompany(this.contact) : '';
    }
}
