import { Pipe, PipeTransform } from '@angular/core';
import { IPayment, IClusteredPayment } from '@zenhomes/domain/payment';
import { isEmpty } from 'lodash';
import { getPaymentCounterpartyName } from '@payment-core/utils/clustered-payments.util';

@Pipe({ name: 'paymentCounterpartyName' })
export class PaymentCounterpartyNamePipe implements PipeTransform {
    transform(payment: IPayment | IClusteredPayment, defaultPlaceholder: string = '-'): string {
        if (isEmpty(payment)) return defaultPlaceholder;

        return getPaymentCounterpartyName(payment) || defaultPlaceholder;
    }
}
