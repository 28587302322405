import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorsModule } from '@zenhomes/interceptors';
import { DocumentsCoreModule } from '@zenhomes/document-core';
import { DocumentMetadataFormModule } from '@zenhomes/document-metadata-form';
import { NavigationModule } from '@zenhomes/navigation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MetaModule } from '@ngx-meta/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PaymentCoreModule } from '@payment-core/payment-core.module';
import { PropertyCoreModule } from '@zenhomes/property-core';
import { AddressBookCoreModule } from '@zenhomes/address-book-core';
import { BankAccountCoreModule } from '@zenhomes/bank-account-core';
import { CategoryCoreModule } from '@zenhomes/category-core';
import { ContractsCoreModule } from '@zenhomes/contracts-core';
import { DeviceModule } from '@zenhomes/device';
import { DomInteractionModule } from '@zenhomes/dom-interaction';
import { ExpenseContractsCoreModule } from '@zenhomes/expense-contracts-core';
import { InvoiceCoreModule } from '@zenhomes/invoice-core';
import { JournalViewCoreModule } from '@zenhomes/journal-view-core';
import { MapsModule } from '@zenhomes/maps';
import { MonitoringModule } from '@zenhomes/monitoring';
import { UserCoreModule } from '@zenhomes/user-core';
import { ValuationCoreModule } from '@zenhomes/valuation-core';
import { environment } from 'environments/environment';
import { ToastMessagesModule } from '@zenhomes/toast-messages';
import { NgrxActionsModule } from '@zenhomes/ngrx-actions';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DialogWizardModule } from '@zenhomes/dialog-wizard';
import { ElementsModule } from '@zenhomes/elements';
import { WildcardRoutingModule } from '../wildcard/wildcard-routing.module';
import { AppComponent } from './components/app/app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MainRoutingModule } from './main.routing';
import { LanguageSelectionModule } from '@zenhomes/language-selection';
import { IFrameHustlaUiModule } from '@zenhomes/iframe-hustla-ui';
import { AnalyticsModule } from '@zenhomes/analytics';
import { AuthModule } from '@zenhomes/auth';
import { ModuleOrchestrationEffects } from './state/effects/module-orchestration.effects';
import { TranslateHttpLoader, LocaleSetupService, IframeHustlaInitService } from './services';
import { WebsocketModule } from '@zenhomes/websocket';
import { UsagesCoreModule } from '@zenhomes/usages';
import { LandingComponent } from './components/landing/landing.component';

const AUTH_TOKEN_BLACK_LIST = ['/i18n/', environment.APP.API_AUTH0_DOMAIN, 'googleapis'];

const PUBLIC_IMG_URL =
    window.location.hostname === `localhost`
        ? `//portal.dev.vermietet.de/img`
        : `//${window.location.host}/img`;

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, LoaderComponent, LandingComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgrxActionsModule,
        StoreModule.forRoot({}, { initialState: {} }),
        FormsModule,
        MainRoutingModule,
        BrowserAnimationsModule,
        LanguageSelectionModule.forRoot(),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: TranslateHttpLoader }
        }),
        AuthModule.forRoot({
            authTokenBlackList: AUTH_TOKEN_BLACK_LIST,
            auth0Domain: environment.APP.API_AUTH0_DOMAIN,
            auth0ClientID: environment.APP.API_AUTH0_CLIENT,
            isEnvironmentProduction: environment.production
        }),
        UserCoreModule,
        DialogWizardModule.forRoot(),
        ElementsModule.forRoot(),
        InterceptorsModule.forRoot({
            apiHost: environment.APP.ZHM_API_HOST
        }),
        NavigationModule.forRoot(),
        DocumentsCoreModule.forRoot({
            uploadDocumentUrl: environment.APP.ZHM_API_HOST + '/documents',
            apiHost: environment.APP.ZHM_API_HOST
        }),
        DocumentMetadataFormModule,
        AddressBookCoreModule,
        EffectsModule.forRoot([]),
        PropertyCoreModule.forRoot(),
        MetaModule.forRoot(),
        WildcardRoutingModule,
        ModalModule.forRoot(),
        InvoiceCoreModule.forRoot(),
        PaymentCoreModule.forRoot(),
        CategoryCoreModule.forRoot(),
        BankAccountCoreModule.forRoot(),
        DomInteractionModule.forRoot(),
        ContractsCoreModule.forRoot(),
        DeviceModule.forRoot(),
        IFrameHustlaUiModule,
        AnalyticsModule.forRoot({
            logConsole: environment.ENVIRONMENT === 'local' || environment.ENVIRONMENT === 'dev'
        }),
        MonitoringModule,
        ToastMessagesModule.forRoot(),
        ExpenseContractsCoreModule.forRoot(),
        ValuationCoreModule.forRoot(),
        JournalViewCoreModule.forRoot(),
        MapsModule.forRoot({
            googleApiKey: environment.APP.API_GOOGLE_MAPS_STATIC_KEY,
            publicImgUrl: PUBLIC_IMG_URL
        }),
        EffectsModule.forFeature([ModuleOrchestrationEffects]),
        WebsocketModule,
        UsagesCoreModule
    ]
})
export class MainModule {
    constructor(
        private iframeHustlaInitService: IframeHustlaInitService,
        private localeSetupService: LocaleSetupService
    ) {
        this.iframeHustlaInitService.init();
        this.localeSetupService.setupLocale();
        this.localeSetupService.configureTranslations();
    }
}

export { AppComponent };
