import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { GoogleMapsLoaderService } from '../services/google-maps-loader.service';

@Directive({
    selector: '[google-address-autocomplete]',
    host: {
        '(input)': 'onChange()'
    }
})
export class GoogleAddressAutocompleteDirective {
    @Output() onAddressSelected: EventEmitter<Object> = new EventEmitter();
    @Input() countryRestriction: string;

    private autocomplete: any;

    constructor(private el: ElementRef, private googleMapsLoaderService: GoogleMapsLoaderService) {
        this.googleMapsLoaderService.load().then(google => {
            this.onGoogleMapsLoaded(el, google);
        });
    }

    onGoogleMapsLoaded(el: ElementRef, google: any) {
        this.autocomplete = new google.maps.places.Autocomplete(el.nativeElement, {
            types: ['address'],
            componentRestrictions: this.getComponentRestrictions()
        });

        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

    getComponentRestrictions() {
        return this.countryRestriction ? { country: this.countryRestriction } : undefined;
    }

    ngOnChanges(changes: any) {
        if (changes.countryRestriction && this.autocomplete) {
            this.autocomplete.setComponentRestrictions(this.getComponentRestrictions());
        }
    }

    invokeEvent(place: Object) {
        this.onAddressSelected.emit(place);
    }

    onChange() {}
}
