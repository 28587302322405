import { find } from 'lodash';
import { get } from 'lodash';
import { USER_PARTY_TYPE } from '../../contact';
import {
    IBuildingModel,
    IPropertyManager,
    IPropertyOwner,
    IPropertyRole,
    IPropertyRoleType,
    IUnitModel
} from '../interfaces';

type Func0<TResult> = () => TResult;
type Func1<T1, TResult> = (t1: T1) => TResult;
type Func2<T1, T2, TResult> = (t1: T1, t2: T2) => TResult;

type Predicate1<T1> = (t1: T1) => boolean;
type Predicate2<T1, T2> = (t1: T1, t2: T2) => boolean;

export const hasRolePropertyManager: Predicate1<IBuildingModel | IUnitModel> = entity => {
    return !!find(get(entity, 'roles'), {
        type: IPropertyRoleType.Manager,
        partyType: USER_PARTY_TYPE
    });
};

export const hasRolePropertyOwner: Predicate1<IBuildingModel | IUnitModel> = entity => {
    return !!find(get(entity, 'roles'), {
        type: IPropertyRoleType.Owner,
        partyType: USER_PARTY_TYPE
    });
};

export function isPropertyManagerRole(role: IPropertyRole): role is IPropertyManager {
    return role && role.type === IPropertyRoleType.Manager;
}

export function isPropertyOwnerRole(role: IPropertyRole): role is IPropertyOwner {
    return role && role.type === IPropertyRoleType.Owner;
}
