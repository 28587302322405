import { Component, OnInit, Input, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-icon-tile-selector',
    templateUrl: './icon-tile-selector.component.html',
    styleUrls: ['./icon-tile-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTileSelectorComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() selected: boolean;
    @Input() disabled: boolean;
    @Input() removable: boolean;

    constructor() {}
}
