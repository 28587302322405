import { HttpErrorResponse } from '@angular/common/http';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { Action } from '@ngrx/store';
import { typeWithNamespace } from '@util/type-cache.util';
import { IInvoice } from '@zenhomes/domain/invoice';
import { BookmarkableFilterOptions } from '@zenhomes/filter-core';

const INVOICE_CORE = 'InvoiceCore';

export class InvoiceCoreLoadInvoices implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Load Invoices');
    readonly type = InvoiceCoreLoadInvoices.TYPE;
    constructor() {}
}

export class InvoiceCoreLoadInvoicesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Load invoices success');
    readonly type = InvoiceCoreLoadInvoicesSuccess.TYPE;
    constructor(public payload: PageableElementsModel<IInvoice>) {}
}

export class InvoiceCoreLoadInvoicesFail implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Load invoices fail');
    readonly type = InvoiceCoreLoadInvoicesFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class InvoiceCoreGetInvoice implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Get invoice');
    readonly type = InvoiceCoreGetInvoice.TYPE;
    constructor() {}
}

export class InvoiceCoreGetInvoiceSuccess implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Get invoice success');
    readonly type = InvoiceCoreGetInvoiceSuccess.TYPE;
    constructor(public payload: IInvoice) {}
}

export class InvoiceCoreGetInvoiceFail implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Get invoice fail');
    readonly type = InvoiceCoreGetInvoiceFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class InvoiceCoreDeleteInvoice implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Delete invoice');
    readonly type = InvoiceCoreDeleteInvoice.TYPE;
    constructor(public payload: string) {}
}

export class InvoiceCoreDeleteInvoiceSuccess implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Delete invoice success');
    readonly type = InvoiceCoreDeleteInvoiceSuccess.TYPE;
    constructor(public payload: IInvoice) {}
}

export class InvoiceCoreDeleteInvoiceFail implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Delete invoice fail');
    readonly type = InvoiceCoreDeleteInvoiceFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class InvoiceCoreResetInvoices implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Reset invoices');
    readonly type = InvoiceCoreResetInvoices.TYPE;
    constructor() {}
}

export class InvoiceCoreUpdateListFilters implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Update list filters');
    readonly type = InvoiceCoreUpdateListFilters.TYPE;
    constructor(public filterOptions: BookmarkableFilterOptions) {}
}

export class InvoiceCoreClearListFilters implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Clear list filters');
    readonly type = InvoiceCoreClearListFilters.TYPE;
    constructor() {}
}

export class UpdateNoteText implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Update Note text');
    readonly type = UpdateNoteText.TYPE;
    constructor(public payload: { text: string; invoiceId: string }) {}
}

export class UpdateNoteTextSuccess implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Update Note text Success');
    readonly type = UpdateNoteTextSuccess.TYPE;
    constructor(private payload: { text: string; updatedOn: string }) {}
}

export class UpdateNoteTextFail implements Action {
    static readonly TYPE = typeWithNamespace(INVOICE_CORE, 'Update Note text Fail');
    readonly type = UpdateNoteTextFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}
