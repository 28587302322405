import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { IInvoice } from '@zenhomes/domain/invoice';
import {
    BookmarkableFilterOptions,
    FILTER_AMOUNT_MAX,
    FILTER_AMOUNT_MIN
} from '@zenhomes/filter-core';

export interface InvoiceState {
    invoices: PageableElementsModel<IInvoice>;
    invoicesSelected: IInvoice[];
    invoicesAreLoaded: boolean;
    invoicesBeingLoaded: boolean;
    invoice: IInvoice;
    invoiceWaitingForBackend: boolean;
    filterOptions: BookmarkableFilterOptions;
}

export const initialInvoiceCoreStoreState: InvoiceState = {
    invoices: null,
    invoicesAreLoaded: false,
    invoicesBeingLoaded: false,
    invoice: null,
    invoicesSelected: [],
    invoiceWaitingForBackend: false,
    filterOptions: {
        page: '1',
        query: '',
        startDate: null,
        endDate: null,
        propertySelection: [],
        counterpartySelection: [],
        subjectSelection: [],
        userCategorySelection: [],
        status: [],
        matchingStatus: [],
        transactionType: [],
        amountFrom: FILTER_AMOUNT_MIN,
        amountTo: FILTER_AMOUNT_MAX
    }
};
