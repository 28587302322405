export function isElementVerticallyVisible(
    innerElement: string | HTMLElement,
    outerElement: string | HTMLElement | Window,
    offset: number
): { top: boolean; bottom: boolean } {
    const innerEl: HTMLElement =
        typeof innerElement === 'string'
            ? <HTMLElement>document.querySelector(<string>innerElement)
            : innerElement;
    const outerEl: HTMLElement | Window =
        typeof outerElement === 'string'
            ? <HTMLElement | Window>document.querySelector(<string>outerElement)
            : outerElement;

    const innerRect = innerEl.getBoundingClientRect();

    if (outerEl === window) {
        return {
            top:
                innerRect.bottom - offset > window.innerHeight &&
                innerRect.top < window.innerHeight,
            bottom: innerRect.bottom - offset > 0 && innerRect.bottom < window.innerHeight
        };
    } else {
        const outerRect = (<HTMLElement>outerEl).getBoundingClientRect();
        const defaultView = (innerEl.ownerDocument || document).defaultView;
        const computedStyle = defaultView.getComputedStyle(<HTMLElement>outerEl, null);
        const outerRectBorderTopWidth = parseInt(
            computedStyle.getPropertyValue('border-top-width'),
            10
        );

        const topVisible =
            innerRect.top >= outerRect.top && innerRect.top + offset < outerRect.bottom;
        const bottomVisible =
            innerRect.bottom > outerRect.top + outerRectBorderTopWidth &&
            innerRect.bottom + offset < outerRect.bottom;

        return {
            top: topVisible,
            bottom: bottomVisible
        };
    }
}
