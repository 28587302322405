import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PROPERTY_NAVIGATION_PARAM } from '../navigation/navigation.constants';
import { LandingComponent } from './components/landing/landing.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: `/dashboard/portfolio`,
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        redirectTo: `/dashboard/portfolio`,
        pathMatch: 'full'
    },
    {
        path: `dashboard/:${PROPERTY_NAVIGATION_PARAM}`,
        component: LandingComponent
    },
    {
        path: 'journal-view',
        loadChildren:
            '../../journal-view/journal-view-route/journal-view-route.module#JournalViewRouteModule'
    },
    {
        path: 'export',
        loadChildren: '../../export/export-route/export.module#ExportModule'
    },
    {
        path: 'user',
        loadChildren:
            '../../subscription/subscription-route/subscription-route.module#SubscriptionRouteModule'
    },
    {
        path: `accounting/:${PROPERTY_NAVIGATION_PARAM}/contracts-list`,
        loadChildren:
            '../../expense-contracts/expense-contracts-route/expense-contracts-route.module#ExpenseContractsRouteModule'
    },
    {
        path: `accounting/:${PROPERTY_NAVIGATION_PARAM}/meter-consumption`,
        loadChildren:
            '../../meter-consumption/meter-consumption-route/meter-consumption-route.module#MeterConsumptionRouteModule'
    },
    {
        path: `property-portfolio`,
        redirectTo: `/property-portfolio/portfolio`,
        pathMatch: 'full'
    },
    {
        path: `property-portfolio/:${PROPERTY_NAVIGATION_PARAM}`,
        loadChildren:
            '../../investment/investment-route/investment-route.module#InvestmentRouteModule'
    },
    {
        path: `property-portfolio/:${PROPERTY_NAVIGATION_PARAM}/investment`,
        loadChildren:
            '../../investment/investment-route/investment-route.module#InvestmentRouteModule'
    },
    {
        path: `property-portfolio/:${PROPERTY_NAVIGATION_PARAM}/property-information`,
        loadChildren:
            '../../property-information/property-information-overview/property-information-overview.module#PropertyInformationOverviewModule'
    },
    {
        path: `information`,
        redirectTo: `/information/portfolio`,
        pathMatch: 'full'
    },
    {
        path: `information/:${PROPERTY_NAVIGATION_PARAM}/handover_protocol`,
        loadChildren:
            '../../handover-protocol/handover-protocol-route/handover-protocol-route.module#HandoverProtocolRouteModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule {}
