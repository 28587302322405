import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PaymentCoreState } from '@payment-core/interfaces';
import {
    DEFAULT_PAGE_SIZE,
    MAX_PAGE_SIZE,
    PaymentApiService
} from '@payment-core/services/payment-api.service';
import { PaymentService } from '@payment-core/services/payment.service';
import * as storeActions from '@payment-core/state/actions/payment.actions';
import {
    getIdsFromPayments,
    getPaymentsFromClusters,
    getPaymentsPresentIn,
    getTransferIdsFromPayments
} from '@payment-list/utils/payment-selection';
import { ConfirmationService } from '@zenhomes/iframe-hustla-ui';
import { IPayment } from '@zenhomes/domain/payment';
import {
    mapBookmarkToFilterState,
    mapFilterStateToBookmark,
    replaceQueryString
} from '@zenhomes/filter-core';
import { InvoiceCoreActions } from '@zenhomes/invoice-core';
import { JournalViewActions } from '@zenhomes/journal-view-core';
import { userActionTypes, UserService } from '@zenhomes/user-core';
import { includes, map, omit, range, reduce } from 'lodash';
import { ofAction } from '@zenhomes/ngrx-actions';
import { equals } from 'ramda';
import { Observable } from 'rxjs/Observable';
import { mapTo } from 'rxjs/operators';
import { PropertyActions } from '@zenhomes/property-core';

const RELOAD_PAYMENTS_ON_FILTER_DEBOUNCE = 100;
const RELOAD_ALL_PAYMENTS_DEBOUNCE = 500;

@Injectable()
export class PaymentEffects {
    constructor(
        private paymentApiService: PaymentApiService,
        private actions$: Actions,
        private store: Store<any>,
        private paymentService: PaymentService,
        private location: Location,
        private router: Router,
        private confirmationService: ConfirmationService
    ) {}

    @Effect()
    reloadProperties$ = this.actions$.pipe(
        ofType(
            storeActions.PaymentCoreDeletePaymentSuccess.TYPE,
            storeActions.PaymentCoreUncategorizePaymentsSuccess.TYPE
        ),
        mapTo(new PropertyActions.ReloadProperties())
    );

    @Effect()
    dispatchLoadingAction$: Observable<Action> = ofAction(storeActions.PaymentCoreLoadPayments)(
        this.actions$
    )
        .withLatestFrom(this.paymentService.filterOptions$)
        .switchMap(([action, filterOptions]) => {
            return Observable.zip(
                this.paymentApiService.loadPayments(filterOptions),
                this.paymentApiService.countPayments(filterOptions)
            )
                .map(
                    ([payments, { count }]) =>
                        new storeActions.PaymentCoreCheckPaymentsCanBeLoaded(
                            payments,
                            count,
                            filterOptions
                        )
                )
                .catch(error => Observable.of(new storeActions.PaymentCoreLoadPaymentsFail(error)));
        });

    @Effect()
    loadPaymentsIfNeeded: Observable<Action> = ofAction(
        storeActions.PaymentCoreCheckPaymentsCanBeLoaded
    )(this.actions$)
        .withLatestFrom(this.paymentService.filterOptions$)
        .switchMap(([action, filterOptions]) => {
            const isFilterUnchanged = equals(action.filterOptions, filterOptions);
            return isFilterUnchanged
                ? [new storeActions.PaymentCoreLoadPaymentsSuccess(action.payments, action.count)]
                : [];
        });

    @Effect()
    dispatchReloadingAction$: Observable<Action> = ofAction(storeActions.PaymentCoreReloadPayments)(
        this.actions$
    )
        .withLatestFrom(this.paymentService.state$)
        .switchMap(([action, state]) => {
            const { filterOptions } = state as PaymentCoreState;
            const size = Number(filterOptions.page || 1) * DEFAULT_PAGE_SIZE;
            const page = size > DEFAULT_PAGE_SIZE ? 1 : filterOptions.page;
            const repetitions = range(Math.ceil(size / MAX_PAGE_SIZE)).map((n: number) => n + 1);

            let payments: any = {};

            const apiRequests = map(repetitions, (page: number) =>
                this.paymentApiService
                    .loadPayments({
                        ...filterOptions,
                        size: repetitions.length > 1 ? MAX_PAGE_SIZE.toString() : size.toString(),
                        page: page.toString()
                    })
                    .map((result: any) => (payments[page] = result.content))
            );

            return Observable.zip(
                this.paymentApiService.countPayments(filterOptions),
                ...apiRequests
            ).map(([{ count }]) => {
                const paymentsList = reduce(
                    repetitions,
                    (accum: any[], currentPage: number) => {
                        return [...accum, ...payments[currentPage]];
                    },
                    []
                );
                return new storeActions.PaymentCoreUpdatePaymentsDebounced(
                    paymentsList.slice(0, size),
                    count
                );
            });
        });

    /*
     * Debounces payments list update, based on new reload actions,
     * i.e. delays the update, if new reload actions are triggered
     */
    @Effect()
    dispatchUpdateDebouncedAction$: Observable<Action> = ofAction(
        storeActions.PaymentCoreUpdatePaymentsDebounced,
        storeActions.PaymentCoreReloadPayments
    )(this.actions$)
        .debounceTime(RELOAD_ALL_PAYMENTS_DEBOUNCE)
        .switchMap((action: any) => {
            if (action.payload) {
                return Observable.of(
                    new storeActions.PaymentCoreUpdatePayments(action.payload, action.count)
                );
            } else {
                return Observable.never();
            }
        });

    @Effect()
    dispatchLoadPaymentAction$: Observable<Action> = ofAction(storeActions.PaymentCoreLoadPayment)(
        this.actions$
    ).switchMap((action: any) => {
        return this.paymentApiService
            .getPayment(action.payload)
            .map(result => new storeActions.PaymentCoreLoadPaymentSuccess(result))
            .catch(error => Observable.of(new storeActions.PaymentCoreLoadPaymentFail(error)));
    });

    @Effect()
    changeFilters$: any = ofAction(
        storeActions.PaymentCoreUpdateListFilters,
        storeActions.PaymentCoreClearListFilters
    )(this.actions$)
        .debounceTime(RELOAD_PAYMENTS_ON_FILTER_DEBOUNCE)
        .withLatestFrom(this.paymentService.filterOptions$)
        .map(([action, filterOptions]) => {
            return new storeActions.PaymentCoreLoadPayments();
        });

    @Effect()
    dispatchGetAction$: Observable<Action> = ofAction(storeActions.PaymentCoreGetPayment)(
        this.actions$
    ).switchMap((action: any) => {
        return this.paymentApiService
            .getPayment(action.payload)
            .map(result => new storeActions.PaymentCoreGetPaymentSuccess(result))
            .catch(error => Observable.of(new storeActions.PaymentCoreGetPaymentFail(error)));
    });

    @Effect()
    dispatchDeleteAction$: Observable<Action> = ofAction(storeActions.PaymentCoreDeletePayment)(
        this.actions$
    ).switchMap((action: any) => {
        return this.paymentApiService
            .deletePayment(action.payload)
            .map(result => new storeActions.PaymentCoreDeletePaymentSuccess(result))
            .catch(error => Observable.of(new storeActions.PaymentCoreDeletePaymentFail(error)));
    });

    @Effect()
    loadMorePayments: Observable<Action> = ofAction(
        storeActions.PaymentCoreLoadMorePayments,
        storeActions.PaymentCoreLoadMissingPayments
    )(this.actions$)
        .withLatestFrom(this.paymentService.pagination$)
        .switchMap(([action, pagination]) => {
            const canLoadMorePayments: boolean =
                pagination && pagination.totalPages > pagination.number;

            if (!canLoadMorePayments) return Observable.never();

            return Observable.of(
                new storeActions.PaymentCoreUpdateListFilters(
                    {
                        page: (++pagination.number).toString()
                    },
                    pagination.number === 1
                )
            );
        });

    @Effect()
    checkIfCannotLoadMorePayments: Observable<Action> = ofAction(
        storeActions.PaymentCoreLoadPaymentsSuccess
    )(this.actions$)
        .withLatestFrom(this.paymentService.pagination$)
        .switchMap(([action, pagination]) => {
            const canLoadMorePayments: boolean =
                pagination && pagination.totalPages > pagination.number;

            return !canLoadMorePayments
                ? Observable.of(new storeActions.PaymentCoreUpdateShouldLoadMissingPayments(false))
                : Observable.never();
        });

    @Effect()
    ignorePayments: Observable<Action> = ofAction(storeActions.PaymentCoreIgnorePayments)(
        this.actions$
    ).switchMap(({ payload: payments }) => {
        const transferIds = getTransferIdsFromPayments(payments);

        return this.paymentApiService
            .ignorePayments(transferIds)
            .switchMap(() => [
                new storeActions.PaymentCoreCleanPaymentsSelected([]),
                new storeActions.PaymentCoreReloadPayments()
            ]);
    });

    @Effect()
    unignorePayments: Observable<Action> = ofAction(storeActions.PaymentCoreUnignorePayments)(
        this.actions$
    ).switchMap(({ payload: payments }) => {
        const transferIds = getTransferIdsFromPayments(payments);

        return this.paymentApiService
            .unignorePayments(transferIds)
            .map(() => new storeActions.PaymentCoreReloadPayments());
    });

    @Effect()
    markSelectedPaymentsAsHidden: Observable<Action> = ofAction(
        storeActions.PaymentCoreSnoozePaymentsSelected,
        storeActions.PaymentCoreIgnorePaymentsSelected,
        storeActions.PaymentCoreResetPaymentsSelected,
        storeActions.PaymentCoreUncategorizePaymentsSuccess
    )(this.actions$)
        .switchMap(() => this.paymentService.paymentsSelected$.take(1))
        .map(selectedPayments => {
            return new storeActions.PaymentCoreMarkPaymentsAsHidden(selectedPayments);
        });

    @Effect()
    markPaymentAsHidden: Observable<Action> = ofAction(
        storeActions.PaymentCoreSnoozePayments,
        storeActions.PaymentCoreUnsnoozePayments,
        storeActions.PaymentCoreIgnorePayments,
        storeActions.PaymentCoreUnignorePayments
    )(this.actions$)
        .map((action: any) => action.payload as IPayment[])
        .map(payments => {
            return new storeActions.PaymentCoreMarkPaymentsAsHidden(payments);
        });

    @Effect()
    snoozePayments: Observable<Action> = ofAction(storeActions.PaymentCoreSnoozePayments)(
        this.actions$
    ).switchMap(({ payload: payments }) => {
        const transferIds = getTransferIdsFromPayments(payments);

        return this.paymentApiService
            .snoozePayments(transferIds)
            .switchMap(() => [
                new storeActions.PaymentCoreCleanPaymentsSelected([]),
                new storeActions.PaymentCoreReloadPayments()
            ]);
    });

    @Effect()
    unsnoozePayments: Observable<Action> = ofAction(storeActions.PaymentCoreUnsnoozePayments)(
        this.actions$
    ).switchMap(({ payload: payments }) => {
        const transferIds = getTransferIdsFromPayments(payments);

        return this.paymentApiService
            .unsnoozePayments(transferIds)
            .switchMap(() => [
                new storeActions.PaymentCoreCleanPaymentsSelected([]),
                new storeActions.PaymentCoreReloadPayments()
            ]);
    });

    @Effect()
    ignorePaymentsSelected: Observable<Action> = ofAction(
        storeActions.PaymentCoreIgnorePaymentsSelected
    )(this.actions$)
        .withLatestFrom(this.paymentService.state$)
        .switchMap(([action, state]) => {
            const transferIds = getTransferIdsFromPayments(state.paymentsSelected),
                numberOfTransfers = transferIds.length;

            return this.paymentApiService.ignorePayments(transferIds);
        })
        .mergeMap(() => {
            return [
                new storeActions.PaymentCoreCleanPaymentsSelected([]),
                new storeActions.PaymentCoreReloadPayments()
            ];
        });

    @Effect()
    snoozePaymentsSelected: Observable<Action> = ofAction(
        storeActions.PaymentCoreSnoozePaymentsSelected
    )(this.actions$)
        .withLatestFrom(this.paymentService.state$)
        .switchMap(([action, state]) => {
            const transferIds = getTransferIdsFromPayments(state.paymentsSelected),
                numberOfTransfers = transferIds.length;

            return this.paymentApiService.snoozePayments(transferIds);
        })
        .mergeMap(() => {
            return [
                new storeActions.PaymentCoreCleanPaymentsSelected([]),
                new storeActions.PaymentCoreReloadPayments()
            ];
        });

    @Effect()
    resetPaymentsSelected: Observable<Action> = ofAction(
        storeActions.PaymentCoreResetPaymentsSelected
    )(this.actions$)
        .withLatestFrom(this.paymentService.state$)
        .switchMap(([action, state]) => {
            const transferIds = getTransferIdsFromPayments(state.paymentsSelected),
                numberOfTransfers = transferIds.length;

            return this.paymentApiService.resetPayments(transferIds);
        })
        .mergeMap(() => [
            new storeActions.PaymentCoreCleanPaymentsSelected([]),
            new storeActions.PaymentCoreReloadPayments()
        ]);

    @Effect()
    uncategorizePaymentsSelected: Observable<Action> = ofAction(
        storeActions.PaymentCoreUncategorizePaymentsSelected
    )(this.actions$)
        .withLatestFrom(this.paymentService.state$)
        .switchMap(([action, state]) => {
            const paymentIds = getIdsFromPayments(state.paymentsSelected),
                numberOfPayments = paymentIds.length;

            return this.confirmationService
                .confirm({
                    body: 'confirmation.unmatchPayments.body',
                    confirmButton: 'confirmation.unmatchSelectedInvoices',
                    title: 'confirmation.unmatchPayments.title',
                    warningText: 'confirmation.unmatchPayments.warningText',
                    warningParams: { numberOfPayments }
                })
                .switchMap((confirmed: boolean) => {
                    return confirmed
                        ? this.paymentApiService.uncategorizePayments(paymentIds)
                        : Observable.never();
                })
                .map(() => new storeActions.PaymentCoreUncategorizePaymentsSuccess())
                .catch(error =>
                    Observable.of(new storeActions.PaymentCoreUncategorizePaymentsFail(error))
                );
        });

    @Effect()
    cleanAndReloadPayments$: Observable<Action> = ofAction(
        storeActions.PaymentCoreUncategorizePaymentsSuccess,
        storeActions.PaymentCoreDeletePaymentSuccess
    )(this.actions$).switchMap(() => {
        return [
            new storeActions.PaymentCoreCleanPaymentsSelected([]),
            new storeActions.PaymentCoreReloadPayments()
        ];
    });

    @Effect()
    bankAccountNavigation$: Observable<Action> = this.router.events
        .filter(event => event instanceof NavigationEnd)
        .filter((event: NavigationEnd) => includes(event.urlAfterRedirects, 'bank-account'))
        .map((event: NavigationEnd) => {
            const routerStateRoot = this.router.routerState.snapshot.root;
            return routerStateRoot.queryParamMap;
        })
        .map(paramMap => {
            return new storeActions.PaymentCoreUpdateParseFiltersFromQueryString(paramMap);
        });

    @Effect()
    updateFiltersFromQueryString$: Observable<Action> = ofAction(
        storeActions.PaymentCoreUpdateParseFiltersFromQueryString
    )(this.actions$)
        .map((action: storeActions.PaymentCoreUpdateParseFiltersFromQueryString) => action.paramMap)
        .filter(paramMap => paramMap.keys.length > 0)
        .map(paramMap => {
            return mapBookmarkToFilterState(paramMap);
        })
        .map(filterOptions => {
            return new storeActions.PaymentCoreUpdateListFilters(filterOptions);
        });

    @Effect({ dispatch: false })
    reloadInvoiceAndPayments$: Observable<Action> = ofAction(
        storeActions.PaymentCoreDeletePaymentSuccess
    )(this.actions$).do(() => {
        this.store.dispatch(new InvoiceCoreActions.InvoiceCoreLoadInvoices());
        this.store.dispatch(new JournalViewActions.JournalViewReloadInvoices());

        this.paymentService.reloadPayments();
    });

    @Effect({ dispatch: false })
    reflectFiltersInQueryString = ofAction(
        storeActions.PaymentCoreClearListFilters,
        storeActions.PaymentCoreUpdateListFilters,
        storeActions.PaymentCoreRefreshQueryString
    )(this.actions$)
        .withLatestFrom(this.paymentService.filterOptions$)
        .map(([action, filterOptions]) => {
            const bookmarkableFilterOptions = omit(filterOptions, 'page');
            return mapFilterStateToBookmark(bookmarkableFilterOptions);
        })
        .do(paramMap => {
            const queryString = paramMap.toString();
            replaceQueryString(this.location, queryString);
        });

    @Effect()
    loadTransferCounterparties$: Observable<Action> = ofAction(
        storeActions.PaymentCoreLoadTransferCounterparties
    )(this.actions$).switchMap(() => {
        return this.paymentApiService
            .getTransferCounterparties()
            .map(result => new storeActions.PaymentCoreLoadTransferCounterpartiesSuccess(result))
            .catch(error =>
                Observable.of(new storeActions.PaymentCoreLoadTransferCounterpartiesFail(error))
            );
    });

    @Effect({ dispatch: false })
    cleanSelectedPaymentsInReloadAndFiltersChange: any = ofAction(
        storeActions.PaymentCoreReloadPayments,
        storeActions.PaymentCoreUpdateListFilters,
        storeActions.PaymentCoreClearListFilters
    )(this.actions$)
        .withLatestFrom(this.paymentService.state$)
        .map(([action, state]) => {
            const { shouldLoadMissingPayments } = state as PaymentCoreState;

            if (shouldLoadMissingPayments) return;

            const { payments, paymentsSelected } = state;

            const stillPresentPayments = getPaymentsPresentIn(
                paymentsSelected,
                getPaymentsFromClusters(payments)
            );

            return this.paymentService.cleanPaymentsSelected(stillPresentPayments);
        });

    @Effect({ dispatch: false })
    loadMissingPaymentsWhenSelectingAll: any = ofAction(storeActions.PaymentCoreSelectAllPayments)(
        this.actions$
    )
        .withLatestFrom(this.paymentService.state$)
        .do(([action, state]) => {
            this.paymentService.loadMissingPayments();
        });

    @Effect({ dispatch: false })
    keepLoadingMissingPaymentsIfNeeded: any = ofAction(storeActions.PaymentCoreLoadPaymentsSuccess)(
        this.actions$
    )
        .withLatestFrom(this.paymentService.state$)
        .do(([action, state]) => {
            const {
                shouldLoadMissingPayments,
                payments: clusteredPayments
            } = state as PaymentCoreState;

            if (!shouldLoadMissingPayments) return;

            const payments = getPaymentsFromClusters(clusteredPayments);

            this.paymentService.cleanPaymentsSelected(payments);
            this.paymentService.loadMissingPayments();
        });

    @Effect()
    initialLoadUncategorizedStats$: Observable<Action> = this.actions$
        .pipe(ofType(userActionTypes.USER_LOAD_USER_SUCCESS))
        .map(() => {
            return new storeActions.PaymentCoreLoadUncategorizedPaymentsStats();
        });

    @Effect()
    reloadUncategorizedStats$: Observable<Action> = ofAction(
        storeActions.PaymentCoreDeletePaymentSuccess,
        storeActions.PaymentCoreReloadPayments,
        InvoiceCoreActions.InvoiceCoreDeleteInvoiceSuccess
    )(this.actions$).map(() => {
        return new storeActions.PaymentCoreLoadUncategorizedPaymentsStats();
    });

    @Effect()
    dispatchLoadingStats$: Observable<Action> = ofAction(
        storeActions.PaymentCoreLoadUncategorizedPaymentsStats
    )(this.actions$).switchMap(() => {
        return this.paymentApiService
            .getUncategorizedPaymentStats()
            .map(stats => {
                return new storeActions.PaymentCoreLoadUncategorizedPaymentsStatsSuccess(stats);
            })
            .catch(error => {
                return Observable.of(
                    new storeActions.PaymentCoreLoadUncategorizedPaymentsStatsFail(error)
                );
            });
    });

    @Effect({ dispatch: false })
    saveInitialPaymentSelection: Observable<Action> = ofAction(
        storeActions.PaymentCoreSaveInitialPaymentSelection
    )(this.actions$).switchMap(({ payload: payments }) => {
        return this.paymentApiService.saveInitialPaymentSelection(payments);
    });
}
