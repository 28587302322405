import { Action } from '@ngrx/store';

import { typeWithNamespace } from '@util/type-cache.util';
import { BankAccount } from '../../interfaces';

const BANK_ACCOUNTS = 'Bank Accounts';

export class BankAccountsLoadBankAccounts implements Action {
    static readonly TYPE = typeWithNamespace(BANK_ACCOUNTS, 'Load bank accounts');
    readonly type = BankAccountsLoadBankAccounts.TYPE;
    constructor() {}
}

export class BankAccountsLoadBankAccountsSuccess implements Action {
    static readonly TYPE = typeWithNamespace(BANK_ACCOUNTS, 'Load bank accounts success');
    readonly type = BankAccountsLoadBankAccountsSuccess.TYPE;

    constructor(public payload: BankAccount[]) {}
}

export class BankAccountsLoadBankAccountsFail implements Action {
    static readonly TYPE = typeWithNamespace(BANK_ACCOUNTS, 'Load bank accounts fail');
    readonly type = BankAccountsLoadBankAccountsFail.TYPE;
}
