import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, isArray } from 'lodash';
import { ContactTitlePriority } from '../interfaces/contact-title-type.interface';
import { arrayToStringByObjectProperty } from '@zenhomes/sauron';

export const EMPTY_PLACEHOLDER = '-';
export const SEPARATOR = ', ';

@Pipe({
    name: 'contactTitle'
})
export class ContactTitlePipe implements PipeTransform {
    transform(contact: any, priority: ContactTitlePriority = 'name', subtitle: boolean = false) {
        if (isArray(contact)) {
            const contactTitleArray = contact
                .map(c => this.transformContact(c, priority, subtitle))
                .filter(contactTitle => contactTitle !== EMPTY_PLACEHOLDER)
                .map(contactTitle => ({ contactTitle }));

            return (
                arrayToStringByObjectProperty(contactTitleArray, 'contactTitle') ||
                EMPTY_PLACEHOLDER
            );
        } else {
            return this.transformContact(contact, priority, subtitle);
        }
    }

    transformContact(
        contact: any,
        priority: ContactTitlePriority = 'name',
        subtitle: boolean = false
    ) {
        switch (true) {
            case subtitle && isEmpty(contact):
                return '';
            case subtitle && priority === 'company':
                return (contact.companyName && (contact.fullName || contact.name)) || '';
            case subtitle && priority === 'name':
                return ((contact.fullName || contact.name) && contact.companyName) || '';
            case isEmpty(contact):
                return EMPTY_PLACEHOLDER;
            case priority === 'name':
                return (
                    contact.fullName ||
                    contact.name ||
                    contact.companyName ||
                    contact.email ||
                    EMPTY_PLACEHOLDER
                );
            case priority === 'company':
                return contact.companyName || contact.fullName || contact.name || EMPTY_PLACEHOLDER;
            default:
                return EMPTY_PLACEHOLDER;
        }
    }
}
