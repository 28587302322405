import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PaymentService } from '@payment-core/services/payment.service';
import { getSubjectsForUserCategories, UserTopicsService } from '@zenhomes/category-core';
import { ofAction } from '@zenhomes/ngrx-actions';
import { Observable } from 'rxjs/Observable';
import { InvoiceApiService } from '../../services/invoice-api.service';
import { InvoiceService } from '../../services/invoice.service';
import * as invoiceActions from '../actions/invoice.actions';
import { mapTo } from 'rxjs/operators';
import { PropertyActions } from '@zenhomes/property-core';

const RELOAD_INVOICES_ON_FILTER_DEBOUNCE = 100;

@Injectable()
export class InvoiceEffects {
    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private invoiceApiService: InvoiceApiService,
        private invoiceService: InvoiceService,
        private userTopicsService: UserTopicsService,
        private paymentService: PaymentService
    ) {}

    @Effect()
    dispatchLoadingAction$: Observable<Action> = ofAction(invoiceActions.InvoiceCoreLoadInvoices)(
        this.actions$
    )
        .switchMapTo(this.invoiceService.filterOptions$.take(1))
        .switchMap(filterOptions => {
            return this.userTopicsService.userCategoryDictionary$.map(userCategoryDictionary => ({
                filterOptions,
                userCategoryDictionary
            }));
        })
        .map(({ filterOptions, userCategoryDictionary }) => {
            const subjectSelectionForUserCategories = getSubjectsForUserCategories(
                filterOptions.userCategorySelection,
                userCategoryDictionary
            );

            return {
                ...filterOptions,
                subjectSelection: [
                    ...filterOptions.subjectSelection,
                    ...subjectSelectionForUserCategories
                ]
            };
        })
        .switchMap(filterOptions => {
            return this.invoiceApiService
                .loadInvoices(filterOptions)
                .map(result => new invoiceActions.InvoiceCoreLoadInvoicesSuccess(result))
                .catch(error =>
                    Observable.of(new invoiceActions.InvoiceCoreLoadInvoicesFail(error))
                );
        });

    @Effect()
    changeFilters$: Observable<Action> = ofAction(
        invoiceActions.InvoiceCoreUpdateListFilters,
        invoiceActions.InvoiceCoreClearListFilters
    )(this.actions$)
        .debounceTime(RELOAD_INVOICES_ON_FILTER_DEBOUNCE)
        .map(() => {
            return new invoiceActions.InvoiceCoreLoadInvoices();
        });

    @Effect()
    dispatchGetAction$: Observable<Action> = ofAction(invoiceActions.InvoiceCoreGetInvoice)(
        this.actions$
    ).switchMap((action: any) => {
        return this.invoiceApiService
            .getInvoice(action.payload)
            .map(result => new invoiceActions.InvoiceCoreGetInvoiceSuccess(result))
            .catch(error => Observable.of(new invoiceActions.InvoiceCoreGetInvoiceFail(error)));
    });

    @Effect()
    dispatchUpdateNoteAction$: Observable<Action> = ofAction(invoiceActions.UpdateNoteText)(
        this.actions$
    ).switchMap(({ payload }: invoiceActions.UpdateNoteText) => {
        return this.invoiceApiService
            .updateNoteText(payload.text, payload.invoiceId)
            .map(result => new invoiceActions.UpdateNoteTextSuccess(result))
            .catch(error => Observable.of(new invoiceActions.UpdateNoteTextFail(error)));
    });

    @Effect()
    dispatchDeleteAction$: Observable<Action> = ofAction(invoiceActions.InvoiceCoreDeleteInvoice)(
        this.actions$
    ).switchMap((action: any) => {
        return this.invoiceApiService
            .deleteInvoice(action.payload)
            .map(() => new invoiceActions.InvoiceCoreDeleteInvoiceSuccess(action.payload))
            .catch(error => Observable.of(new invoiceActions.InvoiceCoreDeleteInvoiceFail(error)));
    });

    @Effect()
    reloadInvoices: Observable<Action> = ofAction(
        invoiceActions.InvoiceCoreDeleteInvoiceSuccess,
        invoiceActions.UpdateNoteTextSuccess
    )(this.actions$).map(() => {
        return new invoiceActions.InvoiceCoreLoadInvoices();
    });

    @Effect()
    reloadProperties$ = this.actions$.pipe(
        ofType(invoiceActions.InvoiceCoreDeleteInvoiceSuccess.TYPE),
        mapTo(new PropertyActions.ReloadProperties())
    );
}
