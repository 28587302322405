import { get } from 'lodash';
import { isEmpty } from 'lodash';
import { isUndefined } from 'lodash';
import { IBuildingType, IUnitType } from '../enums';
import { IBuildingModel, IUnitModel } from '../interfaces';
import { hasRolePropertyManager } from './property-role.util';

export const isBuildingUtil = (item: IBuildingModel | IUnitModel): item is IBuildingModel => {
    return !!get(item, 'units');
};

export const isUnitUtil = (item: IBuildingModel | IUnitModel): item is IUnitModel => {
    return !!get(item, 'usages');
};

export const isSingleBuildingUtil = (building: IBuildingModel): boolean => {
    const buildingType = get(building, 'buildingType');

    return !isEmpty(buildingType) && buildingType === IBuildingType.Single;
};

export const isMultiBuildingUtil = (building: IBuildingModel): boolean => {
    const buildingType = get(building, 'buildingType');

    return !isEmpty(buildingType) && buildingType === IBuildingType.Multi;
};

export const isApartmentBuilding = (building: IBuildingModel): boolean => {
    return (
        !!building &&
        isMultiBuildingUtil(building) &&
        !isUndefined(building.isCondo) &&
        !building.isCondo
    );
};

export const isCondoBuilding = (building: IBuildingModel): boolean => {
    return (
        !!building &&
        isMultiBuildingUtil(building) &&
        !isUndefined(building.isCondo) &&
        building.isCondo
    );
};

export const isSingleCondoBuilding = (building: IBuildingModel): boolean => {
    return isCondoBuilding(building) && !!building.units && building.units.length === 1;
};

export const isCondoBuildingPM = (building: IBuildingModel): boolean => {
    return isCondoBuilding(building) && hasRolePropertyManager(building);
};

export const isCondoBuildingAsNotPM = (building: IBuildingModel): boolean => {
    return isCondoBuilding(building) && !hasRolePropertyManager(building);
};

export const isSingleCondoBuildingAsNotPM = (building: IBuildingModel): boolean => {
    return isCondoBuildingAsNotPM(building) && !!building.units && building.units.length === 1;
};

export const isApartmentUnit = (unit: IUnitModel): boolean => {
    const unitType = get(unit, 'type');
    return !!unit && unitType === IUnitType.Apartment;
};
