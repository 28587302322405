import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertiesService } from '@zenhomes/property-core';
import { UserTopicsService } from '@zenhomes/category-core';
import { ICategorizationSubjectDictionary } from '@zenhomes/domain/category';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IBuildingModel, IUnitModel } from '@zenhomes/domain/property';
import { forEach, get, isArray, has, Dictionary } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { IInvoicePeriod } from '@zenhomes/domain/invoice';
import { combineLatest, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { ExpenseContract, ExpenseContractType } from '../../interfaces';

export interface IPrefillData {
    subjects: ICategorizationSubjectDictionary;
    buildings: { [id: string]: IBuildingModel };
    units: { [id: string]: IUnitModel };
}

export type PopulateFn = (data: any) => void;

@Injectable()
export class PrefillDataInterceptor implements HttpInterceptor {
    prefillData$: Observable<IPrefillData>;

    constructor(
        private userTopicsService: UserTopicsService,
        private propertiesService: PropertiesService
    ) {
        this.prefillData$ = combineLatest(
            this.userTopicsService.subjectDictionary$,
            this.propertiesService.allBuildingsDictionary$,
            this.propertiesService.allUnitsDictionary$
        ).pipe(
            map(([subjects, buildings, units]) => ({ subjects, buildings, units })),
            take(1)
        );
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(switchMap(response => this.handleResponse(response as HttpResponse<any>)));
    }

    handleResponse(response: HttpResponse<any>) {
        switch (true) {
            case this.isInterceptableResponse(
                response,
                /accounting-populator\/invoices\/clustered-by-period/
            ): {
                return this.prefillResponse(response, populateInvoicePeriodBodyCategorization);
            }
            case this.isInterceptableResponse(response, /invoices\/investment/): {
                return this.prefillResponse(response, populateInvoiceInvestmentBodyCategorization);
            }
            case this.isInterceptableResponse(response, /invoices/): {
                return this.prefillResponse(response, populateInvoiceBodyCategorization);
            }
            case this.isInterceptableResponse(response, /payments/): {
                return this.prefillResponse(response, populateInvoiceInPaymentBodyCategorization);
            }
            case this.isInterceptableResponse(response, /contracts/): {
                return this.prefillResponse(response, populateExpenseContractBodyCategorization);
            }
            default: {
                return of(response);
            }
        }
    }

    private isInterceptableResponse(response: HttpEvent<any>, endpointPattern: RegExp) {
        return (
            response instanceof HttpResponse &&
            response.status === 200 &&
            endpointPattern.test(response.url)
        );
    }

    private prefillResponse(
        response: HttpResponse<any>,
        populateBody: (populate: PopulateFn, body: any) => void
    ) {
        return this.prefillData$
            .map(prefillData => {
                return (obj: any) => {
                    amendCategorizationFields(obj, prefillData.subjects);
                    amendBuildingField(obj, prefillData.buildings);
                    amendUnitField(obj, prefillData.units);
                };
            })
            .map(populateFn => {
                const body = response.body;

                populateBody(populateFn, body);

                const prefilledResponse = response.clone({ body });

                return prefilledResponse;
            });
    }
}

// body population aggregators
function populateExpenseContractBodyCategorization(populate: PopulateFn, body: any) {
    if (
        body.type === ExpenseContractType.ExpenseContract ||
        body.type === ExpenseContractType.LoanContract
    ) {
        populateExpenseContract(populate, body);
    }

    forEach(isArray(body) ? body : get(body, 'content'), (contract: ExpenseContract) => {
        if (
            contract.type === ExpenseContractType.ExpenseContract ||
            contract.type === ExpenseContractType.LoanContract
        ) {
            populateExpenseContract(populate, contract);
        }
    });
}

function populateInvoiceInPaymentBodyCategorization(populate: PopulateFn, body: any) {
    forEach(get(body, 'payments'), payment => {
        forEach(get(payment, 'paymentItems'), paymentItem => {
            populateInvoice(populate, get(paymentItem, 'invoice'));
        });
    });

    forEach(get(body, 'content'), item => {
        forEach(get(item, 'payments'), payment => {
            forEach(get(payment, 'paymentItems'), paymentItem => {
                populateInvoice(populate, get(paymentItem, 'invoice'));
            });
        });
    });
}

function populateInvoicePeriodBodyCategorization(populate: PopulateFn, body: any) {
    forEach(body, period => {
        populateInvoicePeriod(populate, period);
    });
}

function populateInvoiceInvestmentBodyCategorization(populate: PopulateFn, body: any) {
    let inventory = get(body, 'inventory');

    forEach(get(inventory, 'items'), invoice => {
        populateInvoice(populate, invoice);
    });
    let purchaseAndConstruction = get(body, 'purchaseAndConstruction');

    forEach(get(purchaseAndConstruction, 'items'), invoice => {
        populateInvoice(populate, invoice);
    });

    let renovation = get(body, 'renovation');

    forEach(get(renovation, 'items'), invoice => {
        populateInvoice(populate, invoice);
    });
}

function populateInvoiceBodyCategorization(populate: PopulateFn, body: any) {
    populateInvoice(populate, body);

    forEach(get(body, 'content'), invoice => {
        populateInvoice(populate, invoice);
    });
}

function populateInvoicePeriod(populate: PopulateFn, invoicePeriod: IInvoicePeriod) {
    forEach(get(invoicePeriod, 'items'), populate);
    forEach(get(invoicePeriod, 'invoices'), (invoice: IInvoice) =>
        populateInvoice(populate, invoice)
    );
}

// iterators
function populateExpenseContract(populate: PopulateFn, contract: ExpenseContract) {
    forEach(get(contract, 'periods'), item => {
        forEach(get(item, 'items'), populate);
    });
}

function populateInvoice(populate: PopulateFn, invoice: IInvoice) {
    populateExpenseContract(populate, get(invoice, 'contract'));

    forEach(get(invoice, 'items'), item => {
        populate(item);
        forEach(get(item, 'splitItems'), populate);
    });
}

// population functions
export function amendCategorizationFields(
    item: any,
    subjectDictionary: ICategorizationSubjectDictionary
) {
    const categorization = item && item.subjectId && subjectDictionary[item.subjectId];

    if (categorization) {
        item.subject = item.subject || categorization.subject;
        item.userCategory = item.userCategory || categorization.userCategory;
        item.userTopic = item.userTopic || categorization.userTopic;
    }
}

interface ItemWithBuilding {
    building?: IBuildingModel;
    buildingId?: string;
}

interface ItemWithUnit {
    unit?: IUnitModel;
    unitId?: string;
}

export function amendBuildingField(item: ItemWithBuilding, buildings: Dictionary<IBuildingModel>) {
    has(item, 'building')
        ? completeBuildingData(item, buildings)
        : fillEmptyBuilding(item, buildings);
}

export function amendUnitField(item: ItemWithUnit, units: { [id: string]: IUnitModel }) {
    has(item, 'unit') ? completeUnitData(item, units) : fillEmptyUnit(item, units);
}

const fillEmptyBuilding = (item: ItemWithBuilding, buildings: Dictionary<IBuildingModel>) => {
    const building = item && item.buildingId && buildings[item.buildingId];

    if (building) item.building = building;
};

const completeBuildingData = (item: ItemWithBuilding, buildings: Dictionary<IBuildingModel>) => {
    const building = buildings[item.building.id];

    if (building) item.building = { ...item.building, ...building };
};

const fillEmptyUnit = (item: ItemWithUnit, units: Dictionary<IUnitModel>) => {
    const unit = item && item.unitId && units[item.unitId];

    if (unit) item.unit = unit;
};

const completeUnitData = (item: ItemWithUnit, units: Dictionary<IUnitModel>) => {
    const unit = units[item.unit.id];

    if (unit) item.unit = { ...item.unit, ...unit };
};
