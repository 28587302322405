import { BookmarkableFilterOptions } from '@zenhomes/filter-core';
import { createPlaceholderItem, filterPlaceholderItems } from '@zenhomes/optimistic-update-util';
import { get, map, omit, reject } from 'lodash';
import { Action, Store } from '@zenhomes/ngrx-actions';
import {
    initialJournalViewStoreState,
    JournalViewState
} from '../../state/constants/journal-view.constants';
import { toggleInvoice } from '../../utils/invoice-selection';
import * as actions from '../actions/journal-view.actions';

@Store(initialJournalViewStoreState)
export class JournalViewStore {
    @Action(actions.JournalViewLoadInvoices, actions.JournalViewLoadMoreInvoices)
    loadInvoices(
        state: JournalViewState,
        action: actions.JournalViewLoadInvoices
    ): JournalViewState {
        return { ...state, journalViewInvoicesBeingLoaded: true };
    }

    @Action(actions.JournalViewLoadInvoicesSuccess)
    loadInvoicesSuccess(
        state: JournalViewState,
        { payload }: actions.JournalViewLoadInvoicesSuccess
    ): JournalViewState {
        return {
            ...state,
            journalViewInvoicesBeingLoaded: false,
            journalViewInvoicesAreLoaded: true,
            journalViewInvoices: payload.items,
            pagination: omit(payload.pagination, 'content')
        };
    }

    @Action(actions.JournalViewLoadMoreInvoicesSuccess)
    loadMoreInvoicesSuccess(
        state: JournalViewState,
        { payload }: actions.JournalViewLoadInvoicesSuccess
    ): JournalViewState {
        return {
            ...state,
            journalViewInvoicesBeingLoaded: false,
            journalViewInvoicesAreLoaded: true,
            journalViewInvoices: [...state.journalViewInvoices, ...payload.items],
            pagination: omit(payload.pagination, 'content')
        };
    }

    @Action(actions.JournalViewLoadInvoicesFail, actions.JournalViewLoadMoreInvoicesFail)
    loadInvoicesFail(
        state: JournalViewState,
        { payload }: actions.JournalViewLoadInvoicesFail
    ): JournalViewState {
        return {
            ...state,
            journalViewInvoicesBeingLoaded: false,
            journalViewInvoicesAreLoaded: false
        };
    }

    @Action(actions.JournalViewReset)
    resetJournalView(state: JournalViewState, action: actions.JournalViewReset): JournalViewState {
        return { ...initialJournalViewStoreState };
    }

    @Action(actions.JournalViewUpdateListFilters)
    updateListFilters(
        state: JournalViewState,
        { filterOptions }: actions.JournalViewUpdateListFilters
    ): JournalViewState {
        const mergedFilterOptions: BookmarkableFilterOptions = {
            ...state.filterOptions,
            ...filterOptions
        };
        return {
            ...state,
            filterOptions: mergedFilterOptions,
            journalViewInvoicesBeingLoaded: true
        };
    }

    @Action(actions.JournalViewClearListFilters)
    resetListFilters(state: JournalViewState): JournalViewState {
        const filterOptions = {
            ...state.filterOptions,
            ...initialJournalViewStoreState.filterOptions,
            propertySelection: state.filterOptions.propertySelection
        };
        return { ...state, filterOptions };
    }

    @Action(actions.JournalViewToggleInvoice)
    toggleInvoice(
        state: JournalViewState,
        action: actions.JournalViewToggleInvoice
    ): JournalViewState {
        return {
            ...state,
            journalViewInvoicesSelected: toggleInvoice(
                state.journalViewInvoicesSelected,
                action.payload
            )
        };
    }

    @Action(actions.JournalViewSelectAllInvoices)
    selectAllInvoices(state: JournalViewState): JournalViewState {
        const { journalViewInvoices } = state;

        return { ...state, journalViewInvoicesSelected: journalViewInvoices };
    }

    @Action(actions.JournalViewCleanInvoicesSelected)
    cleanInvoicesSelected(
        state: JournalViewState,
        action: actions.JournalViewCleanInvoicesSelected
    ): JournalViewState {
        return {
            ...state,
            journalViewInvoicesSelected: action.payload,
            journalViewShouldLoadMissingInvoices: false
        };
    }

    @Action(actions.JournalViewLoadMissingInvoices)
    loadMissingInvoices(state: JournalViewState): JournalViewState {
        return {
            ...state,
            journalViewShouldLoadMissingInvoices: true
        };
    }

    @Action(actions.JournalViewUpdateShouldLoadMissingInvoices)
    updateShouldLoadMissingInvoices(
        state: JournalViewState,
        {
            payload: journalViewShouldLoadMissingInvoices
        }: actions.JournalViewUpdateShouldLoadMissingInvoices
    ): JournalViewState {
        return {
            ...state,
            journalViewShouldLoadMissingInvoices
        };
    }

    @Action(actions.JournalViewToggleBulkOperationMode)
    toggleBulkOperationMode(state: JournalViewState): JournalViewState {
        return {
            ...state,
            ...{ journalViewBulkOperationMode: !state.journalViewBulkOperationMode }
        };
    }

    @Action(actions.JournalViewAddPaymentItemOptimistic)
    addPaymentItemOptimisticUpdate(
        state: JournalViewState,
        { payload }: actions.JournalViewAddPaymentItemOptimistic
    ): JournalViewState {
        const { targetInvoiceId, paymentItem } = payload;

        const journalViewInvoices = map(state.journalViewInvoices, invoice => {
            if (invoice.id !== targetInvoiceId) return invoice;

            const payments = [
                ...invoice.payments,
                createPlaceholderItem({ amount: 0, date: paymentItem.date }) as any
            ];

            return { ...invoice, payments };
        });

        return { ...state, journalViewInvoices };
    }

    @Action(actions.JournalViewAddPaymentItemOptimisticUndo)
    addPaymentItemOptimisticUpdateUndo(
        state: JournalViewState,
        action: actions.JournalViewAddPaymentItemOptimisticUndo
    ): JournalViewState {
        const journalViewInvoices = map(state.journalViewInvoices, invoice => {
            return { ...invoice, payments: filterPlaceholderItems(invoice.payments) };
        });

        return { ...state, journalViewInvoices };
    }

    @Action(actions.JournalViewDeletePaymentItemOptimistic)
    deletePaymentItemOptimisticUpdateUndo(
        state: JournalViewState,
        { paymentId }: actions.JournalViewDeletePaymentItemOptimistic
    ): JournalViewState {
        const journalViewInvoices = map(state.journalViewInvoices, invoice => {
            const payments = reject(
                invoice.payments,
                paymentItem => get(paymentItem, 'payment.id') === paymentId
            );

            return { ...invoice, payments };
        });

        return { ...state, journalViewInvoices };
    }

    @Action(actions.SetExpandedInvoice)
    setExpandedInvoice(
        state: JournalViewState,
        { invoice }: actions.SetExpandedInvoice
    ): JournalViewState {
        return { ...state, expandedInvoice: invoice };
    }
}
