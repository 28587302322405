import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ElementsModule } from '@zenhomes/elements';
import { TranslateModule } from '@ngx-translate/core';
import { DomInteractionModule } from '@zenhomes/dom-interaction';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BuildingsDropdownComponent } from './components/buildings-dropdown/buildings-dropdown.component';
import { PropertyBuildingUnitMultiSelectorComponent } from './components/property-building-unit-multi-selector/property-building-unit-multi-selector.component';
import { PropertyListItemComponent } from './components/property-list-item/property-list-item.component';
import { PropertyListLabelComponent } from './components/property-list-label/property-list-label.component';
import { UnitsDropdownComponent } from './components/units-dropdown/units-dropdown.component';
import { FloorNumberPipe } from './pipes/floor-number.pipe';
import { PropertySubtypeLabelPipe } from './pipes/property-subtype-label.pipe';
import { PropertyTypeLabelPipe } from './pipes/property-type-label.pipe';
import { UnitSubtypeLabelPipe } from './pipes/unit-subtype-label.pipe';
import { PropertyIconPipe } from './pipes/property-icon.pipe';

const DECLARATIONS = [
    PropertyTypeLabelPipe,
    PropertySubtypeLabelPipe,
    UnitSubtypeLabelPipe,
    FloorNumberPipe,
    PropertyListItemComponent,
    PropertyBuildingUnitMultiSelectorComponent,
    PropertyListLabelComponent,
    BuildingsDropdownComponent,
    UnitsDropdownComponent,
    PropertyIconPipe
];

@NgModule({
    declarations: [...DECLARATIONS],
    exports: [...DECLARATIONS],
    imports: [
        CommonModule,
        ElementsModule,
        TranslateModule,
        DomInteractionModule,
        BsDropdownModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PropertyUiModule {}
