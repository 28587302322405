import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-scale-svg',
    templateUrl: './scale-image-svg.component.html',
    styleUrls: ['./scale-image-svg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScaleImageSvgComponent {
    @Input() isActive: boolean = false;
    @Input() isClassVisible: boolean;

    get activeFill() {
        return this.isActive
            ? `url(${window.location.href}#linearGradient-1)`
            : 'rgb(229, 229, 229)';
    }
}
