import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogWizardComponent } from './components/dialog-wizard.component';
import { DialogWizardService } from './services/dialog-wizard.service';
import { ConfirmationDialogDirective } from './components/confirmation-dialog.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog.component';
import { ComponentRefFactory } from './services/component-ref-factory.service';
import { ConfirmationService } from './services/confirmation.service';
import { DialogWizardServiceToken } from './types/token';

export const DECLARATIONS = [
    DialogWizardComponent,
    ConfirmationDialogDirective,
    ConfirmationDialogComponent
];

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
    exports: [...DECLARATIONS],
    declarations: [...DECLARATIONS],
    entryComponents: [DialogWizardComponent, ConfirmationDialogComponent]
})
export class DialogWizardModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DialogWizardModule,
            providers: [
                ComponentRefFactory,
                DialogWizardService,
                { provide: DialogWizardServiceToken, useExisting: DialogWizardService },
                ConfirmationService
            ]
        };
    }
}
