import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-button-pill',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'button-pill.component.html',
    styleUrls: ['./button-pill.component.scss']
})
export class ButtonPillComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() disabled: boolean;
    @Input() loading: boolean;
    @Output() click: EventEmitter<null> = new EventEmitter();

    onClick($event: any) {
        $event.stopPropagation();
        if (!this.disabled) {
            this.click.emit($event);
        }
    }

    getClass() {
        const buttonClass = {
            disabled: this.disabled,
            loading: this.loading
        };
        return buttonClass;
    }
}
