import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'zhm-autocomplete-suggestion-item',
    templateUrl: './autocomplete-suggestion-item.component.html',
    styleUrls: ['./autocomplete-suggestion-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteSuggestionItemComponent implements OnInit {
    @Input() title: string;
    @Input() highlight: string;
    @Input() badge: string;

    constructor() {}

    ngOnInit() {}
}
