import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DocumentMetadataFormActions } from '@zenhomes/document-metadata-form';
import { DocumentsApiService } from '@zenhomes/document-core';
import { JournalViewDocumentsService } from '../../services/journal-view-documents.service';
import { JournalViewService } from '../../services/journal-view.service';
import { ConfirmationService } from '@zenhomes/iframe-hustla-ui';
import { IInvoice } from '@zenhomes/domain/invoice';
import { Observable } from 'rxjs/Observable';
import { isTrue } from '@zenhomes/sauron';
import { switchMap, map as fmap, filter } from 'rxjs/operators';
import * as actions from '../actions/journal-view-documents.actions';
import * as journalViewActions from '../actions/journal-view.actions';

@Injectable()
export class JournalViewDocumentsEffects {
    constructor(
        private journalViewDocumentsService: JournalViewDocumentsService,
        private journalViewService: JournalViewService,
        private documentsApiService: DocumentsApiService,
        private actions$: Actions,
        private store: Store<any>,
        private confirmationService: ConfirmationService
    ) {}

    @Effect()
    upateDocuments$ = this.actions$
        .pipe(
            ofType(
                journalViewActions.SetExpandedInvoice.TYPE,
                actions.DeleteDocumentSuccess.TYPE,
                actions.UpdateInvoiceDocumentSuccess.TYPE
            )
        )
        .withLatestFrom(this.journalViewService.expandedInvoice$)
        .pipe(
            fmap(
                ([action, invoice]: [Action, IInvoice]) =>
                    new actions.GetExpandedInvoiceDocuments(invoice)
            )
        );

    @Effect()
    documentEditClosed$ = this.actions$
        .pipe(
            ofType<DocumentMetadataFormActions.SaveSuccess>(
                DocumentMetadataFormActions.SaveSuccess.TYPE
            )
        )
        .pipe(fmap(({ document }) => new actions.UpdateInvoiceDocument(document)));

    @Effect()
    updateInvoiceDocument = this.actions$
        .pipe(ofType<actions.UpdateInvoiceDocument>(actions.UpdateInvoiceDocument.TYPE))
        .pipe(
            switchMap(({ document }) => {
                return this.documentsApiService
                    .saveDocument(document)
                    .map(document => new actions.UpdateInvoiceDocumentSuccess(document))
                    .catch(errorResponse =>
                        Observable.of(new actions.UpdateInvoiceDocumentFail(errorResponse))
                    );
            })
        );

    @Effect()
    getExpandedInvoiceDocuments$ = this.actions$
        .pipe(ofType(actions.GetExpandedInvoiceDocuments.TYPE))
        .pipe(
            filter(({ invoice }: actions.GetExpandedInvoiceDocuments) => !!invoice),
            switchMap(({ invoice }: actions.GetExpandedInvoiceDocuments) => {
                return this.documentsApiService
                    .getDocuments({ invoiceId: invoice.id, pageSize: 100, includeUnits: true })
                    .map(result => new actions.GetExpandedInvoiceDocumentsSuccess(result.items))
                    .catch(errorResponse =>
                        Observable.of(new actions.GetExpandedInvoiceDocumentsFail(errorResponse))
                    );
            })
        );

    @Effect()
    deleteDocument = this.actions$
        .pipe(ofType<actions.DeleteDocument>(actions.DeleteDocument.TYPE))
        .pipe(
            switchMap(({ document }) => {
                return this.confirmationService
                    .confirm({
                        title: 'confirmation.document.title',
                        body: 'confirmation.document.body',
                        confirmButton: 'confirmation.deleteButtonLabel'
                    })
                    .filter(isTrue)
                    .switchMapTo(this.documentsApiService.deleteDocument(document))
                    .map(() => new actions.DeleteDocumentSuccess(document))
                    .catch(errorResponse =>
                        Observable.of(new actions.DeleteDocumentFail(errorResponse))
                    );
            })
        );
}
