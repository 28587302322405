import { Injectable } from '@angular/core';
import { IElementsPaginationModel } from '@zenhomes/domain/pagination';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IInvoice } from '@zenhomes/domain/invoice';
import { BookmarkableFilterOptions, isDefaultFilter } from '@zenhomes/filter-core';
import { IDropPaymentToInvoice, initialInvoiceCoreStoreState } from '@zenhomes/invoice-core';
import { isEmpty } from 'lodash';
import { ofAction } from '@zenhomes/ngrx-actions';
import { Observable } from 'rxjs/Observable';
import * as fromState from '../state';
import * as actions from '../state/actions/journal-view.actions';
import { IPayment } from '@zenhomes/domain/payment';
import { JournalViewState } from '../state/constants/journal-view.constants';
import { toPayload } from '@util/to-payload.util';
import { UserTopicsService, getSubjectsForUserCategories } from '@zenhomes/category-core';
import { withLatestFrom, map } from 'rxjs/operators';

@Injectable()
export class JournalViewService {
    state$: Observable<JournalViewState> = this.store.select(fromState.getJournalViewState);
    allInvoices$: Observable<IInvoice[]> = this.store.select(fromState.getJournalViewInvoices);
    invoicesSelected$: Observable<IInvoice[]> = this.store.select(
        fromState.getJournalViewInvoicesSelected
    );
    expandedInvoice$: Observable<IInvoice> = this.store.select(fromState.getExpandedInvoice);
    shouldLoadMissingInvoices$: Observable<boolean> = this.store.select(
        fromState.getJournalViewShouldLoadMissingInvoices
    );
    isBulkOperationMode$: Observable<boolean> = this.store.select(
        fromState.getJournalViewBulkOperationMode
    );
    filterOptions$: Observable<BookmarkableFilterOptions> = this.store.select(
        fromState.getFilterOptions
    );
    invoicesBeingLoaded$: Observable<boolean> = this.store.select(
        fromState.getJournalViewInvoicesBeingLoaded
    );
    invoicesAreLoaded$: Observable<boolean> = this.store.select(
        fromState.getJournalViewInvoicesAreLoaded
    );
    containsInvoices$: Observable<boolean> = this.allInvoices$.map(
        invoicesList => !isEmpty(invoicesList)
    );
    pagination$: Observable<IElementsPaginationModel> = this.store.select(fromState.getPagination);
    journalViewPayInvoicesSuccess$: Observable<boolean> = ofAction(
        actions.JournalViewPayInvoicesSuccess
    )(this.actions$).map(toPayload);
    journalViewUnpayInvoicesSuccess$: Observable<boolean> = ofAction(
        actions.JournalViewUnpayInvoicesSuccess
    )(this.actions$).map(toPayload);
    canLoadMoreInvoices$: Observable<boolean>;
    isDefaultJournalViewFilterState$: Observable<boolean>;

    filterOptionsWithSubjects$: Observable<BookmarkableFilterOptions>;

    constructor(
        private store: Store<JournalViewState>,
        private actions$: Actions,
        private userTopicsService: UserTopicsService
    ) {
        this.canLoadMoreInvoices$ = Observable.combineLatest([
            this.invoicesAreLoaded$,
            this.invoicesBeingLoaded$,
            this.pagination$
        ]).map(([invoicesAreLoaded, invoicesBeingLoaded, pagination]) => {
            return invoicesAreLoaded && !invoicesBeingLoaded && !pagination.last;
        });

        this.isDefaultJournalViewFilterState$ = this.filterOptions$.map(filterOptions => {
            return isDefaultFilter(initialInvoiceCoreStoreState.filterOptions, filterOptions, [
                'propertySelection',
                'query'
            ]);
        });

        this.filterOptionsWithSubjects$ = this.userTopicsService.userCategoryDictionary$.pipe(
            withLatestFrom(this.filterOptions$),
            map(([userCategoryDictionary, filterOptions]) => {
                const subjectSelectionForUserCategories = getSubjectsForUserCategories(
                    filterOptions.userCategorySelection,
                    userCategoryDictionary
                );

                const subjectSelection = [
                    ...filterOptions.subjectSelection,
                    ...subjectSelectionForUserCategories
                ];

                return { ...filterOptions, subjectSelection };
            })
        );
    }

    updateFilters(filterOptions: BookmarkableFilterOptions) {
        this.store.dispatch(new actions.JournalViewUpdateListFilters(filterOptions));
    }

    clearFilters() {
        this.store.dispatch(new actions.JournalViewClearListFilters());
    }

    reset() {
        this.store.dispatch(new actions.JournalViewReset());
    }

    loadMoreInvoices() {
        this.store.dispatch(new actions.JournalViewLoadMoreInvoices());
    }

    loadMissingInvoices() {
        this.store.dispatch(new actions.JournalViewLoadMissingInvoices());
    }

    selectAllInvoices() {
        this.store.dispatch(new actions.JournalViewSelectAllInvoices());
    }

    cleanInvoicesSelected(invoicesInitiallySelected: IInvoice[] = []) {
        this.store.dispatch(
            new actions.JournalViewCleanInvoicesSelected(invoicesInitiallySelected)
        );
    }

    toggleInvoice(invoice: IInvoice) {
        this.store.dispatch(new actions.JournalViewToggleInvoice(invoice));
    }

    payInvoicesSelected() {
        this.store.dispatch(new actions.JournalViewPayInvoicesSelected());
    }

    unpayInvoicesSelected() {
        this.store.dispatch(new actions.JournalViewUnpayInvoicesSelected());
    }

    deleteInvoicesSelected() {
        this.store.dispatch(new actions.JournalViewDeleteInvoicesSelected());
    }

    resetInvoicesSelected() {
        this.store.dispatch(new actions.JournalViewResetInvoicesSelected());
    }

    toggleBulkOperationMode() {
        this.store.dispatch(new actions.JournalViewToggleBulkOperationMode());
    }

    dropPaymentToInvoice(dropEvent: IDropPaymentToInvoice) {
        this.store.dispatch(new actions.JournalViewDropPaymentToInvoice(dropEvent));
    }

    editPayment(payment: IPayment) {
        this.store.dispatch(new actions.EditPayment(payment));
    }

    setExpandedInvoice(invoice: IInvoice) {
        this.store.dispatch(new actions.SetExpandedInvoice(invoice));
    }
}
