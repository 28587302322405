import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IBuildingModel } from '@zenhomes/domain/property';
import { sanitizeBuildingModel } from '../utilities/property-sanitize.util';

@Injectable({
    providedIn: 'root'
})
export class BuildingService {
    constructor(private http: HttpClient) {}

    getBuildingById(buildingId: string, params?: HttpParams): Observable<IBuildingModel> {
        return this.http.get(`/buildings/${buildingId}`, { params }).map(sanitizeBuildingModel);
    }

    saveBuilding(buildingProperty: any): Observable<IBuildingModel> {
        let action = 'post';
        let url = '/buildings';

        if (buildingProperty.id) {
            action = 'put';
            url += `/${buildingProperty.id}`;
        }

        return this.http[action](url, buildingProperty).map(sanitizeBuildingModel);
    }
}
