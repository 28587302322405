import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { USAGES_MODULE_INIT_STATE, reducers } from './state/usages-state';
import { UsagesEffects } from './state/effects/usages.effects';
import { UsagesApiService, UsagesService } from './services';

@NgModule({
    imports: [
        StoreModule.forFeature('UsagesCoreModule', reducers, {
            initialState: USAGES_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([UsagesEffects])
    ]
})
export class UsagesCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: UsagesCoreModule,
            providers: [UsagesApiService, UsagesService]
        };
    }
}
