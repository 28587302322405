import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IPropertyListViewInfo } from '@zenhomes/domain/property';

@Component({
    selector: 'zhm-property-list-label',
    templateUrl: './property-list-label.component.html',
    styleUrls: ['./property-list-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyListLabelComponent implements OnInit {
    @Input() propertyList: IPropertyListViewInfo[] = [];
    @Input() limit: number = 2;
    @Input() portfolioLabel: string = 'invoices.noProperty';

    constructor() {}

    ngOnInit() {}

    get isEveryItemPortfolio() {
        return this.propertyList.length > 0 && this.propertyList.every(item => item.isPortfolio);
    }

    get displayProperties() {
        if (this.isEveryItemPortfolio) {
            return [this.propertyList[0]];
        }

        return this.propertyList;
    }
}
