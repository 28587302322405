import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsActions } from '@zenhomes/analytics';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

const TEASER_STATE_KEY = 'zhm-teaser';

const ProductTeaserDismissed = 'product_teaser_dismissed';

interface TeaserState {
    [teaserName: string]: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TeaserStatusService {
    private teaserState: BehaviorSubject<TeaserState>;

    constructor(private store: Store<any>) {
        this.teaserState = new BehaviorSubject<TeaserState>(this.getState());
    }

    isTeaserActive$(teaserName: string) {
        return this.teaserState.pipe(
            map(teaserState => teaserState[teaserName] !== false),
            distinctUntilChanged()
        );
    }

    dismissTeaser(teaserName: string) {
        const newTeasersState = { ...this.getState(), [teaserName]: false };

        this.setState(newTeasersState);
        this.teaserState.next(newTeasersState);

        this.store.dispatch(
            new AnalyticsActions.TrackEvent(ProductTeaserDismissed, { teaser_name: teaserName })
        );
    }

    private getState() {
        try {
            return JSON.parse(sessionStorage.getItem(TEASER_STATE_KEY)) || {};
        } catch (e) {
            return {};
        }
    }

    private setState(state: any) {
        try {
            sessionStorage.setItem(TEASER_STATE_KEY, JSON.stringify(state || {}));
        } catch (e) {}
    }
}
