export enum Breaks {
    'baseFontSize' = '16px',
    'xlBreak' = '1200px',
    'lgBreak' = '992px',
    'lgScreen' = '"only screen and (max-width: 992px)"',
    'mdBreak' = '768px',
    'mdScreen' = '"only screen and (max-width: 768px)"',
    'smBreak' = '576px',
    'smScreen' = '"only screen and (max-width: 576px)"'
}

export enum SharedStyles {
    'defaultBorderRadius' = '4px',
    'pageMaxWidth' = '1300px',
    'topbarDesktopHeight' = '60px',
    'topbarTabletHeight' = '48px',
    'sidebarPropertyWidth' = '277px',
    'subcontentNavHeight' = '95px',
    'sidebarContentWidth' = '290px',
    'transition' = 'all .3s ease',
    'dialogShadow' = '0 8px 32px 0 rgba(0,0,0,0.15),0 2px 4px 0 rgba(0,0,0,0.15)',
    'unitShadow' = '0 2px 8px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.2)',
    'unitShadowHover' = '0 2px 8px 0 rgba(0,0,0,0.3),0 1px 2px 0 rgba(0,0,0,0.4)',
    'modalHeight' = '659px',
    'modalWidth' = '675px',
    'modalWidthBg' = '1000px',
    'footerHeight' = '4.375rem',
    'filterTabHeightDesktop' = '3.75rem',
    'filterTabHeightMobile' = '2.85rem',
    'fontXxsmall' = '.625rem',
    'fontXsmall' = '.75rem',
    'fontSmall' = '.875rem',
    'fontDefault' = '1rem',
    'fontLarge' = '1.125rem',
    'fontXlarge' = '1.375rem',
    'fontXxlarge' = '1.5rem',
    'exposeOverviewWidth' = '51.5rem',
    'exposeEditorWidth' = '27rem',
    'borderThin' = '0.0625rem'
}

export enum IconSizes {
    'iconXsmall' = '1rem',
    'iconSmall' = '1.5rem',
    'iconMedium' = '2rem',
    'iconLarge' = '3rem',
    'iconXlarge' = '4rem',
    'iconXxlarge' = '6rem'
}

export enum ZIndex {
    'navDesktopIndex' = '200',
    'navIndex' = '900',
    'overlayIndex' = '901',
    'zhmModalBackdropIndex' = '1040',
    'zhmModalIndex' = '1050',
    'pageFooterIndex' = '2',
    'dialogFooterIndex' = '10',
    'dropdownIndex' = '3',
    'loaderIndex' = '800',
    'exposeWizardOverlay' = '6000',
    'activeExposeWizardField' = '7000'
}

export enum CloseIconSizes {
    'small' = '0.625rem',
    'medium' = '1rem',
    'large' = '1.5rem'
}

export const LAYOUT_CONFIG = {
    NAVBAR_SIDEBAR_LOGO_HEIGHT: '6.25rem',
    SIDEBAR_COMPANY_LOGO_DEFAULT_WIDTH: '9.625rem'
};
