import {
    none,
    curry,
    eqBy,
    path,
    symmetricDifferenceWith,
    differenceWith,
    flatten,
    map,
    prop,
    compose,
    defaultTo,
    sum,
    length,
    lte,
    innerJoin
} from 'ramda';

import { IPayment, IClusteredPayment } from '@zenhomes/domain/payment';

const eqByBankTransferId = eqBy(path(['bankTransfer', 'id']));

export const isPaymentSelected = curry((payments: IPayment[], payment: IPayment) => {
    return payments.some(eqByBankTransferId(payment));
});

export const allPaymentsAreSelected = curry(
    (paymentsSelected: IPayment[], payments: IPayment[]) => {
        return payments.every(isPaymentSelected(paymentsSelected));
    }
);
export const hasNoPaymentsSelected = curry((paymentsSelected: IPayment[], payments: IPayment[]) => {
    return none(isPaymentSelected(paymentsSelected), payments);
});
export const getPaymentsUnselected = differenceWith(eqByBankTransferId);

export const togglePayments = (paymentsSelected: IPayment[], payments: IPayment[]): IPayment[] =>
    symmetricDifferenceWith(eqByBankTransferId, paymentsSelected, payments);

export const getPaymentsFromClusters: (clusters: IClusteredPayment[]) => IPayment[] = compose(
    flatten,
    map(prop('payments')),
    defaultTo([])
);

const getNumberOfPaymentsForCluster: (cluster: IClusteredPayment) => number = compose(
    length,
    prop('payments')
);

export const getNumberOfPaymentsForClusters: (clusters: IClusteredPayment[]) => number = compose(
    sum,
    map(getNumberOfPaymentsForCluster),
    defaultTo([])
);

const getTransferIdFromPayment: (payment: IPayment) => string = path(['bankTransfer', 'id']);
const getIdFromPayment: (payment: IPayment) => string = prop('id');

export const getTransferIdsFromPayments = (payments: IPayment[]) => {
    payments = defaultTo([], payments);

    return map(getTransferIdFromPayment, payments);
};

export const getIdsFromPayments = (payments: IPayment[]) => {
    payments = defaultTo([], payments);

    return map(getIdFromPayment, payments);
};

export const getPaymentsPresentIn = innerJoin(eqByBankTransferId);
