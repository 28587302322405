import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

export interface IOption {
    label: string;
    value: string;
}

@Component({
    selector: 'zhm-options-toggle',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'options-toggle.component.html',
    styleUrls: ['./options-toggle.component.scss']
})
export class OptionsToggleComponent {
    @Input() disabled: boolean;
    @Input() options: IOption[];
    @Input() option: IOption;

    @Output() optionChange: EventEmitter<IOption> = new EventEmitter();

    onOptionChange(opt: IOption) {
        if ((!this.disabled && this.option && this.option.value !== opt.value) || !this.option) {
            this.optionChange.emit(opt);
        }
    }
}
