import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    ICategorizationSubjectDictionary,
    ICategorizationUserCategoryDictionary,
    ICategorizationUserTopic
} from '@zenhomes/domain/category';
import { Observable } from 'rxjs/Observable';
import { ICategoryCoreState } from '../interfaces';
import * as fromCategoryCoreState from '../state';
import { filter, switchMapTo } from 'rxjs/operators';

@Injectable()
export class UserTopicsService {
    userTopics$: Observable<ICategorizationUserTopic[]> = this.store.select(
        fromCategoryCoreState.getUserTopics
    );
    userTopicsAreLoaded$: Observable<boolean> = this.store.select(
        fromCategoryCoreState.getUserTopicsAreLoaded
    );
    userTopicsAreLoading$: Observable<boolean> = this.store.select(
        fromCategoryCoreState.getUserTopicsAreLoading
    );

    userTopicsAfterLoading$: Observable<ICategorizationUserTopic[]>;

    subjectDictionary$: Observable<
        ICategorizationSubjectDictionary
    > = this.userTopicsAreLoaded$.pipe(
        filter(areLoaded => areLoaded),
        switchMapTo(this.store.select(fromCategoryCoreState.getSubjectDictionary))
    );

    userCategoryDictionary$: Observable<
        ICategorizationUserCategoryDictionary
    > = this.userTopicsAreLoaded$.pipe(
        filter(areLoaded => areLoaded),
        switchMapTo(this.store.select(fromCategoryCoreState.getUserCategoryDictionary))
    );

    constructor(private store: Store<ICategoryCoreState>, private actions$: Actions) {
        this.userTopicsAfterLoading$ = this.userTopicsAreLoaded$.pipe(
            filter(loaded => loaded),
            switchMapTo(this.userTopics$)
        );
    }
}
