import { Injectable, ComponentRef, ComponentFactoryResolver, Injector, Type } from '@angular/core';

@Injectable()
export class ComponentRefFactory {
    constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {}

    createComponentRef<T>(component: Type<T>): ComponentRef<T> {
        const componentFactory = this.resolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.injector);
        return componentRef;
    }
}
