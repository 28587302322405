import { ModuleWithProviders, NgModule } from '@angular/core';
import { ContractProviderService } from './services/contract-provider.service';

@NgModule({
    imports: []
})
export class ContractsCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ContractsCoreModule,
            providers: [ContractProviderService]
        };
    }
}
