import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export interface ITipBoxStep {
    type: string;
    sectionId: string;
}

@Component({
    selector: 'zhm-tip-box',
    templateUrl: './tip-box.component.html',
    styleUrls: ['./tip-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipBoxComponent implements OnInit {
    @Input() step: ITipBoxStep;

    constructor() {}

    ngOnInit() {}

    getAmountStepCopy() {
        if (this.step.sectionId === 'MANUAL_PAYMENT' || this.step.sectionId === 'PAYMENT_TYPE') {
            return 'accounting.tipbox.paymentForm.amountStep';
        }
        return 'accounting.tipbox.amountStep';
    }

    getSectionOverviewStepCopy() {
        switch (this.step.sectionId) {
            case 'COUNTER_PARTY':
                return 'accounting.tipbox.counterpartySectionOverview';
            case 'ATTACHED_TO':
                return 'accounting.tipbox.attachedToSectionOverview';
            case 'CATEGORIZATION':
                return 'accounting.tipbox.categorizationSectionOverview';
            case 'SPLIT_AMOUNT':
                return 'accounting.tipbox.splitAmountSectionOverview';
            case 'PAYMENT_TYPE':
                return 'accounting.tipbox.paymentTypeSectionOverview';
            case 'MANUAL_PAYMENT':
                return 'accounting.tipbox.manualPaymentSectionOverview';
            case 'BANK_PAYMENT':
                return 'accounting.tipbox.bankPaymentSectionOverview';
            case 'AGREEMENT':
                return 'accounting.tipbox.agreementSectionOverview';
        }
    }

    get stepTypeCopy() {
        switch (this.step.type) {
            case 'Dates':
                return 'accounting.tipbox.dueDateStep';
            case 'PaymentDate':
                return 'accounting.tipbox.paymentDateStep';
            case 'Subject':
                return 'accounting.tipbox.subjectStep';
            case 'Partner':
                return 'accounting.tipbox.partnerStep';
            case 'PaymentManualDesktop':
                return 'accounting.tipbox.paymentForm.manualFieldsDesktopStep';
            case 'Amount':
                return this.getAmountStepCopy();
            case 'Property':
                return 'accounting.tipbox.propertyStep';
            case 'ContractSelector':
                return 'accounting.tipbox.contractSelectorStep';
            case 'Topic':
                return 'accounting.tipbox.topicStep';
            case 'CategorySubject':
                return 'accounting.tipbox.categorySubjectStep';
            case 'SplitAmount':
                return 'accounting.tipbox.splitAmountStep';
            case 'InvoiceStatus':
                return 'accounting.tipbox.invoiceStatusStep';
            case 'InvoiceAndProperty':
                return 'accounting.tipbox.invoicePropertyStep';
            case 'AmountAssign':
                return 'accounting.tipbox.amountAssignStep';
            case 'Note':
                return 'accounting.tipbox.noteStep';
            case 'PaymentType':
                return 'accounting.tipbox.paymentTypeStep';
            case 'SelectTransfer':
                return 'accounting.tipbox.selectTransferStep';
            case 'Counterparty':
                return 'accounting.tipbox.counterpartyStep';
            case 'Remark':
                return 'accounting.tipbox.remarkStep';
            case 'TimePeriod':
                return 'accounting.tipbox.timerPeriodStep';
            case 'PaymentConditions':
                return 'accounting.tipbox.paymentConditionsStep';
            case 'PaymentSelection':
                return 'accounting.tipbox.paymentSelectionStep';
            case 'SectionOverviewStep':
                return this.getSectionOverviewStepCopy();
            default:
                return '';
        }
    }
}
