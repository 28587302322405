import { HttpErrorResponse } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { Action } from '@ngrx/store';
import { typeWithNamespace } from '@util/type-cache.util';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IPayment, IPaymentItem } from '@zenhomes/domain/payment';
import { BookmarkableFilterOptions } from '@zenhomes/filter-core';
import { IDropPaymentToInvoice } from '@zenhomes/invoice-core';
import { IContract } from '@zenhomes/domain/contract';
import { IPropertySelection } from '@zenhomes/domain/property';

const JOURNAL_VIEW = 'JournalView';

export class JournalViewLoadInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load Journal View Invoices');
    readonly type = JournalViewLoadInvoices.TYPE;
    constructor() {}
}

export class JournalViewLoadInvoicesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load Journal View Invoices success');
    readonly type = JournalViewLoadInvoicesSuccess.TYPE;
    constructor(public payload: PageableElementsModel<IInvoice>) {}
}

export class JournalViewLoadInvoicesFail implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load Journal View Invoices fail');
    readonly type = JournalViewLoadInvoicesFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class JournalViewReset implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Reset Journal View Invoices');
    readonly type = JournalViewReset.TYPE;
    constructor() {}
}

export class JournalViewUpdateListFilters implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Update journal view list filters');
    readonly type = JournalViewUpdateListFilters.TYPE;
    constructor(public filterOptions: BookmarkableFilterOptions) {}
}

export class JournalViewClearListFilters implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Clear journal view list filters');
    readonly type = JournalViewClearListFilters.TYPE;
    constructor() {}
}

export class JournalViewUpdateParseFiltersFromQueryString implements Action {
    static readonly TYPE = typeWithNamespace(
        JOURNAL_VIEW,
        'Parse journal view filters from query string'
    );
    readonly type = JournalViewUpdateParseFiltersFromQueryString.TYPE;
    constructor(public paramMap: ParamMap) {}
}

export class JournalViewRefreshQueryString implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Refresh query string');
    readonly type = JournalViewRefreshQueryString.TYPE;
    constructor() {}
}

export class JournalViewLoadMoreInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load more invoices');
    readonly type = JournalViewLoadMoreInvoices.TYPE;
    constructor() {}
}

export class JournalViewLoadMoreInvoicesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load more invoices success');
    readonly type = JournalViewLoadMoreInvoicesSuccess.TYPE;
    constructor(public payload: PageableElementsModel<IInvoice>) {}
}

export class JournalViewLoadMoreInvoicesFail implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load more invoices fail');
    readonly type = JournalViewLoadMoreInvoicesFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class JournalViewReloadInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Reload invoices');
    readonly type = JournalViewReloadInvoices.TYPE;
    constructor() {}
}

export class JournalViewToggleInvoice implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Toggle invoice');
    readonly type = JournalViewToggleInvoice.TYPE;

    constructor(public payload: IInvoice) {}
}

export class JournalViewSelectAllInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Select all invoices');
    readonly type = JournalViewSelectAllInvoices.TYPE;

    constructor() {}
}

export class JournalViewCleanInvoicesSelected implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Clean invoices selected');
    readonly type = JournalViewCleanInvoicesSelected.TYPE;

    constructor(public payload: IInvoice[]) {}
}

export class JournalViewLoadMissingInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Load Missing Invoices');
    readonly type = JournalViewLoadMissingInvoices.TYPE;

    constructor() {}
}

export class JournalViewPayInvoicesSelected implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Pay invoices selected');
    readonly type = JournalViewPayInvoicesSelected.TYPE;

    constructor() {}
}

export class JournalViewPayInvoicesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Pay Journal View Invoices success');
    readonly type = JournalViewPayInvoicesSuccess.TYPE;
    constructor() {}
}

export class JournalViewPayInvoicesFail implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Pay Journal View Invoices fail');
    readonly type = JournalViewPayInvoicesFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class JournalViewUnpayInvoicesSelected implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Unpay invoices selected');
    readonly type = JournalViewUnpayInvoicesSelected.TYPE;

    constructor() {}
}

export class JournalViewUnpayInvoicesSuccess implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Unpay Journal View Invoices success');
    readonly type = JournalViewUnpayInvoicesSuccess.TYPE;
    constructor() {}
}

export class JournalViewUnpayInvoicesFail implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Unpay Journal View Invoices fail');
    readonly type = JournalViewUnpayInvoicesFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}

export class JournalViewDeleteInvoicesSelected implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Delete invoices selected');
    readonly type = JournalViewDeleteInvoicesSelected.TYPE;

    constructor() {}
}

export class JournalViewResetInvoicesSelected implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Reset invoices selected');
    readonly type = JournalViewResetInvoicesSelected.TYPE;

    constructor() {}
}

export class JournalViewUpdateShouldLoadMissingInvoices implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Update should load missing invoices');
    readonly type = JournalViewUpdateShouldLoadMissingInvoices.TYPE;

    constructor(public payload: boolean) {}
}

export class JournalViewToggleBulkOperationMode implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Toggle bulk operation mode');
    readonly type = JournalViewToggleBulkOperationMode.TYPE;

    constructor() {}
}

export class JournalViewDropPaymentToInvoice implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Drop payment to invoice');
    readonly type = JournalViewDropPaymentToInvoice.TYPE;

    constructor(public payload: IDropPaymentToInvoice) {}
}

export class JournalViewDropPaymentToInvoiceSuccess implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Drop payment to invoice success');
    readonly type = JournalViewDropPaymentToInvoiceSuccess.TYPE;

    constructor() {}
}

export class JournalViewDropPaymentToInvoiceFail implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Drop payment to invoice fail');
    readonly type = JournalViewDropPaymentToInvoiceFail.TYPE;

    constructor() {}
}

export class JournalViewAddPaymentItemOptimistic implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Add payment item optimistic');
    readonly type = JournalViewAddPaymentItemOptimistic.TYPE;

    constructor(public payload: { targetInvoiceId: string; paymentItem: IPaymentItem }) {}
}

export class JournalViewAddPaymentItemOptimisticUndo implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Add payment item optimistic undo');
    readonly type = JournalViewAddPaymentItemOptimisticUndo.TYPE;

    constructor() {}
}

export class JournalViewDeletePaymentItemOptimistic implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Delete payment item optimistic');
    readonly type = JournalViewDeletePaymentItemOptimistic.TYPE;

    constructor(public paymentId: string) {}
}

export class EditPayment implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Edit payment');
    readonly type = EditPayment.TYPE;

    constructor(public payment: IPayment) {}
}

export class SetExpandedInvoice implements Action {
    static readonly TYPE = typeWithNamespace(JOURNAL_VIEW, 'Set expanded invoice');
    readonly type = SetExpandedInvoice.TYPE;

    constructor(public invoice: IInvoice) {}
}
