import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'zhm-wid-numeric-spinner',
    templateUrl: 'numeric-spinner.widget.component.html',
    styleUrls: ['./numeric-spinner.widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericSpinnerWidgetComponent),
            multi: true
        }
    ]
})
export class NumericSpinnerWidgetComponent implements ControlValueAccessor {
    @Input() spinnable: boolean = true;
    @Input() clearable: boolean = true; // whether can be cleaned after the value was set
    @Input() id: string = '';
    @Input() class: string = '';
    @Input() value: string = '';
    @Input() placeholder: string = '';
    @Input() mult: string = '';
    @Input() step: string = '1';
    @Input() min: string = '1';
    @Input() max: string = '100';
    @Input() aliases = {};
    @Input() readonly: boolean;
    @Input() disabled: boolean;
    @Input() isRequired: boolean = false;

    @Output() onInput = new EventEmitter();
    @Output() onValueChanged = new EventEmitter();
    @Output() blur = new EventEmitter();
    @Output() focus = new EventEmitter();

    @Input()
    set numericValue(value: string) {
        this.setFormValue(value);
    }
    @Output() numericValueChange = new EventEmitter();

    @ViewChild('input', { static: true }) inp: ElementRef;

    private val: string;
    private old = '';

    propagateChange: any = (_: any) => {};

    ngOnInit(): void {
        this.setValue(this.normalize(this.getFormValue() ? this.getFormValue() : this.value || ''));
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        if (typeof value !== undefined) {
            this.setValue(value);
        }
    }

    onBlur() {
        if (!this.getFormValue() && this.isRequired) {
            this.setValue(this.normalize(this.old));
        } else {
            this.setValue(this.normalize());
        }

        this.blur.emit();
    }

    onFocus() {
        this.focus.emit();
    }

    getMin(): number {
        return !isNaN(parseFloat(this.min)) ? parseFloat(this.min) : 1;
    }

    getMax(): number {
        return !isNaN(parseFloat(this.max)) ? parseFloat(this.max) : 100;
    }

    getMult(): number {
        let r = parseFloat(this.mult);
        return !isNaN(r) && r > 0 ? r : this.getStep();
    }

    getStep(): number {
        let r = parseFloat(this.step);
        return !isNaN(r) && r > 0 ? r : 1;
    }

    setStep(step: number): void {
        this.step = step.toString();
        this.setValue(this.getFormValue());
        this.normalize();
    }

    decrement(): void {
        if (this.disabled) return;

        this.change(-this.getStep());
    }

    increment(): void {
        if (this.disabled) return;

        this.change(this.getStep());
    }

    getFormValue(): string {
        return this.convertFromFormValue(this.inp.nativeElement.value);
    }

    setFormValue(value: string) {
        this.inp.nativeElement.value = this.convertToFormValue(value);
    }

    getValue(): number {
        return parseFloat(this.getFormValue());
    }

    setValue(value: string) {
        this.old = this.val; // this.getFormValue();

        let dec = function(num: number) {
            return (num.toString().split('.')[1] || []).length;
        };

        if (
            this.isValid(this.convertFromFormValue(value))
            // || ( value !== null && this.getFormValue().length >= value.length && this.getFormValue().indexOf(value) === 0 )
        ) {
            this.val = this.convertFromFormValue(value);
        }

        let tmp = parseFloat(this.val);

        // document.title = ( dec(tmp) > dec(this.getMult()) ) + ' ### ' +  Math.random().toString();

        // this.inp.setValue(...) in case of FormControl
        this.setFormValue(
            !isNaN(tmp) && (dec(tmp) > dec(this.getMult()) || dec(this.getMult()) === 0)
                ? tmp.toFixed(dec(this.getMult()))
                : this.val
        );

        this.onInput.emit({ target: { value: this.val } });

        if (this.old !== this.getFormValue()) {
            this.onValueChanged.emit(this);

            const valueToPropagate = !isNaN(tmp) ? tmp : null;
            this.propagateChange(valueToPropagate);
            this.numericValueChange.emit(valueToPropagate);
        }

        // propagate whatever
    }

    private change(by: number): void {
        let tmp = this.normalize(this.getFormValue());
        let val = parseFloat(this.normalize((parseFloat(tmp || '0') + 0 + by).toString()));

        this.setValue(
            tmp === this.getFormValue()
                ? val < this.getMin() || val > this.getMax()
                    ? this.normalize(
                          val < this.getMin() ? this.getMin().toString() : this.getMax().toString()
                      )
                    : val.toString()
                : tmp
        );
    }

    private isValid(value: string): boolean {
        let min = this.getMin();
        let max = this.getMax();
        let val = parseFloat(value);
        let dot =
            value !== null && value !== undefined
                ? value
                      .toString()
                      .substring(val.toString().length)
                      .match(/^\.?0*/)
                : '';

        let r =
            (value !== null && value !== undefined && !value.length) ||
            (value === val.toString() + dot &&
                (val.toString() + dot).split('.').length <= 2 &&
                val >= min &&
                val <= max);

        // console.log('??? ' + value + ' >> ' + val + ' >> ' + dot + ' >> ' + r);

        return r;
    }

    private normalize(value: string = null): string {
        let tmp = parseFloat(value !== null ? value : this.getFormValue());
        let dec = function(num: number) {
            return (num.toString().split('.')[1] || []).length;
        };
        let val = !isNaN(tmp)
            ? tmp -
              (parseFloat((tmp / this.getMult()).toFixed(dec(this.getMult()))) % 1) * this.getMult()
            : null;
        let str = val !== null ? val.toFixed(Math.min(dec(val), dec(this.getMult()))) : value;

        // console.log('>>> ' + tmp + ' | ' + val + ' | ' + str + ' | ' + Math.min( dec(val), dec(this.getMult()) ));

        // toFixed( Math.min( dec(/*val*/this.getMult()), dec(this.getMult()) ) ) )
        return val !== null && tmp > val
            ? parseFloat((parseFloat(str) + this.getMult()).toFixed(dec(this.getMult()))).toString()
            : str;
    }

    convertFromFormValue(str: string): string {
        let r = str !== null && typeof str !== 'undefined' ? str.toString().replace(',', '.') : '';

        for (let k in this.aliases) {
            // make sure that only .replace will be applied if it matches with the start of the string
            // to avoid this case: 0 -> EG and 10 -> 1EG
            const regex = new RegExp(`^${k}`);
            r = r.replace(regex, this.aliases[k]);
        }
        return r;
    }

    convertToFormValue(str: string) {
        let r = str !== null && typeof str !== 'undefined' ? str.toString().replace('.', ',') : '';

        for (let k in this.aliases) {
            // make sure that .replace will be applied if it matches with the start of the string
            // to avoid this case: 0 -> EG and 10 -> 1EG
            const regex = new RegExp(`^${this.aliases[k]}`);
            r = r.replace(regex, k);
        }

        return r;
    }
}
