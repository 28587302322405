import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AddressMapComponent } from './components/address-map/address-map.component';
import { GoogleAddressDropdownToggleComponent } from './components/google-address-dropdown-toggle/google-address-dropdown-toggle.component';
import { GoogleAddressDropdownComponent } from './components/google-address-dropdown/google-address-dropdown.component';
import { GoogleAddressAutocompleteDirective } from './directives/google-address-autocomplete.directive';
import { IMapsModuleConfig, MapsConfigToken } from './maps.config';
import { GoogleMapsLoaderService } from './services/google-maps-loader.service';

const EXPORTED_COMPONENTS = [
    AddressMapComponent,
    GoogleAddressAutocompleteDirective,
    GoogleAddressDropdownComponent
];

@NgModule({
    declarations: [...EXPORTED_COMPONENTS, GoogleAddressDropdownToggleComponent],
    imports: [CommonModule, TranslateModule],
    exports: [...EXPORTED_COMPONENTS]
})
export class MapsModule {
    static forRoot(config: IMapsModuleConfig): ModuleWithProviders {
        return {
            ngModule: MapsModule,
            providers: [{ provide: MapsConfigToken, useValue: config }, GoogleMapsLoaderService]
        };
    }
}
