import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListFilterOptions } from '@zenhomes/domain/pagination';
import { PageableElementsModel } from '@zenhomes/domain/pagination';
import { convertObjectToHttpParams } from '@util/convert-object-to-http-params.util';
import {
    IExpenseContractDraft,
    IExpenseContract,
    IExpenseContractMetadata,
    IExpenseContractDatesValidationResponse,
    IExpenseContractDatesValidationRequest,
    INewExpenseContractDatesValidationRequest
} from '@zenhomes/domain/contract';
import { Observable } from 'rxjs/Observable';
import {
    BookmarkableFilterOptions,
    formAmountParams,
    formCounterpartyIdsParams
} from '@zenhomes/filter-core';
import { map } from 'lodash';

@Injectable()
export class ExpenseContractsApiService {
    constructor(private http: HttpClient) {}

    loadContracts(
        filterOptions: ListFilterOptions = {}
    ): Observable<PageableElementsModel<IExpenseContract>> {
        let params = formGetExpenseContractsParams(filterOptions);

        return this.http
            .get<any>(`/contracts/expense`, { params })
            .map((response: any) => new PageableElementsModel<IExpenseContract>(response));
    }

    getContract(contractId: string): Observable<IExpenseContract> {
        return this.http.get<IExpenseContract>(`/contracts/${contractId}`);
    }

    createContract(contract: IExpenseContractDraft): Observable<IExpenseContract> {
        return this.http.post<IExpenseContract>(`/contracts`, contract);
    }

    validateNewContractDates(
        contract: INewExpenseContractDatesValidationRequest
    ): Observable<IExpenseContractDatesValidationResponse> {
        return this.http.post<IExpenseContractDatesValidationResponse>(
            `/contracts/validation/dates-for-new-contract`,
            contract
        );
    }

    validateContractDates(
        contract: IExpenseContractDatesValidationRequest,
        contractId: string
    ): Observable<IExpenseContractDatesValidationResponse> {
        return this.http.post<IExpenseContractDatesValidationResponse>(
            `/contracts/${contractId}/validation/new-contract-dates`,
            contract
        );
    }

    updateContract(contract: IExpenseContractMetadata): Observable<IExpenseContract> {
        return this.http.put<IExpenseContract>(`/contracts/${contract.id}`, contract);
    }

    deleteContract(contractId: string): Observable<IExpenseContract> {
        return this.http.delete<IExpenseContract>(`/contracts/${contractId}`);
    }
}

function formGetExpenseContractsParams(
    filterOptions: Partial<BookmarkableFilterOptions>
): HttpParams {
    const {
        page = '0',
        size = '10',
        propertyBuildingId = '',
        propertyUnitId = '',
        search = '',
        category = '',
        sortTransferDate = '',
        autoMatchable = '',
        excludeUnits = '',
        startDate = '',
        endDate = ''
    } = filterOptions || {};

    const { amountFrom, amountTo } = formAmountParams(filterOptions || {});
    const { counterpartyId } = formCounterpartyIdsParams(filterOptions || {});
    const subjectId = filterOptions && map(filterOptions.subjectSelection, subject => subject.id);

    const params = convertObjectToHttpParams({
        page,
        size,
        buildingId: propertyBuildingId,
        unitId: propertyUnitId,
        search,
        category,
        sortTransferDate,
        startDate,
        endDate,
        amountFrom,
        amountTo,
        autoMatchable: autoMatchable + '',
        excludeUnits: excludeUnits,
        unitFilterPriority: !!propertyUnitId,
        includeSubjectId: subjectId,
        counterpartyId
    });

    return params;
}
