import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IPropertiesEndpointResponse } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class PropertiesApiService {
    constructor(private http: HttpClient) {}

    loadProperties(): Observable<IPropertiesEndpointResponse> {
        return this.http.get<IPropertiesEndpointResponse>('/properties');
    }
}
