import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { parsePropertyParam } from '@zenhomes/property-core';
import { IPropertyNavigationParams } from '@zenhomes/domain/property';
import { PROPERTY_NAVIGATION_PARAM } from '../navigation.constants';

@Injectable()
export class PropertyNavigationParamsResolver implements Resolve<IPropertyNavigationParams> {
    resolve(route: ActivatedRouteSnapshot) {
        const propertyNavigationParam = this.getParamFromRoute(route, PROPERTY_NAVIGATION_PARAM);

        return parsePropertyParam(propertyNavigationParam);
    }

    getParamFromRoute(route: ActivatedRouteSnapshot, paramKey: string) {
        return (
            this.getRouteParam(route, paramKey) ||
            this.getRouteParam(route.parent, paramKey) ||
            this.getRouteParam(route.parent && route.parent.parent, paramKey)
        );
    }

    getRouteParam(route: ActivatedRouteSnapshot, paramKey: string) {
        return route && route.params[paramKey];
    }
}
