import { Injectable } from '@angular/core';
import { isEmpty, keyBy, cloneDeep } from 'lodash';

interface Language {
    language: string;
    locale: string;
}

const LANGUAGES: Language[] = [
    {
        language: 'de',
        locale: 'de-EU'
    },
    {
        language: 'en',
        locale: 'en-US'
    }
];

const LANGUAGES_DICT = keyBy(LANGUAGES, 'language');

@Injectable()
export class LanguageService {
    languages: Language[] = cloneDeep(LANGUAGES);
    private currentLanguage: Language;

    constructor() {
        this.currentLanguage = this.getCurrentLanguage();
    }

    getLanguage(): string {
        return this.currentLanguage.language;
    }

    getLocale(): string {
        return this.currentLanguage.locale;
    }

    private getConfiguredLanguage(): string {
        try {
            const nativeWindow = window;

            const localStorage = nativeWindow.localStorage;

            const languageFromLocalStorage = localStorage.getItem('zhmLanguage');

            return languageFromLocalStorage;
        } catch (error) {
            return LANGUAGES[0].language;
        }
    }

    private getCurrentLanguage(): Language {
        const languageFromLocalStorage: string = this.getConfiguredLanguage();

        return !isEmpty(languageFromLocalStorage) &&
            !isEmpty(LANGUAGES_DICT[languageFromLocalStorage])
            ? LANGUAGES_DICT[languageFromLocalStorage]
            : LANGUAGES[0];
    }
}
