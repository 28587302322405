import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import * as fromTransactionsState from '../state';
import * as fromBankAccountsActions from '../state/actions/bank-accounts.actions';
import { BankAccount } from '../interfaces';

@Injectable()
export class BankAccountsService {
    bankAccounts$: Observable<BankAccount[]> = this.store.select(
        fromTransactionsState.getBankAccountsList
    );
    bankAccountsAreLoaded$: Observable<boolean> = this.store.select(
        fromTransactionsState.getBankAccountsAreLoaded
    );
    bankAccountsAfterLoading$: Observable<BankAccount[]>;
    thereAreNoBankAccounts$: Observable<boolean>;

    constructor(private store: Store<any>) {
        this.bankAccountsAfterLoading$ = this.bankAccountsAreLoaded$
            .filter(loaded => loaded)
            .switchMapTo(this.bankAccounts$);

        this.thereAreNoBankAccounts$ = this.bankAccountsAfterLoading$.map(bankAccounts =>
            isEmpty(bankAccounts)
        );
    }

    loadBankAccounts() {
        this.store.dispatch(new fromBankAccountsActions.BankAccountsLoadBankAccounts());
    }
}
