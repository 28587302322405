import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { CloseIconSizes, Colors } from '@zenhomes/ui';

@Component({
    selector: 'zhm-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseButtonComponent implements OnInit {
    @Input() iconSize: string;
    @Input() color: string;

    defaultColor: string = Colors.blackBase900;
    defaultSize: string = CloseIconSizes.medium;

    @Output() closeClicked = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    onClose() {
        this.closeClicked.emit();
    }
}
