import { Pipe, PipeTransform } from '@angular/core';
import { IClusteredPayment } from '@zenhomes/domain/payment';
import { TranslateService } from '@ngx-translate/core';
import { getPurposeString } from '@payment-core/utils/clustered-payments.util';

@Pipe({
    name: 'clusterPurpose'
})
export class ClusterPurposePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: IClusteredPayment): any {
        const getTranslatedWarning = (purposeCount: number) =>
            this.translateService.instant('payments.deviantPurposesWarning', { purposeCount });

        return value && value.payments
            ? getPurposeString(getTranslatedWarning, value.payments)
            : '';
    }
}
