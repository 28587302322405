import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Tenancy } from '@zenhomes/usages';

@Component({
    selector: 'zhm-tenancy-selector-dropdown',
    templateUrl: './tenancy-selector-dropdown.component.html',
    styleUrls: ['./tenancy-selector-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenancySelectorDropdownComponent {
    @Input() tenancies: Tenancy[];
    @Input() model: Tenancy;
    @Input() emptyPlaceholder: string = 'placeholder.noSelection';
    @Output() onSelect: EventEmitter<any> = new EventEmitter();

    select(tenancy: Tenancy) {
        this.onSelect.emit(tenancy);

        this.model = tenancy;
    }

    tenancyTrackBy(index: any, item: any) {
        return item.id;
    }
}
