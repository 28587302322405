import { Pipe, PipeTransform } from '@angular/core';
import { IPayment, IClusteredPayment } from '@zenhomes/domain/payment';
import { getPaymentNote } from '@payment-core/utils/clustered-payments.util';
import { isEmpty } from 'lodash';

@Pipe({ name: 'paymentComment' })
export class PaymentCommentPipe implements PipeTransform {
    transform(payment: IPayment | IClusteredPayment, defaultPlaceholder: string = '-'): string {
        if (isEmpty(payment)) return defaultPlaceholder;

        return getPaymentNote(payment) || defaultPlaceholder;
    }
}
