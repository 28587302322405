import { Pipe, PipeTransform } from '@angular/core';
import { ICategorizationUserTopic } from '@zenhomes/domain/category';

export const DEFAULT_ICON = 'icon-money-thin';

@Pipe({ name: 'userTopicIcon' })
export class UserTopicIconPipe implements PipeTransform {
    transform(topic: ICategorizationUserTopic): string {
        switch (topic && topic.id) {
            case '1': {
                return '/illustrations/illustration_miete_kaution.svg';
            }
            case '2': {
                return '/illustrations/illustrations_betriebs_nebenkosten.svg';
            }
            case '3': {
                return '/illustrations/illustration_reparatur_wartung.svg';
            }
            case '4': {
                return '/illustrations/illustration_anschaffung_inventar.svg';
            }
            case '5': {
                return '/illustrations/illustration_Renovierung_Ausbau.svg';
            }
            case '6': {
                return '/illustrations/illustration_ankauf_errichtung.svg';
            }
            case '7': {
                return '/illustrations/illustration_finanzierung_kredite.svg';
            }
            case '8': {
                return '/illustrations/illustration_sonstige_einnahmen.svg';
            }
            default: {
                return DEFAULT_ICON;
            }
        }
    }
}
