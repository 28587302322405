export const UrlParamRefTeaser = 'ref_teaser';

export enum ProductSlug {
    JointSubscription = 'joint_subscription',
    BasicSubscription = 'basic_subscription',
    PhotoService = 'fotobearbeitung',
    FloorPlan = 'grundriss',
    EnergyCertificate = 'energieausweis',
    RentDebtCertificate = 'mietschuldenfreiheitsbescheinigung',
    HandoverProtocol = 'handover_protocol'
}
