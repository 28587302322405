import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AuthService } from '@zenhomes/auth';
import { RendererProxyService } from '@zenhomes/device';
import { DeviceService } from '@zenhomes/device';
import { UserService } from '@zenhomes/user-core';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    signedIn: boolean;
    private sub: Subscription;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private deviceService: DeviceService,
        private renderer: Renderer2,
        private rendererProxyService: RendererProxyService
    ) {
        this.sub = this.userService.user$
            .map(user => !isEmpty(user))
            .subscribe(signedIn => {
                this.signedIn = signedIn;
            });
    }

    ngOnInit() {
        this.deviceService.setDeviceInfoOnBody();
        this.authService.checkInitialState();
        this.rendererProxyService.renderer = this.renderer;
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }
}
