import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tenantsNames'
})
export class TenantsNamesPipe implements PipeTransform {
    transform(tenants: any[]): string {
        return (tenants || [])
            .map(item => item.fullName || item.name || item.companyName || item.email)
            .join(', ');
    }
}
