import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    forwardRef,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs/Subscription';

export interface RadioGroupOption {
    id: string;
    label: string;
}

@Component({
    selector: 'zhm-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls: ['./radio-button-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonGroupComponent),
            multi: true
        }
    ]
})
export class RadioButtonGroupComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() options: RadioGroupOption[];

    control: FormControl;

    propagateChange = (_: any) => {};
    registerOnTouched(fn: any): void {}

    private sub: Subscription;

    ngOnInit(): void {
        this.control = new FormControl();

        this.sub = this.control.valueChanges
            .do((optionId: string) => this.propagateChange(optionId))
            .subscribe();
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }

    writeValue(value: string) {
        this.control.setValue(!isEmpty(value) ? value : null, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
}
