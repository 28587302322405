import { Injectable } from '@angular/core';
import { DeviceService } from './device.service';

declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage?: Function;
        };
    }
}

@Injectable()
export class WindowMessagingService {
    constructor(private deviceService: DeviceService) {}

    sendMessage(message: string, payload?: any) {
        if (payload) {
            this.sendMessageWithPayload(message, payload);
        } else {
            this.sendMessageWithoutPayload(message);
        }
    }

    private sendMessageWithPayload(message: string, payload: any) {
        if (this.deviceService.isIos()) {
            this.reactNativePostMessage({ message, payload });
        }

        this.postMessageWebView({ message, payload });

        window.postMessage(JSON.stringify({ message, payload }), '*');
    }

    private sendMessageWithoutPayload(message: string) {
        if (this.deviceService.isIos()) {
            this.reactNativePostMessage({ message });
        }

        this.postMessageWebView({ message });

        window.postMessage(message, '*');
    }

    private postMessageWebView(message: string | object) {
        try {
            if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
                window.ReactNativeWebView.postMessage(JSON.stringify(message));
            }
        } catch (error) {
            console.log('Error on ReactNativeWebView.postMessage');
        }
    }

    /**
     * @deprecated This method will be removed once React Native apps are updated
     */
    private reactNativePostMessage(messagePayload: any) {
        const webkit = (window as any).webkit;

        const reactNative = webkit && webkit.messageHandlers && webkit.messageHandlers.reactNative;

        if (reactNative && reactNative.postMessage) {
            reactNative.postMessage(messagePayload);
        }
    }
}
