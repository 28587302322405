import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';
import { isSelectedById, toggleById } from '@zenhomes/sauron';
import { expandUnknownHeight } from '../../animations/expand-unknown-height.animation';
import {
    ICategorizationSubject,
    ICategorizationUserCategory,
    ICategorizationUserCategoryDictionary
} from '@zenhomes/domain/category';
import { filter, some } from 'lodash';
import { CategoryBadgeMode } from '../subject-item/subject-item.component';
import { UserTopicsService } from '../../services/user-topics.service';
import { getSubjectsForUserCategories } from '../../utilities/categorization-grouping.util';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'zhm-category-subject-selector',
    templateUrl: './category-subject-selector.component.html',
    styleUrls: ['./category-subject-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [expandUnknownHeight('300ms', '150ms', '0s', 'ease-in', 'translateY(0)')]
})
export class CategorySubjectSelectorComponent implements OnInit, OnDestroy {
    @Input() categorySelectable: boolean = false;
    @Input() selectAllLabel: string;
    @Input() categoryBadgeMode: CategoryBadgeMode = CategoryBadgeMode.Accounting;
    @Input() showUserTopic: boolean = false;
    @Input() availableUserCategories: ICategorizationUserCategory[] = [];

    @Input() subjects: ICategorizationSubject[];
    @Output() subjectsChange = new EventEmitter<ICategorizationSubject[]>();
    @Output() subjectSelect = new EventEmitter<ICategorizationSubject>();
    @Output() subjectDeselect = new EventEmitter<ICategorizationSubject>();

    @Input() categories: ICategorizationUserCategory[] = [];
    @Output() categoriesChange = new EventEmitter<ICategorizationUserCategory[]>();
    @Output() categorySelect = new EventEmitter<ICategorizationUserCategory>();
    @Output() categoryDeselect = new EventEmitter<ICategorizationUserCategory>();

    userCategoryDictionary: ICategorizationUserCategoryDictionary;
    expandedCategory: { [categoryId: string]: boolean } = {};

    private sub: Subscription;

    constructor(private userTopicsService: UserTopicsService) {}

    ngOnInit() {
        this.sub = this.userTopicsService.userCategoryDictionary$.subscribe(
            userCategoryDictionary => {
                this.userCategoryDictionary = userCategoryDictionary;
            }
        );

        this.initExpandedCategories();
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }

    trackById(index: number, item: any) {
        return item.id;
    }

    getUserTopic$(category: ICategorizationUserCategory) {
        return this.userTopicsService.userCategoryDictionary$.map(
            userCategoryDictionary => userCategoryDictionary[category.id].userTopic
        );
    }

    initExpandedCategories() {
        this.availableUserCategories.forEach(category => {
            if (this.isCategoryExpanded(category)) {
                this.expandedCategory[category.id] = true;
            }
        });
    }

    isCategoryExpanded(category: ICategorizationUserCategory) {
        return (
            this.isCategorySelected(category) ||
            some(this.subjects, subject => isSelectedById(category.subjects, subject))
        );
    }

    toggle(categoryId: string) {
        this.expandedCategory[categoryId] = !this.isExpanded(categoryId);
    }

    isExpanded(categoryId: string): boolean {
        return !!this.expandedCategory[categoryId];
    }

    isSubjectSelected(subject: ICategorizationSubject) {
        return isSelectedById(this.subjects, subject);
    }

    isCategorySelected(category: ICategorizationUserCategory) {
        return isSelectedById(this.categories, category);
    }

    onSubjectPressed(subject: ICategorizationSubject) {
        if (this.isSubjectSelected(subject)) {
            this.subjectDeselect.emit(subject);
        } else {
            this.subjectSelect.emit(subject);
        }

        const newSubjects = toggleById(this.subjects, subject);
        this.subjectsChange.emit(newSubjects);
    }

    onCategoryPressed(category: ICategorizationUserCategory) {
        if (this.isCategorySelected(category)) {
            this.categoryDeselect.emit(category);
        } else {
            this.categorySelect.emit(category);
        }

        const newCategories = toggleById(this.categories, category);
        this.categoriesChange.emit(newCategories);
    }

    getNumberOfSubjectSelectedForCategory(category: ICategorizationUserCategory) {
        if (this.isCategorySelected(category)) {
            return getSubjectsForUserCategories([category], this.userCategoryDictionary).length;
        } else {
            return filter(category.subjects, subject => this.isSubjectSelected(subject)).length;
        }
    }
}
