import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined, isNaN, isNull } from 'lodash';

@Pipe({
    name: 'day'
})
export class DayPipe implements PipeTransform {
    transform(value: number, args: string[] = [], locale: string = 'de-DE'): string {
        return !isUndefined(value) && !isNaN(value) && !isNull(value) ? `${value}.` : '';
    }
}
