import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { IContact } from '@zenhomes/domain/contact';
import { MessagesService } from '@zenhomes/toast-messages';
import * as fromUserActions from '@zenhomes/user-core';
import { range } from 'lodash';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map, mergeMap, switchMap, tap, zip } from 'rxjs/operators';
import { ContactsApiService } from '../../services/contacts.api.service';
import * as fromContactsActions from '../actions/contacts.actions';

const PAGE_SIZE = '100';

@Injectable()
export class ContactsEffects {
    constructor(
        private contactsApiService: ContactsApiService,
        private messagesService: MessagesService,
        private actions$: Actions
    ) {}

    @Effect()
    loadContactsOnLogin$: Observable<Action> = this.actions$.pipe(
        ofType(fromUserActions.userActionTypes.USER_LOAD_USER_SUCCESS),
        map(() => new fromContactsActions.ContactsLoadContacts())
    );

    @Effect()
    resetTheStateOnLogout$: Observable<Action> = this.actions$.pipe(
        ofType(fromUserActions.userActionTypes.USER_RESET),
        map(() => new fromContactsActions.ContactsReset())
    );

    @Effect()
    loadContacts$: Observable<Action> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsLoadContacts.TYPE),
        switchMap(() => {
            return this.contactsApiService.loadContacts({ size: PAGE_SIZE }).pipe(
                mergeMap(({ items, pagination }) => {
                    const loadContactsSuccess = new fromContactsActions.ContactsLoadContactsSuccess(
                        items
                    );

                    if (pagination.last) {
                        return [loadContactsSuccess];
                    }

                    return [
                        loadContactsSuccess,
                        new fromContactsActions.ContactsLoadRemainingContacts(pagination.totalPages)
                    ];
                }),
                catchError((error: HttpErrorResponse) =>
                    Observable.of(new fromContactsActions.ContactsLoadContactsFail(error))
                )
            );
        })
    );

    @Effect()
    loadRemainingContacts: Observable<Action> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsLoadRemainingContacts.TYPE),
        map(({ totalPages }: any) => range(1, totalPages)),
        switchMap(pageIndexesToLoad => {
            return from(pageIndexesToLoad).pipe(
                mergeMap(pageIndex =>
                    this.contactsApiService.loadContacts({ size: PAGE_SIZE, page: `${pageIndex}` })
                ),
                zip(),
                map((pages: any[]) => {
                    const remainingContacts = pages.reduce(
                        (acc, page) => acc.concat(page.items),
                        []
                    );
                    return new fromContactsActions.ContactsLoadRemainingContactsSuccess(
                        remainingContacts
                    );
                }),
                catchError(error =>
                    Observable.of(new fromContactsActions.ContactsLoadRemainingContactsFail(error))
                )
            );
        })
    );

    @Effect()
    reloadContactsOnUpdateDeleteContact$: Observable<Action> = this.actions$.pipe(
        ofType(
            fromContactsActions.ContactsCreateContactSuccess.TYPE,
            fromContactsActions.ContactsUpdateContactSuccess.TYPE
        ),
        map(() => {
            return new fromContactsActions.ContactsLoadContacts();
        })
    );

    @Effect()
    deleteContact$: Observable<Action> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsDeleteContact.TYPE),
        map((action: any) => action.payload),
        switchMap((contact: IContact) => {
            return this.contactsApiService.deleteContact(contact).pipe(
                map(() => new fromContactsActions.ContactsDeleteContactSuccess(contact)),
                catchError((error: HttpErrorResponse) =>
                    Observable.of(new fromContactsActions.ContactsDeleteContactFail(error))
                )
            );
        })
    );

    @Effect()
    createContact$: Observable<Action> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsCreateContact.TYPE),
        map((action: any) => action.payload),
        switchMap((contact: IContact) => {
            return this.contactsApiService.createContact(contact).pipe(
                map(
                    createdContact =>
                        new fromContactsActions.ContactsCreateContactSuccess(createdContact)
                ),
                catchError((error: HttpErrorResponse) =>
                    Observable.of(new fromContactsActions.ContactsCreateContactFail(error))
                )
            );
        })
    );

    @Effect()
    updateContact$: Observable<Action> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsUpdateContact.TYPE),
        map((action: any) => action.payload),
        switchMap((contact: IContact) => {
            return this.contactsApiService.updateContact(contact).pipe(
                map(
                    updatedContact =>
                        new fromContactsActions.ContactsUpdateContactSuccess(updatedContact)
                ),
                catchError((error: HttpErrorResponse) =>
                    Observable.of(new fromContactsActions.ContactsUpdateContactFail(error))
                )
            );
        })
    );

    @Effect({ dispatch: false })
    showNotyOnError$: Observable<Action> = this.actions$.pipe(
        ofType(
            fromContactsActions.ContactsLoadContactsFail.TYPE,
            fromContactsActions.ContactsDeleteContactFail.TYPE
        ),
        tap(() => this.messagesService.showMessage('errors.default', 'DANGER', true))
    );
}
