import { ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as fromUserReducer from './reducers/user.reducer';

export interface IUserModuleState {
    user: fromUserReducer.IUserState;
}

export const USER_MODULE_INIT_STATE: IUserModuleState = {
    user: fromUserReducer.initialUserStoreState
};

export const reducers: ActionReducerMap<IUserModuleState> = {
    user: fromUserReducer.userReducer
};

// main selectors
export const getUserState = (state: any) => state.UserCoreModule.user;

// selectors
export const getUser = createSelector(getUserState, (state: fromUserReducer.IUserState) => state.user);
export const getUserIsLoaded = createSelector(getUserState, (state: fromUserReducer.IUserState) => state.userIsLoaded);
export const getUserIsBeingLoading = createSelector(getUserState, (state: fromUserReducer.IUserState) => state.userIsBeingLoaded);
