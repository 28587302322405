import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent {
    @Input() disabled: boolean = false;
    @Input() checked: string = '';
    @Input() label: string = '';

    @Output() change = new EventEmitter<any>();

    onCheckboxChange($event: any) {
        this.change.emit($event);
    }
}
