import { Observable, timer } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

export class Message {
    id: string;
    visible: boolean;
    removed: boolean;
    translate: boolean;
    text: string;
    stateChangeCallback: Function;
    type: 'SUCCESS' | 'INFO' | 'WARNING' | 'DANGER';

    constructor() {
        this.id = this.generateUUID();
        this.text = null;
        this.type = 'SUCCESS';
        this.visible = false;
        this.removed = false;
        this.translate = false;
    }

    show() {
        this.visible = true;
        this.stateChangeCallback();
    }

    hide() {
        this.visible = false;
        this.stateChangeCallback();
    }

    remove() {
        this.removed = true;
        this.stateChangeCallback();
    }

    showForSeconds(seconds: number): Observable<number> {
        return timer(0).pipe(
            tap(() => this.show()),
            delay(seconds * 1000),
            tap(() => this.hide()),
            delay(1000),
            tap(() => this.remove())
        );
    }

    removeFadeOut(): Observable<number> {
        return timer(0).pipe(
            tap(() => this.hide()),
            delay(1000),
            tap(() => this.remove())
        );
    }

    generateUUID() {
        return Math.round(Math.random() * 1000000).toString();
    }
}
