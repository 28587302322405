export type FilterSectionType =
    | 'category'
    | 'payment'
    | 'dates'
    | 'property'
    | 'counterparty'
    | 'subject';

export const FILTER_AMOUNT_MIN = 0;
export const FILTER_AMOUNT_MAX = 10000;
