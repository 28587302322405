import { ActionReducerMap } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as fromPropertiesReducer from './reducers/properties.reducer';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface BuildingsModuleState {
    properties: fromPropertiesReducer.IPropertiesState;
}

export const BUILDINGS_MODULE_INIT_STATE: BuildingsModuleState = {
    properties: fromPropertiesReducer.initialPropertiesStoreState
};

export const reducers: ActionReducerMap<BuildingsModuleState> = {
    properties: fromPropertiesReducer.propertiesReducer
};

// main selectors
export const getBuildingsState = (state: any) => state.PropertyCoreModule.buildings;
export const getPropertiesState = (state: any) => state.PropertyCoreModule.properties;
export const getSearchPropertiesState = (state: any) => state.PropertyCoreModule.searchProperties;

// selectors
export const getPropertiesList = createSelector(
    getPropertiesState,
    (state: fromPropertiesReducer.IPropertiesState) => state.propertiesList
);
export const getPropertiesAreLoaded = createSelector(
    getPropertiesState,
    (state: fromPropertiesReducer.IPropertiesState) => state.propertiesAreLoaded
);
export const getPropertiesAreBeingLoaded = createSelector(
    getPropertiesState,
    (state: fromPropertiesReducer.IPropertiesState) => state.propertiesAreBeingLoaded
);
