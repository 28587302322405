import {
    OnInit,
    OnDestroy,
    Input,
    Component,
    ChangeDetectionStrategy,
    ContentChild,
    TemplateRef
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

const DEFAULT_DIALOG_CONFIG = {};

@Component({
    selector: 'zhm-modal-proxy',
    template: '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalProxyComponent implements OnInit, OnDestroy {
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
    @Input() config: any = {};
    @Input() set open(open: boolean) {
        if (open === true) {
            this.openModal();
        } else {
            this.closeModal();
        }
    }

    get isOpen() {
        return !!this.modalRef;
    }

    private modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    ngOnInit() {}

    ngOnDestroy = () => this.closeModal();

    openModal() {
        if (this.isOpen) return;

        this.modalRef = this.modalService.show(this.template, {
            ...DEFAULT_DIALOG_CONFIG,
            ...this.config
        });
    }

    closeModal() {
        if (!this.isOpen) return;

        this.modalRef.hide();
        delete this.modalRef;
    }
}
