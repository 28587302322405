import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BUILDINGS_MODULE_INIT_STATE, reducers } from './state/property-state';
import { PropertiesEffects } from './state/effects/properties.effects';

@NgModule({
    imports: [
        StoreModule.forFeature('PropertyCoreModule', reducers, {
            initialState: BUILDINGS_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([PropertiesEffects])
    ]
})
export class PropertyCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PropertyCoreModule,
            providers: []
        };
    }
}
