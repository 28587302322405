import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ICategorizationUserTopic } from '@zenhomes/domain/category';

export class CategoryCoreLoadUserTopics implements Action {
    static readonly TYPE = '[CategoryCore] Load User Topics';
    readonly type = CategoryCoreLoadUserTopics.TYPE;
    constructor() {}
}

export class CategoryCoreLoadUserTopicsSuccess implements Action {
    static readonly TYPE = '[CategoryCore] Load User Topics Success';
    readonly type = CategoryCoreLoadUserTopicsSuccess.TYPE;
    constructor(public userTopics: ICategorizationUserTopic[]) {}
}

export class CategoryCoreLoadUserTopicsFail implements Action {
    static readonly TYPE = '[CategoryCore] Load User Topics Fail';
    readonly type = CategoryCoreLoadUserTopicsFail.TYPE;
    constructor(public payload: HttpErrorResponse) {}
}
