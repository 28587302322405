import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ICategorizationSubject, ICategorizationUserCategory } from '@zenhomes/domain/category';
import { debounce } from 'lodash';
import { searchCategorizationSubjects } from '../../utilities/search-subjects.util';

const SEARCH_DEBOUNCE = 300;

@Component({
    selector: 'zhm-subject-search',
    templateUrl: './subject-search.component.html',
    styleUrls: ['./subject-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectSearchComponent implements OnInit {
    @Input() query: string;
    @Output() queryChange = new EventEmitter<string>();

    @Input() userCategories: ICategorizationUserCategory[];
    @Output() suggestSubjects = new EventEmitter<ICategorizationSubject[]>();

    debouncedSearch: (query: string) => void;

    constructor() {}

    ngOnInit() {
        this.debouncedSearch = debounce((query: string) => {
            const suggestedSubjects = searchCategorizationSubjects(query, this.userCategories);
            this.suggestSubjects.emit(suggestedSubjects);
        }, SEARCH_DEBOUNCE);
    }

    onSearchQueryChange(query: string) {
        this.queryChange.emit(query);
        this.debouncedSearch(query);
    }

    onClearSearch() {
        this.queryChange.emit(null);
    }
}
