// internal libraries
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
// modules
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceModule } from '@zenhomes/device';
import { ElementsModule } from '@zenhomes/elements';
import { PropertyUiModule } from '@zenhomes/property-ui';
import { SharedModule } from '@zenhomes/shared';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { DocumentUploadItemComponent } from './components/document-upload-item/document-upload-item.component';
import { DocumentsDropSectionPlaceholderComponent } from './components/documents-drop-section-placeholder/documents-drop-section-placeholder.component';
import { DocumentsDropSectionComponent } from './components/documents-drop-section/documents-drop-section.component';
import { DocumentsCoreConfig, DocumentsCoreConfigToken } from './documents-core.config';
import { ThumbnailIconClassPipe } from './pipes/thumbnail-icon-class.pipe';
// components
import { DocumentCategoriesApiService } from './services/document-categories.api.service';
import { DocumentCategoriesService } from './services/document-categories.service';
import { DocumentsApiService } from './services/documents.api.service';
// providers
import { DOCUMENTS_MODULE_INIT_STATE, reducers } from './state/document-core-state';
import { DocumentCategoriesEffects } from './state/effects/document-categories.effects';

export const DECLARATIONS = [
    DocumentsDropSectionComponent,
    DocumentsDropSectionPlaceholderComponent,
    DocumentUploadItemComponent,
    ThumbnailIconClassPipe
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ElementsModule,
        FileUploadModule,
        PropertyUiModule,
        StoreModule.forFeature('DocumentsModule', reducers, {
            initialState: DOCUMENTS_MODULE_INIT_STATE
        }),
        EffectsModule.forFeature([DocumentCategoriesEffects]),
        TranslateModule,
        DeviceModule,
        SharedModule
    ],
    declarations: [DECLARATIONS],
    exports: [DECLARATIONS]
})
export class DocumentsCoreModule {
    static forRoot(config: DocumentsCoreConfig): ModuleWithProviders {
        return {
            ngModule: DocumentsCoreModule,
            providers: [
                { provide: DocumentsCoreConfigToken, useValue: config },
                DocumentsApiService,
                DocumentCategoriesApiService,
                DocumentCategoriesService
            ]
        };
    }
}
