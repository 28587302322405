import { getSubjectById } from '@zenhomes/category-core';
import {
    ICategorizationSubject,
    ICategorizationSubjectDictionary
} from '@zenhomes/domain/category';
import {
    IInvoice,
    PaymentMatchingStatusType,
    PaymentStatusType,
    PaymentTransactionType,
    PAYMENT_STATUS_CATEGORIZED,
    PAYMENT_STATUS_IGNORED,
    PAYMENT_STATUS_NOTPAID,
    PAYMENT_STATUS_OVERPAID,
    PAYMENT_STATUS_PAID,
    PAYMENT_STATUS_PARTIALPAID,
    PAYMENT_STATUS_SNOOZED,
    PAYMENT_STATUS_UNCATEGORIZED,
    TRANSACTION_TYPE_IN,
    TRANSACTION_TYPE_OUT
} from '@zenhomes/domain/invoice';
import { PaymentStatusFilter } from '@zenhomes/filter-core';
import { chain, get } from 'lodash';

export function getLabelForPaymentStatus(
    status: PaymentStatusType | PaymentMatchingStatusType | PaymentStatusFilter
) {
    switch (status) {
        case PAYMENT_STATUS_PAID: {
            return 'journalViewFilters.paymentStatus.paid';
        }
        case PAYMENT_STATUS_PARTIALPAID: {
            return 'journalViewFilters.paymentStatus.partialPaid';
        }
        case PAYMENT_STATUS_NOTPAID: {
            return 'journalViewFilters.paymentStatus.unPaid';
        }
        case PaymentStatusFilter.Overdue: {
            return 'journalViewFilters.paymentStatus.overdue';
        }
        case PAYMENT_STATUS_OVERPAID: {
            return 'journalViewFilters.paymentStatus.overpaid';
        }
        case PAYMENT_STATUS_CATEGORIZED: {
            return 'bankModule.paymentStatus.categorized';
        }
        case PAYMENT_STATUS_UNCATEGORIZED: {
            return 'bankModule.paymentStatus.uncategorized';
        }
        case PAYMENT_STATUS_SNOOZED: {
            return 'bankModule.paymentStatus.snoozed';
        }
        case PAYMENT_STATUS_IGNORED: {
            return 'bankModule.paymentStatus.ignored';
        }
        default: {
            return '';
        }
    }
}

export function getLabelForTransactionType(type: PaymentTransactionType) {
    switch (type) {
        case TRANSACTION_TYPE_IN: {
            return 'journalView.filters.transaction.in';
        }
        case TRANSACTION_TYPE_OUT: {
            return 'journalView.filters.transaction.out';
        }
    }
}

export function getInvoiceTypeTranslations(invoiceType: 'INDIVIDUAL' | 'CONTRACT') {
    switch (invoiceType) {
        case 'INDIVIDUAL': {
            return 'journalViewFilters.invoiceType.oneOff';
        }
        case 'CONTRACT': {
            return 'journalViewFilters.invoiceType.contract';
        }
    }
}

export function getInvoiceSubjects(
    invoice: IInvoice,
    subjectDictionary: ICategorizationSubjectDictionary
): ICategorizationSubject[] {
    return chain(invoice.items)
        .flatMap((item: any) => item.splitItems || get(item, 'paymentHistory[0].subTransactions'))
        .map(
            (splitItem: any) =>
                splitItem.subject || getSubjectById(subjectDictionary, splitItem.subjectId)
        )
        .filter((subject: ICategorizationSubject) => subject && subject.id)
        .uniqBy((subject: ICategorizationSubject) => subject.id)
        .value();
}

export function getIconForPaymentStatus(status: PaymentMatchingStatusType | PaymentStatusFilter) {
    switch (status) {
        case PAYMENT_STATUS_CATEGORIZED: {
            return 'icon-out_category';
        }
        case PAYMENT_STATUS_UNCATEGORIZED: {
            return 'icon-out_category_no';
        }
        case PAYMENT_STATUS_SNOOZED: {
            return 'icon-out_alarm';
        }
        case PAYMENT_STATUS_IGNORED: {
            return 'icon-out_view-no';
        }
        default: {
            return '';
        }
    }
}

export const getInvoiceWithSubject = (items: IInvoice[], subject: string) => {
    return items.find(invoice =>
        invoice.items.some(item =>
            item.splitItems.some(splitItem => splitItem.subjectId === subject)
        )
    );
};
