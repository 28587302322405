import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'zhm-request-feature-card',
    templateUrl: './request-feature-card.component.html',
    styleUrls: ['./request-feature-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestFeatureCardComponent implements OnInit {
    @Input() headerText: string;
    @Input() bodyText: string;
    @Input() bodyTextFooter: string;

    @Output() buttonClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    onClick() {
        this.buttonClick.emit();
    }
}
