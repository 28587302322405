import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerOverlayComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
