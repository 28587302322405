import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentsEditorComponent } from '../documents-editor/documents-editor.component';
import { DocumentMetadataFormService } from '../../services/document-metadata-form.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDocument } from '@zenhomes/domain/document';
import { Subject } from 'rxjs/Subject';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';
import { DeviceService } from '@zenhomes/device';

const modalConfig = { ignoreBackdropClick: true, keyboard: false, animated: true };

@Component({
    selector: 'zhm-document-metadata-form-dialog-wrapper',
    templateUrl: './document-metadata-form-dialog-wrapper.component.html',
    styleUrls: ['./document-metadata-form-dialog-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentMetadataFormDialogWrapperComponent implements OnInit, OnDestroy {
    private modalRef: BsModalRef;

    private componentDestroyed = new Subject<void>();

    constructor(
        private documentMetadataFormService: DocumentMetadataFormService,
        private deviceService: DeviceService,
        private modalService: BsModalService
    ) {}

    ngOnInit() {
        this.documentMetadataFormService.open$
            .takeUntil(this.componentDestroyed)
            .subscribe(params => {
                this.sendIsOpenDialogIframeMessage(true);

                this.modalRef = this.modalService.show(DocumentsEditorComponent, modalConfig);
                const editor = this.modalRef.content as DocumentsEditorComponent;

                editor.setParams(params);

                editor.onCancelDocument.takeUntil(this.componentDestroyed).subscribe(() => {
                    this.documentMetadataFormService.cancel();
                });

                editor.onSaveDocument
                    .takeUntil(this.componentDestroyed)
                    .subscribe((document: IDocument) => {
                        this.documentMetadataFormService.save(document);
                    });
            });

        this.documentMetadataFormService.close$.takeUntil(this.componentDestroyed).subscribe(() => {
            this.sendIsOpenDialogIframeMessage(false);

            if (this.modalRef) {
                this.modalRef.hide();
                this.modalRef = null;
            }
        });
    }

    private sendIsOpenDialogIframeMessage(isOpen: boolean) {
        if (isOpen) {
            IFrameHustla.sendMessage({
                type: 'CHILD_DIALOG_OPENED',
                dialogName: 'document-metadata-form'
            });
        } else {
            IFrameHustla.sendMessage({
                type: 'CHILD_DIALOG_CLOSED',
                dialogName: 'document-metadata-form'
            });
        }
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
