import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem } from '../dropdown/dropdown.component';

@Component({
    selector: 'zhm-custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {
    @Input() items: DropdownItem[];
    @Input() selected: string;
    @Input() disabled: boolean;
    @Input() isEmptyOptionShown: boolean = true;

    @Output() valueChange = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    onValueChange(value: string) {
        if (this.disabled) return;
        if (value === 'null') return this.valueChange.emit(null);
        this.valueChange.emit(value);
    }
}
