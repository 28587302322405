import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ElementsModule } from '@zenhomes/elements';
import { CountryDropdownModule } from '@zenhomes/country-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomInteractionModule } from '@zenhomes/dom-interaction';
import { TranslateModule } from '@ngx-translate/core';
// components
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactMultiSelectorComponent } from './components/contact-multi-selector/contact-multi-selector.component';
import { ContactPopupComponent } from './components/contact-popup/contact-popup.component';
import { ContactsAutocompleteComponent } from './components/contacts-autocomplete/contacts-autocomplete.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { NewContactCardItemComponent } from './components/new-contact-card-item/new-contact-card-item.component';
// ngrx
import { contactsReducer } from './state/address-book-state';
import { ContactsEffects } from './state/effects/contacts.effects';

const COMPONENTS = [
    ContactsAutocompleteComponent,
    ContactPopupComponent,
    ContactFormComponent,
    ContactCardComponent,
    UserCardComponent,
    ContactMultiSelectorComponent,
    NewContactCardItemComponent
];

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('AddressBookModule', contactsReducer),
        EffectsModule.forFeature([ContactsEffects]),
        ElementsModule,
        CountryDropdownModule,
        FormsModule,
        ReactiveFormsModule,
        DomInteractionModule,
        TranslateModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AddressBookCoreModule {}
