import { cond, T } from 'ramda';

import {
    ICategorizationSubject,
    ICategorizationSubjectDictionary,
    ICategorizationUserCategory,
    ICategorizationUserCategoryDictionary,
    ICategorizationUserTopic
} from '@zenhomes/domain/category';
import { IExpenseContract, IExpenseContractPeriod } from '@zenhomes/domain/contract';
import { IInvoice } from '@zenhomes/domain/invoice';
import { chain, flatMap, get, isEmpty, keyBy, map, minBy } from 'lodash';
import { isExpenseContractType, isExpenseContractPeriod } from '@zenhomes/domain/contract';
import { IInvoiceItem } from '@zenhomes/domain/invoice';

interface Func1<T, R> {
    (t: T): R;
}

export function createSubjectDictionary(
    userTopics: ICategorizationUserTopic[]
): ICategorizationSubjectDictionary {
    const flatSubjects = flatMap(userTopics, userTopic => {
        return flatMap(userTopic.userCategories, userCategory => {
            return map(userCategory.subjects, subject => {
                return { subject, userCategory, userTopic };
            });
        });
    });

    const subjectMap = keyBy(flatSubjects, item => item.subject.id);

    return subjectMap;
}

export function createUserCategoryDictionary(
    userTopics: ICategorizationUserTopic[]
): ICategorizationUserCategoryDictionary {
    const flatCategories = flatMap(userTopics, userTopic => {
        return map(userTopic.userCategories, userCategory => {
            return { userTopic, userCategory };
        });
    });

    const categoryMap = keyBy(flatCategories, item => item.userCategory.id);

    return categoryMap;
}

export function getDistinctUserCategoriesForSubjects(
    subjectDictionary: ICategorizationSubjectDictionary,
    subjects: ICategorizationSubject[]
): ICategorizationUserCategory[] {
    const uniqueCategories = chain(subjects)
        .map(subject => subjectDictionary[subject.id])
        .filter(item => !isEmpty(item))
        .map(item => item.userCategory)
        .uniqBy(userCategory => userCategory.id)
        .value();

    return uniqueCategories;
}

export function getSubjectById(
    subjectDictionary: ICategorizationSubjectDictionary,
    subjectId: string
) {
    const item = subjectDictionary[subjectId];
    return item && item.subject;
}

export function getSubjectsForUserCategories(
    userCategories: ICategorizationUserCategory[],
    userCategoryDictionary: ICategorizationUserCategoryDictionary
) {
    return flatMap(userCategories, userCategory => {
        return get(
            userCategoryDictionary,
            `[${userCategory.id}].userCategory.subjects`,
            []
        ) as ICategorizationSubject[];
    });
}

const getExpenseContractPeriodUserCategories: Func1<
    IExpenseContractPeriod,
    ICategorizationUserCategory[]
> = period => {
    return chain(period.items)
        .flatMap(item => item.userCategory)
        .value();
};

const getExpenseContractUserCategories: Func1<
    IExpenseContract,
    ICategorizationUserCategory[]
> = expenseContract => {
    return chain(expenseContract.periods)
        .flatMap(period => getExpenseContractPeriodUserCategories(period))
        .value();
};

const getInvoiceUserCategories: Func1<IInvoice, ICategorizationUserCategory[]> = invoice => {
    return chain(invoice.items)
        .flatMap((item: IInvoiceItem) => item.splitItems)
        .filter(splitItem => splitItem && splitItem.userCategory)
        .map(splitItem => splitItem.userCategory)
        .value();
};

export const getUserCategories: Func1<
    IInvoice | IExpenseContract | IExpenseContractPeriod,
    ICategorizationUserCategory[]
> = cond([
    [isExpenseContractType, getExpenseContractUserCategories],
    [isExpenseContractPeriod, getExpenseContractPeriodUserCategories],
    [T, getInvoiceUserCategories]
]);

export function getMostRelevantUserCategory(
    categories: ICategorizationUserCategory[]
): ICategorizationUserCategory {
    return !isEmpty(categories) ? minBy(categories, category => category.relevance) : null;
}
