import { pick } from 'lodash';
import { omitBy } from 'lodash';
import { get } from 'lodash';
import { isNull } from 'lodash';
import { isEmpty } from 'lodash';
import { size } from 'lodash';
import { IUser } from '../interfaces/user.interface';

const ADDRESS_FIELDS: string[] = ['street', 'zip', 'house', 'city'];

export function isUserWithCompleteAddress(user: IUser) {
    const addressData = pick(get(user, 'address'), ADDRESS_FIELDS);

    const omittedAddressData = omitBy(addressData, item => isNull(item) || isEmpty(item));

    return size(omittedAddressData as any) === ADDRESS_FIELDS.length;
}

export function userHasFeature(user: IUser, featureName: string) {
    const features = (user && user.features) || [];

    const relevantFeatures = features.filter(feature => feature.name === featureName);

    return relevantFeatures.length > 0;
}
