import { isTrue } from '@zenhomes/sauron';
import { createMultiWordRegexp, normalize } from '@zenhomes/sauron';
import { ICategorizationSubject, ICategorizationUserCategory } from '@zenhomes/domain/category';
import { chain, flatMap, isEmpty } from 'lodash';

const SYNONYM_PRIORITY_OFFSET = 10000;
type ISubjectMatchResult = { subject: ICategorizationSubject; matched: boolean; priority: number };

interface Func1<T, R> {
    (t: T): R;
}

export function searchCategorizationSubjects(
    query: string,
    userCategories: ICategorizationUserCategory[]
): ICategorizationSubject[] {
    if (isEmpty(query)) return [];

    const subjects = flatMap(userCategories, category => category.subjects);

    const filteredSubjects = searchSubjectsByQuery(query, subjects);

    return filteredSubjects;
}

export function searchSubjectsByQuery(
    query: string,
    allSubjects: ICategorizationSubject[]
): ICategorizationSubject[] {
    const matchSubject = createQueryFieldMatcher(query);

    const filteredSubjects = chain(allSubjects)
        .map(subject => matchSubject(subject))
        .filter(({ matched }) => isTrue(matched))
        .sortBy(({ priority }) => priority)
        .map(({ subject }) => subject)
        .value();

    return filteredSubjects;
}

function createQueryFieldMatcher(
    query: string
): Func1<ICategorizationSubject, ISubjectMatchResult> {
    const regexp = createNormalizedQueryRegexp(query);

    return (subject: ICategorizationSubject) => {
        switch (true) {
            case regexp.test(normalize(subject.title)): {
                return { subject, matched: true, priority: subject.relevance };
            }
            case regexp.test(normalize(subject.synonym)): {
                return {
                    subject,
                    matched: true,
                    priority: subject.relevance + SYNONYM_PRIORITY_OFFSET
                };
            }
            default: {
                return { subject, matched: false, priority: 0 };
            }
        }
    };
}

function createNormalizedQueryRegexp(query: string) {
    const normalizedQuery = normalize(query || '');

    const regexp = createMultiWordRegexp(normalizedQuery.split(' '));

    return regexp;
}
