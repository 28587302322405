import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { DisclaimerContainerComponent } from './components/disclaimer-container/disclaimer-container.component';
import { ProgressBarSmallComponent } from './components/progress-bar-small/progress-bar-small.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FileThumbnailComponent } from './components/file-thumbnail/file-thumbnail.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { RoomCountSelectorComponent } from './components/room-count-selector/room-count-selector.component';
import { SelectorIconContainerComponent } from './components/selector-icon-container/selector-icon-container.component';
import { MultiSelectIconContainerComponent } from './components/multi-select-icon-container/multi-select-icon-container.component';
import { SingleIconContainerComponent } from './components/single-icon-container/single-icon-container.component';
import { PropertyIconComponent } from './components/property-icon/property-icon.component';
import { FloorPlansAttachmentsComponent } from './components/floor-plans-attachments/floor-plans-attachments.component';
import { ExploreCardComponent } from './components/explore-card/explore-card.component';
import { BuildingTypeOwnershipSelectorComponent } from './components/building-type-ownership-selector/building-type-ownership-selector.component';
import { PropertyTypeButtonComponent } from './components/property-type-button/property-type-button.component';
import { UnitTypeSelectorComponent } from './components/unit-type-selector/unit-type-selector.component';
import { PhotosContainerUploadItemComponent } from './components/photos-container-upload-item/photos-container-upload-item.component';
import { RequestFeatureCardComponent } from './components/request-feature-card/request-feature-card.component';
import { AddDocumentButtonComponent } from './components/add-document-button/add-document-button.component';
import { InitialsListComponent } from './components/initials-list/initials-list.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CheckboxDropdownComponent } from './components/checkbox-dropdown/checkbox-dropdown.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ScaleImageSvgComponent } from './components/scale-image-svg/scale-image-svg.component';
import { ThermalCharacteristicScaleComponent } from './components/thermal-characteristic-scale/thermal-characteristic-scale.component';
import { ThumbnailIconClassPipe } from './pipes/thumbnail-icon-class.pipe';

import { ElementsModule } from '@zenhomes/elements';
import { PropertyUiModule } from '@zenhomes/property-ui';
import { DialogWizardModule } from '@zenhomes/dialog-wizard';
import { DeviceModule } from '@zenhomes/device';

import { DateModule } from '@zenhomes/date';
import { IFrameHustlaUiModule } from '@zenhomes/iframe-hustla-ui';
import { GuidanceIconComponent } from './components/guidance-icon/guidance-icon.component';

const COMPONENTS = [
    GuidanceIconComponent,
    DisclaimerContainerComponent,
    ProgressBarSmallComponent,
    CarouselComponent,
    FileThumbnailComponent,
    InputNumberComponent,
    RoomCountSelectorComponent,
    SelectorIconContainerComponent,
    MultiSelectIconContainerComponent,
    SingleIconContainerComponent,
    PropertyIconComponent,
    FloorPlansAttachmentsComponent,
    ExploreCardComponent,
    BuildingTypeOwnershipSelectorComponent,
    PropertyTypeButtonComponent,
    UnitTypeSelectorComponent,
    PhotosContainerUploadItemComponent,
    RequestFeatureCardComponent,
    AddDocumentButtonComponent,
    InitialsListComponent,
    BadgeComponent,
    CheckboxDropdownComponent,
    CheckboxComponent,
    ProgressBarComponent,
    ScaleImageSvgComponent,
    ThermalCharacteristicScaleComponent
];

const PIPES = [ThumbnailIconClassPipe];

@NgModule({
    imports: [
        ElementsModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        PropertyUiModule,
        DateModule,
        IFrameHustlaUiModule,
        DialogWizardModule,
        DeviceModule
    ],
    declarations: [...COMPONENTS, ...PIPES],
    exports: [...COMPONENTS]
})
export class SharedModule {}
