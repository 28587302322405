import { ActionReducerMap } from '@ngrx/store';

import { getSelectorWrapper } from '@util/selector-wrapper.util';
import { PaymentCoreState } from '@payment-core/interfaces';
import { createReducer } from '@zenhomes/ngrx-actions';
import { PaymentCoreStore } from '@payment-core/state/stores/payment.store';

export function paymentCoreReducerForPaymentCore(state: any, action: any) {
    return createReducer(PaymentCoreStore)(state, action);
}

export const paymentCoreReducer = {
    payment: paymentCoreReducerForPaymentCore
};

// main selector
export const getPaymentCoreState = (state: any) => state.PaymentCoreModule.payment;

export const selectorWrapper = getSelectorWrapper<PaymentCoreState>(getPaymentCoreState);

// custom selectors
export const getPayments = selectorWrapper('payments');
export const getPaymentsSelected = selectorWrapper('paymentsSelected');
export const getPagination = selectorWrapper('pagination');
export const getPayment = selectorWrapper('payment');
export const getFilterOptions = selectorWrapper('filterOptions');
export const getPaymentsAreBeingLoaded = selectorWrapper('paymentsBeingLoaded');
export const getTransferCounterparties = selectorWrapper('transferCounterparties');
export const getTransferCounterpartiesBeingLoaded = selectorWrapper(
    'transferCounterpartiesBeingLoaded'
);
export const getShouldLoadMissingPayments = selectorWrapper('shouldLoadMissingPayments');
export const getUncategorizedPaymentStats = selectorWrapper('uncategorizedPaymentStats');
export const getUncategorizedPaymentStatsIsLoaded = selectorWrapper(
    'uncategorizedPaymentStatsIsLoaded'
);
