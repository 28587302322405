import { IElementsPaginationModel } from '@zenhomes/domain/pagination';
import { IInvoice } from '@zenhomes/domain/invoice';
import { IClusteredPayment, IPaymentItem } from '@zenhomes/domain/payment';
import { BookmarkableFilterOptions } from '@zenhomes/filter-core';

export interface IPaymentDragData {
    paymentItem: IPaymentItem;
    invoice: IInvoice;
    amount?: number;
}

export interface PaymentListFilterOptions extends BookmarkableFilterOptions {}
export interface IClusteredPaymentsPaginationModel extends IElementsPaginationModel {
    // Count of transfers, not payments
    count?: number;
}

export class PageableClusteredPaymentsModel {
    pagination: IClusteredPaymentsPaginationModel;
    items: IClusteredPayment[];

    constructor(data: any, count: number) {
        if (!(data instanceof PageableClusteredPaymentsModel)) {
            Object.assign(this, {
                items: [].concat(data.items || data.content),
                pagination: { ...data, ...{ count } }
            });
        } else {
            Object.assign(this, data);
        }
    }
}
