import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'validation-errors',
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorsComponent {
    @Input() errors: string;
    @Input() icon: string;
}
