import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BuildingAddressPipe } from '@zenhomes/elements';
import { IContact } from '@zenhomes/domain/contact';
import { chain, includes, isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ContactsService } from '../../services/contacts.service';
import { Subject } from 'rxjs/Subject';
import { debounceTime, tap, filter, switchMap, map, takeUntil } from 'rxjs/operators';

const RESULTS_LIMIT = 5;
const AUTOCOMPLETE_DEBOUNCE = 200;

@Component({
    selector: 'contacts-autocomplete',
    templateUrl: './contacts-autocomplete.component.html',
    styleUrls: ['./contacts-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactsAutocompleteComponent),
            multi: true
        }
    ]
})
export class ContactsAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() queryMinLength: number = 1;
    @Input() resultsLimit: number = RESULTS_LIMIT;
    @Input() resetSearchOnSelect: boolean = true;
    @Input() showClearSearch: boolean = false;
    @Input() selectedContactsIds: string[] = [];
    @Input() highlightedContactsIds: string[] = [];
    @Input() disabled: boolean = false;
    @Output() emitInputValue: EventEmitter<string> = new EventEmitter();
    @Output() emitFoundContacts: EventEmitter<IContact[]> = new EventEmitter();

    @HostListener('document:click', ['$event'])
    onClickOutside(event: any) {
        if (this.resetSearchOnSelect && !this.elementRef.nativeElement.contains(event.target)) {
            this.items = [];
            this.preselectedIdx = -1;

            this.ref.markForCheck();
        }
    }

    @HostListener('keydown', ['$event'])
    keydown(event: any) {
        if (!isEmpty(this.items)) {
            if (event.keyCode === 40) {
                if (this.preselectedIdx + 1 < this.items.length) {
                    this.preselectedIdx++;
                }

                return false;
            }

            if (event.keyCode === 38) {
                if (this.preselectedIdx > 0) {
                    this.preselectedIdx--;
                }

                return false;
            }

            if (event.keyCode === 13) {
                this.selectContact(this.items[this.preselectedIdx]);

                return false;
            }

            this.ref.markForCheck();
        }
    }

    queryControl: FormControl;
    queryControlLastValue: IContact;
    items: IContact[] = [];
    valueIsSelected: boolean = false;
    preselectedIdx: number = -1;
    propagateChange = (_: any) => {};
    registerOnTouched() {}

    private componentDestroyed = new Subject<void>();

    propertiesToShowOnTheSecondLine: string[] = ['companyName', 'email', 'phone', 'address'];

    constructor(
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef,
        private contactsService: ContactsService,
        private buildingAddressPipe: BuildingAddressPipe
    ) {}

    ngOnInit() {
        this.queryControl = new FormControl();

        let debouncedObservable = this.queryControl.valueChanges.pipe(
            debounceTime(AUTOCOMPLETE_DEBOUNCE),
            tap(() => this.propagateChange(null)),
            tap(value => {
                this.valueIsSelected = false;
                this.emitInputValue.emit(value);
            })
        );

        if (this.queryMinLength === 0) {
            debouncedObservable = debouncedObservable.startWith('');
        }

        debouncedObservable
            .pipe(
                filter(query => query.length < this.queryMinLength),
                takeUntil(this.componentDestroyed)
            )
            .subscribe(value => {
                if (this.queryControlLastValue || value) {
                    this.propagateChange(null);
                    this.items = [];
                    this.preselectedIdx = -1;
                    this.queryControlLastValue = value;

                    this.ref.markForCheck();
                }
            });

        debouncedObservable
            .pipe(
                filter(query => query.length >= this.queryMinLength),
                switchMap(query => this.loadItems(query)),
                map(contacts =>
                    contacts.filter(item => !includes(this.selectedContactsIds, item.id))
                ),
                takeUntil(this.componentDestroyed)
            )
            .subscribe((result: IContact[]) => {
                this.items = result.slice(0, this.resultsLimit);

                this.emitFoundContacts.emit(this.items);
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    trackById(index: number, item: any) {
        return item.id;
    }

    isHighlighted(contact: IContact) {
        return includes(this.highlightedContactsIds, contact.id);
    }

    get showListOfItems() {
        return (
            this.queryMinLength === 0 ||
            (this.queryControl.value && !!this.queryControl.value[0] && this.items[0])
        );
    }

    loadItems(query: string = ''): Observable<IContact[]> {
        return this.contactsService.findContacts(query);
    }

    selectContact(contact: IContact) {
        this.propagateChange(contact);

        if (this.resetSearchOnSelect) {
            this.setQueryControlValue(contact);

            this.items = [];
            this.preselectedIdx = -1;
        }

        this.ref.markForCheck();
    }

    writeValue(value: IContact) {
        this.setQueryControlValue(value);
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    onClearSearch() {
        this.queryControl.setValue('', { emitEvent: true });
    }

    getSecondLineContent(contact: IContact): string {
        const values = Object.assign({}, contact, {
            address: this.buildingAddressPipe.transform(contact.address)
        });

        const chainedValue = chain(values)
            .pick(this.propertiesToShowOnTheSecondLine)
            .toArray()
            .filter(item => !isEmpty(item))
            .first() as any;

        return chainedValue.value();
    }

    queryIncludesCompanyName(contact: IContact): boolean {
        const query = this.queryControl.value || '';

        return includes(`${contact.companyName}`.toLowerCase(), query.toLowerCase());
    }

    private setQueryControlValue(contact: IContact) {
        this.valueIsSelected = !!contact;
        const query = this.queryControl.value;
        let value: string = '';

        if (!isEmpty(contact)) {
            if (!isEmpty(query)) {
                value = includes(`${contact.name}`.toLowerCase(), query.toLowerCase())
                    ? contact.name
                    : contact.companyName;
            } else {
                value = !isEmpty(contact.name) ? contact.name : contact.companyName;
            }
        } else {
            value = null;
        }

        this.queryControl.setValue(value, { emitEvent: false });
    }
}
