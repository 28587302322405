import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IContact } from '@zenhomes/domain/contact';
import { Select } from '@zenhomes/ngrx-actions';
import { includesInString } from '@zenhomes/sauron';
import { isEmpty } from 'lodash';
import { path } from 'ramda';
import { Observable } from 'rxjs/Observable';
import { filter, first, map, switchMapTo } from 'rxjs/operators';
import * as fromContactsActions from '../state/actions/contacts.actions';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    @Select(path(['AddressBookModule', 'contacts', 'contactsList']))
    contacts$: Observable<IContact[]>;

    @Select(path(['AddressBookModule', 'contacts', 'contactsAreLoaded']))
    contactsAreLoaded$: Observable<boolean>;

    @Select(path(['AddressBookModule', 'contacts', 'contactsAreLoading']))
    contactsAreLoading$: Observable<boolean>;

    contactsAfterLoading$: Observable<IContact[]>;

    contactCreatedSuccessfully$: Observable<IContact> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsCreateContactSuccess.TYPE),
        map((action: any) => action.payload)
    );

    contactUpdatedSuccessfully$: Observable<IContact> = this.actions$.pipe(
        ofType(fromContactsActions.ContactsUpdateContactSuccess.TYPE),
        map((action: any) => action.payload)
    );

    constructor(private store: Store<any>, private actions$: Actions) {
        this.contactsAfterLoading$ = this.contactsAreLoaded$.pipe(
            filter(loaded => loaded),
            switchMapTo(this.contacts$)
        );
    }

    loadContacts() {
        this.store.dispatch(new fromContactsActions.ContactsLoadContacts());
    }

    deleteContact(contact: IContact) {
        this.store.dispatch(new fromContactsActions.ContactsDeleteContact(contact));
    }

    createContact(contact: IContact) {
        this.store.dispatch(new fromContactsActions.ContactsCreateContact(contact));
    }

    updateContact(contact: IContact) {
        this.store.dispatch(new fromContactsActions.ContactsUpdateContact(contact));
    }

    findContactById(contactId: string): Observable<IContact> {
        return this.contactsAfterLoading$.pipe(
            first(),
            map(contacts => contacts.find(item => item.id === contactId))
        );
    }

    findContacts(query: string = ''): Observable<IContact[]> {
        return this.contactsAfterLoading$.pipe(
            first(),
            map((contacts: IContact[]) => {
                if (isEmpty(query)) {
                    return contacts;
                } else {
                    return contacts.filter(
                        item =>
                            includesInString(item.name, query) ||
                            includesInString(item.companyName, query)
                    );
                }
            })
        );
    }

    reset() {
        this.store.dispatch(new fromContactsActions.ContactsReset());
    }
}
