import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { userActionTypes, UserService } from '@zenhomes/user-core';
import { map, tap, filter, withLatestFrom } from 'rxjs/operators';
import { IUser } from '@zenhomes/domain/user';
import { AnalyticsService } from '../../services/analytics.service';
import * as actions from '../actions/analytics.actions';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsConfigToken, IAnalyticsConfig } from '../../analytics.config';

@Injectable()
export class AnalyticsEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        @Inject(AnalyticsConfigToken) private config: IAnalyticsConfig
    ) {}

    @Effect({ dispatch: false })
    pageEvent$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.userService.user$),
        tap(([]: [NavigationEnd, IUser]) => {
            this.analyticsService.onPathChange();
        })
    );

    @Effect({ dispatch: false })
    changeUser$ = this.actions$.pipe(
        ofType(userActionTypes.USER_LOAD_USER_SUCCESS, userActionTypes.USER_RESET),
        map((action: any) => action.payload),
        tap((user: IUser) => {
            this.analyticsService.identifyUser(user);
        })
    );

    @Effect({ dispatch: false })
    trackEvent$ = this.actions$.pipe(
        ofType<actions.TrackEvent>(actions.TrackEvent.TYPE),
        withLatestFrom(this.userService.user$),
        tap(([{ event, data }]) => {
            if (this.config.logConsole) {
                console.info('[EVENT]', event, data);
            }

            this.analyticsService.trackEvent(event, data);
        })
    );
}
