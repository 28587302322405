<div class="dialog--wizard">
    <div class="outer-flex-container" [hidden]="isHidden">
        <div class="modal-dialog zhm--dialog dialog-wizard">
            <div
                [ngClass]="'zhm--dialog-blink-message ' + 'message-' + blinkMessage?.type + (blinkMessage && blinkMessage.visible ? ' visible' : ' invisible')"
                *ngIf="blinkMessage && blinkMessage.type !== 'EMPTY'"
            >
                <div class="icon">
                    <i
                        class="vicon icon-check-mark-circle"
                        *ngIf="blinkMessage?.type === 'OK'"
                        aria-hidden="true"
                    ></i>
                </div>
                <div class="text">
                    {{ blinkMessage?.text | translate }}
                </div>
            </div>
            <div class="zhm--dialog-header">
                <i
                    *ngIf="isPrevStep() && backButton()"
                    class="btn btn-back icon-chevron-left"
                    (click)="previousStep()"
                ></i>
                <h4>{{ title | translate }}</h4>
                <span class="btn btn-close float-right" (click)="hide()">
                    <i class="icon-remove-thin"></i>
                </span>
                <!--<span class="btn btn-close" *ngIf="isPrevStep()" (click)="previousStep()">Prev Step</span>-->
                <!--<span class="btn btn-close" *ngIf="isNextStep()" (click)="nextStep()">Next Step</span>-->
            </div>
            <ng-content class="zhm--dialog-body"></ng-content>
        </div>
    </div>
</div>
