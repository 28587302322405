import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowMessagingService } from '@zenhomes/device';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { isEmpty, isString, startsWith } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import * as authActions from '../actions/auth.actions';
import { authActionTypes } from '../actions/auth.actions';
import { tap, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private tokenService: TokenService,
        private windowMessagingService: WindowMessagingService
    ) {}

    @Effect()
    checkInitialState$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.AUTH_CHECK_INITIAL_STATE),
        map(() => {
            const token = this.tokenService.token;

            return !isEmpty(token)
                ? new authActions.AuthThereIsTokenInTheStorage(token)
                : new authActions.AuthThereIsNoTokenInTheStorage();
        })
    );

    @Effect()
    thereIsToken$: Observable<Action> = this.actions$.pipe(
        ofType(
            authActionTypes.AUTH_THERE_IS_TOKEN_IN_THE_STORAGE,
            authActionTypes.AUTH_THERE_IS_TOKEN_IN_THE_URL
        ),
        map((action: any) => action.payload),
        map((payload: any) => (isString(payload) ? payload : payload.idToken)),
        map((token: string) => new authActions.AuthSetToken(token))
    );

    @Effect()
    setToken$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.AUTH_SET_TOKEN),
        map((action: any) => action.payload),
        mergeMap((token: string) => {
            if (this.tokenService.checkIfTokenIsExpired(token)) {
                return [];
            }

            this.tokenService.token = token;

            return [new authActions.AuthSetTokenSuccess()];
        })
    );

    @Effect()
    thereIsNoTokenInTheStorage$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.AUTH_THERE_IS_NO_TOKEN_IN_THE_STORAGE),
        map(() => {
            const hash = window.location.hash;
            const params = new HttpParams({
                fromString: startsWith(hash, '#') ? hash.split('#')[1] : hash
            });
            const idTokenField = 'id_token';

            if (params.has(idTokenField)) {
                return new authActions.AuthThereIsTokenInTheUrl(params.get(idTokenField));
            } else {
                return new authActions.AuthThereIsNoTokenInTheUrl();
            }
        })
    );

    @Effect({ dispatch: false })
    logout$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.AUTH_LOGOUT),
        tap(() => {
            this.cleanUserData();
            this.windowMessagingService.sendMessage('logout');
        })
    );

    @Effect({ dispatch: false })
    cleanUserData$: Observable<Action> = this.actions$.pipe(
        ofType(authActionTypes.CLEAN_USER_DATA),
        tap(() => {
            this.cleanUserData();
        })
    );

    cleanUserData() {
        this.tokenService.removeToken();
        this.authService.reset();
    }
}
