import { IExpenseContract } from '@zenhomes/domain/contract';
import { ExpenseContractType } from '../../../modules/interfaces';

export function isExpenseOrLoanContractType(contract: any): contract is IExpenseContract {
    return (
        !!contract &&
        (contract.type === ExpenseContractType.ExpenseContract ||
            contract.type === ExpenseContractType.LoanContract)
    );
}
