import { IContentNavigationItem } from '@zenhomes/navigation';
import {
    CONTENT_ITEM_ACCOUNTING_ID,
    CONTENT_ITEM_ACCOUNTING_PATH,
    CONTENT_ITEM_DASHBOARD_ID,
    CONTENT_ITEM_DASHBOARD_PATH,
    CONTENT_ITEM_INFORMATION_ID,
    CONTENT_ITEM_INFORMATION_PATH,
    CONTENT_ITEM_TENANTS_ID,
    CONTENT_ITEM_TENANTS_PATH,
    CONTENT_ITEM_PROPERTY_PORTFOLIO_ID,
    CONTENT_ITEM_PROPERTY_PORTFOLIO_PATH,
    CONTENT_ITEM_INCIDENTS_ID,
    CONTENT_ITEM_INCIDENTS_PATH
} from '../../navigation.constants';

const CONTENT_ITEM_DASHBOARD: IContentNavigationItem = {
    id: CONTENT_ITEM_DASHBOARD_ID,
    title: 'navigation.content.dashboard',
    path: CONTENT_ITEM_DASHBOARD_PATH,
    icon: 'icon-sol_nav-dashboard'
};

const CONTENT_ITEM_TENANTS: IContentNavigationItem = {
    id: CONTENT_ITEM_TENANTS_ID,
    title: 'navigation.content.tenant',
    path: CONTENT_ITEM_TENANTS_PATH,
    icon: 'icon-sol_nav-tenants'
};

const CONTENT_ITEM_ACCOUNTING: IContentNavigationItem = {
    id: CONTENT_ITEM_ACCOUNTING_ID,
    title: 'navigation.content.expenses',
    path: CONTENT_ITEM_ACCOUNTING_PATH,
    icon: 'icon-sol_nav-accounting'
};

const CONTENT_ITEM_PROPERTY_PORTFOLIO: IContentNavigationItem = {
    id: CONTENT_ITEM_PROPERTY_PORTFOLIO_ID,
    title: 'navigation.content.propertyPortfolio',
    path: CONTENT_ITEM_PROPERTY_PORTFOLIO_PATH,
    icon: 'icon-sol_nav-investment'
};

const CONTENT_ITEM_INFORMATION: IContentNavigationItem = {
    id: CONTENT_ITEM_INFORMATION_ID,
    title: 'navigation.content.rental',
    path: CONTENT_ITEM_INFORMATION_PATH,
    icon: 'icon-out_house_basic'
};

const CONTENT_ITEM_INCIDENTS: IContentNavigationItem = {
    id: CONTENT_ITEM_INCIDENTS_ID,
    title: 'navigation.content.incidents',
    path: CONTENT_ITEM_INCIDENTS_PATH,
    icon: 'icon-returns'
};

export const CONTENT_SELECTION_ITEMS = [
    CONTENT_ITEM_DASHBOARD,
    CONTENT_ITEM_INFORMATION,
    CONTENT_ITEM_TENANTS,
    CONTENT_ITEM_INCIDENTS,
    CONTENT_ITEM_ACCOUNTING,
    CONTENT_ITEM_PROPERTY_PORTFOLIO
];
