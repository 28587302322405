import { ITEM_PLACEHOLDER_TYPE } from '../constants/placeholder.const';
import { reject } from 'lodash';

export function isPlaceholderItem(item: any): item is any {
    return item && !item.id && item.entityType === ITEM_PLACEHOLDER_TYPE;
}

export function filterPlaceholderItems<T>(array: T[]): T[] {
    return reject(array, isPlaceholderItem);
}

export function createPlaceholderItem<T>(item: T): T {
    return {
        ...(item as any),
        id: undefined,
        entityType: ITEM_PLACEHOLDER_TYPE
    };
}
