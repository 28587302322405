import { IBuildingAddress } from '@zenhomes/domain/property';

export function stringifyAddress(address: IBuildingAddress) {
    return `${address.street} ${address.house} ${address.city} ${address.zip} ${address.countryCode}`;
}

export function createStreetViewImgUrl(address: IBuildingAddress, apiKey: string) {
    const loc = stringifyAddress(address);

    return (
        `https://maps.googleapis.com/maps/api/streetview?key=${apiKey}` +
        `&location=${encodeURIComponent(loc)}` +
        `&size=640x480`
    );
}

export function createStreetViewMetadataUrl(address: IBuildingAddress, apiKey: string) {
    const loc = stringifyAddress(address);

    return (
        `https://maps.googleapis.com/maps/api/streetview/metadata?key=${apiKey}` +
        `&location=${encodeURIComponent(loc)}` +
        `&size=640x480`
    );
}

const getCustomMarkerConfig = (publicImgUrl: string): string => {
    if (!publicImgUrl) {
        return '';
    }
    // Google will only retrieve the image over http and not https
    const imageUrl = encodeURIComponent(`http:${publicImgUrl}/googlemap-marker.png`);
    return `icon:${imageUrl}|`;
};

export function createStaticMapImgUrl(
    address: IBuildingAddress,
    apiKey: string,
    publicImgUrl?: string
) {
    const loc = stringifyAddress(address);
    const marker = getCustomMarkerConfig(publicImgUrl);

    return (
        `https://maps.googleapis.com/maps/api/staticmap?key=${apiKey}` +
        `&size=640x640` +
        `&zoom=14` +
        `&scale=1` +
        `&center=${encodeURIComponent(loc)}` +
        `&markers=size:med|${marker}${encodeURIComponent(loc)}` +
        `&style=feature:administrative|element:labels.text.fill|color:0x444444` +
        `&style=feature:landscape|element:all|color:0xf2f2f2` +
        `&style=feature:poi|element:all|visibility:off` +
        `&style=feature:road|element:all|saturation:-100|lightness:45` +
        `&style=feature:road.highway|element:all|visibility:simplified` +
        `&style=feature:road.arterial|element:labels.icon|visibility:off` +
        `&style=feature:transit|visibility:off` +
        `&style=feature:water|element:all|color:0x46bcec|visibility:on`
    );
}
