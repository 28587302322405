import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsActions } from '@zenhomes/analytics';

const DisclaimerCollapsed = 'disclaimer_collapsed';

@Injectable({
    providedIn: 'root'
})
export class DisclaimerService {
    constructor(private store: Store<any>) {}

    onDisclaimerCollapsed(disclaimerName: string) {
        this.store.dispatch(
            new AnalyticsActions.TrackEvent(DisclaimerCollapsed, {
                disclaimer_name: disclaimerName
            })
        );
    }
}
