import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';

const WIDTH_BREAKPOINTS = [576, 768, 992, 1200];
export const MOBILE_NAVIGATION_BREAKPOINT = 992;
export const TABLET_NAVIGATION_BREAKPOINT = 768;
export const SMALL_DESKTOP_NAVIGATION_BREAKPOINT = 900;

export type DeviceCategory = 'app-android' | 'app-ios' | 'web-android' | 'web-ios' | 'web-desktop';

@Injectable()
export class DeviceService {
    private userAgent: string = window.navigator.userAgent;

    isMobile$: Observable<boolean>;
    isTablet$: Observable<boolean>;

    constructor() {
        this.isMobile$ = Observable.fromEvent(window, 'resize')
            .startWith(this.isMobile() as any)
            .map(() => this.isMobile())
            .distinctUntilChanged();

        this.isTablet$ = Observable.fromEvent(window, 'resize')
            .startWith(this.isTablet() as any)
            .map(() => this.isTablet())
            .distinctUntilChanged();
    }

    setDeviceInfoOnBody() {
        let bodyElem: HTMLElement = document.querySelector('body');
        if (this.isAndroid()) {
            bodyElem.classList.add('android');
        }
        if (this.isIphone()) {
            bodyElem.classList.add('iphone');
        }
        if (this.isChrome()) {
            bodyElem.classList.add('chrome');
        }
        if (this.isSafari()) {
            bodyElem.classList.add('safari');
        }
    }

    getUserAgent() {
        return this.userAgent;
    }

    isAndroid(): boolean {
        return /android/i.test(this.userAgent);
    }

    isIphone(): boolean {
        // http://stackoverflow.com/a/9039885/2246938
        return /iPhone/.test(this.userAgent) && !window['MSStream'];
    }

    isIos() {
        return /ios/i.test(this.userAgent);
    }

    isWebkit(): boolean {
        return /WebKit/i.test(this.userAgent);
    }

    isChrome() {
        return this.isWebkit() && /CriOS|Chrome/i.test(this.userAgent);
    }

    isSafari(): boolean {
        return this.isWebkit() && !this.isChrome();
    }

    getWindowBreakpoint() {
        let currentWidth = window.innerWidth;
        for (let i = 0; i < WIDTH_BREAKPOINTS.length; i++) {
            if (WIDTH_BREAKPOINTS[i] > currentWidth) {
                return i;
            }
        }
        return WIDTH_BREAKPOINTS.length;
    }

    isMobile() {
        return window.innerWidth <= MOBILE_NAVIGATION_BREAKPOINT;
    }

    isTablet() {
        return (
            window.innerWidth >= TABLET_NAVIGATION_BREAKPOINT &&
            window.innerWidth < MOBILE_NAVIGATION_BREAKPOINT
        );
    }
}
