import { Injectable } from '@angular/core';
import { BankAccountsApiService } from '../../services/bank-accounts.api.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MessagesService } from '@zenhomes/toast-messages';
import { userActionTypes } from '@zenhomes/user-core';
import * as bankAccountsActions from '../actions/bank-accounts.actions';
import { mergeMap, switchMap, map, tap, catchError, filter, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { hoursDifference } from '../../utils/hours-difference';
import { getLatestUpdatedTime } from '../../utils/get-updated-time';

@Injectable()
export class BankAccountsEffects {
    constructor(
        private bankAccountsApiService: BankAccountsApiService,
        private messagesService: MessagesService,
        private actions$: Actions
    ) {}

    @Effect()
    dispatchLoadingAction$ = this.actions$.pipe(
        ofType(userActionTypes.USER_LOAD_USER_SUCCESS),
        mergeMap(() => {
            return [new bankAccountsActions.BankAccountsLoadBankAccounts()];
        })
    );

    @Effect()
    loadBankAccounts$ = this.actions$.pipe(
        ofType(bankAccountsActions.BankAccountsLoadBankAccounts.TYPE),
        switchMap(() => {
            return this.bankAccountsApiService.loadBankAccounts().pipe(
                map(
                    (bankAccounts: any[]) =>
                        new bankAccountsActions.BankAccountsLoadBankAccountsSuccess(bankAccounts)
                ),
                catchError(err =>
                    err.status === 404
                        ? of(new bankAccountsActions.BankAccountsLoadBankAccountsSuccess([]))
                        : of(new bankAccountsActions.BankAccountsLoadBankAccountsFail())
                )
            );
        })
    );

    @Effect()
    loadBankAccountsSuccess$ = this.actions$.pipe(
        ofType(bankAccountsActions.BankAccountsLoadBankAccountsSuccess.TYPE),
        take(1),
        filter(({ payload }: bankAccountsActions.BankAccountsLoadBankAccountsSuccess) => {
            const bankAccounts = payload;
            if (bankAccounts.length < 1) return false;

            const HOURS_TO_REFRESH = 2;
            let latestUpdateDate = getLatestUpdatedTime(bankAccounts);
            const currentTime = new Date(Date.now()).toString();

            return hoursDifference(currentTime, latestUpdateDate) >= HOURS_TO_REFRESH;
        })
    );

    @Effect({ dispatch: false })
    showNotyOnError$ = this.actions$.pipe(
        ofType(bankAccountsActions.BankAccountsLoadBankAccountsFail.TYPE),
        tap(() => this.messagesService.showMessage('errors.default', 'DANGER', true))
    );
}
