import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { getInitials } from '@zenhomes/sauron';

const DEFAULT_INITIALS = '-';
const MAX_NAMES = 2;
const MAX_INITIALS = 3;

@Component({
    selector: 'zhm-initials-list',
    templateUrl: './initials-list.component.html',
    styleUrls: ['./initials-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitialsListComponent implements OnInit {
    @Input() fullNames: string[];

    limitNames = MAX_NAMES;
    limitInitials = MAX_INITIALS;

    constructor() {}

    ngOnInit() {}

    trackByIndex(index: number) {
        return index;
    }

    getInitials(fullName: string) {
        return getInitials(fullName || DEFAULT_INITIALS);
    }
}
