/// <reference path="jwt-decode.d.ts" />

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { StorageService } from '@zenhomes/device';
import * as authActions from '../state/actions/auth.actions';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

const AUTH_TOKEN_STORAGE_KEY = 'auth_token';

@Injectable()
export class TokenService {
    constructor(private store: Store<any>, private storageService: StorageService) {}
    storedPromise: Promise<string>;

    get token(): string {
        return <string>this.storageService.getItem(AUTH_TOKEN_STORAGE_KEY);
    }

    get tokenPromise(): Promise<string> {
        if (this.itHasValidToken) {
            return Promise.resolve(this.token);
        }

        if (!this.storedPromise) {
            this.storedPromise = new Promise(resolve => {
                IFrameHustla.subscribeToMessage(({ type, auth_token }) => {
                    if (type === 'PARENT_CHILD_AUTH_TOKEN_UPDATED') {
                        this.token = auth_token;
                        this.storedPromise = null;
                        resolve(auth_token);
                    }
                });

                IFrameHustla.sendMessage({ type: 'CHILD_ACCESS_TOKEN_EXPIRED' });
            });
        }

        return this.storedPromise;
    }

    set token(token) {
        this.storageService.setItem(AUTH_TOKEN_STORAGE_KEY, token);
    }

    get itHasValidToken(): boolean {
        const token = this.token;
        const isTokenEmpty = isEmpty(token);
        const isTokenExpired = token && this.checkIfTokenIsExpired(token);

        return !isTokenEmpty && !isTokenExpired;
    }

    createAuthHeader() {
        return { name: 'Authorization', value: `Bearer ${this.token}` };
    }

    removeToken() {
        this.storageService.deleteItem(AUTH_TOKEN_STORAGE_KEY);
    }

    checkIfTokenIsExpired(token: any) {
        const currentTime = Date.now() / 1000;

        const decoded: any = jwtDecode(token);

        return Number(decoded.exp) < currentTime;
    }

    logout() {
        this.store.dispatch(new authActions.AuthLogout());
    }
}
