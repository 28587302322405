export const environment = {
    production: true,
    ENVIRONMENT: 'dev',
    APP: {
        ZHM_API_HOST: 'https://vermietet-api.sandbox-immobilienscout24.de',
        API_AUTH0_CLIENT: 'XqtBTPt9R3uGyg4dIngBcn0Rf1vrXIKt',
        API_AUTH0_DOMAIN: 'auth0.vermietet.is24.de',
        API_GOOGLE_MAPS_STATIC_KEY: 'AIzaSyC2RbEeBtysxTBLlccmuR_lVtgt73SCuHQ',
        IS24_HOST: 'https://www.sandbox-immobilienscout24.de',
        VDE_PORTAL_ROOT: 'https://vermietet.sandbox-immobilienscout24.de'
    },
    DATADOG_RUM: {
        ENABLED: false,
        ENVIRONMENT: ''
    },
    PHRASE: {
        ENABLED_LIVE_TRANSLATIONS: true,
        APP_NAME: 'portal-frontend',
        PROJECT_ID: '8f7445ddcd42dea35b163a0753a6ee03',
        STATIC_KEY: '6eb8fc853aba07fdc6da1d9a953e28d784f02abdb8b501dc036efa8802d157ca'
    },
    DOMAIN_MAP: [
        {
            parent: 'https://vermietet.sandbox-immobilienscout24.de',
            child: 'https://vermietet-next.sandbox-immobilienscout24.de'
        },
        {
            parent: 'https://vermietet.immobilienscout24.de',
            child: 'https://vermietet-next.immobilienscout24.de'
        },
        {
            parent: 'https://vermietet.sandbox-immobilienscout24.de',
            child: 'http://localhost:8080'
        }
    ]
};
