import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { OptimisticUpdateModule } from '@zenhomes/optimistic-update-util';

import { OverpaymentListItemComponent } from '@payment-core/components/overpayment-list-item/overpayment-list-item.component';
import { PaymentCommentPipe } from '@payment-core/pipes/payment-comment.pipe';
import { PaymentCounterpartyNamePipe } from '@payment-core/pipes/payment-counterparty-name.pipe';
import { PaymentDatePipe } from '@payment-core/pipes/payment-date.pipe';
import { PaymentTypeIconPipe } from '@payment-core/pipes/payment-type-icon.pipe';
// Pipes
import { PaymentApiService } from '@payment-core/services/payment-api.service';
// Providers
import { PaymentService } from '@payment-core/services/payment.service';
import { ElementsModule } from '@zenhomes/elements';
import { PaymentListItemComponent } from './components/payment-list-item/payment-list-item.component';
import { PaymentBankTransferComponent } from './components/payment-bank-transfer/payment-bank-transfer.component';
import { ClusterPurposePipe } from './pipes/cluster-purpose.pipe';
import { InvoicesNumberPipe } from './pipes/invoices-number.pipe';
import { PaymentDragAndDropService } from './services/payment-drag-and-drop.service';
// State Management
import { PaymentEffects } from './state/effects/payment.effects';
import { paymentCoreReducer } from './state/index';

const PUBLIC_COMPONENTS = [
    PaymentListItemComponent,
    OverpaymentListItemComponent,
    PaymentBankTransferComponent
];

const PIPES = [
    InvoicesNumberPipe,
    PaymentTypeIconPipe,
    PaymentCommentPipe,
    PaymentCounterpartyNamePipe,
    PaymentDatePipe,
    ClusterPurposePipe
];

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('PaymentCoreModule', paymentCoreReducer),
        EffectsModule.forFeature([PaymentEffects]),
        TranslateModule,
        ElementsModule,
        OptimisticUpdateModule
    ],
    declarations: [...PIPES, ...PUBLIC_COMPONENTS],
    providers: [],
    exports: [...PIPES, ...PUBLIC_COMPONENTS]
})
export class PaymentCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PaymentCoreModule,
            providers: [PaymentApiService, PaymentService, PaymentDragAndDropService]
        };
    }
}
