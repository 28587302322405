// Invoice Categories Labels
export const INVOICE_CATEGORIES_LABELS = {
    rent: {
        label: 'lease.rent.title'
    },
    deposit: {
        label: 'lease.deposit.title'
    },
    loan: {
        label: 'loan'
    }
};

// Invoice Categories Labels
export const INVOICE_SUBCATEGORIES_LABELS = {
    vat: {
        label: 'vat'
    }
};

export enum PAYMENT_MONTHS {
    JANUARY = '1',
    FEBRUARY = '2',
    MARCH = '3',
    APRIL = '4',
    MAY = '5',
    JUNE = '6',
    JULY = '7',
    AUGUST = '8',
    SEPTEMBER = '9',
    OCTOBER = '10',
    NOVEMBER = '11',
    DECEMBER = '12'
}

export enum PAYMENT_WEEKLY_DAY {
    MONDAY = '1',
    TUESDAY = '2',
    WEDNESDAY = '3',
    THURSDAY = '4',
    FRIDAY = '5',
    SATURDAY = '6',
    SUNDAY = '7'
}

export const PAYDAY_TO_WEEK_DAY_MAP = {
    [PAYMENT_WEEKLY_DAY.MONDAY]: 'time.day_01',
    [PAYMENT_WEEKLY_DAY.TUESDAY]: 'time.day_02',
    [PAYMENT_WEEKLY_DAY.WEDNESDAY]: 'time.day_03',
    [PAYMENT_WEEKLY_DAY.THURSDAY]: 'time.day_04',
    [PAYMENT_WEEKLY_DAY.FRIDAY]: 'time.day_05',
    [PAYMENT_WEEKLY_DAY.SATURDAY]: 'time.day_06',
    [PAYMENT_WEEKLY_DAY.SUNDAY]: 'time.day_07'
};
