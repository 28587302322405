import { Action } from '@ngrx/store';
import { IDocument } from '@zenhomes/domain/document';
import { IDocumentMetadataFormOpenParams } from '../../interfaces';

export class Open implements Action {
    static readonly TYPE = '[DocumentMetadataForm] Open metadata form';
    readonly type = Open.TYPE;
    constructor(public payload: IDocumentMetadataFormOpenParams) {}
}

export class Cancel implements Action {
    static readonly TYPE = '[DocumentMetadataForm] Close metadata form';
    readonly type = Cancel.TYPE;
}

export class SaveSuccess implements Action {
    static readonly TYPE = '[DocumentMetadataForm] Save metadata form success';
    readonly type = SaveSuccess.TYPE;
    constructor(public document: IDocument) {}
}

export class SaveFail implements Action {
    static readonly TYPE = '[DocumentMetadataForm] Save metadata form fail';
    readonly type = SaveFail.TYPE;
}
