import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { formatUrl } from '@zenhomes/sauron';
import { InterceptorsConfigToken, InterceptorsConfig } from '../interceptors.config';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    constructor(@Inject(InterceptorsConfigToken) private config: InterceptorsConfig) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const changedRequest = request.clone({
            url: formatUrl(request.url, this.config.apiHost)
        });

        return next.handle(changedRequest);
    }
}
