import { HttpParams } from '@angular/common/http';
import { forOwn, isEmpty, isNumber, omitBy, isBoolean, isArray } from 'lodash';

export const convertObjectToHttpParams: (searchParams: any) => HttpParams = searchParams => {
    let params = new HttpParams();

    forOwn(
        omitBy(searchParams, item => {
            return isEmpty(item) && !isNumber(item) && !isBoolean(item);
        }),
        (value: string | string[], key) => {
            if (isArray(value)) {
                value.forEach(val => {
                    params = params.append(key, val);
                });
                return params;
            } else {
                return (params = params.set(key, value.toString()));
            }
        }
    );

    return params;
};
