import { parsePropertyKey, getPropertySelectionKey } from '@zenhomes/property-core';
import { isNull, isString, isEmpty, keys, isFinite, forEach } from 'lodash';
import { IPropertySelection } from '@zenhomes/domain/property';
import { Location } from '@angular/common';
import { ParamMap } from '@angular/router';
import { FILTER_AMOUNT_MIN, FILTER_AMOUNT_MAX } from '../constants/filter.constants';
import { BookmarkableFilterOptions } from '../interfaces';
import { isArrayOfStrings } from '@zenhomes/sauron';

interface BookmarkParamSerializer {
    serialize: (
        params: URLSearchParams,
        key: string,
        value: any,
        filter: BookmarkableFilterOptions,
        paginationStart: string
    ) => void;
    deserialize: (paramMap: ParamMap, paginationStart: string) => any;
}

const bookmarkSerializers: { [key: string]: BookmarkParamSerializer } = {
    page: {
        serialize: (params, key, value, filter, paginationStart) => {
            if ((isString(value) || isFinite(value)) && value > paginationStart) {
                params.set('page', value as any);
            }
        },
        deserialize: (paramMap, paginationStart) => {
            return paramMap.get('page') || paginationStart;
        }
    },
    query: {
        serialize: (params, key, value, filter) => {
            if (isString(value) && !isEmpty(value)) {
                params.set(key, encodeURIComponent(value as any));
            }
        },
        deserialize: paramMap => {
            return paramMap.has('query') ? decodeURIComponent(paramMap.get('query')) : null;
        }
    },
    bankAccountId: {
        serialize: (params, key, value, filter) => {
            if ((isString(value) && !isEmpty(value)) || isFinite(value)) {
                params.set('bank', value as any);
            }
        },
        deserialize: paramMap => {
            return paramMap.has('bank') ? paramMap.get('bank') : null;
        }
    },
    propertySelection: {
        serialize: (params, key, value, filter) => {
            forEach(filter.propertySelection, property => {
                params.append('property', getPropertySelectionKey(property));
            });
        },
        deserialize: paramMap => {
            const propertySelection = paramMap
                .getAll('property')
                .map(propertyKey => parsePropertyKey(propertyKey))
                .filter(p => !isNull(p))
                .map(p => {
                    return {
                        building: { id: p.buildingId },
                        unit: p.unitId ? { id: p.unitId } : null
                    } as IPropertySelection;
                });

            return propertySelection;
        }
    },
    counterpartySelection: {
        serialize: (params, key, value, filter) => {
            const mapCounterpartyParams = isArrayOfStrings(filter.counterpartySelection)
                ? (c: string) => params.append('counterpartyName', encodeURIComponent(c))
                : (c: any) => params.append('counterpartyId', c.id);

            forEach(filter.counterpartySelection, mapCounterpartyParams);
        },
        deserialize: paramMap => {
            const counterparties = paramMap.getAll('counterpartyName').map(decodeURIComponent);
            const counterpartyIds = paramMap.getAll('counterpartyId').map(id => ({ id }));
            return counterpartyIds.length > 0 ? counterpartyIds : counterparties;
        }
    },
    status: {
        serialize: (params, key, value, filter) => {
            forEach(filter.status, status => params.append('status', status));
        },
        deserialize: paramMap => {
            return paramMap.getAll('status') as any;
        }
    },
    transactionType: {
        serialize: (params, key, value, filter) => {
            forEach(filter.transactionType, transactionType =>
                params.append('type', transactionType)
            );
        },
        deserialize: paramMap => {
            return paramMap.getAll('type') as any;
        }
    },
    subjectSelection: {
        serialize: (params, key, value, filter) => {
            forEach(filter.subjectSelection, subject => params.append('subject', subject.id));
        },
        deserialize: paramMap => {
            return paramMap.getAll('subject').map(id => ({ id }));
        }
    },
    userCategorySelection: {
        serialize: (params, key, value, filter) => {
            forEach(filter.userCategorySelection, userCategory =>
                params.append('category', userCategory.id)
            );
        },
        deserialize: paramMap => {
            return paramMap.getAll('category').map(id => ({ id }));
        }
    },
    matchingStatus: {
        serialize: (params, key, value, filter) => {
            forEach(filter.matchingStatus, status => params.append('matching', status));
        },
        deserialize: paramMap => {
            return paramMap.getAll('matching') as any;
        }
    },
    startDate: {
        serialize: (params, key, value, filter) => {
            if (isString(value) && !isEmpty(value)) {
                params.set('start', value as any);
            }
        },
        deserialize: paramMap => {
            return paramMap.get('start');
        }
    },
    endDate: {
        serialize: (params, key, value, filter) => {
            if (isString(value) && !isEmpty(value)) {
                params.set('end', value as any);
            }
        },
        deserialize: paramMap => {
            return paramMap.get('end');
        }
    },
    amountFrom: {
        serialize: (params, key, value, filter) => {
            if (isFinite(value) && (value !== FILTER_AMOUNT_MIN && value !== FILTER_AMOUNT_MAX)) {
                params.set('min', value as any);
            }
        },
        deserialize: paramMap => {
            return parseFloat(paramMap.get('min')) || FILTER_AMOUNT_MIN;
        }
    },
    amountTo: {
        serialize: (params, key, value, filter) => {
            if (isFinite(value) && (value !== FILTER_AMOUNT_MIN && value !== FILTER_AMOUNT_MAX)) {
                params.set('max', value as any);
            }
        },
        deserialize: paramMap => {
            return parseFloat(paramMap.get('max')) || FILTER_AMOUNT_MAX;
        }
    },
    listView: {
        serialize: (params, key, value, filter) => {
            if (value === true) {
                params.set('listView', value as any);
            }
        },
        deserialize: paramMap => {
            return paramMap.get('listView') === 'true';
        }
    },
    // Param to allow navigation back to where user came from
    navigateBack: {
        serialize: (params, key, value, filter) => {
            if (value) {
                params.set('navigateBack', value as any);
            }
        },
        deserialize: paramMap => {
            return paramMap.get('navigateBack') === 'true';
        }
    }
};

export function mapFilterStateToBookmark(
    filter: BookmarkableFilterOptions,
    paginationStart: string = '1'
): ParamMap {
    let params = new URLSearchParams();

    keys(filter).forEach((key: keyof BookmarkableFilterOptions) => {
        const value = filter[key];

        if (bookmarkSerializers[key]) {
            bookmarkSerializers[key].serialize(params, key, value, filter, paginationStart);
        }
    });

    return params as any;
}

export function mapBookmarkToFilterState(
    paramMap: ParamMap,
    paginationStart: string = '1'
): BookmarkableFilterOptions {
    const filterOptions: BookmarkableFilterOptions = {};
    keys(bookmarkSerializers).forEach(key => {
        filterOptions[key] = bookmarkSerializers[key].deserialize(paramMap, paginationStart);
    });

    return filterOptions;
}

export function replaceQueryString(location: Location, queryString: string) {
    const locationWithoutQuery = location.path(false).split('?')[0];
    location.replaceState(locationWithoutQuery, queryString);
}
