import { Pipe, PipeTransform } from '@angular/core';
import { ICategorizationUserCategory } from '@zenhomes/domain/category';
import { find, isEmpty } from 'lodash';
import { USER_CATEGORY_ICON } from '../data/user-category';

@Pipe({ name: 'userCategoryIconColor' })
export class UserCategoryIconColorPipe implements PipeTransform {
    transform(category: ICategorizationUserCategory): string {
        if (!category) return '';
        const icon = find(USER_CATEGORY_ICON, i => i.id === category.id);

        if (isEmpty(icon)) {
            return '';
        } else {
            return icon.color;
        }
    }
}
