import { Pipe, PipeTransform } from '@angular/core';
import { IDocument } from '@zenhomes/domain/document';
import { getDocumentExtensionsIconClassName } from '../utils/documents-extensions-icons.util';

@Pipe({
    name: 'zhmThumbnailIconClass'
})
export class ThumbnailIconClassPipe implements PipeTransform {
    transform(doc: IDocument): any {
        if (!doc || !doc.extension) {
            return '';
        }
        return getDocumentExtensionsIconClassName(doc.extension);
    }
}
