export enum IBuildingType {
    Single = 'SINGLE',
    Multi = 'MULTI'
}

export enum IBuildingOwnershipBasedType {
    Single = 'SINGLE',
    Multi = 'MULTI',
    MultiCondo = 'MULTICONDO'
}

export enum IUnitType {
    Apartment = 'APARTMENT',
    CommercialUnit = 'COMMERCIAL_UNIT',
    Garage = 'GARAGE',
    Other = 'OTHER'
}

export enum AcquisitionType {
    Purchase = 'PURCHASED',
    Constructed = 'CONSTRUCTED',
    Inherited = 'INHERITED'
}
