import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';

import { DateService } from './services/date.service';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DayPipe } from './pipes/day.pipe';
import { LocalDateTime } from './pipes/local-date-time.pipe';
import { IsPastDatePipe } from './pipes/is-past-date.pipe';
import { FormsModule } from '@angular/forms';
import { DeviceModule } from '@zenhomes/device';

const COMPONENTS = [DatepickerComponent];

const PIPES = [DayPipe, LocalDateTime, IsPastDatePipe];

@NgModule({
    imports: [CommonModule, FormsModule, DeviceModule, NgxMyDatePickerModule.forRoot()],
    exports: [...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
    providers: [DateService, ...PIPES]
})
export class DateModule {}
