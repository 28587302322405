export function setButtonIcon(icon: string) {
    if (icon === 'trash') {
        return 'icon-trash';
    } else if (icon === 'pencil') {
        return 'icon-pencil';
    } else if (icon === 'refresh') {
        return 'icon-rotate-right';
    } else if (icon === 'cross') {
        return 'icon-remove';
    } else if (icon === 'checkmark') {
        return 'icon-check-mark';
    } else {
        return '';
    }
}
