import { isApartmentUnit, isSingleBuildingUtil } from './property-type-guards.util';
import { IBuildingModel, IUnitModel } from '../interfaces';

const COUNTRY_CODE_GERMANY = 'DE';

export function isLocatedInGermany(building: IBuildingModel) {
    return !!building && building.address && building.address.countryCode === COUNTRY_CODE_GERMANY;
}

export function isPropertyPublishable(building: IBuildingModel, unit: IUnitModel) {
    const isSFH = isSingleBuildingUtil(building);

    return !!building && isApartmentUnit(unit) && !isSFH && isLocatedInGermany(building);
}
