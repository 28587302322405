import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router';

@Component({
    selector: 'zhm-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
    private isLoadingComponentShown: boolean = true;

    get loading() {
        return this.isLoadingComponentShown;
    }

    set loading(value: boolean) {
        if (value !== this.isLoadingComponentShown) {
            this.isLoadingComponentShown = value;
            this.changeDetectorRef.detectChanges();
        }
    }

    private routerSubscription: Subscription;

    constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef) {
        this.changeDetectorRef.detach();
    }

    ngOnInit() {
        this.routerSubscription = this.router.events.subscribe(event => {
            this.navigationInterceptor(event);
        });
    }

    ngOnDestroy() {
        this.routerSubscription && this.routerSubscription.unsubscribe();
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: any): void {
        if (event instanceof NavigationStart) {
            this.loading = null;
            setTimeout(() => {
                this.loading = this.loading === null ? true : this.loading;
            }, 100);
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            this.loading = false;
        }
    }
}
