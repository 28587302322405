import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'zhm-selector-icon-container',
    templateUrl: './selector-icon-container.component.html',
    styleUrls: ['./selector-icon-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorIconContainerComponent implements OnInit {
    @Input() items: any;
    @Input() iconColor: string;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    trackById(index: number, items: any) {
        return items.id;
    }

    onSelect(item: any) {
        this.isSelected(item) ? this.valueChange.emit(null) : this.valueChange.emit(item.id);
    }

    isSelected(item: any): boolean {
        return item.id === this.value;
    }
}
