import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'zhm-empty-filtered-list-message',
    templateUrl: './empty-filtered-list-message.component.html',
    styleUrls: ['./empty-filtered-list-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyFilteredListMessageComponent implements OnInit {
    @Output() resetFilters: EventEmitter<void> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    reset() {
        this.resetFilters.emit();
    }
}
