import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    HostBinding,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { TeaserStatusService } from '../../services/teaser-status.service';
import { Subscription } from 'rxjs';
import { Colors } from '@zenhomes/ui';

@Component({
    selector: 'zhm-teaser-wrapper',
    templateUrl: './teaser-wrapper.component.html',
    styleUrls: ['./teaser-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserWrapperComponent implements OnInit, OnDestroy {
    @HostBinding('class.dismissed')
    get display() {
        return !this.isTeaserActive;
    }

    @Input() teaserName: string;

    isTeaserActive: boolean;
    closeIconColor: string = Colors.greyBase800;
    closeIconSize: string = '0.875rem';

    private sub: Subscription;

    constructor(private ref: ChangeDetectorRef, private teaserStatusService: TeaserStatusService) {}

    ngOnInit() {
        this.sub = this.teaserStatusService
            .isTeaserActive$(this.teaserName)
            .subscribe(isTeaserActive => {
                this.isTeaserActive = isTeaserActive;
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }

    onDismissTeaser(event: Event) {
        event.stopPropagation();
        this.teaserStatusService.dismissTeaser(this.teaserName);
    }
}
