import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'zhm-input-number',
    templateUrl: 'input-number.component.html',
    styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent {
    @Input() hasType: boolean = false;
    @Input() value: number;
    @Output() valueChange: EventEmitter<number> = new EventEmitter();

    onInput(event: any) {
        const { value } = event.target;
        // IE11 can return values with commas, which break the Java parser
        const correctedValue = value ? value.replace(',', '.') : value;
        this.valueChange.emit(correctedValue);
    }
}
