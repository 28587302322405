import { IInvoice, IInvoiceItem } from '../interfaces';

export function isInvoiceWithoutProperties(invoice: IInvoice): boolean {
    if (!invoice || !invoice.items) return false;

    return invoice.items.every(isPortfolioItem);
}

export function hasInvoicePortfolioItems(invoice: IInvoice): boolean {
    if (!invoice || !invoice.items) return false;

    return invoice.items.some(isPortfolioItem);
}

function isPortfolioItem(item: IInvoiceItem) {
    return !item.building && !item.unit;
}
