import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import { isEventTargetInside } from '@zenhomes/dom-interaction';

@Component({
    selector: 'zhm-popup-menu',
    templateUrl: './popup-menu.component.html',
    styleUrls: ['./popup-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(document:mousedown)': 'onMouseDown($event)'
    }
})
export class PopupMenuComponent implements OnInit {
    @Input() showFooter: boolean;
    @Input() tooltipPopup: boolean;
    @Input() alignment: string;
    @Input() autoCloseOnClick: boolean;
    @Output() isOpenChange = new EventEmitter<boolean>();

    isOpen: boolean;

    constructor(private elementRef: ElementRef, private ref: ChangeDetectorRef) {}

    ngOnInit() {}

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    onIsOpenChange(isOpen: boolean): void {
        this.isOpen = isOpen;
        this.isOpenChange.emit(this.isOpen);
    }

    onMouseDown(event: Event) {
        if (!this.isOpen) return;

        if (!isEventTargetInside(this.elementRef, event)) {
            this.isOpen = false;
            this.ref.markForCheck();
        }
    }
}
