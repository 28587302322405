import {
    IExpenseContract,
    IExpenseContractItem,
    IExpenseContractPeriod
} from '@zenhomes/domain/contract';
import { IInvoicePeriod } from '@zenhomes/domain/invoice';
import { get } from '@zenhomes/sauron';
import { curry } from 'ramda';

export const getLastPeriodItems = curry((contract: IExpenseContract): IExpenseContractItem[] => {
    return get(contract, 'periods', 0, 'items');
});

export const getPeriodTotal = curry((period: IExpenseContractPeriod | IInvoicePeriod): number => {
    const periodItems = get(period, 'items') || [];
    return periodItems
        ? periodItems.reduce(
              (accum: number, item: IExpenseContractItem) =>
                  (item.signedAmount || item.amount) + accum,
              0
          )
        : 0;
});

export const getLastPeriodTotal = curry((contract: IExpenseContract): number => {
    const lastPeriod = get(contract, 'periods', 0);
    return getPeriodTotal(lastPeriod);
});
