import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EventRouter, RoutingEvent } from '@zenhomes/frontend-routing';
import { ProductSlug } from '../../types';

@Component({
    selector: 'zhm-product-teaser-row',
    templateUrl: './product-teaser-row.component.html',
    styleUrls: ['./product-teaser-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTeaserRowComponent implements OnInit {
    @Input() teaserTitle: string;
    @Input() description: string;
    @Input() buttonLabel: string;
    @Input() productSlug: ProductSlug;
    @Input() teaserName: string;
    @Input() imageUrl: string;
    @Input() target: string = '_self';

    ngOnInit() {}

    onClick(event: Event) {
        event.stopPropagation();

        EventRouter.dispatch(RoutingEvent.ShopProductOpened, {
            slug: this.productSlug,
            teaser: this.teaserName
        });
    }
}
