import { IPayment, IClusteredPayment } from '@zenhomes/domain/payment';
import { first, last, get } from 'lodash';
import {
    curry,
    propOr,
    length,
    groupBy,
    compose,
    equals,
    keys,
    ifElse,
    complement,
    head
} from 'ramda';

export function isClusteredPayment(
    payment: IClusteredPayment | IPayment | IPayment[]
): payment is IClusteredPayment {
    return payment && !!(payment as IClusteredPayment).payments;
}

function getOldestClusterPayment(payment: IClusteredPayment) {
    return last(payment.payments);
}

export function getPaymentNote(payment: IPayment | IClusteredPayment): string {
    return get(isClusteredPayment(payment) ? getOldestClusterPayment(payment) : payment, 'comment');
}

export function getPaymentCounterpartyName(payment: IPayment | IClusteredPayment): string {
    return get(
        isClusteredPayment(payment) ? getOldestClusterPayment(payment) : payment,
        'counterpartyName'
    );
}

const getPaymentPurpose = propOr('---', 'comment');
const groupByPurpose = groupBy(getPaymentPurpose) as any;
const getPurposes = compose(
    keys,
    groupByPurpose
);

const getFirstPurpose = compose(
    head,
    getPurposes
);
const getPurposeCount = compose(
    length,
    getPurposes
);
export const isNormalCluster = compose(
    equals(1),
    getPurposeCount
);
export const isDeviantCluster = complement(isNormalCluster);

type PurposeTranslator = (n: number) => string;

const getWarningMessage = curry((translateFn: PurposeTranslator) =>
    compose(
        translateFn,
        getPurposeCount
    )
);

export const getPurposeString = curry((translateFn: PurposeTranslator, payments: IPayment[]) => {
    return ifElse(isNormalCluster, getFirstPurpose, getWarningMessage(translateFn))(payments);
});
