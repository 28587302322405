import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { IDocument } from '@zenhomes/domain/document';
import { isImageDocument } from '@zenhomes/domain/document';
import { getApiUrl } from '@util/api-url.util';

@Component({
    selector: 'zhm-file-thumbnail',
    templateUrl: './file-thumbnail.component.html',
    styleUrls: ['./file-thumbnail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileThumbnailComponent implements OnInit {
    @Input() name: string;
    @Input() file: IDocument;
    @Input() previewEnabled: boolean = true;
    @Output() fileClick: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    get isImageDocument() {
        return isImageDocument(this.file);
    }

    get thumbnailUrl() {
        return getApiUrl(this.file.url);
    }

    fileClicked(event: Event) {
        if (this.previewEnabled) {
            event.stopPropagation();
            this.fileClick.emit(this.thumbnailUrl);
        }
    }
}
