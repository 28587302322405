import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined, isNaN, isNull, isInteger } from 'lodash';

import { NumericPipe } from './numeric.pipe';

const PLACEHOLDER = '-';
const NO_DECIMAL_FORMAT: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
};
const DECIMAL_FORMAT: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {
    numericPipe: NumericPipe = new NumericPipe();

    transform(
        value: number,
        hideDecimalsForInt: boolean = false,
        locale: string = 'de-DE'
    ): string {
        const isValid = !isUndefined(value) && !isNaN(value) && !isNull(value);

        if (isValid) {
            const format =
                hideDecimalsForInt && isInteger(value) ? NO_DECIMAL_FORMAT : DECIMAL_FORMAT;
            return this.numericPipe.transform(value, format, locale);
        } else {
            return PLACEHOLDER;
        }
    }
}
