import { getSelectorWrapper } from '@util/selector-wrapper.util';
import { createReducer } from '@zenhomes/ngrx-actions';
import { InvoiceState } from '../constants/invoice-state.constants';
import { InvoiceCoreStore } from '../state/store/invoice.store';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface InvoiceCoreModuleState {
    invoice: InvoiceState;
}

export function invoiceReducerForInvoiceCore(state: any, action: any) {
    return createReducer(InvoiceCoreStore)(state, action);
}

export const invoiceCoreReducer = {
    invoice: invoiceReducerForInvoiceCore
};

// main selector
export const getInvoiceState = (state: any) => state.InvoiceCoreModule.invoice;
export const selectorWrapper = getSelectorWrapper<InvoiceState>(getInvoiceState);

// custom selectors
export const getInvoices = selectorWrapper('invoices');
export const getInvoicesSelected = selectorWrapper('invoicesSelected');
export const getInvoicesBeingLoaded = selectorWrapper('invoicesBeingLoaded');
export const getInvoicesAreLoaded = selectorWrapper('invoicesAreLoaded');
export const getInvoice = selectorWrapper('invoice');
export const getInvoiceWaitingForBackend = selectorWrapper('invoiceWaitingForBackend');
export const getFilterOptions = selectorWrapper('filterOptions');
