import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog.component';
import { DialogWizardService } from './dialog-wizard.service';
import { ConfirmationDialogOptions } from '../types';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';

@Injectable()
export class ConfirmationService {
    constructor(private dialogWizardService: DialogWizardService) {}

    confirm(config: ConfirmationDialogOptions): Observable<boolean> {
        const confirm$ = new Observable<boolean>(subscriber => {
            const dialog = this.dialogWizardService
                .loadConfirmationDialog()
                .getComponentRef<ConfirmationDialogComponent>(ConfirmationDialogComponent).instance;

            dialog.title = config.title;
            dialog.body = config.body;
            dialog.confirmButtonLabel = config.confirmButton;
            dialog.confirmButtonClass = config.confirmButtonClass;
            dialog.cancelButtonLabel = config.cancelButton;
            dialog.warningText = config.warningText;
            dialog.warningParams = config.warningParams;
            dialog.hideConfirmButton = config.hideConfirmButton;

            this.dialogWizardService.openConfirmationDialog();

            const confirmSub = dialog.confirm$.pipe(take(1)).subscribe(confirmResult => {
                subscriber.next(confirmResult);
                subscriber.complete();
            });

            const closeSub = dialog.close$.pipe(take(1)).subscribe(() => {
                subscriber.complete();
            });

            return () => {
                confirmSub && confirmSub.unsubscribe();
                closeSub && closeSub.unsubscribe();

                this.dialogWizardService.destroyComponent(ConfirmationDialogComponent);
            };
        });

        return confirm$.pipe(take(1));
    }
}
