export enum Colors {
    'black' = '#000',
    'white' = '#fff',
    'blackBase900' = '#333333',
    'blue100' = '#E7FDF1',
    'blue200' = '#00FFD0',
    'blue300' = '#00FFD0',
    'blue400' = '#00FFD0',
    'blue500' = '#00FFD0',
    'blue600' = '#00FFD0',
    'blueBase700' = '#00FFD0',
    'blue800' = '#00D9B1',
    'green200' = '#E7FDF1',
    'green300' = '#00D9B1',
    'green500' = '#00D9B1',
    'green600' = '#00D9B1',
    'greenBase700' = '#00D9B1',
    'green800' = '#00807D',
    'yellow100' = '#FFEFCF',
    'yellow200' = '#F2CA26',
    'yellow300' = '#F2CA26',
    'yellow400' = '#F2CA26',
    'yellow500' = '#F6DA67',
    'yellow600' = '#F2CA26',
    'yellowBase700' = '#F2CA26',
    'yellow800' = '#F2CA26',
    'red200' = '#F87063',
    'red300' = '#E74B3C',
    'red400' = '#E74B3C',
    'red500' = '#E74B3C',
    'red600' = '#E74B3C',
    'redBase700' = '#E74B3C',
    'red800' = '#E74B3C',
    'grey100' = '#EAEAEA',
    'grey200' = '#E0E0E0',
    'grey300' = '#C4C4C4',
    'grey400' = '#ADADAD',
    'grey500' = '#676767',
    'grey600' = '#333333',
    'grey700' = '#242424',
    'greyBase800' = '#333333'
}
