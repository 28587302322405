import { Component, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { isEmpty } from 'lodash';

import { IContact } from '@zenhomes/domain/contact';
import { UserService } from '@zenhomes/user-core';
import { IUser } from '@zenhomes/domain/user';

@Component({
    selector: 'contact-popup',
    templateUrl: 'contact-popup.component.html',
    styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent {
    @ViewChild('template', { static: true }) template: ElementRef;
    @Output() onSave: EventEmitter<IContact | IUser> = new EventEmitter();

    modalRef: BsModalRef;
    contact: IContact | IUser;
    title: string;

    constructor(private modalService: BsModalService, private userService: UserService) {}

    open(contact?: IContact | IUser) {
        this.modalRef = this.modalService.show(this.template, { animated: false });
        this.contact = contact;
        this.title = isEmpty(this.contact)
            ? 'addressBookModule.addNewContactPopupTitle'
            : 'addressBookModule.editContactPopupTitle';
    }

    openForEditingUser() {
        this.userService.user$.take(1).subscribe((user: IUser) => this.open(user));
    }

    close() {
        this.modalRef.hide();
    }

    save(data: any) {
        this.close();
        this.onSave.emit(data);
    }
}
