import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { getPropertyParams } from '../utilities/get-property-params.util';
import { Tenancy, UsageList } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class UsagesApiService {
    constructor(private http: HttpClient) {}

    loadUsages(buildingId: string, unitId: string): Observable<any> {
        return this.http.get<UsageList[]>(
            `/usages/micro/list${getPropertyParams(buildingId, unitId)}`
        );
    }
    loadTenancies(buildingId: string, unitId: string): Observable<any> {
        return this.http.get<Tenancy[]>(
            `/usages/tenancy/micro/list${getPropertyParams(buildingId, unitId)}`
        );
    }
}
