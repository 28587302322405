import { Directive, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';

const INPUT_TAG_NAME = 'INPUT';

const DEFAULT_TIMEOUT = 100;

@Directive({
    selector: '[zhmFocusWithTimeout]'
})
export class FocusWithTimeoutDirective implements OnInit {
    constructor(private elementRef: ElementRef) {}

    @Input('zhmFocusWithTimeout') enabled = true;
    @Input('zhmFocusTimeout') timeout = DEFAULT_TIMEOUT;

    ngOnInit() {
        if (!this.enabled) return;

        this.focus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.enabled && changes.enabled && changes.enabled.currentValue === true) {
            this.focus();
        }
    }

    focus() {
        setTimeout(() => {
            const target = this.getTarget();

            if (!target) {
                console.error(
                    'No input element found, for use with the zhmFocusWithTimeout directive'
                );
                return;
            }

            target.focus();
        }, this.timeout);
    }

    private isInput() {
        return this.elementRef.nativeElement.tagName === INPUT_TAG_NAME;
    }

    private getTarget() {
        return this.isInput()
            ? this.elementRef.nativeElement
            : this.elementRef.nativeElement.querySelector('input');
    }
}
