import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { IAddressInputResult } from '../../interfaces/address-input-result.interface';
import { googlePlaceToBuildingAddress } from '../../util/google-places-autocomplete.util';

const DEFAULT_COUNTRY = 'de';

@Component({
    selector: 'zhm-google-address-dropdown',
    templateUrl: './google-address-dropdown.component.html',
    styleUrls: ['./google-address-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleAddressDropdownComponent {
    @Input() disabled: boolean = false;
    @Input() value: string = '';

    @Output() onSelected = new EventEmitter<IAddressInputResult>();

    @ViewChild('address', { static: true }) addressInput: ElementRef;

    countryRestriction = DEFAULT_COUNTRY;

    toggleCountryRestriction() {
        this.countryRestriction =
            this.countryRestriction === DEFAULT_COUNTRY ? undefined : DEFAULT_COUNTRY;
    }

    onCheckboxChange(e: any) {
        this.toggleCountryRestriction();
    }

    onAddressSelected(place: any) {
        const selectedGoogleAddressLabel = this.addressInput.nativeElement.value;
        const selectedAddress = googlePlaceToBuildingAddress(place);

        this.onSelected.emit({ selectedAddress, selectedGoogleAddressLabel });
    }
}
