<div class="zhm--datepicker" [ngClass]="{ readonly: readonly }">
    <ng-container *zhmShowOnDesktopOnly>
        <input
            [id]="id"
            [attr.formControlName]="formControlName"
            class="form-control"
            placeholder="{{ placeholder }}"
            ngx-mydatepicker
            #dp="ngx-mydatepicker"
            (dateChanged)="onDateChange($event)"
            (inputFieldChanged)="onValueChange($event)"
            [options]="options"
            [ngClass]="ngClass"
            [(ngModel)]="val"
            (keydown)="dp.closeCalendar()"
            (click)="openCalendar()"
            (calendarToggle)="onCalendarToggle($event)"
            [readonly]="readonly"
        />
    </ng-container>

    <ng-container *zhmShowOnMobileOnly>
        <input
            type="date"
            #dp
            class="form-control"
            placeholder="{{ placeholder }}"
            (change)="onDateInputChange(dp.value)"
            [ngClass]="ngClass"
            [(ngModel)]="dateValue"
            [readonly]="readonly"
        />
    </ng-container>

    <span (click)="openCalendar()">
        <i class="icon_time icon-out_calendar"></i>
    </span>
</div>
