import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';
import { DateService } from '../services/date.service';
import * as isBefore from 'date-fns/isBefore';
import * as isSameDay from 'date-fns/isSameDay';

@Pipe({
    name: 'isPastDate'
})
export class IsPastDatePipe implements PipeTransform {
    constructor(private dateService: DateService) {}

    transform(date: string): boolean {
        if (isEmpty(date)) return false;

        const toDate = this.dateService.fromBackendFormatToDate(date);
        const currentDate = new Date();

        const isToDateInPast = isBefore(toDate, currentDate) && !isSameDay(toDate, currentDate);

        return isToDateInPast;
    }
}
