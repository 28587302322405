import { Store, Action } from '@zenhomes/ngrx-actions';
import * as actions from '../actions/bank-accounts.actions';
import {
    IBankAccountsState,
    initialBankAccountsStoreState
} from '../constants/bank-accounts.constants';

@Store(initialBankAccountsStoreState)
export class BankAccountsStore {
    @Action(actions.BankAccountsLoadBankAccounts)
    loadBankAccounts(state: IBankAccountsState): IBankAccountsState {
        return { ...state, bankAccountsAreBeingLoaded: true };
    }

    @Action(actions.BankAccountsLoadBankAccountsSuccess)
    loadBankAccountsSuccess(
        state: IBankAccountsState,
        { payload }: actions.BankAccountsLoadBankAccountsSuccess
    ): IBankAccountsState {
        return {
            ...state,
            bankAccountsList: payload,
            bankAccountsAreLoaded: true,
            bankAccountsAreBeingLoaded: false
        };
    }

    @Action(actions.BankAccountsLoadBankAccountsFail)
    loadBankAccountsFail(state: IBankAccountsState): IBankAccountsState {
        return {
            ...state,
            bankAccountsAreLoaded: true,
            bankAccountsAreBeingLoaded: false
        };
    }
}
