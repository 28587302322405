import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { expandFromVoid } from '../../animations/expanding.animation';
import { fadeIn } from '../../animations/fading.animation';
import { IActionSheetAction } from './action-sheet-action.interface';

@Component({
    selector: 'zhm-action-sheet-modal',
    templateUrl: './action-sheet-modal.component.html',
    styleUrls: ['./action-sheet-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [expandFromVoid('300ms', '150ms', '250ms', 'ease-out'), fadeIn()]
})
export class ActionSheetModalComponent {
    @Input() actions: IActionSheetAction[];
    @Input() open: boolean;
    @Output() openChange = new EventEmitter<boolean>();

    onActionClick(event: Event, action: IActionSheetAction) {
        event.stopPropagation();
        action.onClick(event);
        this.openChange.emit(false);
    }

    onOverlayClick(event: Event) {
        event.stopPropagation();
        this.openChange.emit(false);
    }

    onTouchMove(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }
}
