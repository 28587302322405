import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { isUndefined, includes } from 'lodash';

@Component({
    selector: 'zhm-multi-select-icon-container',
    templateUrl: './multi-select-icon-container.component.html',
    styleUrls: ['./multi-select-icon-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectIconContainerComponent implements OnInit {
    @Input() items: any;
    @Input() iconColor: string;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        this.value = this.value || [];
    }

    trackById(index: number, items: any) {
        return items.id;
    }

    onSelect(item: any) {
        const selected = this.isSelected(item);
        let newSelection: any;

        if (selected) {
            newSelection = this.value.filter((i: any) => i !== item.id);
        } else {
            newSelection = [...this.value, item.id];
        }
        this.valueChange.emit(newSelection);
    }

    isSelected(item: any): boolean {
        return includes(this.value, item.id);
    }
}
