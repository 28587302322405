import { propertiesActionTypes } from '../actions/properties.actions';

export interface IPropertiesState {
    propertiesList: any[];
    propertiesAreLoaded: boolean;
    propertiesAreBeingLoaded: boolean;
}

export const initialPropertiesStoreState: IPropertiesState = {
    propertiesList: [],
    propertiesAreLoaded: false,
    propertiesAreBeingLoaded: false
};

export function propertiesReducer(
    state: IPropertiesState = initialPropertiesStoreState,
    action: any
): IPropertiesState {
    switch (action.type) {
        case propertiesActionTypes.LOAD_PROPERTIES: {
            return Object.assign({}, state, {
                propertiesAreBeingLoaded: true
            });
        }

        case propertiesActionTypes.LOAD_PROPERTIES_SUCCESS: {
            return Object.assign({}, state, {
                propertiesList: action.payload.propertiesPage.items,
                propertiesAreLoaded: true,
                propertiesAreBeingLoaded: false
            });
        }

        case propertiesActionTypes.LOAD_PROPERTIES_FAIL: {
            return Object.assign({}, state, {
                propertiesAreLoaded: true,
                propertiesAreBeingLoaded: false
            });
        }

        case propertiesActionTypes.RESET_PROPERTIES: {
            return initialPropertiesStoreState;
        }

        default: {
            return state;
        }
    }
}
