import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsPlaceholderItemPipe } from './pipes/is-placeholder-item.pipe';

@NgModule({
    declarations: [IsPlaceholderItemPipe],
    imports: [CommonModule],
    exports: [IsPlaceholderItemPipe],
    providers: []
})
export class OptimisticUpdateModule {}
