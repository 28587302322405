<div class="zhm--dialog-ConfirmationDialogComponent zhm--dialog-body zhm--dialog-body--scrollable">
    <div class="zhm--dialog-body-wrapper zhm--details-form zhm--dialog-body-scroll">
        <p *ngIf="warningText !== ''" class="warningText">{{ getWarningText() }}</p>
        <p translate>{{ getBody() }}</p>
    </div>

    <div class="zhm--dialog-footer">
        <button type="button" (click)="confirm(false)" class="btn mr-3 btn-secondary">
            {{ getCancelButtonLabel() | translate }}
        </button>
        <ng-container *ngIf="!hideConfirmButton">
            <button
                type="button"
                (click)="confirm(true)"
                class="btn"
                [ngClass]="getConfirmButtonClass()"
            >
                {{ getConfirmButtonLabel() | translate }}
            </button>
        </ng-container>
    </div>
</div>
