import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy
} from '@angular/core';

import { IBuildingModel, PropertyTypeMap, getUnitTypes } from '@zenhomes/domain/property';
import { IUnitType } from '@zenhomes/domain/property';

@Component({
    selector: 'zhm-unit-type-selector',
    templateUrl: './unit-type-selector.component.html',
    styleUrls: ['./unit-type-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitTypeSelectorComponent implements OnInit {
    @Input() building: IBuildingModel;
    @Input() value: IUnitType;

    @Output() valueChange: EventEmitter<IUnitType> = new EventEmitter<IUnitType>();

    unitTypes: PropertyTypeMap[];

    ngOnInit() {
        this.unitTypes = getUnitTypes(this.building);
    }

    onSelectUnitType(type: IUnitType) {
        this.valueChange.emit(type);
    }

    isUnitTypeSelected(unitType: PropertyTypeMap): boolean {
        return unitType.unitType === this.value;
    }

    trackById(index: number, unitType: PropertyTypeMap) {
        return unitType.id;
    }
}
