import { createReducer } from '@zenhomes/ngrx-actions';
import { ContactsStore, IContactsState } from './store/contacts.store';

export interface AddressBookModuleState {
    contacts: IContactsState;
}

export function contactsReducerForAddressBook(state: any, action: any) {
    return createReducer(ContactsStore)(state, action);
}

export const contactsReducer = {
    contacts: contactsReducerForAddressBook
};
