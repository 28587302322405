import { IContact } from '@zenhomes/domain/contact';
import { Action, Store } from '@zenhomes/ngrx-actions';
import * as actions from '../actions/contacts.actions';

export interface IContactsState {
    contactsList: IContact[];
    contactsAreLoaded: boolean;
    contactsAreLoading: boolean;
}

export const initialContactsStoreState: IContactsState = {
    contactsList: [],
    contactsAreLoaded: false,
    contactsAreLoading: false
};

@Store(initialContactsStoreState)
export class ContactsStore {
    @Action(actions.ContactsLoadContacts)
    loadContacts(state: IContactsState, action: actions.ContactsLoadContacts): IContactsState {
        return { ...state, contactsAreLoading: true };
    }

    @Action(actions.ContactsLoadContactsSuccess)
    loadContactsSuccess(
        state: IContactsState,
        action: actions.ContactsLoadContactsSuccess
    ): IContactsState {
        return {
            ...state,
            contactsList: action.payload,
            contactsAreLoaded: true,
            contactsAreLoading: false
        };
    }

    @Action(actions.ContactsLoadRemainingContactsSuccess)
    loadRemainingContactsSuccess(
        state: IContactsState,
        { payload }: actions.ContactsLoadRemainingContactsSuccess
    ): IContactsState {
        return {
            ...state,
            contactsList: [...state.contactsList, ...payload],
            contactsAreLoaded: true,
            contactsAreLoading: false
        };
    }

    @Action(actions.ContactsLoadContactsFail)
    loadContactsFail(
        state: IContactsState,
        action: actions.ContactsLoadContactsFail
    ): IContactsState {
        return {
            ...state,
            contactsAreLoaded: true,
            contactsAreLoading: false
        };
    }

    @Action(actions.ContactsCreateContactSuccess)
    createContactSuccess(
        state: IContactsState,
        action: actions.ContactsCreateContactSuccess
    ): IContactsState {
        return { ...state, contactsList: [...state.contactsList, action.payload] };
    }

    @Action(actions.ContactsUpdateContactSuccess)
    updateContactSuccess(
        state: IContactsState,
        action: actions.ContactsUpdateContactSuccess
    ): IContactsState {
        const contactIndex = state.contactsList.findIndex(item => item.id === action.payload.id);

        return {
            ...state,
            contactsList: [
                ...state.contactsList.slice(0, contactIndex),
                action.payload,
                ...state.contactsList.slice(contactIndex + 1)
            ]
        };
    }

    @Action(actions.ContactsReset)
    reset(state: IContactsState, action: actions.ContactsReset): IContactsState {
        return { ...initialContactsStoreState };
    }
}
