import { Pipe, PipeTransform } from '@angular/core';
import { isNull, size } from 'lodash';

@Pipe({
    name: 'numericSign'
})
export class NumericSignPipe implements PipeTransform {
    transform(value: string): string | number {
        return parseFloat(value) > 0 ? `+${value}` : value;
    }
}
