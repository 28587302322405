import { IBuildingAddress } from '@zenhomes/domain/property';
import { find, curry } from 'lodash';
import { omitNilProps } from '@zenhomes/sauron';

export interface IGooglePlace {
    address_components: { long_name: string; short_name: string; types: string[] }[];
    [key: string]: any;
}

export function googlePlaceToBuildingAddress(place: IGooglePlace): IBuildingAddress {
    const long = getValueForType(place, 'long_name');
    const short = getValueForType(place, 'short_name');

    const address: IBuildingAddress = {
        street: long('route'),
        house: short('street_number'),
        city:
            long('postal_town') ||
            long('locality') ||
            long('administrative_area_level_3') ||
            long('administrative_area_level_2') ||
            long('administrative_area_level_1') ||
            '',
        zip: short('postal_code'),
        countryCode: short('country')
    };

    const result = omitNilProps(address);

    return result;
}

const getValueForType = curry((place: IGooglePlace, fieldType: string, type: string) => {
    const component = find(place.address_components, ({ types }) => types[0] === type);

    const result = component && component[fieldType];

    return result as string;
});
