import { documentCategoriesActionTypes } from '../actions/document-categories.actions';
import { IDocumentCategory } from '@zenhomes/domain/document';

export interface IDocumentCategoriesState {
    documentCategoriesList: IDocumentCategory[];
    documentCategoriesAreLoaded: boolean;
    documentCategoriesAreLoading: boolean;
}

export const initialDocumentCategoriesStoreState: IDocumentCategoriesState = {
    documentCategoriesList: [],
    documentCategoriesAreLoaded: false,
    documentCategoriesAreLoading: false
};

export function documentCategoriesReducer(
    state: IDocumentCategoriesState = initialDocumentCategoriesStoreState,
    action: any
): IDocumentCategoriesState {
    switch (action.type) {
        case documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES: {
            return Object.assign({}, state, {
                documentCategoriesAreLoading: true
            });
        }

        case documentCategoriesActionTypes.DOCUMENTS_LOAD_DOCUMENT_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                documentCategoriesList: action.payload,
                documentCategoriesAreLoaded: true,
                documentCategoriesAreLoading: false
            });
        }

        case documentCategoriesActionTypes.DOCUMENTS_DOCUMENT_CATEGORIES_RESET: {
            return initialDocumentCategoriesStoreState;
        }

        default: {
            return state;
        }
    }
}
