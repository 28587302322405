import { Pipe, PipeTransform } from '@angular/core';
import { ICategorizationUserCategory } from '@zenhomes/domain/category';
import {
    IExpenseContract,
    IExpenseContractPeriod,
    IRentalContract,
    isRentalContract
} from '@zenhomes/domain/contract';
import { IInvoice } from '@zenhomes/domain/invoice';
import { isEmpty } from 'lodash';
import {
    getMostRelevantUserCategory,
    getUserCategories
} from '../utilities/categorization-grouping.util';

const RENTAL_CATEGORY: ICategorizationUserCategory = {
    id: '53',
    title: 'Miete',
    relevance: 1,
    subjects: []
} as ICategorizationUserCategory;

@Pipe({ name: 'mostRelevantUserCategory' })
export class MostRelevantUserCategoryPipe implements PipeTransform {
    transform(
        item: IInvoice | IRentalContract | IExpenseContract | IExpenseContractPeriod
    ): ICategorizationUserCategory {
        if (isEmpty(item)) return null;
        if (isRentalContract(item)) return RENTAL_CATEGORY;

        const userCategories = getUserCategories(item);

        const result = getMostRelevantUserCategory(userCategories);

        return result;
    }
}
