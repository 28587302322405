const HOUR_CONST = 3600; // 60 mins * 60 seconds   -> 3600 is seconds

export const hoursDifference = (currentTime: string, pastTime: string) => {
    const current = new Date(currentTime);
    const reference = new Date(pastTime);
    let diff = (current.getTime() - reference.getTime()) / 1000;
    diff /= HOUR_CONST;

    return Math.abs(Math.round(diff));
};
