import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'zhm-button-toggle',
    templateUrl: './button-toggle.component.html',
    styleUrls: ['./button-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent implements OnInit {
    @Input() labelTrue: string;
    @Input() labelFalse: string;
    @Input() value: boolean;
    @Output() valueChange = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    onClickButton(event: Event, newValue: boolean) {
        this.valueChange.emit(newValue);
    }
}
