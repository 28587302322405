import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'zhm-ui-guidance-icon',
    templateUrl: './guidance-icon.component.html',
    styleUrls: ['./guidance-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuidanceIconComponent implements OnInit {
    @Input() text: string;
    isOpen: boolean = false;

    constructor() {}

    ngOnInit() {}

    toggleOpen(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }
}
