import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { IDocument } from '@zenhomes/domain/document';
import { isImageDocument } from '@zenhomes/domain/document';
import { ConfirmationService } from '@zenhomes/iframe-hustla-ui';
import { ComponentDestroyedMixin } from '@zenhomes/mixins';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'zhm-photos-container-upload-item',
    templateUrl: './photos-container-upload-item.component.html',
    styleUrls: ['./photos-container-upload-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotosContainerUploadItemComponent extends ComponentDestroyedMixin implements OnInit {
    @Input() name: string;
    @Input() progress: number;
    @Input() completed: boolean;
    @Input() url: string;
    @Input() extension: string;
    @Output() cancel = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    closeIconSize: string = '0.56rem';

    constructor(private confirmationService: ConfirmationService) {
        super();
    }

    ngOnInit() {}

    get isImageDocument() {
        return isImageDocument({ extension: this.extension } as IDocument);
    }

    onClickCancel() {
        this.cancel.emit();
    }

    onClickRequestDelete() {
        this.confirmationService
            .confirm({
                confirmButton: 'confirmation.deleteButtonLabel',
                title: 'confirmation.expose.deleteDocument.title',
                body: 'confirmation.expose.deleteDocument.body'
            })
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(confirm => {
                if (confirm) {
                    this.delete.emit();
                }
            });
    }

    onClickDelete() {
        this.delete.emit();
    }
}
