export enum IExpenseContractType {
    ExpenseContract = 'EXPENSE_CONTRACT',
    LoanContract = 'LOAN_CONTRACT'
}

export enum ExpenseContractTermType {
    OpenEnded = 'OPEN_ENDED',
    FixedTerm = 'FIXED_TERM',
    MinimumDuration = 'MINIMUM_DURATION'
}

export enum IExpenseContractDurationUnit {
    Months = 'MONTHS',
    Weeks = 'WEEKS'
}

export enum ExpenseContractPaymentStatus {
    Overdue = 'OVERDUE',
    PartialPaid = 'PARTIAL_PAID',
    Overpaid = 'OVERPAID',
    Paid = 'PAID'
}
