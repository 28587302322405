import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as uuid from 'uuid/v1';

export interface RadioGroupOption {
    id: string;
    label: string;
}

@Component({
    selector: 'radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent {
    @Input() options: RadioGroupOption[];
    @Input() selectedOptionId: string;
    @Output() change: EventEmitter<string> = new EventEmitter();
    @Input() className: string;

    radioGroupId: string = uuid();

    onChange(radioId: string): void {
        if (radioId !== this.selectedOptionId) {
            this.change.emit(radioId);
        }
    }
}
