export interface IUserCategoryIcon {
    id: string;
    name: string;
    color: string;
}

export const USER_CATEGORY_ICON: IUserCategoryIcon[] = [
    {
        id: '1',
        name: 'icon-1-urban-development',
        color: '#025ABB'
    },
    {
        id: '2',
        name: 'icon-2-outdoor-facilities',
        color: '#02796B'
    },
    {
        id: '3',
        name: 'icon-3-building',
        color: '#FFC426'
    },
    {
        id: '4',
        name: 'icon-4-acquisition',
        color: '#025ABB'
    },
    {
        id: '5',
        name: 'icon-5-planing',
        color: '#FFC426'
    },
    {
        id: '6',
        name: 'icon-6-15-28-44-56-other',
        color: '#333333'
    },
    {
        id: '7',
        name: 'icon-7-electrics-lighting',
        color: '#333333'
    },
    {
        id: '8',
        name: 'icon-8-windows-doors',
        color: '#785548'
    },
    {
        id: '10',
        name: 'icon-10-garden',
        color: '#02796B'
    },
    {
        id: '11',
        name: 'icon-11-25-47-heating',
        color: '#FF9B01'
    },
    {
        id: '12',
        name: 'icon-12-kitchen',
        color: '#785548'
    },
    {
        id: '13',
        name: 'icon-13-furniture',
        color: '#785548'
    },
    {
        id: '14',
        name: 'icon-14-plumbing',
        color: '#8A96A0'
    },
    {
        id: '15',
        name: 'icon-6-15-28-44-56-other',
        color: '#333333'
    },
    {
        id: '16',
        name: 'icon-16-31-tv-internet-phone',
        color: '#05DF53'
    },
    {
        id: '17',
        name: 'icon-17-59-laundry-facilities',
        color: '#785548'
    },
    {
        id: '18',
        name: 'icon-18-taxes',
        color: '#F7665C'
    },
    {
        id: '20',
        name: 'icon-20-water',
        color: '#5CA8FF'
    },
    {
        id: '21',
        name: 'icon-21-garden',
        color: '#02796B'
    },
    {
        id: '23',
        name: 'icon-23-ucc',
        color: '#F7665C'
    },
    {
        id: '24',
        name: 'icon-24-janitor',
        color: '#003382'
    },
    {
        id: '25',
        name: 'icon-11-25-47-heating',
        color: '#FF9B01'
    },
    {
        id: '26',
        name: 'icon-26-garbage-collection',
        color: '#8A96A0'
    },
    {
        id: '27',
        name: 'icon-27-cleaning',
        color: '#8A96A0'
    },
    {
        id: '28',
        name: 'icon-6-15-28-44-56-other',
        color: '#4A4A4A'
    },
    {
        id: '29',
        name: 'icon-29-other-insurances',
        color: '#663AB7'
    },
    {
        id: '30',
        name: 'icon-30-electricity',
        color: '#333333'
    },
    {
        id: '31',
        name: 'icon-16-31-tv-internet-phone',
        color: '#05DF53'
    },
    {
        id: '32',
        name: 'icon-32-property-management',
        color: '#003382'
    },
    {
        id: '33',
        name: 'icon-33-property-insurances',
        color: '#663AB7'
    },
    {
        id: '35',
        name: 'icon-35-loans',
        color: '#025ABB'
    },
    {
        id: '37',
        name: 'icon-37-balcony-terrace',
        color: '#785548'
    },
    {
        id: '38',
        name: 'icon-38-61-elevator',
        color: '#785548'
    },
    {
        id: '39',
        name: 'icon-39-floors-windows-doors',
        color: '#785548'
    },
    {
        id: '40',
        name: 'icon-40-parking',
        color: '#785548'
    },
    {
        id: '41',
        name: 'icon-41-plumbing',
        color: '#333333'
    },
    {
        id: '42',
        name: 'icon-42-walls',
        color: '#FFC426'
    },
    {
        id: '43',
        name: 'icon-43-damage',
        color: '#663AB7'
    },
    {
        id: '44',
        name: 'icon-6-15-28-44-56-other',
        color: '#333333'
    },
    {
        id: '45',
        name: 'icon-45-insulation',
        color: '#FF9B01'
    },
    {
        id: '46',
        name: 'icon-46-electrics',
        color: '#333333'
    },
    {
        id: '47',
        name: 'icon-11-25-47-heating',
        color: '#FF9B01'
    },
    {
        id: '48',
        name: 'icon-48-outdoor-facilities',
        color: '#333333'
    },
    {
        id: '49',
        name: 'icon-49-expenses',
        color: '#F7665C'
    },
    {
        id: '50',
        name: 'icon-50-income',
        color: '#00D9B1'
    },
    {
        id: '51',
        name: 'icon-51-legal',
        color: '#D6A773'
    },
    {
        id: '52',
        name: 'icon-52-deposit',
        color: '#FF7AB4'
    },
    {
        id: '53',
        name: 'icon-out_house_basic_rent2',
        color: '#333333'
    },
    {
        id: '54',
        name: 'icon-54-reductions',
        color: '#00D9B1'
    },
    {
        id: '55',
        name: 'icon-55-rental',
        color: '#00D9B1'
    },
    {
        id: '56',
        name: 'icon-6-15-28-44-56-other',
        color: '#4A4A4A'
    },
    {
        id: '57',
        name: 'icon-57-management',
        color: '#D6A773'
    },
    {
        id: '58',
        name: 'icon-58-roof',
        color: '#785548'
    },
    {
        id: '59',
        name: 'icon-17-59-laundry-facilities',
        color: '#785548'
    },
    {
        id: '60',
        name: 'icon-60-Inventory-facilities',
        color: '#8A96A0'
    },
    {
        id: '61',
        name: 'icon-38-61-elevator',
        color: '#785548'
    }
];
