import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isUserWithCompleteAddress, IUser, IUserFeature } from '@zenhomes/domain/user';
import { Observable } from 'rxjs/Observable';
import { filter, first, map, switchMapTo } from 'rxjs/operators';
import * as fromUserState from '../state/user.state';
import * as actions from '../state/actions/user.actions';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user$: Observable<IUser> = this.store.select(fromUserState.getUser);
    userIsLoaded$: Observable<boolean> = this.store.select(fromUserState.getUserIsLoaded);
    userAfterLoading$: Observable<IUser>;
    addressIsCompleted$: Observable<boolean>;

    constructor(private store: Store<any>, private actions$: Actions) {
        this.userAfterLoading$ = this.userIsLoaded$.pipe(
            filter(loaded => loaded),
            switchMapTo(this.user$),
            first()
        );
        this.addressIsCompleted$ = this.user$.pipe(map(user => isUserWithCompleteAddress(user)));
    }

    loadUser() {
        this.store.dispatch(new actions.UserLoadUser());
    }
    addFeatures(features: IUserFeature[]) {
        this.store.dispatch(new actions.UserAddFeatures(features));
    }
}
