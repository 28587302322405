import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { resolvePropertyRouteParams } from '../utilities/property-navigation.util';
import { IBuildingModel } from '@zenhomes/domain/property';
import { PropertiesService } from '../services/properties.service';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BuildingFromStoreResolver implements Resolve<IBuildingModel> {
    constructor(private propertiesService: PropertiesService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<IBuildingModel> {
        const navParams = resolvePropertyRouteParams(route);

        if (!navParams.propertyBuildingId) {
            return of(null);
        }

        return this.propertiesService.allBuildingsDictionary$.pipe(
            map(buildings => buildings[navParams.propertyBuildingId]),
            take(1)
        );
    }
}
