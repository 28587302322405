import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { IPropertySelection } from '@zenhomes/domain/property';
import { expandFromVoid } from '../../animations/expand-from-void.animation';
import {
    IBuildingModel,
    IProperty,
    IUnitModel,
    isApartmentBuilding,
    isCondoBuildingAsNotPM,
    isCondoBuildingPM,
    isSingleBuildingUtil,
    isSingleCondoBuildingAsNotPM
} from '@zenhomes/domain/property';
import { chain, get, includes, isEmpty, isEqual, keyBy, sortBy } from 'lodash';
import {
    getPropertySelectionKey,
    isSamePropertySelection,
    isSameBuildingSelected,
    findPropertySelection
} from '@zenhomes/property-core';

@Component({
    selector: 'zhm-property-building-unit-multi-selector',
    templateUrl: './property-building-unit-multi-selector.component.html',
    styleUrls: ['./property-building-unit-multi-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [expandFromVoid('200ms', '150ms', '0ms', 'ease-in')]
})
export class PropertyBuildingUnitMultiSelectorComponent implements OnInit {
    @Input() showTags: boolean = false;
    @Input() title: string = 'invoices.chooseProperty';

    @Input() isMulti: boolean = true;
    @Input() isBuildingSelectable: boolean = true;
    @Input() disabled: boolean = false;
    @Input() disabledUnitIds: string[];
    @Input() selected: IPropertySelection[];
    @Input() properties: IProperty[];
    @Input() showUsages: boolean = true;
    @Input() showOnlyFirstTenants: boolean = true;
    @Input() usages: any[];
    @Output() selectedChange = new EventEmitter<IPropertySelection[]>();

    expandedBuildings: { [buildingId: string]: boolean } = {};

    constructor() {}

    ngOnInit() {
        this.initExpandedState();
    }

    getFilledPropertySelection(selection: IPropertySelection) {
        return findPropertySelection(
            this.properties,
            get(selection, 'building.id'),
            get(selection, 'unit.id')
        );
    }

    getPropertyItemType(property: IProperty) {
        const building = property.propertyObject;

        switch (true) {
            case isSingleBuildingUtil(building) || isSingleCondoBuildingAsNotPM(building):
                return 'single';
            case isApartmentBuilding(building) || isCondoBuildingPM(building):
                return 'multi';
            case isCondoBuildingAsNotPM(building):
                return 'condo';
        }
    }

    isDisabled(unit?: IUnitModel) {
        return this.disabled || (unit && includes(this.disabledUnitIds, unit.id));
    }

    isBuildingTag(selection: IPropertySelection) {
        switch (true) {
            case !selection.unit:
                return true;
            case isSingleBuildingUtil(selection.building):
                return true;
            default:
                return false;
        }
    }

    createContext(property: IProperty) {
        return { property };
    }

    trackByProperty(index: number, property: IProperty) {
        return property.propertyObject.id;
    }

    trackById(index: number, item: { id: string }) {
        return item.id;
    }

    trackByPropertySelection(index: number, propertySelection: IPropertySelection) {
        return index;
    }

    initExpandedState() {
        this.selected.forEach(propertySelection => {
            this.expandedBuildings[propertySelection.building.id] = true;
        });
    }

    toggleExpanded(buildingId: string) {
        this.expandedBuildings[buildingId] = !this.isExpanded(buildingId);
    }

    isExpanded(buildingId: string): boolean {
        return !!this.expandedBuildings[buildingId];
    }

    isBuildingSelected(building: IBuildingModel) {
        const forBuilding = this.selected.filter(s =>
            isSameBuildingSelected({ building, unit: null }, s)
        );
        return !forBuilding.some(s => !!s.unit) && forBuilding.some(s => !s.unit);
    }

    getNumberOfUnitSelectedForBuilding(buildingId: string) {
        const unitsInBuilding = this.selected.filter(
            s => !isEmpty(s.unit) && buildingId === s.unit.building
        );
        return unitsInBuilding.length;
    }

    isSelected(building: IBuildingModel, unit: IUnitModel) {
        const refSelection: IPropertySelection = { building, unit };
        return this.selected.some(selection => isSamePropertySelection(refSelection, selection));
    }

    orderSelectionByProperties(
        properties: IProperty[],
        selection: IPropertySelection[]
    ): IPropertySelection[] {
        const propertyKeys = chain(properties)
            .map(property => property.propertyObject)
            .flatMap(building => {
                return [
                    this.createPropertyKey(building.id),
                    ...building.units.map(unit => this.createPropertyKey(building.id, unit.id))
                ];
            })
            .value();

        const propertyOrderMap = keyBy(
            propertyKeys.map((propertyKey, index) => ({ propertyKey, index })),
            'propertyKey'
        );

        const orderedSelection = sortBy(
            selection,
            item => propertyOrderMap[getPropertySelectionKey(item)].index
        );

        return orderedSelection;
    }

    private createPropertyKey(buildingId: string, unitId?: string) {
        return unitId ? `${buildingId}-${unitId}` : buildingId;
    }

    private emitNewSelection(newSelection: IPropertySelection[]) {
        const orderedSelection = this.orderSelectionByProperties(this.properties, newSelection);
        this.selectedChange.emit(orderedSelection);
    }

    toggle(building: IBuildingModel, unit: IUnitModel) {
        if (this.isDisabled(unit)) return;

        const isCurrentlySelected = this.isSelected(building, unit);
        const newSelection: IPropertySelection = { building, unit };

        switch (true) {
            case !this.isMulti && !isCurrentlySelected: {
                this.emitNewSelection([newSelection]);
                break;
            }
            case this.isMulti && isCurrentlySelected: {
                const newPropertySelection = this.getNewSelectionOnDeselected(
                    this.selected,
                    newSelection
                );
                this.emitNewSelection(newPropertySelection);
                break;
            }
            case this.isMulti && !isCurrentlySelected: {
                const newPropertySelection = this.getNewSelectionOnSelected(
                    this.selected,
                    newSelection
                );
                this.emitNewSelection(newPropertySelection);
                break;
            }
        }
    }

    removeSelection(selectionToRemove: IPropertySelection) {
        const newSelection = this.selected.filter(
            selection => !isSamePropertySelection(selectionToRemove, selection)
        );
        this.emitNewSelection(newSelection);
    }

    getNewSelectionOnSelected(
        currentSelection: IPropertySelection[],
        selectedItem: IPropertySelection
    ) {
        let newSelection = [...currentSelection, selectedItem];

        if (isEmpty(selectedItem.unit)) {
            newSelection = this.removeSelectedBuildingOfBuilding(
                newSelection,
                selectedItem.building.id
            );

            newSelection = [...newSelection, selectedItem];
        } else {
            newSelection = this.removeSelectedUnitsOfBuilding(
                newSelection,
                selectedItem.building.id
            );
        }

        return newSelection;
    }

    getNewSelectionOnDeselected(
        currentSelection: IPropertySelection[],
        deselectedItem: IPropertySelection
    ) {
        let newSelection = [...currentSelection];

        if (isEmpty(deselectedItem.unit)) {
            const hadUnitsOfBuildingSelected = this.hasUnitsOfBuildingSelected(
                newSelection,
                deselectedItem.building.id
            );

            newSelection = this.removeSelectedBuildingOfBuilding(
                newSelection,
                deselectedItem.building.id
            );

            if (hadUnitsOfBuildingSelected) {
                newSelection = [...newSelection, deselectedItem];
            }
        } else {
            newSelection = this.removeSelectedUnitOfUnit(
                newSelection,
                deselectedItem.building.id,
                deselectedItem.unit.id
            );
        }

        return newSelection;
    }

    private hasUnitsOfBuildingSelected(selection: IPropertySelection[], buildingId: string) {
        return selection.some(p => p.building.id === buildingId && !isEmpty(p.unit));
    }

    private removeSelectedBuildingOfBuilding(selection: IPropertySelection[], buildingId: string) {
        return selection.filter(p => !isEqual(p.building.id, buildingId));
    }

    private removeSelectedUnitsOfBuilding(selection: IPropertySelection[], buildingId: string) {
        return selection.filter(p => {
            const isSameBuilding = isEqual(p.building.id, buildingId);
            return !isSameBuilding || (isSameBuilding && !isEmpty(p.unit));
        });
    }

    private removeSelectedUnitOfUnit(
        selection: IPropertySelection[],
        buildingId: string,
        unitId: string
    ) {
        return selection.filter(p => {
            return !(isEqual(p.building.id, buildingId) && isEqual(p.unit.id, unitId));
        });
    }
}
