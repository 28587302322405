import { Pipe, PipeTransform } from '@angular/core';
import { IPayment } from '@zenhomes/domain/payment';
import { TranslateService } from '@ngx-translate/core';
import { get } from '@zenhomes/sauron';

@Pipe({
    name: 'invoicesNumber'
})
export class InvoicesNumberPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: IPayment): string {
        const numberOfPaymentItems = get(value, 'paymentItems', 'length') || 0;

        return numberOfPaymentItems === 1
            ? this.translateService.instant('paymentModule.singleInvoice')
            : this.translateService.instant('paymentModule.invoicesNumber', {
                  numberOfInvoices: numberOfPaymentItems
              });
    }
}
