import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CountryModuleConfig, CountryConfigToken } from './country.config';

import { CountriesDropdownComponent } from './components/countries-dropdown/countries-dropdown.component';
import { CountryNamePipe } from './pipes/country-name.pipe';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [CountriesDropdownComponent, CountryNamePipe],
    exports: [CountriesDropdownComponent, CountryNamePipe]
})
export class CountryDropdownModule {
    static forRoot(config: CountryModuleConfig): ModuleWithProviders {
        return {
            ngModule: CountryDropdownModule,
            providers: [{ provide: CountryConfigToken, useValue: config }]
        };
    }
}
