import { Pipe, PipeTransform } from '@angular/core';
import { toUtcDate } from '@zenhomes/sauron';
import { isEmpty } from 'lodash';
import * as format from 'date-fns/format';
import { DeviceService } from '@zenhomes/device';

@Pipe({
    name: 'localDateTime'
})
export class LocalDateTime implements PipeTransform {
    constructor(private deviceService: DeviceService) {}
    transform(
        value: string,
        formatStr: string = 'DD.MM.YYYY HH:mm',
        defaultPlaceholder: string = '-'
    ): string {
        if (isEmpty(value)) return defaultPlaceholder;

        const utcDate = this.deviceService.isSafari()
            ? new Date(value)
            : toUtcDate(new Date(value));

        const formattedDate = format(utcDate, formatStr);

        return formattedDate;
    }
}
