import { IBuildingModel, IUnitModel, IProperty } from '@zenhomes/domain/property';
import { IBuildingType, IUnitType } from '@zenhomes/domain/property';

export function getUnitIconClass(type: string) {
    if (type === IUnitType.Apartment) {
        return 'icon-out_condominium';
    } else if (type === IUnitType.CommercialUnit) {
        return 'icon-commercial';
    } else if (type === IUnitType.Garage) {
        return 'icon-out_garage';
    } else {
        return 'icon-other-key';
    }
}

export function getPropertyIconClass(type: string) {
    if (type === IBuildingType.Single) {
        return 'icon-out_house_basic';
    } else if (type === IBuildingType.Multi) {
        return 'icon-out_apartment';
    } else {
        return '';
    }
}

export function getPropertyIcon(
    building: IBuildingModel,
    unit: IUnitModel,
    defaultClass: string = 'icon-properties'
) {
    if (building && unit) {
        return getUnitIconClass(unit.type);
    } else if (building && !unit) {
        return getPropertyIconClass(building.buildingType);
    } else {
        return defaultClass;
    }
}
