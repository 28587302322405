import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IContact } from '@zenhomes/domain/contact';

export class ContactsLoadContacts implements Action {
    static readonly TYPE = '[Contacts] Load contacts';
    readonly type = ContactsLoadContacts.TYPE;
}

export class ContactsLoadContactsSuccess implements Action {
    static readonly TYPE = '[Contacts] Load contacts success';
    readonly type = ContactsLoadContactsSuccess.TYPE;

    constructor(public payload: IContact[]) {}
}

export class ContactsLoadContactsFail implements Action {
    static readonly TYPE = '[Contacts] Load contacts fail';
    readonly type = ContactsLoadContactsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class ContactsLoadRemainingContacts implements Action {
    static readonly TYPE = '[Contacts] Load remaining contacts';
    readonly type = ContactsLoadRemainingContacts.TYPE;

    constructor(public totalPages: number) {}
}

export class ContactsLoadRemainingContactsSuccess implements Action {
    static readonly TYPE = '[Contacts] Load remaining contacts success';
    readonly type = ContactsLoadRemainingContactsSuccess.TYPE;

    constructor(public payload: IContact[]) {}
}

export class ContactsLoadRemainingContactsFail implements Action {
    static readonly TYPE = '[Contacts] Load remaining contacts fail';
    readonly type = ContactsLoadRemainingContactsFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class ContactsCreateContact implements Action {
    static readonly TYPE = '[Contacts] Create contact';
    readonly type = ContactsCreateContact.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsCreateContactSuccess implements Action {
    static readonly TYPE = '[Contacts] Create contact success';
    readonly type = ContactsCreateContactSuccess.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsCreateContactFail implements Action {
    static readonly TYPE = '[Contacts] Create contact fail';
    readonly type = ContactsCreateContactFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class ContactsUpdateContact implements Action {
    static readonly TYPE = '[Contacts] Update contact';
    readonly type = ContactsUpdateContact.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsUpdateContactSuccess implements Action {
    static readonly TYPE = '[Contacts] Update contact success';
    readonly type = ContactsUpdateContactSuccess.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsUpdateContactFail implements Action {
    static readonly TYPE = '[Contacts] Update contact fail';
    readonly type = ContactsUpdateContactFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class ContactsDeleteContact implements Action {
    static readonly TYPE = '[Contacts] Delete contact';
    readonly type = ContactsDeleteContact.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsDeleteContactSuccess implements Action {
    static readonly TYPE = '[Contacts] Delete contact success';
    readonly type = ContactsDeleteContactSuccess.TYPE;

    constructor(public payload: IContact) {}
}

export class ContactsDeleteContactFail implements Action {
    static readonly TYPE = '[Contacts] Delete contact fail';
    readonly type = ContactsDeleteContactFail.TYPE;

    constructor(public payload: HttpErrorResponse) {}
}

export class ContactsReset implements Action {
    static readonly TYPE = '[Contacts] Reset';
    readonly type = ContactsReset.TYPE;
}
