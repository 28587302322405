import { Pipe, PipeTransform } from '@angular/core';
import { IPayment } from '@zenhomes/domain/payment';
import { isEmpty } from 'lodash';

@Pipe({
    name: 'paymentTypeIcon'
})
export class PaymentTypeIconPipe implements PipeTransform {
    constructor() {}

    transform(payment: IPayment): string {
        const hasBankTransfersAttached = !isEmpty(payment.bankTransfer);

        if (isEmpty(payment.type)) return 'icon-out_credit_card_bank';

        switch (payment.type as string) {
            case 'CASH': {
                return 'icon-payment-cash';
            }
            case 'BANK': {
                if (hasBankTransfersAttached) {
                    return 'icon-out_credit_card_bank';
                } else {
                    return 'icon-credit-card';
                }
            }
            case 'BANK_DELETED': {
                return 'icon-credit-card';
            }
            default: {
                '';
            }
        }
    }
}
