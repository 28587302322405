import { Pipe, PipeTransform } from '@angular/core';
import { IUnitModel, getUnitSubTypeLabel } from '@zenhomes/domain/property';
import { get } from '@zenhomes/sauron';

@Pipe({
    name: 'unitSubtypeLabel'
})
export class UnitSubtypeLabelPipe implements PipeTransform {
    transform(unit: IUnitModel): string {
        const unitType = get(unit, 'type');
        return unitType ? getUnitSubTypeLabel(unit) : '';
    }
}
