import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

@Pipe({
    name: 'decimalDigits'
})
export class DecimalDigitsPipe implements PipeTransform {
    transform(value: number, digits: number): string {
        let result = '';
        const dotRegex = /\./g;
        const trailingZeroRegex = /0+$|\,0+$/g;

        if (!isNull(value) && !isUndefined(value)) {
            return value
                .toFixed(digits)
                .replace(dotRegex, ',')
                .replace(trailingZeroRegex, '');
        }

        return result;
    }
}
