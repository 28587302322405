<div class="zhm--messages" *ngIf="messages && messages.length">
    <div
        class="zhm--message"
        *ngFor="let message of messages; trackBy: trackById"
        [ngClass]="{ shown: message.visible, hidden: !message.visible }"
    >
        <div
            class="alert"
            [ngClass]="{
                'alert-success': message.type == 'SUCCESS',
                'alert-info': message.type == 'INFO',
                'alert-warning': message.type == 'WARNING',
                'alert-danger': message.type == 'DANGER'
            }"
        >
            <span>{{ message.translate ? (message.text | translate) : message.text }}</span>
            <i (click)="remove(message)" class="vicon icon-remove-thin"></i>
        </div>
    </div>
</div>
