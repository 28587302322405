import { ICategorizationSubject } from '@zenhomes/domain/category';
import { IExpenseContract, IExpenseContractItem } from '@zenhomes/domain/contract';
import { compose, curry, defaultTo, map, prop } from 'ramda';
import { getLastPeriodItems } from './contract-items.util';

export const getContractSubjects = curry((contract: IExpenseContract): ICategorizationSubject[] => {
    const getItemSubjects: (
        contractItems: IExpenseContractItem[]
    ) => ICategorizationSubject[] = map(prop('subject'));

    return compose(
        getItemSubjects,
        defaultTo([]),
        getLastPeriodItems
    )(contract);
});
