import { NgModule, ErrorHandler } from '@angular/core';
import { datadogRum } from './datadog';
import { UserService } from '../user-core/lib/services/user.service';
import { MonitoringErrorHandler } from './services/monitoring-error-handler.service';
import { environment } from 'environments/environment';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

@NgModule({
    providers: [{ provide: ErrorHandler, useClass: MonitoringErrorHandler }]
})
export class MonitoringModule {
    constructor(private userService: UserService) {
        IFrameHustla.subscribeToMessage(({ type }) => {
            if (type === 'PARENT_CHILD_RUM_CONSENT_GIVEN') {
                console.log('Receive PARENT_CHILD_RUM_CONSENT_GIVEN');
                this.initializeMonitoring();
            }
        });
    }

    async initializeMonitoring() {
        if (!environment.DATADOG_RUM.ENABLED) return;
        this.configureDataDog();
        this.applyUser();
    }

    configureDataDog() {
        datadogRum.init({
            applicationId: '712da21d-4474-4e39-a666-26f317bfc00a',
            clientToken: 'pube019cdda209822fd68ed4c642a86bf27',
            site: 'datadoghq.com',
            service: 'portal-frontend',
            env: environment.DATADOG_RUM.ENVIRONMENT,
            version: '1.0.0',
            traceSampleRate: 100,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask'
        });

        datadogRum.setGlobalContextProperty('team', 'ice-breakers');
        datadogRum.setGlobalContextProperty('component-id', 'portal-frontend');
        datadogRum.startSessionReplayRecording();
    }

    applyUser() {
        this.userService.user$.subscribe(user => {
            if (user && user.customerId) {
                datadogRum.setUser({ id: user.customerId });
            } else {
                datadogRum.clearUser();
            }
        });
    }
}
