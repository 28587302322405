import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IFrameHustla } from '@zenhomes/iframe-hustla-utils';

@Component({
    selector: 'zhm-show-fullscreen-iframe',
    templateUrl: './show-fullscreen-iframe.component.html',
    styleUrls: ['./show-fullscreen-iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowFullscreenIframeComponent implements OnInit, OnDestroy {
    @Input() dialogName: string;

    ngOnInit() {
        IFrameHustla.sendMessage({
            type: 'CHILD_DIALOG_OPENED',
            dialogName: this.dialogName.toLowerCase()
        });
    }

    ngOnDestroy() {
        IFrameHustla.sendMessage({
            type: 'CHILD_DIALOG_CLOSED',
            dialogName: this.dialogName.toLowerCase()
        });
    }
}
