<div dropdown class="dropdown" [isDisabled]="disabled">
    <div dropdownToggle class="form-control label" [class.disabled]="disabled">
        <span>{{
            model ? (model.address | buildingAddress) : (emptyPlaceholder | translate)
        }}</span>
        <i class="icon_navigation icon-sol_caret-down pull-right" aria-hidden="true"></i>
    </div>

    <ul *dropdownMenu class="dropdown-menu">
        <li (click)="select(null)">
            <span class="dropdown-item" [ngClass]="{ active: !model }">{{
                emptyPlaceholder | translate
            }}</span>
        </li>

        <li *ngFor="let building of buildings" (click)="select(building)">
            <span class="dropdown-item" [ngClass]="{ active: model?.id === building.id }">{{
                building.address | buildingAddress
            }}</span>
        </li>
    </ul>
</div>
